import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { filenameToFontAwesomeIcon } from '../../../../../../js/utils/io/file';
import { AssetDownloadManager } from '../../../../../../js/viewer/elements/asset-download-manager';
import { Dxf } from '../../../../../../js/viewer/elements/dxf';
import { Glb } from '../../../../../../js/viewer/elements/glb';
import { useDialog } from '../../../../../hooks/use-dialog';
import { useSharedView } from '../../../../../hooks/use-shared-view';
import { Icon } from '../../../../icon/icon';
import { MoveIcon } from '../../../../skyview/right-menu/components/move-icon';
import { useSubscribeShowDeleteAssetDialog } from '../../../hooks/subscribe-show-delete-asset-dialog';
import { AssetHeader } from '../../components/asset-blocks/asset-header';
import { DeleteAssetDialog } from '../../dialogs/delete-asset-dialog';
import { DeleteIcon } from '../../icons/delete-icon';
import { DownloadIcon } from '../../icons/download-icon';

type Props = {
  asset: Dxf | Glb;
};

const CadHeader = (props: Props) => {
  const { t } = useTranslation();
  const [isDownloading, setIsDownloading] = React.useState(false);
  const { sharedViewId, isSharedView } = useSharedView();
  const deleteDialog = useDialog();
  useSubscribeShowDeleteAssetDialog(props.asset, deleteDialog, isSharedView);

  const match = /(.+?) - version #(\d+)$/i;
  const name = props.asset.name.match(match)![1];
  const version = parseInt(props.asset.name.match(match)![2]);

  const deleteIcon = (
    <DeleteIcon
      key="deleteIcon"
      onClick={(e) => deleteDialog.show({ x: e.clientX, y: e.clientY })}
    />
  );

  const downloadIcon =
    props.asset instanceof Dxf ? (
      <DownloadIcon
        key="downloadIcon"
        loading={isDownloading}
        title={
          isDownloading
            ? t('oneMoment', { ns: 'common' })
            : t('cad.icons.download', { ns: 'skyviewAssetMenu' })
        }
        onClick={async () => {
          // Error handling is included in downloadDxfAsset.
          // When AngularJS is removed, move error handling here.
          try {
            setIsDownloading(true);
            await new AssetDownloadManager().downloadDxfAsset(
              props.asset as Dxf,
              undefined,
              undefined,
              sharedViewId,
            );
          } finally {
            setIsDownloading(false);
          }
        }}
      />
    ) : (
      <></>
    );

  const ifcWarningIcon =
    props.asset instanceof Glb && props.asset.getFileType() === 'Ifc' ? (
      <Icon
        disabled={true}
        icon="exclamation-triangle"
        title={t('cad.icons.ifcWarning', { ns: 'skyviewAssetMenu' })}
      />
    ) : (
      <></>
    );

  const moveIcon =
    props.asset instanceof Glb && props.asset.canBeTransformed() ? (
      <MoveIcon asset={props.asset} key="moveIcon" />
    ) : (
      <></>
    );

  const { icon, color } = filenameToFontAwesomeIcon(name);

  return (
    <>
      <AssetHeader
        additionalIcons={isSharedView ? undefined : [moveIcon, deleteIcon]}
        asset={props.asset}
        icons={[ifcWarningIcon, downloadIcon]}
        name={name}
        namePrefix={
          <IconStack>
            <Icon color={color} fixedWidth={true} icon={['fad', icon]} />
            <VersionBadge>v{version}</VersionBadge>
          </IconStack>
        }
      />

      {deleteDialog.render((pos) => (
        <DeleteAssetDialog asset={props.asset} pos={pos} onClose={deleteDialog.hide} />
      ))}
    </>
  );
};

const IconStack = styled.div`
  position: relative;
`;

const VersionBadge = styled.div`
  position: absolute;
  left: -25%;
  top: -30%;
  font-size: 0.55em;
  background-color: #fffa;
  padding: 0.2em;
  border-radius: 0.5em;
  line-height: 1em;
  border: 1px solid #ddd;
  font-feature-settings: 'tnum';
`;

export { CadHeader, Props as CadHeaderProps };
