import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FeatureFlagsManager } from '../../../../js/core/feature-flags-manager';
//@ts-ignore
import { CompanyStore } from '../../../../js/stores/company.store';
import { UserStore } from '../../../../js/stores/user-store';
import { permissionChecks } from '../../../../js/utils/permission-check';
import { isDefined } from '../../../../js/utils/variables';
import { useRouter } from '../../../hooks/use-router';
import { reset } from '../../../utils/styled-reset';
import { LabelledContainer } from '../../labelled-container/labelled-container';
import { Card } from '../../project/overview/card';
import { Stack } from '../../stack/stack';

export const CompanyOverview = () => {
  const { t } = useTranslation();
  const { redirectToAngularState } = useRouter();
  const company = useRef(CompanyStore.instance.company);
  const showLicensesCard =
    FeatureFlagsManager.instance.isEnabled('SK-5667-licenses-page') ||
    UserStore.instance.isOrganizationAdmin();

  const invoiceEmail = company.current.licenses
    .map((x) => x.invoiceEmail)
    .filter(isDefined)
    .join(', ');

  const invoiceTag = company.current.licenses
    .map((x) => x.invoiceTag)
    .filter(isDefined)
    .join(', ');

  const invoiceReference = company.current.licenses
    .map((x) => x.invoiceReference)
    .filter(isDefined)
    .join(', ');

  return (
    <Component>
      <Stack spacing={1}>
        <Title>
          {t('overview.title', { ns: 'companies' })} - {company.current.name}
        </Title>

        {isDefined(company.current.orgNumber) && (
          <LabelledContainer text={t('overview.orgNumber', { ns: 'companies' })}>
            <Span>{company.current.orgNumber}</Span>
          </LabelledContainer>
        )}

        {isDefined(company.current.vatNumber) && (
          <LabelledContainer text={t('overview.vatNumber', { ns: 'companies' })}>
            <Span>{company.current.vatNumber}</Span>
          </LabelledContainer>
        )}

        {isDefined(company.current.address) && (
          <LabelledContainer text={t('overview.address', { ns: 'companies' })}>
            <Span>{company.current.address}</Span>
          </LabelledContainer>
        )}

        {isDefined(company.current.city) && (
          <LabelledContainer text={t('overview.city', { ns: 'companies' })}>
            <Span>{company.current.city}</Span>
          </LabelledContainer>
        )}

        {isDefined(company.current.zipCode) && (
          <LabelledContainer text={t('overview.zipCode', { ns: 'companies' })}>
            <Span>{company.current.zipCode}</Span>
          </LabelledContainer>
        )}

        {isDefined(company.current.country) && (
          <LabelledContainer text={t('overview.country', { ns: 'companies' })}>
            <Span>{company.current.country}</Span>
          </LabelledContainer>
        )}

        {isDefined(invoiceEmail) && invoiceEmail.trim().length > 0 && (
          <LabelledContainer text={t('overview.invoiceEmail', { ns: 'companies' })}>
            <Span>{invoiceEmail}</Span>
          </LabelledContainer>
        )}

        {isDefined(invoiceReference) && invoiceReference.trim().length > 0 && (
          <LabelledContainer text={t('overview.invoiceReference', { ns: 'companies' })}>
            <Span>{invoiceReference}</Span>
          </LabelledContainer>
        )}

        {isDefined(invoiceTag) && invoiceTag.trim().length > 0 && (
          <LabelledContainer text={t('overview.invoiceTag', { ns: 'companies' })}>
            <Span>{invoiceTag}</Span>
          </LabelledContainer>
        )}

        <Stack direction="row" spacing={0.5}>
          {showLicensesCard &&
            permissionChecks.hasAccessToView(
              'sky.companies.list.company.licenses',
              'view:company_licenses',
            ) && (
              <Card
                big={true}
                disableGoogleTranslate={true}
                icon={'id-card'}
                title={t('columns.licenses', { ns: 'companies' })}
                onClick={() =>
                  redirectToAngularState('sky.companies.list.company.licenses', {
                    companyId: company.current.id,
                  })
                }
              />
            )}
          {permissionChecks.hasAccessToView(
            'sky.companies.list.company.admins',
            'view:company_admins',
          ) && (
            <Card
              big={true}
              disableGoogleTranslate={true}
              icon={'user'}
              title={t('companyAdmins.title', { ns: 'companies' })}
              onClick={() =>
                redirectToAngularState('sky.companies.list.company.admins', {
                  companyId: company.current.id,
                })
              }
            />
          )}
        </Stack>
      </Stack>
    </Component>
  );
};

const Component = styled.div`
  ${reset}
`;

// Styling is made to match the other pages in angular.
const Title = styled.h1`
  font-size: 19px;
  font-weight: bold;
  text-transform: uppercase;
`;

const Span = styled.label`
  font-weight: 500;
`;

CompanyOverview.propTypes = {
  wrapWithLanguageProvider: PropTypes.any,
};
