import React from 'react';
import styled, { css } from 'styled-components';

import { publish, SubscriptionTopic } from '../../../../js/messaging/pubsub';
import { CameraOrientationController } from '../../../../js/viewer/cameras/camera-orientation-controller';
import { DesignTool } from '../../../../js/viewer/tools/design-tool';
import { OnDemandTool } from '../../../../js/viewer/tools/on-demand-tool';
import { ReferencePointTransformTool } from '../../../../js/viewer/tools/reference-point-transform-tool';
import { ReportTool } from '../../../../js/viewer/tools/report-tool';
import { VolumeCalculatorTool } from '../../../../js/viewer/tools/volume-calculator-tool';
import { VolumeInspectorTool } from '../../../../js/viewer/tools/volume-inspector-tool';
import { WmsTool } from '../../../../js/viewer/tools/wms-tool';
import { reset } from '../../../utils/styled-reset';
import { LanguageProvider } from '../../language-provider/language-provider';
import { CutFillMap } from '../../skyview-new/components/cut-fill-map/cut-fill-map';
import { OnDemandMenu } from '../../skyview-new/components/on-demand/on-demand-menu';
import { ReportToolMenu } from '../../skyview-new/components/report-tool-menu/report-tool-menu';
import { VolumeCalculationTool } from '../../skyview-new/components/volume-calculation-tool/volume-calculation-tool';
import { WmsToolMenu } from '../../skyview-new/components/wms-tool-menu';
import { SkyviewContext } from '../skyview-state';
import { useIntercomWidgetPositioner } from './hooks/use-intercom-widget-positioner';
import { DesignToolMenu } from './tools/design-tool-menu/design-tool-menu';
import { ReferencePointTransform } from './tools/reference-point-transform/reference-point-transform';

export const rightMenuWidth = 300;
export const rightMenuPadding = 0.4;

type Props = {
  className?: string;
};

const RightMenuAutoExpandContainer = (props: Props) => {
  const { activeTool } = React.useContext(SkyviewContext);
  const ref = useIntercomWidgetPositioner<HTMLDivElement>();

  const getContent = () => {
    if (activeTool instanceof DesignTool) {
      return <DesignToolMenu tool={activeTool} />;
    }

    if (activeTool instanceof ReferencePointTransformTool) {
      return <ReferencePointTransform tool={activeTool} />;
    }

    if (activeTool instanceof VolumeInspectorTool) {
      // todo: Jest mock fails (cut-fill-dialog.test.tsx) having an import of CutFillMap in this file.
      return <CutFillMap />;
    }

    if (activeTool instanceof VolumeCalculatorTool) {
      return <VolumeCalculationTool />;
    }

    if (activeTool instanceof ReportTool) {
      return <ReportToolMenu />;
    }

    if (activeTool instanceof OnDemandTool) {
      return <OnDemandMenu />;
    }

    if (activeTool instanceof WmsTool) {
      return <WmsToolMenu wmsTool={activeTool} />;
    }

    return undefined;
  };

  const content = getContent();
  const expanded = content !== undefined;

  CameraOrientationController.instance().setRightMenuExpanded(expanded);
  publish(SubscriptionTopic.RightMenuToggled, expanded);

  return (
    <Component className={props.className} data-expanded={expanded} ref={ref}>
      <Content data-expanded={expanded}>
        <LanguageProvider>{content}</LanguageProvider>
      </Content>
    </Component>
  );
};

const Component = styled.div.attrs<{ 'data-expanded': boolean }>((props) => ({
  style: {
    width: props['data-expanded'] ? `${rightMenuWidth}px` : '0px',
  },
}))`
  ${reset}

  position: relative;
  overflow-y: auto;
  font-size: 14px;

  height: 100%;
  background-color: white;
  transition: width 0.5s ease-in-out;
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);

  // todo: Bad UX - remove and let user select text. Apply this where necessary.
  user-select: none;
`;

const Content = styled.div<{ 'data-expanded': boolean }>`
  position: absolute;
  right: 0;
  width: ${rightMenuWidth}px;
  height: 100%;
  overflow-x: hidden;

  > * {
    transition: 0s visibility;
    visibility: hidden;
    height: 100%;
    width: 100%;

    ${(props) =>
      props['data-expanded'] &&
      css`
        visibility: visible;
        transition-delay: 0.1s;
      `}
  }
`;

export { RightMenuAutoExpandContainer };
