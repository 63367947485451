import axios from 'axios';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  FileModel,
  FolderModel,
  GetFilesResponse,
  GetFoldersResponse,
} from '../../../../../typings/api/skymap/rest/v1/.common';
import ImageEmptyFolder from '../../../../../www/static/empty_folder.svg';
import ImageErrorFolder from '../../../../../www/static/error_folder.svg';
import { SkyMapAxiosServiceFactory } from '../../../../js/services/axios/skymap-axios-service-factory';
import { useNonce } from '../../../hooks/use-nonce';
import { AppContext } from '../../../state/app-state';
import { ProjectContext } from '../../../state/project-state';
import { Center } from '../../../styles/center';
import { reset } from '../../../utils/styled-reset';
import { Button } from '../../button/button';
import { Icon } from '../../icon/icon';
import { useCheckCadStatus } from '../hooks/use-check-cad-status';
import { FolderListViewContext } from '../state/folder-list-view-state';
import { TreeContext } from '../state/folder-tree-state';
import { FolderListViewTable } from './folder-list-view-table';

enum Status {
  Idle,
  Loading,
  LoadingError,
}

const FolderListView = () => {
  const { t } = useTranslation();
  const { hasAdminRights } = React.useContext(AppContext);
  const { project, sharedFolderId } = React.useContext(ProjectContext);
  const {
    setCheckedItems,
    setSelectedFile,
    setActionBarEnabled,
    setCurrentSubFolders,
    setCurrentFolderFiles,
    setCurrentFolderId,
    refreshNonce: refreshFolderListViewNonce,
  } = React.useContext(FolderListViewContext);
  const { refreshNonce: refreshTreeNonce, selectedFolderId } = React.useContext(TreeContext);

  const [files, setFiles] = React.useState<FileModel[]>([]);
  const [folders, setFolders] = React.useState<FolderModel[]>([]);
  const [status, setStatus] = React.useState(Status.Idle);
  const [tryAgainNonce, setTryAgainNonce] = useNonce();

  useCheckCadStatus(files, (fileVersionsChanged) => {
    // Update file version CAD processing status.
    setFiles(
      files.map((file) => {
        const cadStatus = fileVersionsChanged.find((x) => x.fileId === file.id);
        return file.id === cadStatus?.fileId
          ? {
              ...file,
              versions: file.versions.map((version) =>
                version.id === cadStatus.dxf?.fileVersionId
                  ? {
                      ...version,
                      dxf: cadStatus.dxf,
                    }
                  : version.id === cadStatus.glb?.fileVersionId
                    ? {
                        ...version,
                        glb: cadStatus.glb,
                      }
                    : version,
              ),
            }
          : file;
      }),
    );
  });

  // Clear checked items and hide file info panel when folder is changed.
  React.useEffect(() => {
    setCheckedItems([], []);
    setSelectedFile(undefined);
    setCurrentFolderId(selectedFolderId);
  }, [selectedFolderId, setCheckedItems, setSelectedFile, setCurrentFolderId]);

  React.useEffect(() => {
    setActionBarEnabled(status !== Status.Loading);
  }, [setActionBarEnabled, status]);

  React.useEffect(() => {
    setCurrentFolderFiles(files);
  }, [files, setCurrentFolderFiles]);

  React.useEffect(() => {
    setCurrentSubFolders(folders);
  }, [folders, setCurrentSubFolders]);

  React.useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    const fetch = async () => {
      try {
        setStatus(Status.Loading);

        let folders!: GetFoldersResponse;
        let files!: GetFilesResponse;

        if (sharedFolderId && selectedFolderId) {
          [folders, files] = await Promise.all([
            SkyMapAxiosServiceFactory.instance.createSharedFolderServiceV1().getSubFolders({
              path: {
                sharedFolderId: sharedFolderId,
                folderId: selectedFolderId,
              },
              query: {},
            }),
            SkyMapAxiosServiceFactory.instance.createSharedFolderServiceV1().getFiles({
              path: {
                sharedFolderId: sharedFolderId,
                folderId: selectedFolderId,
              },
              query: {},
            }),
          ]);
        } else if (sharedFolderId) {
          files = { data: [] };
          [folders] = await Promise.all([
            SkyMapAxiosServiceFactory.instance.createSharedFolderServiceV1().getRootFolders({
              path: {
                sharedFolderId: sharedFolderId,
              },
              query: { includeSubFolders: false },
            }),
          ]);
        } else if (selectedFolderId) {
          [folders, files] = await Promise.all([
            SkyMapAxiosServiceFactory.instance.createFolderServiceV1().getSubFolders(
              {
                path: {
                  folderId: selectedFolderId,
                },
                query: {
                  include: ['folder.sharedFolder'],
                },
              },
              cancelTokenSource.token,
            ),
            SkyMapAxiosServiceFactory.instance.createFolderServiceV1().getSubFolderFiles(
              {
                path: {
                  folderId: selectedFolderId,
                },
                query: {
                  include: [
                    'file.dynamicAssets',
                    'file.version.createdBy',
                    'file.version.dxf',
                    'file.version.glb',
                  ],
                },
              },
              cancelTokenSource.token,
            ),
          ]);
        } else {
          [folders, files] = await Promise.all([
            SkyMapAxiosServiceFactory.instance.createProjectServiceV1().getRootFolders(
              {
                path: {
                  projectId: project.id,
                },
                query: {
                  includeSubFolders: false,
                  include: ['folder.sharedFolder', 'folder.tags', 'folder.skyMapConnectFolder'],
                },
              },
              cancelTokenSource.token,
            ),
            SkyMapAxiosServiceFactory.instance.createProjectServiceV1().getRootFolderFiles(
              {
                path: {
                  projectId: project.id,
                },
                query: {
                  include: [
                    'file.dynamicAssets',
                    'file.version.createdBy',
                    'file.version.dxf',
                    'file.version.glb',
                  ],
                },
              },
              cancelTokenSource.token,
            ),
          ]);
        }

        setFiles(files.data);
        setFolders(folders.data);

        setStatus(Status.Idle);
      } catch (err) {
        if (!axios.isCancel(err)) {
          setStatus(Status.LoadingError);
        }

        // Clear folders if requests fail, instead of before the request is sent.
        // This in order to keep the selected files in folder list view state. This list of
        // selected files is used in INIT.
        setFolders([]);
        setFiles([]);
      }
    };

    void fetch();

    return () => {
      cancelTokenSource.cancel();
    };
  }, [
    project,
    selectedFolderId,
    refreshTreeNonce,
    refreshFolderListViewNonce,
    tryAgainNonce,
    sharedFolderId,
  ]);

  const getContent = () => {
    switch (status) {
      case Status.Idle:
        return getIdleContent();

      case Status.Loading:
        return getLoadingContent();

      case Status.LoadingError:
        return getErrorContent();
    }
  };

  const getIdleContent = () => {
    if (folders.length + files.length === 0) {
      return (
        <FolderEmpty>
          <ImageEmptyFolder />
          <h3>{t('fileManager.folderListView.emptyFolder.title', { ns: 'components' })}</h3>
          {
            // This should be relate to folder tags? If the user has write access in the folder,
            // even when not admin, it should be to upload files and create folders.
          }
          {!sharedFolderId && hasAdminRights() && (
            <span>
              <Trans
                components={{ bold: <strong /> }}
                i18nKey="fileManager.folderListView.emptyFolder.text"
                ns="components"
              />
            </span>
          )}
        </FolderEmpty>
      );
    }

    return (
      <DndProvider backend={HTML5Backend}>
        <FolderListViewTable files={files} folders={folders} />
      </DndProvider>
    );
  };

  const getLoadingContent = () => {
    return (
      <Center>
        <Icon icon="spinner" size="2x" spin={true} />
      </Center>
    );
  };

  const getErrorContent = () => {
    return (
      <ErrorFolder>
        <ImageErrorFolder />
        <h3>{t('fileManager.folderListView.unexpectedError.title', { ns: 'components' })}</h3>
        <span>{t('fileManager.folderListView.unexpectedError.text', { ns: 'components' })}</span>
        <Button color="primary" variant="contained" onClick={setTryAgainNonce}>
          {t('retry', { ns: 'common' })}
        </Button>
      </ErrorFolder>
    );
  };

  return <Component>{getContent()}</Component>;
};

const Component = styled.div`
  ${reset}
  font-size: 14px;

  margin-top: 1em;
`;

const FolderEmpty = styled(Center)`
  img {
    height: 150px;
    margin-bottom: 30;
  }

  h3 {
    font-size: 1.5em;
    margin: 0.8em 0;
    font-weight: 200;
  }

  span {
    width: 350px;
    text-align: center;
    font-weight: 300;
    line-height: 1.5em;

    strong {
      font-weight: 500;
    }
  }
`;

const ErrorFolder = styled(FolderEmpty)`
  button {
    margin-top: 0.8em;
  }
`;

FolderListView.styled = Component;

export { FolderListView };
