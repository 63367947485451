import React from 'react';
import styled from 'styled-components';

import { isDefined } from '../../../js/utils/variables';
import { ToTransientProps } from '../../utils/styled-helpers';

interface Props {
  id?: string;
  defaultChecked?: boolean;
  /**
   * Used for controlled handling.
   */
  checked?: boolean;
  disabled?: boolean;
  onChange?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  /**
   * Set to a value > 0.
   * 1 equals 100% of default size (adapted to current font size).
   * @default 1
   */
  size?: number;
}

const Switch = ({ disabled = false, size = 1, ...props }: Props) => {
  const [checked, setChecked] = React.useState(props.checked ?? props.defaultChecked);

  return (
    <Component $size={size} aria-checked={checked} aria-disabled={disabled} role="switch">
      <input
        checked={props.checked}
        defaultChecked={props.checked ?? props.defaultChecked}
        disabled={disabled}
        id={props.id}
        readOnly={true}
        type="checkbox"
        onClick={(e) => {
          props.onChange?.(e);

          if (!isDefined(props.checked) && !e.defaultPrevented) {
            setChecked((x) => !x);
          }
        }}
      />
    </Component>
  );
};

type ComponentProps = ToTransientProps<Required<Pick<Props, 'size'>>>;

const Component = styled.label<ComponentProps>`
  --size: ${(props) => props.$size};
  --spacing: calc(var(--size) * 0.15em);
  --track-height: calc(var(--size) * 1.6em);
  --track-width: calc(var(--size) * 3.5em);
  --thumb-size: calc(var(--size) * var(--track-height) * (1 - var(--spacing) * 2));
  --thumb-size: calc(var(--track-height) - 2 * var(--spacing));
  --thumb-transition: left 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  --background-transition: background-color 0.3s ease-in-out;

  position: relative;
  background-color: #ccc;
  display: inline-block;
  height: var(--track-height);
  width: var(--track-width);
  border-radius: var(--track-height); // 50% radius.
  transition: var(--background-transition);

  &:has(input:disabled) {
    opacity: 0.5;
  }

  &:not(:has(input:disabled)) {
    cursor: pointer;

    // Thumb shadow.
    &:hover::before {
      box-shadow: 0 0 0 calc(var(--thumb-size) / 2) rgb(0 0 0 / 5%);
    }
  }

  // Hide checkbox.
  input {
    display: none;
  }

  // Thumb
  &:hover::before,
  &::after {
    content: '';
    position: absolute;
    width: var(--thumb-size);
    height: var(--thumb-size);
    top: calc(calc(var(--track-height) - var(--thumb-size)) / 2);
    left: var(--spacing);
    display: block;
    border-radius: 50%;
    background-color: #ffff;
    transition: var(--thumb-transition);
  }

  // Thumb shadow.
  &::after {
    box-shadow: 0 0 0.1em 0 rgb(0 0 0 / 30%);
  }

  // Checked state.
  &:has(input:checked) {
    background-color: ${(props) => props.theme.color.brand.dark};
    transition: var(--background-transition);

    &::after,
    &::before {
      left: calc(var(--track-width) - calc(var(--thumb-size)) - var(--spacing));
    }
  }
`;

export { Switch, Props as SwitchProps };
