import React from 'react';
import styled, { css } from 'styled-components';

//@ts-ignore
import { angularGlobals } from '../../../../../www/js/core/config';
import { ProjectStore } from '../../../../js/stores/project-store';
import { UserStore } from '../../../../js/stores/user-store';
import { permissionChecks } from '../../../../js/utils/permission-check';
import { isDefined } from '../../../../js/utils/variables';
import { useRouter } from '../../../hooks/use-router';
import { reset } from '../../../utils/styled-reset';
import { Card } from './card';

const Overview = () => {
  const { redirectToAngularState } = useRouter();
  const hasElevatedAccess = () => {
    return (
      UserStore.instance.isActiveRoleProjectUser() === false &&
      (UserStore.instance.isOrganizationAdmin() || UserStore.instance.isCompanyAdmin())
    );
  };

  const project = ProjectStore.instance.project!;

  return (
    <Component hasDescription={isDefined(project.description)}>
      <h3 className="notranslate">{project.name}</h3>

      {isDefined(project.description) && project.description?.trim() !== '' && (
        <span className="notranslate">{project.description}</span>
      )}

      <section>
        {permissionChecks.hasAccessToView('sky.viewer', 'view:project_skyview') && (
          <Card
            big={true}
            disableGoogleTranslate={true}
            icon={'globe'}
            title="SkyView"
            onClick={() => redirectToAngularState('sky.viewer', { projectId: project.id })}
          />
        )}

        {permissionChecks.hasAccessToView(
          'sky.project.details.geodata',
          'view:project_geodata_list',
          [hasElevatedAccess],
        ) && (
          <Card
            big={true}
            icon={'cloud'}
            title="Molnprocesseringar"
            onClick={() =>
              redirectToAngularState('sky.project.details.geodata', { projectId: project.id })
            }
          />
        )}
      </section>

      <section>
        {permissionChecks.hasAccessToView(
          'sky.project.details.filemanager',
          'view:project_file_manager',
        ) && (
          <Card
            icon={'cabinet-filing'}
            title="Filhanterare"
            onClick={() =>
              angularGlobals
                .getState()
                .go('sky.project.details.filemanager', { projectId: project.id })
            }
          />
        )}

        {permissionChecks.hasAccessToView(
          'sky.project.details.timeline',
          'view:project_timelines',
          [hasElevatedAccess],
        ) && (
          <Card
            icon={'clock'}
            title="Tidslinje"
            onClick={() =>
              angularGlobals
                .getState()
                .go('sky.project.details.timeline', { projectId: project.id })
            }
          />
        )}

        {permissionChecks.hasAccessToView(
          'sky.project.details.projectusers',
          'view:project_administration_users',
          [hasElevatedAccess],
        ) && (
          <Card
            icon={'user'}
            title="Projektdeltagare"
            onClick={() =>
              angularGlobals
                .getState()
                .go('sky.project.details.projectusers', { projectId: project.id })
            }
          />
        )}

        {permissionChecks.hasAccessToView('sky.project.details.userlist', 'view:project_users', [
          () => !hasElevatedAccess(),
        ]) && (
          <Card
            icon={'user'}
            title="Projektdeltagare"
            onClick={() =>
              angularGlobals
                .getState()
                .go('sky.project.details.userlist', { projectId: project.id })
            }
          />
        )}

        {permissionChecks.hasAccessToView('sky.project.details.email', 'view:project_email') && (
          <Card
            icon={'envelope'}
            title="Mailutskick"
            onClick={() =>
              redirectToAngularState('sky.project.details.email', { projectId: project.id })
            }
          />
        )}

        {permissionChecks.hasAccessToView(
          'sky.project.details.integrations',
          'view:project_connect',
          [hasElevatedAccess],
        ) && (
          <Card
            disableGoogleTranslate={true}
            icon={'puzzle-piece'}
            title="Connect"
            onClick={() =>
              angularGlobals
                .getState()
                .go('sky.project.details.integrations', { projectId: project.id })
            }
          />
        )}

        {permissionChecks.hasAccessToView('sky.project.details.settings', 'view:project_settings', [
          hasElevatedAccess,
        ]) && (
          <Card
            icon={'cog'}
            title="Inställningar"
            onClick={() =>
              angularGlobals
                .getState()
                .go('sky.project.details.settings', { projectId: project.id })
            }
          />
        )}
      </section>
    </Component>
  );
};

const Component = styled.div<{ hasDescription: boolean }>`
  ${reset}

  > h3 {
    margin-bottom: 1em;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 1.2em;
    font-weight: bold;
    color: ${(props) => props.theme.color.gray.darkest};
  }

  > span {
    font-size: 0.9em;
    font-weight: 500;

    ${(props) =>
      props.hasDescription &&
      css`
        font-style: italic;
      `}
  }

  section {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    margin-top: 2em;
  }
`;

export { Overview };
