import React from 'react';
import styled from 'styled-components';

import { reset } from '../../utils/styled-reset';

export interface Props {
  children: React.ReactNode;
}

const TableBody = (props: Props) => {
  return <Component>{props.children}</Component>;
};

const Component = styled.tbody`
  ${reset}

  // Currently a work-around to have React Dnd drag preview position correctly when drag is started
  // and container is scrolled.
  //
  // TODO: Check if DragSourceMonitor offset functions can be used to position the preview without
  // TODO: relying on this CSS line.
  // TODO: https://react-dnd.github.io/react-dnd/docs/api/drag-source-monitor
  position: relative;
`;

export { TableBody };
