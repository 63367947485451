import type * as CSS from 'csstype';
import React from 'react';
import styled from 'styled-components';

import { reset } from '../../utils/styled-reset';

interface Props {
  min: number;
  max: number;
  maxPercentage?: number;
  value: number;
  text?: string;
  /**
   * @default 1.5em
   */
  height?: CSS.Properties['height'];
  /**
   * @default 100%
   */
  width?: CSS.Properties['width'];
  /**
   * @default white
   */
  backgroundColor?: CSS.Properties['backgroundColor'];
}

const ProgressBar = ({
  width = '100%',
  height = '1.4em',
  backgroundColor = '#FFF',
  ...props
}: Props) => {
  const calcPercentage = () => {
    // Invalid range min/max?
    if (props.max < props.min) {
      return 0;
    }

    // Specified value less than minimum value?
    if (props.value < props.min) {
      return 0;
    }

    // Specified value larger than maximum value?
    if (props.value > props.max) {
      return 100;
    }

    // Use floor() instead of round() to make sure percent it set to 100 only when
    // bytes downloaded equals total bytes.
    const positiveRange = props.max - props.min;
    const positiveValue = props.value - props.min;

    return Math.floor((100 * positiveValue) / positiveRange);
  };

  const percentage = calcPercentage();
  const displayPercentage = Math.min(props.maxPercentage ?? 100, percentage);

  return (
    <Component
      backgroundColor={backgroundColor}
      height={height}
      percent={percentage}
      role="progressbar"
      width={width}
    >
      <ProgressText>{props.text ?? `${displayPercentage.toFixed(0)} %`}</ProgressText>
    </Component>
  );
};

type ComponentProps = Pick<Props, 'height' | 'width' | 'backgroundColor'> & {
  percent: number;
};

const Component = styled.div<ComponentProps>`
  ${reset}

  position: relative;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: 0.3em;
  background-color: ${(props) => props.backgroundColor};
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
  overflow: hidden;
  isolation: isolate;

  &:after {
    content: '';
    position: absolute;
    width: ${(props) => `${props.percent}%`};
    top: 0;
    left: 0;
    height: 100%;
    background-color: ${(props) => props.theme.color.brand.dark};
    z-index: 1;
  }
`;

const ProgressText = styled.span`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  z-index: 2;
`;

export { ProgressBar };
