import React, { ReactNode, RefObject } from 'react';
import styled, { css } from 'styled-components';

import { isDefined } from '../../../../../js/utils/variables';
import { Icon } from '../../../icon/icon';
import { Stack } from '../../../stack/stack';
import { TooltipInfoIcon } from '../../../tooltip-info-icon/tooltip-info-icon';

type Props = {
  title: string;
  titleUpperCase?: boolean;
  children: ReactNode[] | ReactNode;
  expanded: boolean;
  headerRef?: RefObject<HTMLDivElement>;
  onSetExpanded: (state: boolean) => void;
  noBorder?: boolean;
  tooltip?: { title: string; text: React.ReactNode };
  ref?: React.RefObject<HTMLDivElement>;
};

const CollapsableSection = ({ titleUpperCase = false, ...props }: Props) => {
  return (
    <Component expanded={props.expanded} ref={props.ref}>
      <Header
        ref={props.headerRef}
        onClick={() => {
          props.onSetExpanded(!props.expanded);
        }}
      >
        <Stack alignItems="center" direction="row" spacing={0.5}>
          <Icon
            fixedWidth={true}
            icon={props.expanded ? ['fas', 'caret-down'] : ['fas', 'caret-right']}
          />

          <Title role="heading">{titleUpperCase ? props.title.toUpperCase() : props.title}</Title>

          {isDefined(props.tooltip) && (
            <TooltipInfoIcon content={props.tooltip.text} title={props.tooltip.title} />
          )}
        </Stack>
      </Header>

      {props.expanded && <Content>{props.children}</Content>}
    </Component>
  );
};

const Component = styled.div<{ expanded: boolean }>`
  position: relative;

  ${(props) =>
    props.expanded &&
    css`
      box-shadow:
        0 0 3px rgb(0 0 0 / 12%),
        0 0 2px rgb(0 0 0 / 24%);
      z-index: 2;
    `}
`;

const Header = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  padding: 0.6em 0.8em;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  background-color: #f8f8f8;

  &:hover {
    background-color: #fff8d5;
  }
`;

const Title = styled.span`
  font-size: 14px;
  word-wrap: break-word;
`;

const Content = styled.div`
  transition: height 0.25s cubic-bezier(0.57, 0.06, 0.58, 0.88);
  padding: 0.8em;
  position: relative;
`;

export { CollapsableSection, Header as CollapsableSectionHeaderStyled };
