import React from 'react';
import styled from 'styled-components';

import { reset } from '../../../utils/styled-reset';
import { Icon } from '../../icon/icon';

const SelectGeodata = () => {
  return (
    <Component>
      <Content>
        <Icon color="#adadad" icon={'cloud'} size="4x" />
        <Text titleText={true} topMargin={true}>
          Välj en molnprocessering i listan.
        </Text>
      </Content>
    </Component>
  );
};

const Text = styled.span<{
  titleText?: boolean;
  topMargin?: boolean;
  bottomMargin?: boolean;
}>`
  display: block;

  font-weight: ${(props) => (props.titleText ? 200 : 300)};
  font-size: ${(props) => (props.titleText ? '1.5em' : '1.0em')};

  strong {
    font-weight: 500;
  }

  line-height: 1.5em;
  margin-top: ${(props) => (props.topMargin ? '0.5em' : undefined)};
  margin-bottom: ${(props) => (props.bottomMargin ? '0.5em' : undefined)};
`;

const Content = styled.div`
  display: flex;
  width: 350px;
  height: 100%;

  gap: 4px;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Component = styled.div`
  ${reset}
  font-size: 14px;

  display: grid;
  place-content: center;
  height: 100%;
`;

export { SelectGeodata };
