import { darken, lighten } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';

import { nameof } from '../../utils/nameof';
import { TableContext } from './table-state';

export type Props = {
  children: React.ReactNode;
  checked?: boolean;
  selected?: boolean;
  borderTop?: boolean;
  onClick?: (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
  onMouseEnter?: (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
};

const TableRow = React.forwardRef<HTMLTableRowElement, Props>((props: Props, ref) => {
  const { renderBorderBetweenRows } = React.useContext(TableContext);

  return (
    <Component
      {...props}
      clickable={!!props.onClick}
      ref={ref}
      renderBorderBetweenRows={renderBorderBetweenRows}
      onClick={(event) => props.onClick && props.onClick?.(event)}
      onMouseEnter={(event) => props.onMouseEnter?.(event)}
      onMouseLeave={(event) => props.onMouseLeave?.(event)}
    >
      {props.children}
    </Component>
  );
});

const renderTopBorder = (renderBorderBetweenRows: boolean, borderTop?: boolean) =>
  renderBorderBetweenRows ? borderTop !== false : borderTop;

const Component = styled.tr<
  Pick<Props, 'borderTop' | 'checked' | 'selected'> & {
    clickable: boolean;
    renderBorderBetweenRows: boolean;
  }
>`
  // Apply top border to all rows (cells) expect first row.
  ${(props) =>
    renderTopBorder(props.renderBorderBetweenRows, props.borderTop) &&
    css`
      &:not(:first-child) td {
        border-top: 1px solid #eee;
      }
    `}

  ${(props) =>
    props.clickable &&
    css`
      &:hover td {
        background-color: #fafafa;
        cursor: pointer;

        &:first-child::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 3px;
          background-color: #fed530;
          z-index: 1;
        }
      }
    `}

  ${(props) =>
    !props.checked &&
    props.selected &&
    css`
      & td {
        background-color: #fafafa;
        cursor: pointer;

        &:first-child::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 3px;
          background-color: #fed530;
          z-index: 1;
        }
      }
    `}

    ${(props) =>
    props.checked &&
    props.selected &&
    css`
      & td {
        background-color: ${darken(0.01, props.theme.color.brand.lightest)};

        &:first-child::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 3px;
          background-color: #fed530;
          z-index: 1;
        }
      }

      &:hover td {
        background-color: ${darken(0.01, props.theme.color.brand.lightest)};
      }
    `}

    ${(props) =>
    props.checked &&
    !props.selected &&
    css`
      & td {
        background-color: ${lighten(0.05, props.theme.color.brand.lightest)};
      }

      &:hover td {
        background-color: ${darken(0.01, props.theme.color.brand.lightest)};
      }
    `}
`;

TableRow.displayName = nameof({ TableRow });

export { Component as StyledTableRow, TableRow };
