import { TFunction } from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

import { CompanyLicense } from '../../../../../typings/api/skymap/rest/v0/company';
import { CompanyStore } from '../../../../js/stores/company.store';
import { UserStore } from '../../../../js/stores/user-store';
import { formattedDateTime } from '../../../../js/utils/dateUtils';
import { sessionStorageUtils } from '../../../../js/utils/session-storage-utils';
import { useDialog } from '../../../hooks/use-dialog';
import { Button } from '../../button/button';
import { DataTable, DataTableColumns, DataTableSortType } from '../../data-table/data-table';
import { Stack } from '../../stack/stack';
import { TextBox } from '../../text-box/text-box';
import { DesignSeatDialog } from './design-seat-dialog';

const pageSize = 15;

export interface LicenseTableItem extends Record<string, any> {
  id: string;
  type: CompanyLicense['type'];
  status: CompanyLicense['status'];
  assignedTo: string;
  userId?: string;
  createdAt: Date;
  createdBy: string;
  createdAtText: string;
  validUntil: Date | undefined;
  validUntilText: string;
}

function getColumns(t: TFunction): DataTableColumns<LicenseTableItem> {
  return {
    type: {
      sortableBy: { type: DataTableSortType.STRING },
      title: t('licenses.licenseType', { ns: 'companies' }),
      unfilterable: true,
    },
    status: {
      sortableBy: { type: DataTableSortType.STRING },
      title: t('licenses.status', { ns: 'companies' }),
      unfilterable: true,
    },
    createdBy: {
      sortableBy: { type: DataTableSortType.STRING },
      title: t('licenses.createdBy', { ns: 'companies' }),
      unfilterable: true,
    },
    assignedTo: {
      sortableBy: { type: DataTableSortType.STRING },
      title: t('licenses.assignedTo', { ns: 'companies' }),
      unfilterable: true,
    },
    createdAt: {
      sortableBy: { type: DataTableSortType.DATE },
      title: t('licenses.createdAt', { ns: 'companies' }),
      formatter: (value) => formattedDateTime(value),
      unfilterable: true,
    },
    validUntil: {
      sortableBy: { type: DataTableSortType.DATE },
      title: t('licenses.validUntil', { ns: 'companies' }),
      formatter: (value) =>
        value ? formattedDateTime(value) : t('licenses.values.noEndDate', { ns: 'companies' }),
      unfilterable: true,
    },
  };
}

const createTableView = (licenses: CompanyLicense[], t: TFunction): LicenseTableItem[] =>
  licenses.map((license) => ({
    type: license.type,
    status: license.status,
    id: license.id,
    userId: license.userId,
    assignedTo: license.userEmail
      ? license.userEmail
      : license.type === 'DESIGN_SEAT' || license.type === 'DESIGN_SEAT_DEMO'
        ? t('licenses.values.unassigned', { ns: 'companies' })
        : t('licenses.values.allAdmins', { ns: 'companies' }),
    createdBy: license.createdBy ?? '',
    createdAt: new Date(license.createdAt),
    createdAtText: formattedDateTime(license.createdAt),
    validUntil: license.validUntil ? new Date(license.validUntil) : undefined,
    validUntilText: license.validUntil
      ? formattedDateTime(license.validUntil)
      : t('licenses.values.noEndDate', { ns: 'companies' }),
  }));

const searchColumns = ['type', 'status', 'belongsTo', 'createdAtText', 'validUntilText'] as const;

function filterItems(items: LicenseTableItem[], searchText: string) {
  return items.filter((item) => {
    const searchValue = searchText.trim().toLowerCase();
    return searchColumns.some((x) => (item[x] ?? '').toLowerCase().includes(searchValue));
  });
}

/**
 * Hook that preserves text in search during the user session.
 */
function useLicensesSearchFilter() {
  const [searchText, setSearchText] = useState(
    sessionStorageUtils.getUserItemOrDefault('licenseListSearchText', ''),
  );

  useEffect(() => {
    sessionStorageUtils.setUserItem('licenseListSearchText', searchText);
  }, [searchText]);

  return { searchText, setSearchText };
}

export type DispatchLicenseTableItem =
  | {
      type: 'REMOVE';
      id: string;
    }
  | {
      type: 'ADD' | 'UPDATE';
      items: LicenseTableItem[];
    };

function licencesReducer(
  state: LicenseTableItem[],
  action: DispatchLicenseTableItem,
): LicenseTableItem[] {
  if (action.type === 'UPDATE') {
    return state.map((existingItem) => {
      const matchingItem = action.items.find((x) => x.id === existingItem.id);
      return matchingItem ?? existingItem;
    });
  }

  if (action.type === 'REMOVE') {
    return state.filter((x) => x.id !== action.id);
  }

  if (action.type === 'ADD') {
    const newItems = action.items.filter(
      (x) => !state.some((existingItem) => existingItem.id === x.id),
    );
    return [...state, ...newItems];
  }

  return state;
}

export const CompanyLicenses = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const designSeatDialog = useDialog();
  const isOrganisationAdmin = UserStore.instance.isOrganizationAdmin();
  const [selectedItem, setSelectedItem] = useState<LicenseTableItem>();
  const company = useRef(CompanyStore.instance.company);
  const [fullTableView, dispatchAction] = useReducer(
    licencesReducer,
    createTableView(company.current.licenses, t),
  );
  const [tableView, setTableView] = useState<LicenseTableItem[]>(fullTableView);
  const { searchText, setSearchText } = useLicensesSearchFilter();

  const columns = useRef(getColumns(t));

  useEffect(() => {
    const items = filterItems(fullTableView, searchText);
    setTableView(items);
  }, [searchText, theme, fullTableView]);

  return (
    <>
      <Title>
        {t('columns.licenses', { ns: 'companies' })} - {company.current.name}
      </Title>
      <Stack alignItems="baseline" direction="row" justifyContent="space-between" spacing={0.5}>
        <SearchFilterContainer direction="row" spacing={0.5}>
          <TextBox
            placeholder={t('search', { ns: 'common' })}
            value={searchText}
            width={250}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </SearchFilterContainer>

        {isOrganisationAdmin && (
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setSelectedItem(undefined);
              designSeatDialog.show();
            }}
          >
            Nytt designsäte
          </Button>
        )}
      </Stack>

      <DataTable
        columns={columns.current}
        fixedLayout={false}
        items={tableView}
        pageSize={pageSize}
        tableStyle={{
          rowCell: {
            fontSize: '14px',
          },
          headerCell: {
            fontSize: '14px',
          },
        }}
        onRowClicked={(item) => {
          if (isOrganisationAdmin && item.type === 'DESIGN_SEAT') {
            setSelectedItem(item);
            designSeatDialog.show();
          }
        }}
      />
      {designSeatDialog.render(
        <DesignSeatDialog
          dialog={designSeatDialog}
          dispatchAction={dispatchAction}
          item={selectedItem}
        />,
      )}
    </>
  );
};

// Styling is made to match the other pages in angular.
const Title = styled.h1`
  font-size: 19px;
  font-weight: bold;
  text-transform: uppercase;
`;

const SearchFilterContainer = styled(Stack)`
  width: 450px;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

CompanyLicenses.propTypes = {
  wrapWithLanguageProvider: PropTypes.any,
};
