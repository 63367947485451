import type * as CSS from 'csstype';
import React from 'react';
import styled, { css } from 'styled-components';

import { reset } from '../../utils/styled-reset';

type Props = {
  items: (
    | NonNullable<React.ReactNode>
    | {
        value: NonNullable<React.ReactNode>;
        marker?: {
          color?: CSS.Properties['color'];
        };
      }
  )[];
  marker?: 'disc' | 'number';
  useMarkerForSingleItem?: boolean;
  /**
   * Marks index with aria-current=true.
   */
  currentIndex?: number;
};

const List = ({ useMarkerForSingleItem = true, ...props }: Props) => {
  return props.items.length > 0 ? (
    <Component
      items={props.items}
      marker={props.marker}
      useMarkerForSingleItem={useMarkerForSingleItem}
    >
      {props.items.map((x, i) =>
        typeof x === 'object' && 'marker' in x ? (
          <Item aria-current={i === props.currentIndex} key={i} markerColor={x.marker?.color}>
            {x.value}
          </Item>
        ) : (
          <Item aria-current={i === props.currentIndex} key={i}>
            {x as React.ReactNode}
          </Item>
        ),
      )}
    </Component>
  ) : null;
};

const Component = styled.ul<Pick<Props, 'marker' | 'items' | 'useMarkerForSingleItem'>>`
  ${reset}

  // Set marker if any specified.
  list-style-type: ${(props) => props.marker ?? 'none'};
  padding-left: ${(props) => (props.marker ? '1em' : 0)};

  // Disable marker (override above) for single item if specified.
  ${(props) =>
    props.items.length === 1 &&
    !props.useMarkerForSingleItem &&
    css`
      list-style-type: none;
      padding-left: 0;
    `}
`;

const Item = styled.li<{ markerColor?: CSS.Properties['color'] }>`
  &::marker {
    color: ${(props) => props.markerColor};
  }
`;

export { List };
