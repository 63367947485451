import React from 'react';
import styled from 'styled-components';

export const VolumeReportCanvas = () => {
  return (
    <Component>
      <StyledCanvas id="volumeReportCanvas" />
      <img id="volumeReportImg" />;
    </Component>
  );
};

const Component = styled.div`
  display: none;
`;

const StyledCanvas = styled.canvas`
  height: 700px;
  width: 450px;
`;
