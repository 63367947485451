import React from 'react';
import styled from 'styled-components';

import { isDefined } from '../../../../../js/utils/variables';
import { Select } from '../../../select/select';
import { TextBox } from '../../../text-box/text-box';
import {
  AddOnData,
  FilteredPointCloudClassesOptions,
  FilteredPointCloudFormatOptions,
} from '../add-ons';
import { ContentList } from './card';

export type Props = {
  selected: boolean;
  spacing?: number;
  format?: FilteredPointCloudFormatOptions;
  classes?: FilteredPointCloudClassesOptions;
  onChange: (data: AddOnData) => void;
};

const FilteredPointCloudContent = (props: Props) => {
  const [pointClass, setPointClass] = React.useState<FilteredPointCloudClassesOptions>(
    props.classes ?? 'ALL',
  );
  const [exportFormat, setExportFormat] = React.useState<FilteredPointCloudFormatOptions>(
    props.format ?? 'DXF',
  );
  const [spacing, setSpacing] = React.useState<number>(props?.spacing ?? 0.02);
  const [spacingInput, setSpacingInput] = React.useState<string>(spacing.toString());

  const onClassesChanged = (value: FilteredPointCloudClassesOptions) => {
    switch (value) {
      case 'ALL':
        props.onChange({ filteredPointCloud: { classes: 'ALL' } });
        setPointClass(value);
        break;
      case 'GROUND':
        props.onChange({ filteredPointCloud: { classes: 'GROUND' } });
        setPointClass(value);
        break;
      default:
        throw Error('Invalid format');
    }
  };

  const onFormatChanged = (value: FilteredPointCloudFormatOptions) => {
    switch (value) {
      case 'LAZ':
      case 'LAS':
      case 'DXF':
        props.onChange({ filteredPointCloud: { format: value } });
        setExportFormat(value);
        break;
      default:
        throw Error('Invalid format');
    }
  };

  const onSpacingChanged = () => {
    let newSpacing = Number(spacingInput);
    if (isDefined(newSpacing)) {
      newSpacing = Math.max(0, Number(newSpacing.toFixed(3)));
      setSpacing(Math.max(0, newSpacing));
      setSpacingInput(newSpacing.toString());
      props.onChange({ filteredPointCloud: { spacing: newSpacing } });
    }
  };

  return (
    <Component>
      <ContentList>
        <li>
          <span>
            Genererar ett utglesat punktmoln baserat på valda punktklasser och avstånd mellan dem.
            Detta punktmoln innehåller färre punkter och därmed blir storleken på nedladdningsbar
            fil mindre. Kan användas exempelvis för att förenkla inläsning i tredjepartsprogram.
          </span>
        </li>

        <li>
          <span>Export av data (.las, .laz, .dxf)</span>
        </li>
      </ContentList>

      <label>
        <span>Punktklasser</span>
        <Select
          disabled={!props.selected}
          options={
            [
              { id: 'ALL', name: 'Alla' },
              { id: 'GROUND', name: 'Mark' },
            ] as { id: FilteredPointCloudClassesOptions; name: string }[]
          }
          value={pointClass}
          onChange={(e) => onClassesChanged(e.target.value as FilteredPointCloudClassesOptions)}
          onClick={(e) => e.stopPropagation()}
        />
      </label>
      <label>
        <span>Avstånd mellan punkter (meter)</span>
        <TextBox
          disabled={!props.selected}
          step={0.001}
          type="number"
          value={spacingInput}
          onBlur={onSpacingChanged}
          onChange={(e) => setSpacingInput(e.target.value)}
          onClick={(e) => e.stopPropagation()}
        />
      </label>
      <label>
        <span>Exportformat</span>
        <Select
          disabled={!props.selected}
          options={
            [{ name: 'LAS' }, { name: 'LAZ' }, { name: 'DXF' }] as {
              name: FilteredPointCloudFormatOptions;
            }[]
          }
          value={exportFormat}
          onChange={(e) => onFormatChanged(e.target.value as FilteredPointCloudFormatOptions)}
          onClick={(e) => e.stopPropagation()}
        />
      </label>
    </Component>
  );
};

const Component = styled.section`
  ul {
    margin-bottom: 1em;
  }

  label {
    span {
      font-size: 0.9em;
    }

    select {
      // TODO: Remove this styling when padding issue with select is resolved.
      padding-left: 0.25em !important;
    }

    input,
    select {
      margin: 0.5em 0 0.5em 0 !important;

      &:disabled {
        background-color: white;
        color: ${(props) => props.theme.color.gray.medium};
        border: 1px solid ${(props) => props.theme.color.gray.light};
      }
    }
  }
`;

export { FilteredPointCloudContent };
