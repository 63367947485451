import {
  FloatingArrow,
  FloatingPortal,
  useDelayGroup,
  useDelayGroupContext,
  useMergeRefs,
  UseTransitionStatusProps,
  useTransitionStyles,
} from '@floating-ui/react';
import React from 'react';
import styled from 'styled-components';

import { reset } from '../../utils/styled-reset';
import { LanguageProvider } from '../language-provider/language-provider';
import { Stack } from '../stack/stack';
import { useTooltipState } from './tooltip-context';

type Props = {
  title?: string;
  children: React.ReactNode;
  transitionDuration?: UseTransitionStatusProps['duration'];
};

const defaultTransitionDelay: UseTransitionStatusProps['duration'] = {
  open: 250,
  close: 100,
};

const TooltipContent = React.forwardRef<HTMLDivElement, Props>(function TooltipContent(
  { transitionDuration = defaultTransitionDelay, ...props },
  propRef,
) {
  const state = useTooltipState();
  const { isInstantPhase } = useDelayGroupContext();
  const ref = useMergeRefs([state.refs.setFloating, propRef]);

  // Enable grouping.
  useDelayGroup(state.context, { id: state.context.floatingId });

  // No duration when moving to another tooltip wihtin the same delay group.
  const { isMounted, styles } = useTransitionStyles(state.context, {
    duration: isInstantPhase
      ? {
          open: 0,
          close: 0,
        }
      : transitionDuration,
  });

  return isMounted ? (
    <FloatingPortal>
      <LanguageProvider>
        <Component
          ref={ref}
          style={{
            ...state.floatingStyles,
            ...styles,
          }}
          {...state.getFloatingProps(props)}
        >
          <Stack spacing={1}>
            {props.title && <Title>{props.title}</Title>}
            <div>{props.children}</div>
          </Stack>

          {state.showArrow && <Arrow context={state.context} ref={state.arrowRef} />}
        </Component>
      </LanguageProvider>
    </FloatingPortal>
  ) : null;
});

const Component = styled.div`
  ${reset}
  font-size: 12px;

  background-color: ${(props) => props.theme.color.gray.darkest};
  color: #fff;
  padding: 1.5em;
  border-radius: 0.5em;
  max-width: 400px;
  isolation: isolate;
  z-index: 1;
`;

const Title = styled.span`
  font-weight: bold;
  display: block;
  text-transform: uppercase;
`;

const Arrow = styled(FloatingArrow)`
  fill: ${(props) => props.theme.color.gray.darkest};
`;

export { Props, TooltipContent };
