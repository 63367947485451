import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import { SkyMapConnectDongle } from '../../../../typings/api/skymap/rest/v1/.common';
import { RegisterDongleParameters } from '../../../../typings/api/skymap/rest/v1/dongle';
import { SkyMapAxiosServiceFactory } from '../../../js/services/axios/skymap-axios-service-factory';
import { RequestParamsToApiPaths } from '../../../js/utils/typescript-utils';
import { useErrorHandling } from '../../hooks/use-error-handling';
import { ProjectContext } from '../../state/project-state';
import { Button } from '../button/button';
import { IntegrationsContext } from '../integrations/state/integrations-state';
import { LabelledContainer } from '../labelled-container/labelled-container';
import { Select } from '../select/select';
import { Stack } from '../stack/stack';
import { TextBox } from '../text-box/text-box';

interface RegisterDongleFormProps {
  onClose: () => void;
}

const registerDongle = async (dongleId: string, projectId: string, adminId: string) => {
  const dongleService = SkyMapAxiosServiceFactory.instance.createDongleServiceV1();
  const response = await dongleService.registerDongle({
    path: { dongleId },
    body: { projectId, adminId },
  });

  return response.data;
};

export const RegisterDongleForm = ({ onClose }: RegisterDongleFormProps) => {
  const { project } = React.useContext(ProjectContext);
  const { handleError, buildErrorList, includeErrorPaths } =
    useErrorHandling<RequestParamsToApiPaths<RegisterDongleParameters>>();

  const { skyMapDongles, loadDonglesConnection } = useContext(IntegrationsContext);

  const [selectedDongle, setSelectedDongle] = useState<SkyMapConnectDongle>();
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const [registeringInProgress, setRegisteringInProgress] = useState(false);

  const setNewSelectedDongle = (dongleId: string) => {
    const newSelectedDongle = skyMapDongles.find((dongle) => dongle.id === dongleId);
    if (newSelectedDongle) {
      setSelectedDongle(newSelectedDongle);
    }
  };

  const registerDongleAndCloseForm = async () => {
    if (selectedDongle && selectedUserId) {
      setRegisteringInProgress(true);
      try {
        const response = await registerDongleFunctions.registerDongle(
          selectedDongle.id,
          project.id,
          selectedUserId,
        );

        if (response) {
          onClose();
          await loadDonglesConnection(project.id, project.companyId);
        }
      } catch (error) {
        handleError(error);
        setRegisteringInProgress(false);
      }
    }
  };

  return (
    <FormContainer>
      {buildErrorList({
        filter: includeErrorPaths(['path.dongleId', 'body.projectId']),
        infoBoxProps: {
          bottomMargin: true,
        },
      })}
      <Stack direction={'row'} justifyContent={'space-between'} spacing={0.5}>
        <LabelledContainer text={`Tillgängliga enheter`}>
          {(formElementId) => (
            <Select
              id={formElementId()}
              options={skyMapDongles.map((dongle) => ({ id: dongle.id, name: dongle.id }))}
              placeholderText={'-- Välj dongle --'}
              value={selectedDongle?.id ? selectedDongle?.id : ''}
              onChange={(e) => setNewSelectedDongle(e.target.value)}
            />
          )}
        </LabelledContainer>

        <LabelledContainer text={`Enhetsnamn`}>
          <TextBox
            disabled={true}
            maxVisibleChars={22}
            value={selectedDongle?.name ?? selectedDongle?.id ?? ''}
            onChange={() => null}
          />
        </LabelledContainer>

        <LabelledContainer text={`Administratör`}>
          {(formElementId) => (
            <Select
              id={formElementId()}
              options={project.companyAdmins.map((user) => ({ id: user.id, name: user.email }))}
              placeholderText={'-- Välj användare --'}
              value={selectedUserId}
              onChange={(e) => setSelectedUserId(e.target.value)}
            />
          )}
        </LabelledContainer>
      </Stack>
      <Stack direction={'row'} justifyContent={'flex-end'} spacing={0.5}>
        <Button
          color={'primary'}
          disabled={!selectedDongle || !selectedUserId}
          loading={registeringInProgress}
          variant={'contained'}
          onClick={registerDongleAndCloseForm}
        >
          Lägg till
        </Button>
      </Stack>
    </FormContainer>
  );
};

const FormContainer = styled.div`
  width: 50rem;
`;

export const registerDongleFunctions = {
  registerDongle,
};
