import styled, { css } from 'styled-components';

import { reset } from '../../../utils/styled-reset';

export const itemDimension = css`
  --size: 30px;

  min-width: var(--size);
  width: var(--size);
  min-height: var(--size);
  height: var(--size);
  display: grid;
  place-content: center;
`;

export const SelectableItem = styled.div<{
  boldText: boolean;
  lightColor: boolean;
  enabled: boolean;
  selected: boolean;
}>`
  ${reset}
  ${itemDimension}
  
  position: relative;

  font-weight: 400;
  user-select: none;

  // Pseudo element for background circle. Hidden default.
  // Also for disabled items as an disabled item can be
  // selected when using min/max date/time and pressing
  // button Today for instance.
  &::before {
    display: none;
    position: absolute;
    content: '';
    left: 1px;
    top: 1px;
    right: 1px;
    bottom: 1px;
    border-radius: 50%;
    z-index: -1;
  }

  // Bold text?
  ${(props) =>
    props.boldText &&
    css`
      font-weight: 600;
    `}

  // Lighter color?
  ${(props) =>
    props.lightColor &&
    css`
      color: #bbb;
    `}

  // Enabled / Disabled?
  ${(props) =>
    props.enabled
      ? css`
          cursor: pointer;
        `
      : css`
          color: #bbb;
          font-weight: 200;
        `}

  // Dark yellow for selected item.
  ${(props) =>
    props.selected &&
    css`
      &::before {
        display: block;
        background-color: ${(props) => props.theme.color.brand.dark};
      }
    `}

  // Light yellow for items hovered excluding selected item.
  ${(props) =>
    props.enabled &&
    !props.selected &&
    css`
      &:hover::before {
        display: block;
        background-color: ${(props) => props.theme.color.brand.medium};
      }
    `}

  // Red for selected and disabled item.
  ${(props) =>
    props.selected &&
    !props.enabled &&
    css`
      color: #fff;

      &::before {
        display: block;
        background-color: ${(props) => props.theme.color.red};
      }
    `}
`;
