import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import type * as CSS from 'csstype';
import { darken } from 'polished';
import PropTypes from 'prop-types';
import React, { KeyboardEventHandler, MouseEvent, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { nameof } from '../../utils/nameof';
import { reset } from '../../utils/styled-reset';
import { Icon } from '../icon/icon';

type ButtonVariant = 'text' | 'contained';

type ButtonColor = 'primary' | 'secondary' | 'error';
export type Props = {
  alignSelf?: CSS.Properties['alignSelf'];
  padding?: CSS.Properties['padding'];
  autoFocus?: boolean;
  children: ReactNode;
  color?: ButtonColor;
  contentAlignment?: 'left' | 'center';
  disabled?: boolean;
  leftIcon?: FontAwesomeIconProps;
  /**
   * Displays a spinner and disables the button.
   * If set, this will override props `disabled` and `leftIcon`.
   */
  loading?: boolean;
  noWrap?: boolean;
  onClick?: (e: MouseEvent) => void;
  onMouseEnter?: (e: MouseEvent) => void;
  onMouseLeave?: (e: MouseEvent) => void;
  onKeyDown?: KeyboardEventHandler<HTMLButtonElement>;
  title?: string;
  type?: 'button' | 'submit';
  variant: ButtonVariant;
  width?: CSS.Properties['width'];
  /**
   * Specifies the form the button belongs to.
   */
  form?: string;
};

const Button = React.forwardRef<HTMLButtonElement, Props>(
  ({ type = 'button', ...props }: Props, ref) => {
    const buttonProps = { ...props };

    // Remove props that does not belong to the <button> element..
    delete buttonProps.loading;

    return (
      <Component
        {...buttonProps}
        disabled={props.loading ? true : props.disabled}
        form={props.form}
        ref={ref}
        type={type}
        onClick={props.onClick}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
      >
        {props.leftIcon && !props.loading && <Icon {...props.leftIcon} />}
        {props.loading && <Icon icon="spinner" spin={true} />}
        <div>{props.children}</div>
      </Component>
    );
  },
);

Button.displayName = nameof({ Button });

Button.propTypes = {
  children: PropTypes.any.isRequired,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  leftIcon: PropTypes.any,
  onClick: PropTypes.func,
  variant: PropTypes.string.isRequired,
};

const Component = styled.button<
  Pick<
    Props,
    'padding' | 'variant' | 'color' | 'width' | 'alignSelf' | 'contentAlignment' | 'noWrap'
  >
>`
  ${reset}

  position: relative;
  font-size: 14px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => props.contentAlignment ?? 'center'};
  align-self: ${(props) => props.alignSelf};
  gap: 0.5em;

  padding: ${(props) => props.padding ?? 'calc(0.5em * 0.75) calc(1.3em * 0.75)'};
  border-radius: 3px;
  border: none;
  cursor: pointer;

  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}

  ${(props) =>
    props.noWrap &&
    css`
      white-space: nowrap;
    `}

  // variant: text
  // color: default
  ${(props) =>
    props.variant === 'text' &&
    !props.color &&
    css`
      background-color: transparent;
      color: #000;

      &:not(:disabled):hover {
        background-color: ${(props) => darken(0.05, props.theme.color.gray.lightest)};
      }

      &:not(:disabled):active {
        background-color: ${(props) => darken(0.1, props.theme.color.gray.lightest)};
      }
    `}

  // variant: text
  // color: primary
  ${(props) =>
    props.variant === 'text' &&
    props.color === 'primary' &&
    css`
      background-color: transparent;
      color: ${(props) => darken(0.1, props.theme.color.brand.dark)};

      &:not(:disabled):hover {
        background-color: ${(props) => darken(0.1, props.theme.color.brand.dark)};
        color: #000;
      }

      &:not(:disabled):active {
        background-color: ${(props) => darken(0.15, props.theme.color.brand.dark)};
        color: #000;
      }
    `}

  // Text - Secondary
  ${(props) =>
    props.variant === 'text' &&
    props.color === 'secondary' &&
    css`
      background-color: transparent;
      color: ${(props) => darken(0.15, props.theme.color.gray.light)};

      &:not(:disabled):hover {
        background-color: ${(props) => darken(0.15, props.theme.color.gray.light)};
        color: #000;
      }

      &:not(:disabled):active {
        background-color: ${(props) => darken(0.2, props.theme.color.gray.light)};
        color: #000;
      }
    `}

  // Text - Error
  ${(props) =>
    props.variant === 'text' &&
    props.color === 'error' &&
    css`
      background-color: transparent;
      color: ${(props) => props.theme.color.red};

      &:not(:disabled):hover {
        background-color: ${(props) => props.theme.color.red};
        color: #fff;
      }
    `}

  // Contained - Default
  ${(props) =>
    props.variant === 'contained' &&
    !props.color &&
    css`
      background-color: ${(props) => props.theme.color.gray.lightest};

      &:not(:disabled):hover {
        background-color: ${(props) => darken(0.05, props.theme.color.gray.lightest)};
      }

      &:not(:disabled):active {
        background-color: ${(props) => darken(0.1, props.theme.color.gray.lightest)};
      }
    `}

  // Contained - Primary
  ${(props) =>
    props.variant === 'contained' &&
    props.color === 'primary' &&
    css`
      background-color: ${(props) => props.theme.color.brand.dark};

      &:not(:disabled):hover {
        background-color: ${(props) => darken(0.1, props.theme.color.brand.dark)};
      }

      &:not(:disabled):active {
        background-color: ${(props) => darken(0.15, props.theme.color.brand.dark)};
      }
    `}

  // Contained - Secondary
  ${(props) =>
    props.variant === 'contained' &&
    props.color === 'secondary' &&
    css`
      background-color: ${(props) => props.theme.color.gray.light};

      &:not(:disabled):hover {
        background-color: ${(props) => darken(0.15, props.theme.color.gray.light)};
      }

      &:not(:disabled):active {
        background-color: ${(props) => darken(0.2, props.theme.color.gray.light)};
      }
    `}

  // Contained - Error
  ${(props) =>
    props.variant === 'contained' &&
    props.color === 'error' &&
    css`
      background-color: ${(props) => props.theme.color.red};
      color: #fff;

      &:not(:disabled):hover {
        background-color: ${(props) => darken(0.1, props.theme.color.red)};
      }

      &:not(:disabled):active {
        background-color: ${(props) => darken(0.15, props.theme.color.red)};
      }
    `}

  // todo: Move up to each style above to improve appearance for each style.
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    * {
      cursor: not-allowed;
    }
  }
`;

export { Button, Component as ButtonStyled };
