import { t } from 'i18next';
import React from 'react';
import styled, { css } from 'styled-components';

import { CellGridVolume } from '../../../../../../js/viewer/elements/volume/cell-grid-volume';
import { translateVolumeCalculationType } from '../../volume-calculation-tool/volume-calculation-helper';
import { getAssetVolumeCalculationDataType, VolumeWithAsset } from './utils';

type Props = {
  volume: VolumeWithAsset;
  selected: boolean;
  loading: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
};

export const VolumeListVolumeCard = (props: Props) => {
  return (
    <VolumeItem
      key={props.volume.id}
      loading={props.loading}
      selected={props.selected}
      onClick={props.onClick}
    >
      <span>{props.volume.volume.name ?? ''}</span>
      <div>
        <span>{t('volumeListSection.type', { ns: 'skyviewReport' })}</span>
        <span>
          {props.volume.volume instanceof CellGridVolume
            ? t('section.method.itemCellGrid.text', { ns: 'skyviewVolumeCalculation' })
            : t('section.method.itemPrism.text', { ns: 'skyviewVolumeCalculation' })}
        </span>
      </div>
      <div>
        <span>{t('common.sourceSurface', { ns: 'skyviewVolumeCalculation' })}</span>
        <span>{props.volume.sourceName ?? ''}</span>
      </div>
      <div>
        <span>{t('common.targetSurface', { ns: 'skyviewVolumeCalculation' })}</span>
        <span>
          {props.volume.asset.name ?? ''} (
          {translateVolumeCalculationType(getAssetVolumeCalculationDataType(props.volume.asset))})
        </span>
      </div>
    </VolumeItem>
  );
};

const VolumeItem = styled.div<{ selected: boolean; loading: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.4em;
  padding: 0.8em;
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);

  > span {
    font-weight: 500;
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 0.2em;

    span:first-child {
      font-weight: 300;
    }
  }

  // Background color on hover
  ${(props) =>
    !props.loading &&
    css`
      &:hover {
        cursor: pointer;
        background-color: #f5f5f5;
      }
    `}

  // Background color when selected.
  ${(props) =>
    props.selected &&
    css`
      background-color: #fff8d5;
    `}

  // Background color when selected and hover.
  ${(props) =>
    props.selected &&
    !props.loading &&
    css`
      &:hover {
        background-color: #fff1ae;
      }
    `}
`;
