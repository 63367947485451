import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { iiafe } from '../../../../../../utilities/async';
import { reset } from '../../../../utils/styled-reset';
import { Icon } from '../../../icon/icon';
import { Stack } from '../../../stack/stack';
import { Table } from '../../../table/table';
import { TableBody } from '../../../table/table-body';
import { TableCell } from '../../../table/table-cell';
import { TableHead } from '../../../table/table-head';
import { TableHeaderCell } from '../../../table/table-header-cell';
import { StyledTableRow, TableRow } from '../../../table/table-row';
import { TextBox } from '../../../text-box/text-box';
import { Item, useAssetPoints } from './use-asset-points';

const AssetPointsPanel = () => {
  const { t } = useTranslation();
  const {
    currentItems,
    flattenGeometry,
    orderByItemIds,
    originalItems,
    restoreAllItems,
    restoreItem,
    selectedAsset,
    updateEasting,
    updateMas,
    updateNorthing,
  } = useAssetPoints();

  const isItemChanged = (item1: Item, item2: Item) =>
    item1.e.value !== item2.e.value ||
    item1.n.value !== item2.n.value ||
    item1.mas.value !== item2.mas.value;

  const anyItemsChanged = () =>
    [...originalItems.entries()].some(([id, value]) => isItemChanged(value, currentItems.get(id)!));

  const isGeometryFlattened =
    new Set([...currentItems.values()].map((x) => x.mas.value)).size === 1;

  // If no asset is selected, render nothing.
  if (!selectedAsset) {
    return null;
  }

  return (
    <Component spacing={0.5}>
      <Header>{t('assetPointsPanel.title', { ns: 'components' })}</Header>
      <Body>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeaderCell padding={0.5}>
                {t('assetPointsPanel.tableHeader.northing', { ns: 'components' })}
              </TableHeaderCell>
              <TableHeaderCell padding={0.5}>
                {t('assetPointsPanel.tableHeader.easting', { ns: 'components' })}
              </TableHeaderCell>
              <TableHeaderCell padding={0.5}>
                {t('assetPointsPanel.tableHeader.height', { ns: 'components' })}
              </TableHeaderCell>
              <TableHeaderCell padding={0.5} />
              <TableHeaderCell padding={0.5}>
                <Icon
                  disabled={!anyItemsChanged()}
                  fixedWidth={true}
                  icon={['fal', 'arrow-rotate-left']}
                  title={t('assetPointsPanel.icon.undoAllChanges', { ns: 'components' })}
                  onClick={restoreAllItems}
                  onHoverStyle={{ icon: ['fad', 'arrow-rotate-left'] }}
                />
              </TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderByItemIds.map((id, index) => {
              const currentPoint = currentItems.get(id)!;
              const isChanged = isItemChanged(originalItems.get(id)!, currentItems.get(id)!);

              const flattenIconDisabled =
                isGeometryFlattened ||
                !currentPoint.e.isValid ||
                !currentPoint.n.isValid ||
                !currentPoint.mas.isValid;

              return (
                <TableRow
                  data-selected={selectedAsset.highlightedIndex === index}
                  key={id}
                  onMouseEnter={() => selectedAsset.mouseOver(index)}
                  onMouseLeave={() => selectedAsset.mouseOver(-1)}
                >
                  <TableCell padding={0.5}>
                    <TextBox
                      errorMessage={currentPoint.n.isValid ? undefined : ''}
                      maxVisibleChars={11}
                      monospaced="roboto"
                      padding={0.25}
                      step={0.01}
                      type="number"
                      value={currentPoint.n.value}
                      onInput={(e) => iiafe(() => updateNorthing(id, e.currentTarget.value))}
                    />
                  </TableCell>
                  <TableCell padding={0.5}>
                    <TextBox
                      errorMessage={currentPoint.e.isValid ? undefined : ''}
                      maxVisibleChars={11}
                      monospaced="roboto"
                      padding={0.25}
                      step={0.01}
                      type="number"
                      value={currentPoint.e.value}
                      onInput={(e) => iiafe(() => updateEasting(id, e.currentTarget.value))}
                    />
                  </TableCell>
                  <TableCell padding={0.5}>
                    <TextBox
                      errorMessage={currentPoint.mas.isValid ? undefined : ''}
                      maxVisibleChars={8}
                      monospaced="roboto"
                      padding={0.25}
                      step={0.01}
                      type="number"
                      value={currentPoint.mas.value}
                      onChange={(e) => iiafe(() => updateMas(id, e.currentTarget.value))}
                    />
                  </TableCell>
                  <TableCell padding={0.5}>
                    <Icon
                      disabled={flattenIconDisabled}
                      fixedWidth={true}
                      icon={['fal', 'arrows-to-line']}
                      title={
                        flattenIconDisabled
                          ? undefined
                          : t('assetPointsPanel.icon.flatten', { ns: 'components' })
                      }
                      onClick={() => iiafe(() => flattenGeometry(id))}
                      onHoverStyle={{ icon: ['fad', 'arrows-to-line'] }}
                    />
                  </TableCell>
                  <TableCell padding={0.5}>
                    <Icon
                      color={'#000'}
                      disabled={!isChanged}
                      fixedWidth={true}
                      icon={['fal', 'arrow-rotate-left']}
                      title={
                        isChanged
                          ? t('assetPointsPanel.icon.undoChanges', { ns: 'components' })
                          : undefined
                      }
                      onClick={() => iiafe(() => restoreItem(id))}
                      onHoverStyle={{ icon: ['fad', 'arrow-rotate-left'] }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Body>
    </Component>
  );
};

const Component = styled(Stack)`
  max-height: 600px;
  border: 1px solid #888;
  position: absolute;
  right: 20px;
  top: 70px;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 0.25em;
  background-color: #fff;
`;

const Header = styled.h2`
  ${reset}

  font-size: 16px;
  padding: 0.75em;
  background-color: #ddd;
  text-transform: uppercase;
`;

const Body = styled.div`
  overflow-y: scroll;

  ${StyledTableRow} {
    &:hover,
    &[data-selected='true'] {
      background-color: #fff1ae;
    }
  }
`;

export { AssetPointsPanel, Component as AssetPointsPanelStyled };
