import { FloatingDelayGroup } from '@floating-ui/react';
import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { isDefined } from '../../../../../js/utils/variables';
import { truncateText } from '../../../../utils/styled-helpers';
import { Button } from '../../../button/button';
import { Stack } from '../../../stack/stack';
import { Tooltip } from '../../../tooltip/tooltip';

type Item<T> = {
  title: string;
  value: T;
  onSelect?: () => void;
  content?: ReactNode;
  tooltipText?: ReactNode;
  disabled?: boolean;
};

type Props<T> = {
  active: T;
  itemRows: Item<T>[][];
};

const getActiveCard = (rows: Item<any>[][], active: any) => {
  return rows.flat().find((x) => x.value === active) ?? rows[0][0];
};

function OptionLabels<T>(props: Props<T>) {
  const posRef = React.useRef<HTMLDivElement>(null);

  const [activeCard, setActiveCard] = React.useState<Item<any>>(
    getActiveCard(props.itemRows, props.active),
  );

  React.useEffect(() => {
    setActiveCard(getActiveCard(props.itemRows, props.active));
  }, [props]);

  const renderCards = () => {
    return (
      <Stack ref={posRef} spacing={0.1}>
        {props.itemRows.map((row, idx) => (
          <Stack direction="row" key={idx} spacing={0.1}>
            {row.map((item) => {
              const button = (
                <StyledButton
                  active={activeCard.value === item.value}
                  disabled={item?.disabled}
                  variant="contained"
                  onClick={() => {
                    setActiveCard(item);
                    item.onSelect?.();
                  }}
                >
                  {item.title}
                </StyledButton>
              );

              return isDefined(item.tooltipText) ? (
                <Tooltip
                  content={item.tooltipText}
                  key={item.title}
                  mainAxisOffset={22}
                  placement="left-start"
                  posRef={posRef}
                  title={item.title}
                >
                  {button}
                </Tooltip>
              ) : (
                button
              );
            })}
          </Stack>
        ))}
      </Stack>
    );
  };

  return (
    <Component>
      <Stack direction="column" spacing={0.8}>
        <FloatingDelayGroup delay={{ open: 500, close: 100 }}>{renderCards()}</FloatingDelayGroup>
        {activeCard.content && <div>{activeCard.content}</div>}
      </Stack>
    </Component>
  );
}

const StyledButton = styled(Button)<{ active: boolean }>`
  ${truncateText(1)}

  padding: 0.3em;
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
  border-radius: 0;

  background-color: ${(props) => props.theme.color.gray.light};

  ${(props) =>
    props.active &&
    css`
      background-color: ${props.theme.color.gray.dark} !important;
      color: white !important;
    `}
`;

const Component = styled.div``;

export { OptionLabels };
