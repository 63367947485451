import useResizeObserver from '@react-hook/resize-observer';
import React from 'react';
import { useDrop } from 'react-dnd';
import styled from 'styled-components';

import { isElementOverflowed } from '../../../../js/utils/dom/element';
import { AppContext } from '../../../state/app-state';
import { ProjectContext } from '../../../state/project-state';
import { Icon, IconStyled } from '../../icon/icon';
import { BlockIcon } from '../folder-list-view/block-icon';
import { DropIcon } from '../folder-list-view/drop-icon';
import { DraggableItem } from '../move-file-folder-helper';
import { TreeContext } from '../state/folder-tree-state';

const FolderTreeHeader = () => {
  const nameRef = React.useRef<HTMLSpanElement>(null);
  const [title, setTitle] = React.useState('');
  const { currentUser } = React.useContext(AppContext);
  const {
    selectedFolderId,
    selectFolder: setSelectedFolderId,
    hasWriteAccessToFolder,
  } = React.useContext(TreeContext);
  const { project } = React.useContext(ProjectContext);

  useResizeObserver(nameRef, (entry) => {
    setTitle(isElementOverflowed(entry.target) ? project.name : '');
  });

  const [{ canDrop, isOver }, dropRef] = useDrop(() => ({
    accept: ['File', 'Folder'],
    drop: (item: DraggableItem) => {
      item.setTargetFolder({ id: null, name: project.name, projectId: project.id });
      item.dialog.show();
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: (item: DraggableItem) => {
      return (
        !item.selectedEntities.some((entity) => entity.parentId === null) &&
        hasWriteAccessToFolder(currentUser)
      );
    },
  }));

  return (
    <Component
      data-selected={selectedFolderId === undefined}
      hoveredWithDroppable={isOver}
      ref={dropRef}
      onClick={() => setSelectedFolderId(undefined)}
    >
      {isOver ? (
        canDrop ? (
          <DropIcon />
        ) : (
          <BlockIcon />
        )
      ) : (
        <Icon fixedWidth={true} icon={['fad', 'folder-open']} />
      )}
      <span ref={nameRef} title={title}>
        {project.name.toUpperCase()}
      </span>
    </Component>
  );
};

type StyledComponentProps = {
  hoveredWithDroppable?: boolean;
};

const Component = styled.header<Pick<StyledComponentProps, 'hoveredWithDroppable'>>`
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  width: 100%;
  padding: 0 10px;
  height: 46px; // Height of action bar.
  background-color: ${(props) =>
    props.hoveredWithDroppable ? props.theme.color.brand.dark : props.theme.color.brand.light};
  // Render above folder tree.
  z-index: 1;

  span {
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover,
  &[data-selected='true'] {
    cursor: pointer;
    background-color: ${(props) => props.theme.color.brand.dark};
  }

  ${IconStyled} {
    margin-right: 8px;
  }
`;

export { FolderTreeHeader };
