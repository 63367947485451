import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

import { permissionChecks } from '../../../js/utils/permission-check';
import { isDefined } from '../../../js/utils/variables';
import { AppContext } from '../../state/app-state';
import { ProjectContext } from '../../state/project-state';
import { reset } from '../../utils/styled-reset';
import { BreadCrumbItem, BreadCrumbs } from '../bread-crumbs/bread-crumbs';
import { TreeContext } from './state/folder-tree-state';

const FolderBreadCrumbs = () => {
  const { hasAdminRights } = React.useContext(AppContext);
  const { selectAndOpenFolder, selectedFolderChain } = React.useContext(TreeContext);
  const { project } = React.useContext(ProjectContext);

  const getBreadCrumbItems = () => {
    const items: BreadCrumbItem[] = [];

    // Root folder (project name).
    items.push({
      id: 'root',
      name: project.name.toUpperCase(),
      shared: false,
      onClick: selectedFolderChain.length > 0 ? () => selectAndOpenFolder() : undefined,
    });

    if (selectedFolderChain.length > 0) {
      // Sub folders but last.
      for (const item of selectedFolderChain.slice(0, -1)) {
        items.push({
          id: item.id,
          name: item.name,
          shared:
            permissionChecks.hasAccessToFeature('feature:file_manager:share_folder', [
              hasAdminRights,
            ]) && isDefined(item.sharedFolder?.id),
          onClick: () => selectAndOpenFolder(item.id),
        });
      }

      // Last folder.
      const lastItem = selectedFolderChain[selectedFolderChain.length - 1];
      items.push({
        id: lastItem.id,
        name: lastItem.name,
        shared:
          permissionChecks.hasAccessToFeature('feature:file_manager:share_folder', [
            hasAdminRights,
          ]) && isDefined(lastItem.sharedFolder?.id),
      });
    }

    return items;
  };

  return (
    <Component>
      <BreadCrumbs divider={<FontAwesomeIcon icon="caret-right" />}>
        {{ items: getBreadCrumbItems() }}
      </BreadCrumbs>
    </Component>
  );
};

const Component = styled.main`
  ${reset}
  font-size: 14px;
`;

export { FolderBreadCrumbs };
