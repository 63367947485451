import React from 'react';
import styled, { css } from 'styled-components';

import { isDefined } from '../../../js/utils/variables';
import { useDialog } from '../../hooks/use-dialog';
import {
  DateTimePickerDialog,
  DateTimePickerDialogProps,
} from '../date-time-picker-dialog/date-time-picker-dialog';
import { Icon } from '../icon/icon';
import { NoTranslateSpan } from '../no-translate/no-translate';
import { Stack } from '../stack/stack';

interface Props extends Omit<DateTimePickerDialogProps, 'onCancel' | 'startDate'> {
  collapseOnEmpty?: boolean;
  padding?: number;
  disabled?: boolean;
  placeholder?: string;
  initialValue?: Date;
  onCleared?: () => void;
  dateFormatter: (date?: Date) => string;
  onCancel?: () => void;
}

/**
 *  A small component wrapping the DateTimePickerDialog extending the functionality with displaying
 *  the DateTimePickerDialog picker and the selected values, as well as logic for clearing selected value. *
 */
const DateTimeInput = (props: Props) => {
  const dateTimePickerDialog = useDialog();
  const [date, setDate] = React.useState<Date | undefined>(props.initialValue);

  return (
    <Component
      aria-label="date-time-field-container"
      collapseOnEmpty={props.collapseOnEmpty}
      padding={props.padding}
      placeholder={props.placeholder}
      onClick={() => {
        if (props.disabled) {
          return;
        }
        dateTimePickerDialog.show();
      }}
    >
      <Stack alignItems="center" direction="row" spacing={0.5}>
        <NoTranslateSpan>
          <span style={{ whiteSpace: 'nowrap' }}>{props.dateFormatter(date)}</span>
        </NoTranslateSpan>
        {!isDefined(date) && <Icon icon={['far', 'calendar-days']} />}
        {isDefined(date) && isDefined(props.onCleared) && (
          <Icon
            icon={['far', 'xmark']}
            onClick={(e: React.MouseEvent<SVGSVGElement>) => {
              if (props.disabled) {
                return;
              }

              e.stopPropagation();
              dateTimePickerDialog.hide();

              setDate(undefined);
              props.onCleared?.();
            }}
          />
        )}
      </Stack>

      {dateTimePickerDialog.render(
        <DateTimePickerDialog
          displayTime={props.displayTime}
          maxDate={props.maxDate}
          minDate={props.minDate}
          startDate={date ?? new Date()}
          onCancel={() => {
            dateTimePickerDialog.hide();
            props.onCancel?.();
          }}
          onDateChoosen={(newDate: Date) => {
            dateTimePickerDialog.hide();
            setDate(newDate);
            props.onDateChoosen(newDate);
          }}
        />,
      )}
    </Component>
  );
};

type ComponentProps = Pick<Props, 'collapseOnEmpty' | 'padding'>;

const Component = styled.div<ComponentProps>`
  border: 1px solid black;
  padding: ${(props) => props.padding ?? 0.5}em;

  cursor: pointer;

  ${(props) =>
    props.collapseOnEmpty === false &&
    css`
      min-width: 164px;
    `}
`;

export { DateTimeInput, Props as DateTimeInputProps, Component as DateTimeInputStyle };
