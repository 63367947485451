import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Color } from 'three';
import _ from 'underscore';

import { publish, SubscriptionTopic } from '../../../../../../../../js/messaging/pubsub';
import { isDefined } from '../../../../../../../../js/utils/variables';
import { RevertChange } from '../../../../../../../../js/viewer/elements/design/design-node/design-node';
import { getAttributeValue } from '../../../../../../../../js/viewer/elements/design/design-node/utils/meta-data';
import {
  AttributeGroupEntry,
  AttributeId,
  SkyMapAttribute,
  ValueId,
} from '../../../../../../../../js/viewer/elements/design/skymap-extras';
import { DesignTool } from '../../../../../../../../js/viewer/tools/design-tool';
import { addAndCommitAction } from '../../../../../../../../js/viewer/tools/utils/context-menu/design-tool/utils/actions';
import {
  getAttributeDataOrAddValue,
  getEnumAttributeOrCreate,
  setAttributesOnSelection,
} from '../../../../../../../../js/viewer/tools/utils/context-menu/design-tool/utils/attributes';
import { SelectionResult } from '../../../../../../../../js/viewer/tools/utils/context-menu/design-tool/utils/select';
import { useDialog } from '../../../../../../../hooks/use-dialog';
import { truncateText } from '../../../../../../../utils/styled-helpers';
import { Button } from '../../../../../../button/button';
import { CheckBox } from '../../../../../../check-box/check-box';
import { Icon } from '../../../../../../icon/icon';
import { InputDialog } from '../../../../../../input-dialog/input-dialog';
import { LabelledContainer } from '../../../../../../labelled-container/labelled-container';
import { NoTranslateSpan } from '../../../../../../no-translate/no-translate';
import { Select } from '../../../../../../select/select';
import { Stack } from '../../../../../../stack/stack';
import { Tooltip } from '../../../../../../tooltip/tooltip';
import { NewAttributeDialog } from '../../dialogs/new-attribute-dialog';
import { useAttributes } from '../../hooks/use-attributes';
import { EditAttribute } from './edit-attribute';

function createAttributeGroup(
  tool: DesignTool,
  csvResult: { name: string; headers: string[]; rows: string[][] },
) {
  let changes: RevertChange[] = [];

  addAndCommitAction(tool, {
    commit: () => {
      changes = [];
      const attributeRows: AttributeGroupEntry[] = [];

      const headerAttributes: SkyMapAttribute[] = csvResult.headers.map((x) => {
        const result = getEnumAttributeOrCreate(tool, x);
        if (isDefined(result.change)) {
          changes.unshift(result.change);
        }
        return result.attribute;
      });

      const nameIdx =
        headerAttributes.findIndex((x) =>
          ['kod', 'code', 'name', 'namn'].includes(x.name.toLowerCase()),
        ) ?? 0;
      const descriptionIndex = headerAttributes.findIndex((x) =>
        ['beskrivning', 'description'].includes(x.name.toLowerCase()),
      );

      for (const row of csvResult.rows) {
        const rowData: AttributeGroupEntry = { displayName: '', data: new Map() };
        let name = `Value ${attributeRows.length + 1}`;
        let description: string | undefined;

        for (let i = 0; i < headerAttributes.length; i++) {
          const attribute = headerAttributes[i];
          const result = getAttributeDataOrAddValue(attribute, row[i]);

          if (isDefined(result)) {
            if (i === nameIdx) {
              name = getAttributeValue(attribute, result.attributeData);
            }
            if (i === descriptionIndex) {
              description = getAttributeValue(attribute, result.attributeData);
            }

            if (isDefined(result.change)) {
              changes.unshift(result.change);
            }

            rowData.data.set(attribute.id, result.attributeData);
          }
        }

        rowData.displayName = name + (description ? ` - ${description}` : '');
        attributeRows.push(rowData);
      }

      changes.unshift(tool.asset.addAttributeGroup(csvResult.name, attributeRows));
      tool.asset.designNode.refresh();
      tool.asset.refreshMetadata();
      publish(SubscriptionTopic.DesignAttributesChanged, undefined);
    },
    rollback: () => {
      tool.asset.designNode.revert(changes);
      tool.asset.designNode.refresh();
      tool.asset.refreshMetadata();
      publish(SubscriptionTopic.DesignAttributesChanged, undefined);
    },
  });
}

function createNewAttribute(
  tool: DesignTool,
  name: string,
  type: string,
  values: Map<ValueId, string>,
  colors: Map<ValueId, Color>,
  unit: any,
) {
  let changes: RevertChange[] = [];

  const attributes = tool.asset.getAttributes();
  const nextAttributeId = (
    attributes.length > 0 ? _.max(attributes.map((x) => x.id)) + 1 : 0
  ) as AttributeId;

  if (isDefined(attributes.find((x) => x.name === name))) {
    throw new Error('Error creating attribute. Attribute with name already exists.');
  }

  addAndCommitAction(tool, {
    commit: () => {
      changes = [];

      if (type === 'enum') {
        changes.unshift(
          tool.asset.addAttribute({
            id: nextAttributeId,
            type: 'enum',
            colorMap: colors,
            valueMap: values,
            name,
            source: 'skymap-design',
          }),
        );
      } else if (type === 'text') {
        changes.unshift(
          tool.asset.addAttribute({
            id: nextAttributeId,
            type: 'string',
            name,
            source: 'skymap-design',
          }),
        );
      } else {
        changes.unshift(
          tool.asset.addAttribute({
            id: nextAttributeId,
            type: 'number',
            unit,
            name,
            source: 'skymap-design',
          }),
        );
      }

      publish(SubscriptionTopic.DesignAttributesChanged, undefined);
    },
    rollback: () => {
      tool.asset.designNode.revert(changes);
      publish(SubscriptionTopic.DesignAttributesChanged, undefined);
    },
  });

  return nextAttributeId;
}

export const AttributeList = (props: { tool: DesignTool }) => {
  const { t } = useTranslation();
  const newAttributeDialog = useDialog();
  const renameDialog = useDialog();
  const [activeAttributeId, setActiveAttributeId] = useState<AttributeId>();
  const { attributes, attributeGroups } = useAttributes(props.tool);
  const [useColorCodes, setUseColorCodes] = useState(props.tool.asset.colorCoding.enabled);
  const [colorCodingAttributeId, setColorCodingAttributeId] = useState<AttributeId | undefined>(
    props.tool.asset.colorCoding.attributeId,
  );

  const refresh = useCallback(() => {
    const colorCodeAttribute = attributes.find(
      (x) => x.id === props.tool.asset.colorCoding.attributeId,
    );
    setUseColorCodes(props.tool.asset.colorCoding.enabled);
    setColorCodingAttributeId(colorCodeAttribute?.id);
  }, [props, attributes]);

  useEffect(refresh, [refresh]);

  const toggleActiveAttribute = (newId?: AttributeId) => {
    const id = newId === activeAttributeId ? undefined : newId;
    setActiveAttributeId(id);
  };

  return (
    <Stack spacing={0.8}>
      <Tooltip
        content={t('section.attributes.attributeList.content.useColorCodingTooltip.text', {
          ns: 'skyviewDesign',
        })}
        mainAxisOffset={22}
        placement="left"
        title={t('section.attributes.attributeList.content.useColorCodingTooltip.title', {
          ns: 'skyviewDesign',
        })}
        wrapChildren={true}
      >
        <CheckBox
          checked={useColorCodes}
          onChange={() => {
            props.tool.asset.colorCoding.enabled = !useColorCodes;
            setUseColorCodes(!useColorCodes);
            const attributeId =
              props.tool.asset.colorCoding.attributeId ??
              attributes.find((x) => x.type === 'enum')?.id;
            props.tool.asset.setColorCoding({
              enabled: !useColorCodes,
              attributeId,
            });
            setColorCodingAttributeId(attributeId);
            props.tool.asset.designNode.refresh();
          }}
        >
          {t('section.attributes.attributeList.content.useColorCodingCheckBox', {
            ns: 'skyviewDesign',
          })}
        </CheckBox>
      </Tooltip>

      {useColorCodes && (
        <Select
          options={attributes
            .filter((x) => x.type === 'enum')
            .map((x) => {
              return {
                id: x.id.toString(),
                name: x.name,
              };
            })}
          padding={0.2}
          placeholderText={t(
            'section.attributes.attributeList.content.selectAttributeForColorCoding',
            {
              ns: 'skyviewDesign',
            },
          )}
          value={colorCodingAttributeId?.toString()}
          onChange={(e) => {
            const id = e.target.value;
            const attributeId = Number(id) as AttributeId;
            setColorCodingAttributeId(Number(id) as AttributeId);

            props.tool.asset.setColorCoding({
              enabled: props.tool.asset.colorCoding.enabled,
              attributeId,
            });

            if (props.tool.asset.colorCoding.enabled) {
              props.tool.asset.designNode.refresh();
            }
          }}
        />
      )}

      {attributeGroups.length > 0 && (
        <NoTranslateSpan>
          <LabelledContainer
            text={t('section.attributes.attributeList.content.codeListsTitle', {
              ns: 'skyviewDesign',
            })}
          >
            {attributeGroups.map((x, idx) => (
              <AttributeRowContainer key={idx}>
                <AttributeRow
                  active={false}
                  aria-labelledby={`attribute-group-${idx.toString()}`}
                  type={'enum'}
                >
                  <AttributeName id={`attribute-group-${idx.toString()}`} title={x.name}>
                    {x.name}
                  </AttributeName>

                  <Icon
                    fixedWidth={true}
                    icon={['fal', 'trash-alt']}
                    mouseDownStyle={{ opacity: 0.5 }}
                    title={t('section.attributes.attributeList.content.deleteCodeListIconTooltip', {
                      ns: 'skyviewDesign',
                    })}
                    onClick={(e) => {
                      e.stopPropagation();
                      const changes: RevertChange[] = [];

                      addAndCommitAction(props.tool, {
                        commit: () => {
                          changes.unshift(props.tool.asset.removeAttributeGroup(x.name));
                          props.tool.asset.designNode.refresh();
                          publish(SubscriptionTopic.DesignAttributesChanged, undefined);
                        },
                        rollback: () => {
                          props.tool.asset.designNode.revert(changes);
                          props.tool.asset.designNode.refresh();
                          publish(SubscriptionTopic.DesignAttributesChanged, undefined);
                        },
                      });
                    }}
                    onHoverStyle={{ icon: ['fad', 'trash-alt'] }}
                  />
                </AttributeRow>
              </AttributeRowContainer>
            ))}
          </LabelledContainer>
        </NoTranslateSpan>
      )}

      <section>
        <NoTranslateSpan>
          <LabelledContainer text={t('section.attributes.title', { ns: 'skyviewDesign' })}>
            {attributes.map((x) => (
              <AttributeRowContainer key={x.id}>
                <AttributeRow
                  active={activeAttributeId === x.id}
                  aria-labelledby={`attribute-${x.id.toString()}`}
                  type={x.type}
                  onClick={() => toggleActiveAttribute(x.id)}
                >
                  <AttributeName id={`attribute-${x.id.toString()}`} title={x.name}>
                    {x.name}
                  </AttributeName>

                  <Icon
                    fixedWidth={true}
                    icon={['fal', 'pencil']}
                    mouseDownStyle={{ opacity: 0.5 }}
                    title={t(
                      'section.attributes.attributeList.content.changeAttributeNameIconTooltip',
                      {
                        ns: 'skyviewDesign',
                      },
                    )}
                    onClick={(e) => {
                      e.stopPropagation();
                      setActiveAttributeId(x.id);
                      renameDialog.show();
                    }}
                    onHoverStyle={{ icon: ['fad', 'pencil'] }}
                  />

                  <Icon
                    fixedWidth={true}
                    icon={['fal', 'trash-alt']}
                    mouseDownStyle={{ opacity: 0.5 }}
                    title={t(
                      'section.attributes.attributeList.content.deleteAttributeIconTooltip',
                      { ns: 'skyviewDesign' },
                    )}
                    onClick={(e) => {
                      e.stopPropagation();
                      let changes: RevertChange[] = [];
                      const newAttributes = new Map<AttributeId, false>();

                      const idToRemove = x.id;

                      newAttributes.set(idToRemove, false);
                      const selection: SelectionResult = {
                        points: props.tool.asset.designNode.points,
                        lineSegments: props.tool.asset.designNode.lineSegments,
                        faces: props.tool.asset.designNode.faces,
                      };

                      addAndCommitAction(props.tool, {
                        commit: () => {
                          changes = setAttributesOnSelection(props.tool, selection, newAttributes);
                          changes.unshift(props.tool.asset.removeAttribute(idToRemove));

                          props.tool.asset.designNode.refresh();
                          publish(SubscriptionTopic.DesignAttributesChanged, undefined);
                        },
                        rollback: () => {
                          props.tool.asset.designNode.revert(changes);
                          props.tool.asset.designNode.refresh();
                          publish(SubscriptionTopic.DesignAttributesChanged, undefined);
                        },
                      });
                      toggleActiveAttribute(undefined);
                    }}
                    onHoverStyle={{ icon: ['fad', 'trash-alt'] }}
                  />
                </AttributeRow>

                {activeAttributeId === x.id && x.type !== 'string' && (
                  <EditAttribute attribute={x} tool={props.tool} />
                )}
              </AttributeRowContainer>
            ))}
          </LabelledContainer>
        </NoTranslateSpan>

        <NewAttributeButton
          color="secondary"
          leftIcon={{ icon: ['fal', 'plus'] }}
          variant="contained"
          onClick={() => newAttributeDialog.show()}
        >
          {t('section.attributes.attributeList.content.newAttributeButton', {
            ns: 'skyviewDesign',
          })}
        </NewAttributeButton>
      </section>

      {renameDialog.render(
        <InputDialog
          initialValue={
            isDefined(activeAttributeId)
              ? props.tool.asset.getAttribute(activeAttributeId).name
              : ''
          }
          title={t('section.attributes.attributeList.content.renameAttributeDialog.title', {
            ns: 'skyviewDesign',
          })}
          width={300}
          onCancel={() => renameDialog.hide()}
          onConfirm={(newName) => {
            const attribute = isDefined(activeAttributeId)
              ? props.tool.asset.getAttribute(activeAttributeId)
              : undefined;

            if (!isDefined(attribute) || isDefined(attributes.find((x) => x.name === newName))) {
              throw Error(
                t(
                  'section.attributes.attributeList.content.renameAttributeDialog.nameAlreadyExists',
                  { ns: 'skyviewDesign' },
                ) as string,
              );
            }

            const oldName = attribute.name;

            addAndCommitAction(props.tool, {
              commit: () => {
                attribute.name = newName;
                publish(SubscriptionTopic.DesignAttributesChanged, undefined);
              },
              rollback: () => {
                attribute.name = oldName;
                publish(SubscriptionTopic.DesignAttributesChanged, undefined);
              },
            });

            renameDialog.hide();
          }}
        />,
      )}

      {newAttributeDialog.render(
        <NewAttributeDialog
          tool={props.tool}
          onClose={() => {
            newAttributeDialog.hide();
          }}
          onCreateNewAttribute={(tool, name, type, values, colors, unit, csvResult) => {
            if (isDefined(csvResult)) {
              createAttributeGroup(tool, csvResult);
              return;
            } else {
              const nextAttributeId = createNewAttribute(tool, name, type, values, colors, unit);
              toggleActiveAttribute(nextAttributeId);
            }
            toggleActiveAttribute(undefined);
          }}
        />,
      )}
    </Stack>
  );
};

const NewAttributeButton = styled(Button)`
  margin-top: 1em;
`;

const AttributeRowContainer = styled.div`
  box-shadow:
    0 1px 3px rgb(0 0 0 / 12%),
    0 1px 2px rgb(0 0 0 / 24%);
`;

const AttributeRow = styled.div<{ active: boolean; type: 'number' | 'string' | 'enum' }>`
  display: flex;
  gap: 0.2em;
  direction: row;
  padding: 0.3em;
  background-color: ${(props) => props.theme.color.gray.lightest};

  span {
    flex-grow: 1;
  }

  ${(props) =>
    props.active &&
    css`
      background-color: ${props.theme.color.brand.light};
    `}

  &:hover {
    background-color: ${(props) => props.theme.color.brand.light};
  }
`;

const AttributeName = styled.span`
  ${truncateText(1)}
`;
