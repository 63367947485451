import type * as CSS from 'csstype';
import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { assertNever, isDefined } from '../../../js/utils/variables';
import { usePagination } from '../../hooks/use-pagination';
import { Icon } from '../icon/icon';
import { Stack } from '../stack/stack';
import { Table } from '../table/table';
import { Props as TableCellProps } from '../table/table-cell';
import { HeaderCellProps } from '../table/table-header-cell';
import { TextBox } from '../text-box/text-box';
import { EllipsisContainer } from './ellipsis-container';
import { getNumberOfPages, Pagination } from './pagination';

export enum DataTableSortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum DataTableSortType {
  DATE = 'DATE',
  NUMBER = 'NUMBER',
  STRING = 'STRING',
}

export type DataTableSortOptions = {
  sortOrder?: DataTableSortOrder;
  type: DataTableSortType;
  /**
   * Sorts by another key than the displayed one.
   */
  columnKey?: string;
};

export type DataTableColumns<T extends Record<string, any>> = {
  [K in keyof T]?: {
    title?: string;
    formatter?: (value: T[K]) => string;
    unfilterable?: boolean;
    alignment?: 'left' | 'center' | 'right';
    padding?: CSS.Properties['padding'];
    sortableBy?: DataTableSortOptions;
    monospaced?: 'montserrat' | 'roboto';
    width?: CSS.Properties['width'];
    shrink?: boolean;
    hidden?: boolean;
  };
};

export type DataTableColumnSorting = {
  columnKey: string;
  order: DataTableSortOrder;
};

type Props<T extends Record<string, any>> = {
  columns: DataTableColumns<T>;
  items: T[];
  onListChanged?: (items: T[], filteredItems: T[]) => void;
  flip?: boolean;
  onRowClicked?: (item: T) => void | Promise<void>;
  tableStyle?: {
    headerCell?: HeaderCellProps;
    rowCell?: TableCellProps;
  };
  pageSize?: number;
  headerSeparator?: boolean;

  /**
   * Whether to use the fixed table layout algorithm or not. All cells with text content only
   * (string) will have ellipsis on overflow and title set to the text content when overflowed.
   *
   * Value is ignored if flip argument is set to true.
   */
  fixedLayout?: boolean;
};

export type ActiveColumnFilters<T> = {
  [K in keyof T]: string | undefined;
};

const getDefaultSortingSettings = <T extends Record<string, string>>(
  columns: DataTableColumns<T>,
) => {
  const sortableColumns = Object.keys(columns)
    .filter((key) => columns[key]?.sortableBy?.sortOrder)
    .map(
      (key) =>
        ({
          columnKey: key,
          order: columns[key]?.sortableBy?.sortOrder,
        }) as DataTableColumnSorting,
    );

  if (sortableColumns.length > 1) {
    throw new Error('Only one column can have sortOrder option');
  }

  return sortableColumns?.[0];
};

const sortDates = <T extends Record<string, string>>(
  items: T[],
  columnKey: keyof T,
  order: DataTableSortOrder,
) => {
  return [...items].sort(
    order === DataTableSortOrder.ASC
      ? (a, b) => new Date(a[columnKey]).getTime() - new Date(b[columnKey]).getTime()
      : (a, b) => new Date(b[columnKey]).getTime() - new Date(a[columnKey]).getTime(),
  );
};

const sortStrings = <T extends Record<string, string>>(
  items: T[],
  columnKey: keyof T,
  order: DataTableSortOrder,
) => {
  return [...items].sort(
    order === DataTableSortOrder.DESC
      ? (a, b) => b[columnKey].localeCompare(a[columnKey])
      : (a, b) => a[columnKey].localeCompare(b[columnKey]),
  );
};

/**
 * Sorts numbers. Undefined values will be sorted last.
 */
const sortNumbers = <T extends Record<string, string>>(
  items: T[],
  columnKey: keyof T,
  order: DataTableSortOrder,
) => {
  const max = Number.MAX_SAFE_INTEGER / 2;
  const min = Number.MIN_SAFE_INTEGER / 2;

  return [...items].sort(
    order === DataTableSortOrder.DESC
      ? (a, b) => Number(b[columnKey] ?? min) - Number(a[columnKey] ?? min)
      : (a, b) => Number(a[columnKey] ?? max) - Number(b[columnKey] ?? max),
  );
};

function getSortFunction(type?: DataTableSortType) {
  if (!isDefined(type)) {
    return;
  }

  switch (type) {
    case DataTableSortType.DATE:
      return sortDates;
    case DataTableSortType.STRING:
      return sortStrings;
    case DataTableSortType.NUMBER:
      return sortNumbers;
    default:
      assertNever(type);
  }
}

const rotateOrder = (order: DataTableSortOrder): DataTableSortOrder =>
  order === DataTableSortOrder.ASC ? DataTableSortOrder.DESC : DataTableSortOrder.ASC;

const SortingIcon = (props: { order: DataTableSortOrder }) => (
  <Icon icon={props.order === DataTableSortOrder.ASC ? 'caret-up' : 'caret-down'} />
);

const DataTable = <T extends Record<string, any>>(props: Props<T>) => {
  const [activeFilters, setActiveFilters] = React.useState<Partial<ActiveColumnFilters<T>>>({});
  const [sortingSettings, setSortingSettings] = React.useState<DataTableColumnSorting>();
  const [filteredItems, setFilteredItems] = React.useState<T[]>([]);
  const [sortedAndFilteredItems, setSortedAndFilteredItems] = React.useState<T[]>([]);
  const { currentPage, onPageChange, paginatedItems } = usePagination(
    props.pageSize,
    sortingSettings ? sortedAndFilteredItems : filteredItems,
    sortingSettings,
  );

  useEffect(() => {
    const sortingSettings = getDefaultSortingSettings(props.columns);
    if (sortingSettings) {
      setSortingSettings(sortingSettings);
    }
  }, [props.columns]);

  const onSort = (columnKey: string) => {
    const order: DataTableSortOrder =
      columnKey === sortingSettings?.columnKey
        ? rotateOrder(sortingSettings?.order)
        : DataTableSortOrder.ASC;
    setSortingSettings({ columnKey: columnKey, order: order });
  };

  // This generates the following error:
  // "Argument of type 'string | number | null | undefined' is not assignable to parameter of type 'never'."
  // Code compiles and works, and the usage hinders columnKey to ever be 'never'.
  const resolveRowValue = useCallback(
    (
      columnKey: keyof T,
      item: T,
      // @ts-ignore
    ) => props.columns[columnKey]?.formatter?.(item[columnKey]) ?? String(item[columnKey]),
    [props.columns],
  );

  useEffect(() => {
    const resolveItemFiltered = (item: T) => {
      const filters = Object.entries(activeFilters).filter((entry) => entry[1] !== '') as [
        keyof T,
        string,
      ][];
      return filters.some(
        (entry) => !resolveRowValue(entry[0], item).toLowerCase().includes(entry[1].toLowerCase()),
      );
    };

    // Filter the rows based on the active filters.
    // Returns a subset of the rows as well as the filtered rows;
    const [items, filteredItems] = props.items.reduce(
      (result: [T[], T[]], item: T): [T[], T[]] => {
        if (!resolveItemFiltered(item)) {
          result[0].push(item);
        } else {
          result[1].push(item);
        }

        return result;
      },
      [[], []],
    );
    props.onListChanged?.(items, filteredItems);
    setFilteredItems(items);
  }, [activeFilters, props, props.items, resolveRowValue]);

  useEffect(() => {
    // Sort the filtered rows based on current sorting settings
    if (sortingSettings) {
      const sortableBy = props.columns[sortingSettings.columnKey]?.sortableBy;
      if (sortableBy?.type) {
        const sortFunction = getSortFunction(sortableBy.type);
        const sortedItems = sortFunction?.(
          [...filteredItems],
          sortableBy.columnKey ?? sortingSettings.columnKey,
          sortingSettings.order,
        ) ?? [...filteredItems];
        setSortedAndFilteredItems(sortedItems);
      }
    }
  }, [props, props.items, filteredItems, sortingSettings]);

  const refinedItems = sortingSettings ? sortedAndFilteredItems : filteredItems;
  const renderedItems =
    props.pageSize && currentPage && paginatedItems ? paginatedItems : refinedItems;

  if (props.flip) {
    return (
      <Component>
        <Table>
          <Table.Body>
            {Object.keys(props.columns).map(
              (columnKey, columnIndex) =>
                props.columns[columnKey]?.hidden !== true && (
                  <Table.Row borderTop={false} key={columnIndex}>
                    <Table.Head>
                      <Table.HeaderCell
                        borderBottom={false}
                        key={columnIndex}
                        whiteSpace={'nowrap'}
                        {...props.tableStyle?.headerCell}
                      >
                        {props.columns[columnKey]?.title}
                      </Table.HeaderCell>
                    </Table.Head>
                    {renderedItems.map((item, columnIndex) => (
                      <Table.Cell
                        className="notranslate"
                        key={columnIndex}
                        wrapping={'none'}
                        {...props.tableStyle?.rowCell}
                      >
                        {React.isValidElement(item[columnKey] as React.ReactNode)
                          ? item[columnKey]
                          : resolveRowValue(columnKey as keyof T, item)}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                ),
            )}
          </Table.Body>
        </Table>
      </Component>
    );
  }

  return (
    <Component>
      <Table fixedLayout={props.fixedLayout}>
        <Table.Head>
          <Table.Row>
            {(Object.keys(props.columns) as (keyof T)[]).map((columnKey, index) => {
              const column = props.columns[columnKey];

              return (
                column?.hidden !== true && (
                  <Table.HeaderCell
                    borderBottom={props.headerSeparator ?? true}
                    key={index}
                    whiteSpace={'nowrap'}
                    width={column?.width}
                    onClick={
                      column?.sortableBy && typeof columnKey === 'string'
                        ? () => onSort(columnKey)
                        : undefined
                    }
                    {...props.tableStyle?.headerCell}
                  >
                    {column?.sortableBy ? (
                      <SortableColumnHeader direction="row" spacing={0.5}>
                        <EllipsisContainer text={column?.title ?? ''} />
                        {sortingSettings?.columnKey === columnKey && (
                          <SortingIcon order={sortingSettings.order} />
                        )}
                      </SortableColumnHeader>
                    ) : (
                      <EllipsisContainer text={column?.title ?? ''} />
                    )}

                    {!column?.unfilterable && (
                      <TextBox
                        onChange={(e) =>
                          setActiveFilters({ ...activeFilters, [columnKey]: e.target.value })
                        }
                      />
                    )}
                  </Table.HeaderCell>
                )
              );
            })}
          </Table.Row>
        </Table.Head>

        <Table.Body>
          {renderedItems.map((item, rowKey) => (
            <Table.Row key={rowKey} onClick={() => props.onRowClicked?.(item)}>
              {Object.keys(props.columns).map((columnKey, columnIndex) => {
                const column = props.columns[columnKey];

                if (column?.hidden === true) {
                  return null;
                }
                if (React.isValidElement(item[columnKey] as React.ReactNode)) {
                  return (
                    <Table.Cell key={columnIndex} wrapping={'none'} {...props.tableStyle?.rowCell}>
                      <Stack
                        alignItems={
                          column?.alignment === 'left'
                            ? 'flex-start'
                            : column?.alignment === 'center'
                              ? 'center'
                              : 'flex-end'
                        }
                        justifyContent="center"
                        key={columnKey}
                        spacing={0.5}
                        {...(column?.padding ? { padding: column?.padding } : {})}
                      >
                        {item[columnKey]}
                      </Stack>
                    </Table.Cell>
                  );
                }

                const value = resolveRowValue(columnKey as keyof T, item);
                return (
                  <Table.Cell
                    className="notranslate"
                    key={columnIndex}
                    monospaced={column?.monospaced}
                    textAlign={column?.alignment}
                    wrapping={'none'}
                    {...props.tableStyle?.rowCell}
                  >
                    {props.fixedLayout ? <EllipsisContainer text={value} /> : value}
                  </Table.Cell>
                );
              })}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      {props?.pageSize &&
        currentPage &&
        getNumberOfPages(refinedItems.length, props.pageSize) > 1 && (
          <Pagination
            currentPage={currentPage}
            numberOfItems={refinedItems.length}
            pageSize={props.pageSize}
            onPageChange={(page) => onPageChange(page)}
          />
        )}
    </Component>
  );
};

const Component = styled.div`
  overflow: auto;

  table {
    font-size: 12px;
  }
`;

const SortableColumnHeader = styled(Stack)`
  user-select: none;
  &:hover {
    cursor: pointer;
  }
`;

export { DataTable, Props as DataTableProps };
