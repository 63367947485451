import { TFunction } from 'i18next';
import PropTypes from 'prop-types';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

import { CompanyAdmin } from '../../../../../typings/api/skymap/rest/v0/company';
import { publish, SubscriptionTopic } from '../../../../js/messaging/pubsub';
import { SkyMapAxiosServiceFactory } from '../../../../js/services/axios/skymap-axios-service-factory';
import { CompanyStore } from '../../../../js/stores/company.store';
import { UserStore } from '../../../../js/stores/user-store';
import { formattedDateTime } from '../../../../js/utils/dateUtils';
import { sessionStorageUtils } from '../../../../js/utils/session-storage-utils';
import { isDefined } from '../../../../js/utils/variables';
import { useDialog } from '../../../hooks/use-dialog';
import { Button } from '../../button/button';
import { ConfirmationDialog } from '../../confirmation-dialog/confirmation-dialog';
import { DataTable, DataTableColumns, DataTableSortType } from '../../data-table/data-table';
import { Icon } from '../../icon/icon';
import { InviteCompanyAdminDialog } from '../../invite-company-admin/invite-company-admin-dialog';
import { Stack } from '../../stack/stack';
import { TextBox } from '../../text-box/text-box';

const pageSize = 15;

export interface CompanyAdminTableView extends Record<string, any> {
  name: string;
  userId: string;
  email: string;
  createdAt: Date;
  createdAtText: string;
  deleteIcon: ReactNode;
}

function getColumns(
  isOrganisationAdmin: boolean,
  t: TFunction,
): DataTableColumns<CompanyAdminTableView> {
  const columns: DataTableColumns<CompanyAdminTableView> = {
    name: {
      sortableBy: { type: DataTableSortType.STRING },
      title: t('name', { ns: 'common' }),
      unfilterable: true,
    },
    email: {
      sortableBy: { type: DataTableSortType.STRING },
      title: t('newCompanyAdmin.newUser.form.email', { ns: 'components' }),
      unfilterable: true,
    },
    createdAt: {
      sortableBy: { type: DataTableSortType.DATE },
      title: t('licenses.createdAt', { ns: 'companies' }),
      formatter: (value) => formattedDateTime(value),
      unfilterable: true,
    },
  };

  if (isOrganisationAdmin) {
    columns.deleteIcon = {
      alignment: 'right',
      unfilterable: true,
      width: '40px',
    };
  }

  return columns;
}

function DeleteCompanyAdminIcon() {
  return (
    <Icon
      fixedWidth={true}
      icon={['fad', 'trash-alt']}
      onHoverStyle={{ icon: ['fas', 'trash-alt'] }}
    />
  );
}

const createTableView = (admins: CompanyAdmin[]): CompanyAdminTableView[] =>
  admins.map((admin) => ({
    name: admin.name,
    userId: admin.userId,
    email: admin.email,
    createdAt: new Date(admin.createdAt),
    createdAtText: formattedDateTime(admin.createdAt),
    deleteIcon: DeleteCompanyAdminIcon(),
  }));

const searchColumns = ['type', 'status', 'belongsTo', 'createdAtText', 'validUntilText'] as const;

function filterItems(items: CompanyAdminTableView[], searchText: string) {
  return items.filter((item) => {
    const searchValue = searchText.trim().toLowerCase();
    return searchColumns.some((x) => (item[x] ?? '').toLowerCase().includes(searchValue));
  });
}

/**
 * Hook that preserves text in search during the user session.
 */
function useCompanyAdminSearchFilter() {
  const [searchText, setSearchText] = useState(
    sessionStorageUtils.getUserItemOrDefault('companyAdminListSearchText', ''),
  );

  useEffect(() => {
    sessionStorageUtils.setUserItem('companyAdminListSearchText', searchText);
  }, [searchText]);

  return { searchText, setSearchText };
}

export const CompanyAdmins = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const company = useRef(CompanyStore.instance.company);
  const deleteAdminDialog = useDialog();
  const inviteAdminDialog = useDialog();
  const [selectedItem, setSelectedItem] = useState<CompanyAdminTableView>();

  const [fullTableView, setFullTableView] = useState(
    createTableView(company.current.companyAdmins),
  );
  const [tableView, setTableView] = useState<CompanyAdminTableView[]>(fullTableView);
  const { searchText, setSearchText } = useCompanyAdminSearchFilter();

  const columns = useRef(getColumns(UserStore.instance.isOrganizationAdmin(), t));

  useEffect(() => {
    const items = filterItems(fullTableView, searchText);
    setTableView(items);
  }, [searchText, theme, fullTableView]);

  return (
    <>
      <Title>
        {t('companyAdmins.title', { ns: 'companies' })} - {company.current.name}
      </Title>
      <Stack alignItems="baseline" direction="row" justifyContent="space-between" spacing={0.5}>
        <SearchFilterContainer direction="row" spacing={0.5}>
          <TextBox
            placeholder={t('search', { ns: 'common' })}
            value={searchText}
            width={250}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </SearchFilterContainer>
        <Button color="primary" variant="contained" onClick={() => inviteAdminDialog.show()}>
          {t('companyAdmins.inviteCompanyAdmin.title', { ns: 'companies' })}
        </Button>
      </Stack>

      <DataTable
        columns={columns.current}
        fixedLayout={false}
        items={tableView}
        pageSize={pageSize}
        tableStyle={{
          rowCell: {
            fontSize: '14px',
          },
          headerCell: {
            fontSize: '14px',
          },
        }}
        onRowClicked={
          UserStore.instance.isOrganizationAdmin()
            ? (item) => {
                setSelectedItem(item);
                deleteAdminDialog.show();
              }
            : undefined
        }
      />

      {inviteAdminDialog.render(<InviteCompanyAdminDialog dialog={inviteAdminDialog} />)}

      {isDefined(selectedItem) &&
        deleteAdminDialog.render(
          <ConfirmationDialog
            maxWidth={500}
            negative={true}
            title="Ta bort roll"
            onCancel={deleteAdminDialog.hide}
            onConfirm={async () => {
              const userId = selectedItem.userId;

              await SkyMapAxiosServiceFactory.instance.createCompanyServiceV0().deleteCompanyAdmin({
                path: { companyId: company.current.id, userId },
              });

              company.current.companyAdmins = company.current.companyAdmins.filter(
                (x) => x.userId !== userId,
              );

              setSelectedItem(undefined);
              setFullTableView(createTableView(company.current.companyAdmins));

              publish(SubscriptionTopic.ToastrMessage, {
                type: 'success',
                message: 'Rollen togs bort från användaren.',
              });
              deleteAdminDialog.hide();
            }}
          >
            <span>
              Vill du ta bort rollen <strong>företagsadmin</strong> från{' '}
              <strong>{selectedItem.name}</strong>?
            </span>
          </ConfirmationDialog>,
        )}
    </>
  );
};

// Styling is made to match the other pages in angular.
const Title = styled.h1`
  font-size: 19px;
  font-weight: bold;
  text-transform: uppercase;
`;

const SearchFilterContainer = styled(Stack)`
  width: 450px;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

CompanyAdmins.propTypes = {
  wrapWithLanguageProvider: PropTypes.any,
};
