import useResizeObserver from '@react-hook/resize-observer';
import type * as CSS from 'csstype';
import React from 'react';
import styled, { css } from 'styled-components';

import { isElementOverflowed } from '../../../../../js/utils/dom/element';
import { isDefined } from '../../../../../js/utils/variables';
import { truncateText } from '../../../../utils/styled-helpers';
import { numberOrDefault } from '../../asset-menu/utils/formatting';

type Props = {
  height: number | string;
  surface?: {
    color: CSS.Properties['color'];
    height: CSS.Properties['height'];
    planeArea: number;
  };
};

const ColorBarSection = (props: Props) => {
  const [heightTitle, setHeightTitle] = React.useState<string>();
  const heightRef = React.createRef<HTMLSpanElement>();

  const [areaTitle, setAreaTitle] = React.useState<string>();
  const areaRef = React.createRef<HTMLSpanElement>();

  useResizeObserver(heightRef, (entry) => {
    setHeightTitle(isElementOverflowed(entry.target) ? height : undefined);
  });

  useResizeObserver(areaRef, (entry) => {
    setAreaTitle(isElementOverflowed(entry.target) ? planeArea : undefined);
  });

  const height =
    typeof props.height === 'string'
      ? props.height
      : numberOrDefault(props.height, 'm', 2, 'exceptZero');
  const planeArea = props.surface ? numberOrDefault(props.surface.planeArea, 'm²', 2) : undefined;

  return (
    <Component hasColor={isDefined(props.surface)} height={props.surface?.height}>
      <HeightLabel ref={heightRef} title={heightTitle}>
        {height}
      </HeightLabel>
      <ColorBox color={props.surface?.color} title={areaTitle}>
        <span ref={areaRef}>{planeArea}</span>
      </ColorBox>
    </Component>
  );
};

const Component = styled.div<{ hasColor: boolean; height?: CSS.Properties['height'] }>`
  display: inline-flex;
  gap: 1em;
  height: ${(props) => props.height};
  min-height: 3em;

  // If no color exists, set height to half of font size to make height reflect the portion of the
  // height label that is visible (label moved up by 50%).
  ${(props) =>
    !props.hasColor &&
    css`
      height: 0.5em;
      min-height: 0;
    `}
`;

const HeightLabel = styled.span`
  ${truncateText(1)}
  align-self: flex-start;
  text-align: right;
  transform: translateY(-50%);
  width: 8em;
  font-feature-settings: 'tnum';
`;

const ColorBox = styled.div<{ color: CSS.Properties['color'] }>`
  --section-separator-overflow-by: 0.5em;

  position: relative;
  width: 10em;
  background-color: ${(props) => props.color};
  display: grid;
  place-content: center right;

  &::before {
    content: '';
    position: absolute;
    width: calc(100% + var(--section-separator-overflow-by));
    left: calc(-1 * var(--section-separator-overflow-by));
    height: 1px;
    background-color: #000;
  }

  span {
    ${truncateText(1)}
    padding: 0.3em 0.8em;
    background-color: #fff8;
    margin: 0 0.3em;
    border-radius: 0.5em;
  }
`;

export { ColorBarSection };
