import React from 'react';
import styled from 'styled-components';

import { IFrame } from './i-frame';
import { LinkActions } from './link-actions';
import { LinkGenerator } from './link-generator';

const AccessTokens = () => {
  const [accessTokenUrl, setAccessTokenUrl] = React.useState<string>();
  const [iframeUrl, setIframeUrl] = React.useState<string>();

  return (
    <Component>
      <PageHeader>Sessionslänkar</PageHeader>
      <HorizontalFlexContainer>
        <MenuContainer>
          <LinkGenerator
            onLinkCreated={(url) => setAccessTokenUrl(url)}
            onLinkDeleted={() => {
              setAccessTokenUrl(undefined);
              setIframeUrl(undefined);
            }}
          />
          <LinkActions url={accessTokenUrl} onUrlOpenClicked={(url) => setIframeUrl(url)} />
        </MenuContainer>
        <IFrame url={iframeUrl} />
      </HorizontalFlexContainer>
    </Component>
  );
};

AccessTokens.propTypes = {};

const FlexContainer = styled.div`
  display: flex;
  gap: 20px;
  height: 100%;
`;

const Component = styled(FlexContainer)`
  flex-direction: column;
`;

const HorizontalFlexContainer = styled(FlexContainer)`
  flex-direction: row;
`;

const PageHeader = styled.h1`
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 14pt;
  font-weight: bold;
  color: #333;
`;

const MenuContainer = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  width: 300px;
`;

export { AccessTokens };
