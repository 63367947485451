import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

import { reset } from '../../utils/styled-reset';
import { Button, ButtonStyled } from '../button/button';
import { ClickAwayListener } from '../click-away-listener/click-away-listener';
import { Icon } from '../icon/icon';

export interface SplitButtonItem {
  icon?: FontAwesomeIconProps['icon'];
  name: string;
}

export interface Props {
  items: SplitButtonItem[];
  selectedIndex?: number;
  disabled?: boolean;
  onClick?: (index: number) => void;
}

const DefaultIndex = 0;

const SplitButton = (props: Props) => {
  const buttonGroupRef = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState(false);

  const toggleDropDown = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    if (props.disabled) {
      setOpen(false);
    }
  }, [props.disabled]);

  const handleMenuItemClick = (index: number) => {
    setOpen(false);
    props.onClick?.(index);
  };

  return (
    <Component>
      <ButtonGroup ref={buttonGroupRef}>
        <Button
          disabled={props.disabled}
          leftIcon={{ icon: ['fad', 'plus'] }}
          variant={'text'}
          onClick={() => handleMenuItemClick(props.selectedIndex ?? DefaultIndex)}
        >
          {props.items[props.selectedIndex ?? DefaultIndex].name}
        </Button>

        <Button disabled={props.disabled} variant={'text'} onClick={toggleDropDown}>
          <Icon color="#000" icon={['fas', 'caret-down']} />
        </Button>
      </ButtonGroup>

      {open && (
        <ClickAwayListener excludeClickOnRef={buttonGroupRef} onClickAway={() => setOpen(false)}>
          <DropDown>
            <ul>
              {props.items.map((item, index) => (
                <li key={index}>
                  <Button
                    contentAlignment="left"
                    leftIcon={item.icon && { icon: item.icon, fixedWidth: true }}
                    noWrap={true}
                    variant="text"
                    width="100%"
                    onClick={() => handleMenuItemClick(index)}
                  >
                    {item.name}
                  </Button>
                </li>
              ))}
            </ul>
          </DropDown>
        </ClickAwayListener>
      )}
    </Component>
  );
};

const Component = styled.div`
  ${reset}

  position: relative;
  display: inline-block;
`;

const ButtonGroup = styled.div`
  display: flex;

  ${ButtonStyled}:nth-child(1) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 1px solid #ccc;
  }
  ${ButtonStyled}:nth-child(2) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const DropDown = styled.div`
  ${reset}

  position: absolute;
  top: calc(100% + 0.1em);
  left: 0;

  background-color: #fff;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 0.25em;
  border-radius: 0.25em;

  z-index: 1;

  ul {
    ${reset}

    list-style: none;
  }
`;

export { SplitButton };
