import styled from 'styled-components';

// TODO: Investigate how to expose event handlers based on prop "as" in Stack component:
// TODO: <Stack as="form" onSubmit="...">...</Stack>
// TODO: For now, style form with inline flex CSS.
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: 1em;
`;
