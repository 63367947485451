import { t } from 'i18next';
import React from 'react';
import styled from 'styled-components';

import { Point3d } from '../../../js/viewer/utilities/math-helpers';
import { LabelledContainer } from '../labelled-container/labelled-container';

type Props = {
  point: Point3d;
  display?: [string, string, string];
};

/**
 * Renders a labelled container with the position using a borderless table.
 */
export const Position = ({ display = ['N', 'E', 'H'], point = { x: 0, y: 0, z: 0 } }: Props) => {
  return (
    <LabelledContainer text={t('coordinate', { ns: 'common' })}>
      <Table>
        <TableBody>
          <TableRow>
            <TableHeader>{display[0]}</TableHeader>
            <TableData>{point.x.toFixed(2)}</TableData>
          </TableRow>
          <TableRow>
            <TableHeader>{display[1]}</TableHeader>
            <TableData>{point.y.toFixed(2)}</TableData>
          </TableRow>
          <TableRow>
            <TableHeader>{display[2]}</TableHeader>
            <TableData>{point.z.toFixed(2)}</TableData>
          </TableRow>
        </TableBody>
      </Table>
    </LabelledContainer>
  );
};

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const TableBody = styled.tbody``;
const TableRow = styled.tr``;

const TableHeader = styled.th`
  text-align: left;
  white-space: nowrap;
  width: 1px;
  font-weight: 400;
  padding: 0.1em 0.5em 0.1em 0;
`;

const TableData = styled.td`
  text-align: left;
  font-weight: 300;
  padding: 0.1em 0;
`;
