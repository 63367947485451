import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PasswordValidator } from '../../utils/password-validator';
import { reset } from '../../utils/styled-reset';
import { PasswordConstraintsItem } from './password-constraints-item';

export type Props = {
  password: string;
  minLength: number;
  minUpperCase?: number;
  minLowerCase?: number;
  minDigits?: number;
};

const PasswordConstraints = (props: Props) => {
  const { t } = useTranslation();
  const passwordValidator = new PasswordValidator(
    props.minLength,
    props.minUpperCase,
    props.minLowerCase,
    props.minDigits,
  );

  const resolveStatus = (constraintValueOk: boolean) =>
    props.password.length === 0 ? undefined : constraintValueOk ? 'ok' : 'invalid';

  return (
    <Component>
      <PasswordConstraintsItem
        status={resolveStatus(passwordValidator.isMinLengthValid(props.password))}
      >
        {t('passwordConstraints.minCharacters', {
          ns: 'components',
          count: passwordValidator.minLength,
        })}
      </PasswordConstraintsItem>

      {passwordValidator.minUpperCase && (
        <PasswordConstraintsItem
          status={resolveStatus(passwordValidator.isMinUpperCaseValid(props.password))}
        >
          {t('passwordConstraints.minUpperCase', {
            ns: 'components',
            count: passwordValidator.minUpperCase,
          })}
        </PasswordConstraintsItem>
      )}

      {passwordValidator.minLowerCase && (
        <PasswordConstraintsItem
          status={resolveStatus(passwordValidator.isMinLowerCaseValid(props.password))}
        >
          {t('passwordConstraints.minLowerCase', {
            ns: 'components',
            count: passwordValidator.minLowerCase,
          })}
        </PasswordConstraintsItem>
      )}

      {passwordValidator.minDigits && (
        <PasswordConstraintsItem
          status={resolveStatus(passwordValidator.isMinDigitsValid(props.password))}
        >
          {t('passwordConstraints.minDigits', {
            ns: 'components',
            count: passwordValidator.minDigits,
          })}
        </PasswordConstraintsItem>
      )}
    </Component>
  );
};

const Component = styled.ul`
  ${reset}

  font-size: 13px;
  background-color: #fff;
  z-index: 100;
  list-style: none;
  padding: 0;
  width: 100%;
  margin: 5px 0 0 0;
`;

export { PasswordConstraints };
