import React from 'react';
import styled from 'styled-components';

import { SkyMapAxiosServiceFactory } from '../../../../../js/services/axios/skymap-axios-service-factory';
import { ProjectContext } from '../../../../state/project-state';
import { Button } from '../../../button/button';
import { CheckBox } from '../../../check-box/check-box';
import { Dialog } from '../../../dialog/dialog';
import { Hyperlink } from '../../../hyperlink/hyperlink';
import { InfoBox } from '../../../info-box/info-box';

enum DialogStatus {
  Idle,
  Pending,
  Error,
}

interface Props {
  onClose: (accepted: boolean) => void;
}

const AcceptSkyMapConnectLicenseAgreementDialog = (props: Props) => {
  const { project } = React.useContext(ProjectContext);
  const [checkBoxChecked, setCheckBoxChecked] = React.useState(false);
  const [status, setStatus] = React.useState(DialogStatus.Idle);

  const acceptAgreement = async () => {
    try {
      setStatus(DialogStatus.Pending);

      await SkyMapAxiosServiceFactory.instance
        .createCompanyServiceV1()
        .createSkyMapConnectLicenseAgreement({
          path: {
            companyId: project.companyId!,
          },
        });

      props.onClose(true);
    } catch {
      setStatus(DialogStatus.Error);
    }
  };

  const onCheckBoxChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCheckBoxChecked(e.target.checked);
    },
    [setCheckBoxChecked],
  );

  return (
    <Dialog
      closeIcon={status !== DialogStatus.Pending}
      closeOnDimmerClick={true}
      useGoogleTranslate={true}
      width={500}
      onClose={() => props.onClose(false)}
    >
      {{
        header: 'BESTÄLLNING AV SKYMAP CONNECT',
        content: (
          <Content>
            <p>
              För att aktivera SkyMap Connect i projektet krävs en tilläggslicens. Har ni redan en
              aktiv licens är det bara att fortsätta.
            </p>
            <p>
              Har ni inte en aktiv licens innebär beställning att ni lägger till tjänsten och
              accepterar villkoren. Läs mer om tjänsten{' '}
              <Hyperlink target="_blank" url="https://skymap.se/connect">
                här
              </Hyperlink>
              .
            </p>
            <CheckBoxSection>
              <CheckBox checked={checkBoxChecked} onChange={onCheckBoxChange} />
              <span>Jag har läst och godkänner</span>
              <Hyperlink target="_blank" url="https://skymap.se/villkor-connect">
                användarvillkoren
              </Hyperlink>
              .
            </CheckBoxSection>

            {status === DialogStatus.Error && (
              <InfoBox color="red">Det gick inte att genomföra beställningen.</InfoBox>
            )}
          </Content>
        ),
        footer: {
          right: (
            <>
              <Button
                color="primary"
                disabled={!checkBoxChecked}
                loading={status === DialogStatus.Pending}
                variant="contained"
                onClick={acceptAgreement}
              >
                Beställ
              </Button>
              <Button
                disabled={status === DialogStatus.Pending}
                variant="text"
                onClick={() => props.onClose(false)}
              >
                Stäng
              </Button>
            </>
          ),
        },
      }}
    </Dialog>
  );
};

const CheckBoxSection = styled.p`
  display: flex;

  input {
    margin-right: 1em;
  }

  a {
    margin-left: 4px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;

  > * {
    width: 100%;
  }

  p {
    a {
      color: ${(props) => props.theme.color.blue};
    }
  }
`;

export { AcceptSkyMapConnectLicenseAgreementDialog };
