import PropTypes from 'prop-types';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import styled from 'styled-components';

import { FileModel, FolderModel } from '../../../../typings/api/skymap/rest/v1/.common';
import { AppState } from '../../state/app-state';
import { ProjectState } from '../../state/project-state';
import { IntegrationsState } from '../integrations/state/integrations-state';
import { LeftArea } from './left-area';
import { RightArea } from './right-area';
import { FolderListViewState } from './state/folder-list-view-state';
import { FolderTreeState } from './state/folder-tree-state';

interface Props {
  foldersCheckable?: boolean;
  filesCheckable?: boolean;
  onCheckedFilesChanged?: (files: FileModel[]) => void;
  onCheckedFoldersChanged?: (folders: FolderModel[]) => void;
  onCurrentFolderIdChanged?: (id?: string) => void;
}

const FileManager = (props: Props) => {
  // AppState and ProjectState can be moved upwards when Portal is fully converted to React.
  return (
    <AppState>
      <ProjectState>
        <IntegrationsState>
          <FolderTreeState>
            <Component>
              <DndProvider backend={HTML5Backend}>
                <LeftArea />
                <FolderListViewState
                  filesCheckable={props.filesCheckable ?? true}
                  foldersCheckable={props.foldersCheckable ?? true}
                  onCheckedFilesChanged={props.onCheckedFilesChanged}
                  onCheckedFoldersChanged={props.onCheckedFoldersChanged}
                  onCurrentFolderIdChanged={props.onCurrentFolderIdChanged}
                >
                  <RightArea />
                </FolderListViewState>
              </DndProvider>
            </Component>
          </FolderTreeState>
        </IntegrationsState>
      </ProjectState>
    </AppState>
  );
};

const Component = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  background-color: #fff;

  // Left area.
  ${LeftArea.styled} {
    width: 300px;
    min-width: 300px;
  }

  // Content area.
  ${RightArea.styled} {
    flex: 1;
  }
`;

FileManager.propTypes = {
  wrapWithLanguageProvider: PropTypes.any,
};

export { FileManager, Props as FileManagerDialogProps };
