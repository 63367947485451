import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { GeneralInfo } from './general-info';
import { Projection } from './projection';
import { Status } from './status/status';

const ProjectSettings = () => {
  return (
    <Component>
      <GeneralInfo />
      <Status />
      <Projection />
    </Component>
  );
};

const Component = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'general-info projection'
    'status       projection';
  gap: 2em;

  ${GeneralInfo.styled} {
    grid-area: general-info;
  }

  ${Status.styled} {
    grid-area: status;
  }

  ${Projection.styled} {
    grid-area: projection;
  }
`;

ProjectSettings.propTypes = {
  wrapWithAppAndProjectState: PropTypes.bool,
  projectService: PropTypes.any,
  companyService: PropTypes.any,
  state: PropTypes.any,
};

export { ProjectSettings };
