import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { RegisterCompanyRequest } from '../../../../../typings/api/skymap/rest/v0/company';
import { SkyMapAxiosServiceFactory } from '../../../../js/services/axios/skymap-axios-service-factory';
import { countryDropdownListItems } from '../../../../js/utils/text/translation';
import { Button } from '../../button/button';
import { InfoBox } from '../../info-box/info-box';
import { LabelledContainer } from '../../labelled-container/labelled-container';
import { Stack } from '../../stack/stack';
import { FormStateContext } from '../state/form-state-provider';
import { StepperContext } from '../state/stepper-provider';
import { vatNumberOrOrgNumber } from '../utils';
import { Grid } from './styles';

enum Status {
  Idle,
  InProgress,
  Error,
  Success,
}

type Props = {
  licenseType: 'Portal' | 'Design';
};

const SummaryStep = (props: Props) => {
  const { t } = useTranslation();
  const { prevStep, reset: resetStepper } = React.useContext(StepperContext);
  const { company, admin, reset: resetForm } = React.useContext(FormStateContext);
  const [status, setStatus] = React.useState(Status.Idle);

  const register = async () => {
    try {
      setStatus(Status.InProgress);

      const requestData: RegisterCompanyRequest = {
        user: {
          email: admin.email,
          firstName: admin.firstName ? admin.firstName : undefined,
          lastName: admin.lastName ? admin.lastName : undefined,
          phoneNumber: admin.phone ? admin.phone : undefined,
        },
        company: {
          ...{
            [vatNumberOrOrgNumber(company.countryCode) === 'vatNumber'
              ? 'vatNumber'
              : 'organizationNumber']: company.orgOrVatNumber || undefined,
          },
          name: company.name,
          address: company.address,
          zipCode: company.zipCode,
          city: company.city,
          countryCode:
            company.countryCode.toUpperCase() as RegisterCompanyRequest['company']['countryCode'],
          licenseType: props.licenseType,
        },
      };

      const companyService = SkyMapAxiosServiceFactory.instance.createCompanyServiceV0();
      await companyService.register({ body: requestData });
      setStatus(Status.Success);
    } catch (err) {
      setStatus(Status.Error);
    }
  };

  const buttonsEnabled = status === Status.Idle || status === Status.Error;
  const country = countryDropdownListItems.find((x) => x.value === company.countryCode)!;

  return (
    <Component direction="column" spacing={1}>
      <Stack direction="column" spacing={1}>
        <StepTitle>{t('registerCompany.step3.step1Title', { ns: 'components' })}</StepTitle>
        <Grid>
          <LabelledContainer text={t('registerCompany.step1.companyName', { ns: 'components' })}>
            {company.name}
          </LabelledContainer>
          <LabelledContainer text={t('registerCompany.step1.country', { ns: 'components' })}>
            {country.flag} {country.englishText} ({country.nativeText})
          </LabelledContainer>
          <LabelledContainer
            text={
              company.countryCode === 'SE'
                ? t('registerCompany.step1.organisationNumber', { ns: 'components' })
                : t('registerCompany.step1.vatNumber', { ns: 'components' })
            }
          >
            {company.orgOrVatNumber}
          </LabelledContainer>
          <LabelledContainer text={t('registerCompany.step1.address', { ns: 'components' })}>
            {company.address}
          </LabelledContainer>
          <LabelledContainer text={t('registerCompany.step1.zipCode', { ns: 'components' })}>
            {company.zipCode}
          </LabelledContainer>
          <LabelledContainer text={t('registerCompany.step1.city', { ns: 'components' })}>
            {company.city}
          </LabelledContainer>
        </Grid>
      </Stack>

      <Stack direction="column" spacing={1}>
        <StepTitle>{t('registerCompany.step3.step2Title', { ns: 'components' })}</StepTitle>
        <Grid>
          <LabelledContainer text={t('registerCompany.step2.email', { ns: 'components' })}>
            {admin.email}
          </LabelledContainer>
          {admin.firstName && admin.lastName && admin.phone && (
            <>
              <LabelledContainer text={t('registerCompany.step2.firstName', { ns: 'components' })}>
                {admin.firstName}
              </LabelledContainer>
              <LabelledContainer text={t('registerCompany.step2.lastName', { ns: 'components' })}>
                {admin.lastName}
              </LabelledContainer>
              <LabelledContainer text={t('registerCompany.step2.phone', { ns: 'components' })}>
                {admin.phone}
              </LabelledContainer>
            </>
          )}
        </Grid>
      </Stack>

      {status === Status.Success && (
        <InfoBox color="green" leftIcon={{ icon: ['fad', 'check'] }}>
          {t('registerCompany.step3.registrationSuccessful', { ns: 'components' })}
        </InfoBox>
      )}

      {status === Status.Error && (
        <InfoBox color="red" leftIcon={{ icon: ['fad', 'exclamation'] }}>
          {t('registerCompany.step3.registrationFailure', { ns: 'components' })}
        </InfoBox>
      )}

      <Stack
        alignItems="center"
        direction="row"
        justifyContent={'space-between'}
        margin={'1em 0 0 0'}
        spacing={0.5}
      >
        {status === Status.Success ? (
          <Button
            type="submit"
            variant="contained"
            onClick={() => {
              resetForm();
              resetStepper();
            }}
          >
            {t('registerCompany.step3.backToStartButton', { ns: 'components' })}
          </Button>
        ) : (
          <Button
            color="secondary"
            disabled={!buttonsEnabled}
            variant="contained"
            onClick={prevStep}
          >
            {t('previous', { ns: 'common' })}
          </Button>
        )}

        <Button
          color="primary"
          disabled={!buttonsEnabled}
          loading={status === Status.InProgress}
          type="submit"
          variant="contained"
          onClick={register}
        >
          {t('complete', { ns: 'common' })}
        </Button>
      </Stack>
    </Component>
  );
};

const Component = styled(Stack)`
  ${LabelledContainer.styledContent} {
    word-break: break-all;
    font-weight: 300;
  }
`;

const StepTitle = styled.span`
  border-bottom: 1px solid #888;
  font-weight: 500;
`;

export { SummaryStep };
