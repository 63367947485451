import React from 'react';
import styled from 'styled-components';

import { Button } from '../button/button';
import { Dialog } from '../dialog/dialog';

interface Props {
  onClose: (wasUpdated: boolean) => void;
}

const GcpAdjustmentDialog = (props: Props) => {
  return (
    <Dialog
      closeIcon={true}
      closeOnDimmerClick={true}
      useGoogleTranslate={true}
      width={500}
      onClose={() => props.onClose(false)}
    >
      {{
        header: 'Lägg till GCP',
        content: (
          <Content>
            <Text topMargin={false}>
              GCP-justering har beställts för den här molnprocesseringen. Du behöver inte göra
              något. Vår samarbetspartner löser flaggflyttningen åt dig.
            </Text>
            <Text topMargin={true}>
              Är du säker på att du vill gå vidare till sidan för lägg till GCP?
            </Text>
          </Content>
        ),
        footer: {
          right: (
            <>
              <Button color="primary" variant="contained" onClick={() => props.onClose(true)}>
                Fortsätt
              </Button>

              <Button variant="text" onClick={() => props.onClose(false)}>
                Stäng
              </Button>
            </>
          ),
        },
      }}
    </Dialog>
  );
};

const Text = styled.span<{ topMargin?: boolean; bottomMargin?: boolean }>`
  display: block;

  strong {
    font-weight: 600;
  }

  margin-top: ${(props) => (props.topMargin ? '0.5em' : undefined)};
  margin-bottom: ${(props) => (props.bottomMargin ? '0.5em' : undefined)};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

export { GcpAdjustmentDialog };
