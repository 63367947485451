import type * as CSS from 'csstype';
import React from 'react';
import styled from 'styled-components';

import { isDefined } from '../../../js/utils/variables';
import { reset } from '../../utils/styled-reset';
import { InfoBox } from '../info-box/info-box';

type Option<T> = {
  id?: T;
  name?: string;
  group?: string;
};

type Props<T> = {
  className?: string;
  disabled?: boolean;
  id?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onClick?: (e: React.MouseEvent) => void | Promise<void>;
  options: Option<T>[];
  placeholderText?: string;
  value?: T;
  padding?: number;
  backgroundColor?: CSS.Properties['backgroundColor'];
  color?: CSS.Properties['color'];
  optionColor?: CSS.Properties['color'];
  fontWeight?: CSS.Properties['fontWeight'];
  errorMessage?: string;
};

const Select = <T extends string | boolean>(
  props: Props<T>,
  ref: React.ForwardedRef<HTMLSelectElement>,
) => {
  const optionsWithoutGroups = props.options
    .filter((option) => !option.group)
    .map((option) => {
      return (
        <option key={option.id?.toString()} value={option.id?.toString()}>
          {option.name}
        </option>
      );
    });

  const optionsMap = props.options.reduce((map, option) => {
    if (option.group) {
      if (!map.has(option.group)) {
        map.set(option.group, []);
      }
      map.get(option.group).push(option);
    }
    return map;
  }, new Map());

  const groupedOptions = Array.from(optionsMap, ([group, options], index) => (
    <optgroup key={index} label={group}>
      {options.map((option: Option<string>, index: number) => (
        <option key={index} value={option.id}>
          {option.name}
        </option>
      ))}
    </optgroup>
  ));

  const options = optionsWithoutGroups.concat(groupedOptions);

  if (props.placeholderText) {
    options.unshift(
      <option disabled={true} key="" value="">
        {props.placeholderText}
      </option>,
    );
  }

  return (
    <>
      <Component
        aria-autocomplete="none"
        aria-invalid={isDefined(props.errorMessage)}
        autoComplete="off"
        backgroundColor={props.backgroundColor}
        className={props.className}
        color={props.color}
        defaultValue=""
        disabled={props.options.length === 0 || props.disabled}
        errorMessage={props.errorMessage}
        fontWeight={props.fontWeight}
        id={props.id}
        optionColor={props.optionColor}
        padding={props.padding}
        ref={ref}
        value={props.value?.toString()}
        onChange={props.onChange}
        onClick={props.onClick}
      >
        {options}
      </Component>

      {props.errorMessage && (
        <InfoBox color="red" fontSize="small" padding="small">
          {props.errorMessage}
        </InfoBox>
      )}
    </>
  );
};

const Component = styled.select<{
  padding?: number;
  backgroundColor: CSS.Properties['backgroundColor'];
  optionColor?: CSS.Properties['color'];
  color?: CSS.Properties['color'];
  fontWeight?: CSS.Properties['fontWeight'];
  errorMessage?: string;
}>`
  ${reset}

  width: 100%;
  height: auto;
  padding: ${(props) => props.padding ?? 0.5}em;
  cursor: pointer;
  font-size: 14px;
  border: 1px solid ${(props) => (isDefined(props.errorMessage) ? '#b22222' : '#888')};

  &[disabled] {
    opacity: 0.5;
  }
  ${(props) =>
    props.color &&
    `
      color: ${props.color};
    `}

  ${(props) =>
    props.backgroundColor &&
    `
      background-color: ${props.backgroundColor};
      > option {
        background-color: ${props.backgroundColor};
      }
    `}

  ${(props) =>
    props.fontWeight &&
    `
      font-weight: ${props.fontWeight};
      > option {
        font-weight: ${props.fontWeight};
      }
    `}

  ${(props) =>
    props.optionColor &&
    `
      > option {
        color: ${props.optionColor};
      }
    `}

    option[value='']:disabled {
    display: none;
  }
`;

const ForwardedRefSelect = React.forwardRef(Select);
export { ForwardedRefSelect as Select, Props as SelectProps };
