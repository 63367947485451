import React from 'react';
import styled from 'styled-components';

import { isDefined } from '../../../../../js/utils/variables';
import { SkyView } from '../../../../../js/viewer/skyview';
import { AppState } from '../../../../state/app-state';
import { ProjectState } from '../../../../state/project-state';
import { IntegrationsState } from '../../../integrations/state/integrations-state';
import { LanguageProvider } from '../../../language-provider/language-provider';
import { SkyviewState } from '../../skyview-state';
import { AsbuiltComponentStyle } from './asbuilt-content';
import { AsbuiltPanelState } from './asbuilt-panel-state';

interface Props {
  skyView?: SkyView;
}

const AsbuiltPanel = (props: Props) => {
  const children = (
    <Component>
      <LanguageProvider>
        <AppState displayLoading={false}>
          <ProjectState displayLoading={false}>
            <IntegrationsState>
              <AsbuiltPanelState />
            </IntegrationsState>
          </ProjectState>
        </AppState>
      </LanguageProvider>
    </Component>
  );

  // If skyView is set through props, the component is used from within a AngularJS component. Then
  // wrap asbuilt panel with <SkyviewState>. React SkyView is already wrapped with this state so
  // it's not necessary to add it.
  return isDefined(props.skyView) ? (
    <SkyviewState skyview={props.skyView}>{children}</SkyviewState>
  ) : (
    children
  );
};

const Component = styled.div`
  position: absolute;
  z-index: 1;

  right: 2em;
  top: 4em;

  height: calc(100vh - 19em);
  width: 40vw;

  padding: 2em;

  ${AsbuiltComponentStyle} {
    height: 100%;
  }

  background-color: #fff;
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
`;

export { AsbuiltPanel, Component as AsbuiltPanelStyled };
