import React from 'react';
import styled from 'styled-components';

import { reset } from '../../../utils/styled-reset';

interface Props {
  hour: number;
  minute: number;
}

const TimeHeader = (props: Props) => {
  return (
    <Component>
      {String(props.hour).padStart(2, '0')}:{String(props.minute).padStart(2, '0')}
    </Component>
  );
};

const Component = styled.div`
  ${reset}

  font-size: 14px;
  display: flex;
  justify-content: center;
  user-select: none;
`;

TimeHeader.styled = Component;

export { TimeHeader };
