import React from 'react';
import styled, { css } from 'styled-components';

import { iiafe } from '../../../../../../../utilities/async';
import { publish, SubscriptionTopic } from '../../../../../../js/messaging/pubsub';
import { t } from '../../../../../../js/utils/i18-next';
import cameraManager from '../../../../../../js/viewer/camera-manager';
import { Design } from '../../../../../../js/viewer/elements/design';
import { Dxf } from '../../../../../../js/viewer/elements/dxf';
import { ElementObj } from '../../../../../../js/viewer/elements/element-obj';
import { useElementOverflow } from '../../../../../hooks/use-element-overflow';
import { useSubscribe } from '../../../../../hooks/use-subscribe';
import { truncateText } from '../../../../../utils/styled-helpers';
import { CheckBox } from '../../../../check-box/check-box';
import { Icon, IconProps, IconStyled } from '../../../../icon/icon';
import { Stack } from '../../../../stack/stack';
import { useAsset } from '../../../hooks/use-asset';
import { CadGroupVisibilityContext } from '../../../state/cad-group-visibility-state';
import { CategoryVisibilityContext } from '../../../state/category-visibility-state';
import { CheckBoxContext } from '../../../state/checkbox-state';
import { CheckBoxContainer } from '../../styles/checkbox-container';
import { ExpandableIconsContainer } from '../expandable-icons-container';

type Props = {
  additionalIcons?: React.ReactElement<IconProps>[];
  icons?: React.ReactElement<IconProps>[];
  asset: ElementObj;
  name: string;
  namePrefix?: React.ReactNode;
};

function getErrorFocusingOnAssetMessage(asset: ElementObj) {
  return asset.freeze.translate && !(asset instanceof Design) && !(asset instanceof Dxf)
    ? t('common.assetHeaderIcons.error.focusFrozen', { ns: 'skyviewAssetMenu' })
    : t('common.assetHeaderIcons.error.focusTransformable', { ns: 'skyviewAssetMenu' });
}

function getErrorFocusingOnAssetTitle(asset: ElementObj) {
  switch (asset.assetType) {
    case 'area':
      return t('common.assetHeaderIcons.error.focusArea', { ns: 'skyviewAssetMenu' });
    case 'comment':
      return t('common.assetHeaderIcons.error.focusComment', { ns: 'skyviewAssetMenu' });
    case 'design':
      return t('common.assetHeaderIcons.error.focusDesign', { ns: 'skyviewAssetMenu' });
    case 'dxf':
    case 'glb':
      return t('common.assetHeaderIcons.error.focusCad', { ns: 'skyviewAssetMenu' });
    case 'line':
      return t('common.assetHeaderIcons.error.focusLine', { ns: 'skyviewAssetMenu' });
    case 'model':
      return t('common.assetHeaderIcons.error.focusModel', { ns: 'skyviewAssetMenu' });
    case 'terrain-model':
      return t('common.assetHeaderIcons.error.focusTerrainModel', { ns: 'skyviewAssetMenu' });
  }
}

const AssetHeader = (props: Props) => {
  const {
    visible: checkBoxVisible,
    toggle: toggleCheckbox,
    checkedAssetUuids,
    disabled: checkBoxDisabled,
  } = React.useContext(CheckBoxContext);
  const { visible: categoryVisible } = React.useContext(CategoryVisibilityContext);
  const cadGroupVisibilityContext = React.useContext(CadGroupVisibilityContext);
  const { assetSelected, toggleAsset, toggleVisibility } = useAsset(props.asset);
  const [isAssetFocused, setIsAssetFocused] = React.useState(false);

  const cadGroupVisible = (cadGroupVisibilityContext && cadGroupVisibilityContext.visible) ?? true;

  const { ref: nameElementOverflowRef, isOverflowed: nameElementIsOverflowed } =
    useElementOverflow();

  const resolveUnexpectedErrorLoadingAsset = React.useCallback(() => {
    switch (props.asset.assetType) {
      case 'area':
        return t('common.assetHeaderIcons.error.loadArea', { ns: 'skyviewAssetMenu' });
      case 'comment':
        return t('common.assetHeaderIcons.error.loadComment', { ns: 'skyviewAssetMenu' });
      case 'design':
        return t('common.assetHeaderIcons.error.loadDesign', { ns: 'skyviewAssetMenu' });
      case 'dxf':
      case 'glb':
        return t('common.assetHeaderIcons.error.loadCad', { ns: 'skyviewAssetMenu' });
      case 'line':
        return t('common.assetHeaderIcons.error.loadLine', { ns: 'skyviewAssetMenu' });
      case 'model':
        return t('common.assetHeaderIcons.error.loadModel', { ns: 'skyviewAssetMenu' });
      case 'terrain-model':
        return t('common.assetHeaderIcons.error.loadTerrainModel', { ns: 'skyviewAssetMenu' });
    }
  }, [props.asset.assetType]);

  const onVisibilityIconClick = React.useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();
      await toggleVisibility();
    },
    [toggleVisibility],
  );

  useSubscribe(SubscriptionTopic.SkyviewAssetFocusChanged, (msg) =>
    setIsAssetFocused(msg.assetUuid === props.asset.uuid),
  );

  const isFocusIconVisible =
    categoryVisible &&
    cadGroupVisible &&
    props.asset.visible &&
    props.asset.loadingStatus === 'loaded';

  return (
    <Header selected={assetSelected} onClick={() => toggleAsset(props.asset.uuid)}>
      {checkBoxVisible && (
        <CheckBoxContainer
          disabled={checkBoxDisabled}
          onClick={(e) => {
            if (!checkBoxDisabled) {
              e.stopPropagation();
              toggleCheckbox(props.asset.uuid);
            }
          }}
        >
          <CheckBox
            checked={checkedAssetUuids.includes(props.asset.uuid)}
            disabled={checkBoxDisabled}
            onChange={() => {
              toggleCheckbox(props.asset.uuid);
            }}
          />
        </CheckBoxContainer>
      )}
      <TitleAndIcons>
        <Stack alignItems="center" direction="row" flex={1} spacing={0.25}>
          {props.namePrefix}
          <Title
            ref={nameElementOverflowRef}
            title={nameElementIsOverflowed ? props.name : undefined}
          >
            {props.name}
          </Title>
        </Stack>
        <Icons
          assetLoading={props.asset.loadingStatus === 'loading'}
          assetSelected={assetSelected}
          assetVisible={categoryVisible && cadGroupVisible && props.asset.visible}
        >
          {props.additionalIcons && props.additionalIcons.length > 0 && (
            <ExpandableIconsContainer icons={props.additionalIcons} />
          )}

          {props.icons}

          {isFocusIconVisible && (
            <Icon
              fixedWidth={true}
              icon={isAssetFocused ? ['fas', 'square'] : ['fal', 'square']}
              key="focusIcon"
              mouseDownStyle={{ opacity: 0.5 }}
              stopPropagationOnClick={true}
              title={t('common.assetHeaderIcons.focus', { ns: 'skyviewAssetMenu' })}
              onClick={() => {
                try {
                  cameraManager().getActiveController()!.focusOnAsset(props.asset);
                } catch {
                  publish(SubscriptionTopic.ShowSetAssetCoordinateDialog, {
                    asset: props.asset,
                    infoMessage: getErrorFocusingOnAssetMessage(props.asset),
                    title: getErrorFocusingOnAssetTitle(props.asset),
                  });
                }
              }}
              onHoverStyle={{ icon: ['fad', 'square'] }}
            />
          )}

          <VisibilityIcon
            color={props.asset.loadingStatus === 'error' ? 'crimson' : undefined}
            disabled={!categoryVisible || !cadGroupVisible}
            fixedWidth={true}
            icon={[
              props.asset.loadingStatus === 'error' ? 'fas' : 'fal',
              props.asset.loadingStatus === 'loading'
                ? 'spinner'
                : props.asset.loadingStatus === 'error'
                  ? 'exclamation-triangle'
                  : !categoryVisible || !cadGroupVisible || !props.asset.visible
                    ? 'eye-slash'
                    : 'eye',
            ]}
            mouseDownStyle={{ opacity: 0.5 }}
            spin={props.asset.loadingStatus === 'loading'}
            stopPropagationOnClick={true}
            title={
              !categoryVisible
                ? t('common.cadGroupIcons.showCategory', { ns: 'skyviewAssetMenu' })
                : !cadGroupVisible
                  ? t('common.assetHeaderIcons.showCadGroup', { ns: 'skyviewAssetMenu' })
                  : props.asset.loadingStatus === 'error'
                    ? resolveUnexpectedErrorLoadingAsset()
                    : props.asset.visible
                      ? t('hide', { ns: 'common' })
                      : t('show', { ns: 'common' })
            }
            onClick={(e) => {
              iiafe(() => onVisibilityIconClick(e));
            }}
            onHoverStyle={{
              icon: [
                'fad',
                props.asset.loadingStatus === 'loading'
                  ? 'spinner'
                  : props.asset.loadingStatus === 'error'
                    ? 'exclamation-triangle'
                    : !categoryVisible || !cadGroupVisible || !props.asset.visible
                      ? 'eye-slash'
                      : 'eye',
              ],
              spin: props.asset.loadingStatus === 'loading',
            }}
          />
        </Icons>
      </TitleAndIcons>
    </Header>
  );
};

AssetHeader.displayName = 'AssetHeader';

const VisibilityIcon = styled(Icon)``;

const Header = styled.div<{ selected: boolean }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: #eee;
  cursor: pointer;
  user-select: none;

  ${(props) =>
    props.selected &&
    css`
      background-color: #fff8d5;
    `}

  &:hover {
    background-color: #fff8d5;
  }
`;

const TitleAndIcons = styled.div`
  display: flex;
  gap: 1em;
  flex: 1;
  align-items: center;
  padding: 0.6em 0.7em;
`;

const Title = styled.span`
  ${truncateText(1)}
  font-size: 14px;
`;

const Icons = styled.div<{ assetSelected: boolean; assetVisible: boolean; assetLoading: boolean }>`
  display: flex;
  gap: 0.5em;

  // Hide visibility icon?
  ${(props) =>
    !props.assetSelected &&
    props.assetVisible &&
    !props.assetLoading &&
    css`
      ${Header}:not(:hover) && ${VisibilityIcon} {
        display: none;
      }
    `}

  // Display all icons when asset is selected, otherwise hide all icons except the visibility icon
  // when header is not hovered.
  ${(props) =>
    props.assetSelected
      ? css`
          ${IconStyled} {
            display: block;
          }
        `
      : css`
          ${Header}:not(:hover) && ${IconStyled}:not(${VisibilityIcon}) {
            display: none;
          }
        `}
`;

export { AssetHeader, Props as AssetHeaderProps };
