import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DesignMetaData } from '../../../../../js/viewer/elements/design/design-node/utils/meta-data';
import { Coordinates } from '../../../../../js/viewer/utilities/math-helpers';
import { LabelledContainer } from '../../../labelled-container/labelled-container';
import { Stack } from '../../../stack/stack';
import { CoordinatePropertiesStyled, PositionInfo } from './coordinate-properties';

export type DesignMultiselectProps = {
  type: 'design';
  isMultiselect: true;
  metaData: DesignMetaData;
};

export const DesignMultiselectProperties = (props: {
  coordinate: Coordinates;
  coordinateSystem?: string;
  properties: DesignMultiselectProps;
}) => {
  return (
    <Component>
      <PositionInfo coordinate={props.coordinate} />
      <MultiselectInfo metaData={props.properties.metaData} />
    </Component>
  );
};

/**
 * Displays metadata for multiselect when using design tool
 */
const MultiselectInfo = (props: { metaData: DesignMetaData }) => {
  const { t } = useTranslation();
  return (
    <Stack spacing={0.5}>
      <LabelledContainer
        text={t('assetPositionTooltip.designMultiselectProperties.title', { ns: 'skyview' })}
      >
        <P>
          <Label>
            {t('assetPositionTooltip.designMultiselectProperties.totalLength3d', { ns: 'skyview' })}
          </Label>
          <span>{props.metaData.lineLength.toFixed(2)} m</span>
        </P>
        <P>
          <Label>
            {t('assetPositionTooltip.designMultiselectProperties.totalLength2d', {
              ns: 'skyview',
            })}
          </Label>
          <span>{props.metaData.linePlanarLength.toFixed(2)} m</span>
        </P>
        <P>
          <Label>
            {t('assetPositionTooltip.designMultiselectProperties.totalSurface3d', {
              ns: 'skyview',
            })}
          </Label>
          <span>{props.metaData.area.toFixed(2)} m²</span>
        </P>
        <P>
          <Label>
            {t('assetPositionTooltip.designMultiselectProperties.totalSurface2d', {
              ns: 'skyview',
            })}
          </Label>
          <span>{props.metaData.planarArea.toFixed(2)} m²</span>
        </P>
        <P>
          <Label>
            {t('assetPositionTooltip.designMultiselectProperties.points', {
              ns: 'skyview',
            })}
          </Label>
          <span>{props.metaData.pointCount}</span>
        </P>
        <P>
          <Label>
            {t('assetPositionTooltip.designMultiselectProperties.segments', {
              ns: 'skyview',
            })}
          </Label>
          <span>{props.metaData.lineSegmentCount}</span>
        </P>
        <P>
          <Label>
            {t('assetPositionTooltip.designMultiselectProperties.triangles', {
              ns: 'skyview',
            })}
          </Label>
          <span>{props.metaData.triangleCount}</span>
        </P>
      </LabelledContainer>
    </Stack>
  );
};

const P = styled.p`
  display: flex;
  align-items: baseline;
`;

const Label = styled.label`
  width: 50%;
`;

const Component = styled(CoordinatePropertiesStyled)`
  min-width: 160px;

  p {
    display: flex;
    align-items: baseline;

    label {
      align-items: baseline;
    }
  }

  > p {
    label {
      width: 2em;
    }
  }
`;
