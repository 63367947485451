import { Document, Image, Page, pdf, StyleSheet, Text, View } from '@react-pdf/renderer';
import { t } from 'i18next';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import _ from 'underscore';

import { ProjectStore } from '../../../../../../js/stores/project-store';
import { formattedDateTime } from '../../../../../../js/utils/dateUtils';
import { normalizeString } from '../../../../../../js/utils/text/text';
import { isDefined } from '../../../../../../js/utils/variables';
import { PrismData } from '../../../../../../js/viewer/elements/volume/model/prism-volume-model';
import { PrismVolume } from '../../../../../../js/viewer/elements/volume/prism-volume';
import { reset } from '../../../../../utils/styled-reset';
import { pdfPageDimensions } from '../helpers/types';
import { downloadBlob, VolumeWithAsset } from '../helpers/utils';

const maxPerPage = 40;
const maxPrismPageCount = 49;
export const maxPageCountPrismReport = maxPrismPageCount + 1;
export const maxPrismCountPrismReport = maxPerPage * maxPrismPageCount;

/**
 * Controls how many decimals should be displayed for values in the report.
 */
const decimalPrecision = 2;

type Props = {
  volumeWithAsset: VolumeWithAsset;
  prismData?: PrismData[];
  overviewPictureDataUrl?: string;
  preview: boolean;
};

const PageCount = (props: { page: number; maxPage: number }) => {
  return (
    <View style={styles.pageCount}>
      <Text style={styles.text}>
        {t('prismReport.page', {
          ns: 'skyviewReport',
          currentPage: props.page,
          maxPage: props.maxPage,
        })}
      </Text>
    </View>
  );
};

// Define your document component
const PrismPdfReport = (props: Props) => {
  const prismChunks = props.prismData
    ? (_.chunk<PrismData[]>(props.prismData, maxPerPage) as PrismData[][])
    : [];
  const maxPageCount =
    1 + Math.ceil(((props.volumeWithAsset.volume as PrismVolume).prismCount ?? 0) / maxPerPage);

  return (
    <Document style={styles.flexColumnGap}>
      <Page dpi={72} orientation="landscape" size="A4" style={styles.page}>
        <View>
          <FrontPage
            overviewPictureDataUrl={props.overviewPictureDataUrl}
            preview={props.preview}
            volumeWithAsset={props.volumeWithAsset}
          />
        </View>
        <PageCount maxPage={maxPageCount} page={1} />
      </Page>
      {prismChunks.slice(0, maxPrismPageCount).map((data, index) => {
        return (
          <Page dpi={72} key={index} orientation="landscape" size="A4" style={styles.page}>
            <View>
              <PrismPage prisms={data} volumeWithAsset={props.volumeWithAsset} />
            </View>
            <PageCount maxPage={maxPageCount} page={index + 2} />
          </Page>
        );
      })}
    </Document>
  );
};

const TextWithLabel = (props: { label: string; text?: string | number }) => {
  return (
    <View style={styles.flexColumn}>
      <Text style={styles.sectionHeader}>{props.label}</Text>
      <Text style={styles.text}>{props.text}</Text>
    </View>
  );
};

const FlexRow = (props: { label: string; text?: string | number }) => {
  return (
    <View style={styles.flexRow}>
      <Text style={styles.tableCell}>{props.label}</Text>
      <Text style={styles.tableCell}>{props.text}</Text>
    </View>
  );
};

const Section = (props: { children: ReactNode; label: string }) => {
  return (
    <View style={styles.flexColumn}>
      <Text style={styles.sectionHeader}>{props.label}</Text>
      {props.children}
    </View>
  );
};

const HeaderCell = (props: { children: ReactNode; flex: 1.2 | 0.8 | 1.6 | 3.2 }) => {
  return (
    <View
      style={
        props.flex === 3.2
          ? prismTableStyles.header3p2
          : props.flex === 1.6
            ? prismTableStyles.header1p6
            : props.flex === 1.2
              ? prismTableStyles.header1p2
              : prismTableStyles.header0p8
      }
    >
      <Text>{props.children}</Text>
    </View>
  );
};

const Cell = (props: { children: ReactNode; flex: 0.8 | 1.2 }) => {
  return (
    <View style={props.flex === 1.2 ? prismTableStyles.cell1p2 : prismTableStyles.cell0p8}>
      <Text>{props.children}</Text>
    </View>
  );
};

const PrismPage = (props: { volumeWithAsset: VolumeWithAsset; prisms: PrismData[] }) => {
  return (
    <View style={prismTableStyles.table}>
      <View style={styles.flexRow}>
        <HeaderCell flex={3.2}>{t('prismReport.point1', { ns: 'skyviewReport' })}</HeaderCell>
        <HeaderCell flex={3.2}>{t('prismReport.point2', { ns: 'skyviewReport' })}</HeaderCell>
        <HeaderCell flex={3.2}>{t('prismReport.point3', { ns: 'skyviewReport' })}</HeaderCell>

        <HeaderCell flex={1.6}>{t('prismReport.volume', { ns: 'skyviewReport' })}</HeaderCell>
        <HeaderCell flex={1.6}>
          {t('common.surface3d', { ns: 'skyviewVolumeCalculation' })}
        </HeaderCell>
        <HeaderCell flex={1.6}>
          {t('common.surface2d', { ns: 'skyviewVolumeCalculation' })}
        </HeaderCell>
      </View>

      <View style={styles.flexRow}>
        <HeaderCell flex={1.2}>N</HeaderCell>
        <HeaderCell flex={1.2}>E</HeaderCell>
        <HeaderCell flex={0.8}>dH</HeaderCell>

        <HeaderCell flex={1.2}>N</HeaderCell>
        <HeaderCell flex={1.2}>E</HeaderCell>
        <HeaderCell flex={0.8}>dH</HeaderCell>

        <HeaderCell flex={1.2}>N</HeaderCell>
        <HeaderCell flex={1.2}>E</HeaderCell>
        <HeaderCell flex={0.8}>dH</HeaderCell>

        <HeaderCell flex={0.8}>{t('common.cut', { ns: 'skyviewVolumeCalculation' })}</HeaderCell>
        <HeaderCell flex={0.8}>{t('common.fill', { ns: 'skyviewVolumeCalculation' })}</HeaderCell>

        <HeaderCell flex={0.8}>{t('common.cut', { ns: 'skyviewVolumeCalculation' })}</HeaderCell>
        <HeaderCell flex={0.8}>{t('common.fill', { ns: 'skyviewVolumeCalculation' })}</HeaderCell>

        <HeaderCell flex={0.8}>{t('common.cut', { ns: 'skyviewVolumeCalculation' })}</HeaderCell>
        <HeaderCell flex={0.8}>{t('common.fill', { ns: 'skyviewVolumeCalculation' })}</HeaderCell>
      </View>

      {props.prisms.map((prism, index) => {
        return (
          <View key={index} style={styles.flexRow}>
            <Cell flex={1.2}>{prism[0].toFixed(decimalPrecision)}</Cell>
            <Cell flex={1.2}>{prism[1].toFixed(decimalPrecision)}</Cell>
            <Cell flex={0.8}>{prism[2].toFixed(decimalPrecision)}</Cell>

            <Cell flex={1.2}>{prism[3].toFixed(decimalPrecision)}</Cell>
            <Cell flex={1.2}>{prism[4].toFixed(decimalPrecision)}</Cell>
            <Cell flex={0.8}>{prism[5].toFixed(decimalPrecision)}</Cell>

            <Cell flex={1.2}>{prism[6].toFixed(decimalPrecision)}</Cell>
            <Cell flex={1.2}>{prism[7].toFixed(decimalPrecision)}</Cell>
            <Cell flex={0.8}>{prism[8].toFixed(decimalPrecision)}</Cell>

            <Cell flex={0.8}>
              {prism[9] > 0 ? '' : Math.abs(prism[9]).toFixed(decimalPrecision)}
            </Cell>
            <Cell flex={0.8}>
              {prism[9] < 0 ? '' : Math.abs(prism[9]).toFixed(decimalPrecision)}
            </Cell>

            <Cell flex={0.8}>
              {prism[10] > 0 ? '' : Math.abs(prism[10]).toFixed(decimalPrecision)}
            </Cell>
            <Cell flex={0.8}>
              {prism[10] < 0 ? '' : Math.abs(prism[10]).toFixed(decimalPrecision)}
            </Cell>

            <Cell flex={0.8}>
              {prism[11] > 0 ? '' : Math.abs(prism[11]).toFixed(decimalPrecision)}
            </Cell>
            <Cell flex={0.8}>
              {prism[11] < 0 ? '' : Math.abs(prism[11]).toFixed(decimalPrecision)}
            </Cell>
          </View>
        );
      })}
    </View>
  );
};

const FrontPage = (props: {
  volumeWithAsset: VolumeWithAsset;
  overviewPictureDataUrl?: string;
  preview: boolean;
}) => {
  const prismVolume = props.volumeWithAsset.volume as PrismVolume;
  const cut = prismVolume.cut ?? 0;
  const fill = prismVolume.fill ?? 0;
  const cutArea = prismVolume.cutArea ?? 0;
  const cutPlanArea = prismVolume.cutPlanArea ?? 0;
  const fillArea = prismVolume.fillArea ?? 0;
  const fillPlanArea = prismVolume.fillPlanArea ?? 0;
  const totalArea = cutArea + fillArea;
  const totalPlanArea = cutPlanArea + fillPlanArea;

  const formattedValue = (value: number, unit: 'm³' | 'm²') =>
    `${value?.toFixed(decimalPrecision) ?? '-'} ${unit}`;

  return (
    <View style={styles.flexRow}>
      <View style={styles.flexColumnGap}>
        <Text style={styles.header}>{props.volumeWithAsset.volume.name}</Text>

        <TextWithLabel
          label={t('prismReport.project', { ns: 'skyviewReport' })}
          text={ProjectStore.instance.project?.name}
        />
        <TextWithLabel
          label={t('prismReport.createdAt', { ns: 'skyviewReport' })}
          text={formattedDateTime(props.volumeWithAsset.volume.createdAt)}
        />
        <TextWithLabel
          label={t('common.targetSurface', { ns: 'skyviewVolumeCalculation' })}
          text={props.volumeWithAsset.asset.name}
        />
        <TextWithLabel
          label={t('common.sourceSurface', { ns: 'skyviewVolumeCalculation' })}
          text={props.volumeWithAsset.sourceName}
        />
        <TextWithLabel
          label={t('prismReport.prismCount', { ns: 'skyviewReport' })}
          text={prismVolume.prismCount}
        />

        {(prismVolume.prismCount ?? 0) > maxPrismCountPrismReport && (
          <TextWithLabel
            label={t('prismReport.includedPrismsHeader', { ns: 'skyviewReport' })}
            text={t('prismReport.includedPrismsText', {
              ns: 'skyviewReport',
              value: maxPrismCountPrismReport,
            })}
          />
        )}

        <Section label={t('prismReport.volume', { ns: 'skyviewReport' })}>
          <FlexRow
            label={t('common.cut', { ns: 'skyviewVolumeCalculation' })}
            text={formattedValue(cut, 'm³')}
          />
          <FlexRow
            label={t('common.fill', { ns: 'skyviewVolumeCalculation' })}
            text={formattedValue(fill, 'm³')}
          />
        </Section>

        <Section label={t('common.surface3d', { ns: 'skyviewVolumeCalculation' })}>
          <FlexRow
            label={t('common.cut', { ns: 'skyviewVolumeCalculation' })}
            text={formattedValue(cutArea, 'm²')}
          />
          <FlexRow
            label={t('common.fill', { ns: 'skyviewVolumeCalculation' })}
            text={formattedValue(fillArea, 'm²')}
          />
          <FlexRow
            label={t('prismReport.total', { ns: 'skyviewReport' })}
            text={formattedValue(totalArea, 'm²')}
          />
        </Section>

        <Section label={t('common.surface2d', { ns: 'skyviewVolumeCalculation' })}>
          <FlexRow
            label={t('common.cut', { ns: 'skyviewVolumeCalculation' })}
            text={formattedValue(cutPlanArea, 'm²')}
          />
          <FlexRow
            label={t('common.fill', { ns: 'skyviewVolumeCalculation' })}
            text={formattedValue(fillPlanArea, 'm²')}
          />
          <FlexRow
            label={t('prismReport.total', { ns: 'skyviewReport' })}
            text={formattedValue(totalPlanArea, 'm²')}
          />
        </Section>
      </View>
      {isDefined(props.overviewPictureDataUrl) && (
        <View style={styles.overviewPictureFlexContainer}>
          {props.preview ? (
            <img src={props.overviewPictureDataUrl} style={styles.overviewPicture} />
          ) : (
            <Image src={props.overviewPictureDataUrl} style={styles.overviewPicture} />
          )}
        </View>
      )}
    </View>
  );
};

export const PrismPdfReportPreview = (props: Omit<Props, 'preview'>) => {
  return (
    <PreviewContainer>
      <PrismPdfReport
        overviewPictureDataUrl={props.overviewPictureDataUrl}
        preview={true}
        prismData={props.prismData}
        volumeWithAsset={props.volumeWithAsset}
      />
    </PreviewContainer>
  );
};

/**
 * Styling is adjusted so that the in browser preview
 * will resemble the PDF-report as closely as possible.
 */
const PreviewContainer = styled.div`
  ${reset}
  box-sizing: reset;
  font-family: helvetica;
  line-height: normal;
`;

/**
 * Styling is adjusted for 72 dpi resolution in the PDF.
 */
const styles = StyleSheet.create({
  page: {
    position: 'relative',
    border: '1px solid #a5acaf',
    padding: `${pdfPageDimensions.a4.landscape72Dpi.defaultPadding}px`,
    width: `${pdfPageDimensions.a4.landscape72Dpi.width}px`,
    height: `${pdfPageDimensions.a4.landscape72Dpi.height}px`,
  },
  pageCount: {
    position: 'absolute',
    right: '6px',
    bottom: '6px',
  },
  flexColumnGap: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    flex: 1,
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  },
  header: {
    fontSize: '16px',
  },
  sectionHeader: {
    fontSize: '12px',
    color: '#5e6a71',
  },
  tableCell: {
    fontSize: '12px',
    width: '120px',
  },
  text: {
    fontSize: '12px',
  },
  overviewPictureFlexContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    border: '1px solid #a5acaf',
    boxSizing: 'border-box',
  },
  overviewPicture: {
    width: '98%',
  },
});

const prismTableStyles = StyleSheet.create({
  table: {
    display: 'flex',
    flexDirection: 'column',
    borderLeft: '1px solid #a5acaf',
    borderTop: '1px solid #a5acaf',
    boxSizing: 'border-box',
  },
  header3p2: {
    fontSize: '11px',
    borderBottom: '1px solid #a5acaf',
    borderRight: '1px solid #a5acaf',
    boxSizing: 'border-box',
    flex: 3.2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: '16px',
  },
  header1p6: {
    fontSize: '11px',
    borderBottom: '1px solid #a5acaf',
    borderRight: '1px solid #a5acaf',
    boxSizing: 'border-box',
    flex: 1.6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: '16px',
  },
  header1p2: {
    fontSize: '11px',
    borderBottom: '1px solid #a5acaf',
    borderRight: '1px solid #a5acaf',
    boxSizing: 'border-box',
    flex: 1.2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: '16px',
  },
  header0p8: {
    fontSize: '11px',
    borderBottom: '1px solid #a5acaf',
    borderRight: '1px solid #a5acaf',
    boxSizing: 'border-box',
    flex: 0.8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: '16px',
  },
  cell1p2: {
    fontSize: '8px',
    borderBottom: '1px solid #a5acaf',
    borderRight: '1px solid #a5acaf',
    boxSizing: 'border-box',
    flex: 1.2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: '12px',
  },
  cell0p8: {
    fontSize: '8px',
    borderBottom: '1px solid #a5acaf',
    borderRight: '1px solid #a5acaf',
    boxSizing: 'border-box',
    flex: 0.8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: '12px',
  },
});

export async function exportPrismPdfReport(
  volumeWithAsset: VolumeWithAsset,
  prismData?: PrismData[],
  overviewPictureDataUrl?: string,
) {
  const component = (
    <PrismPdfReport
      overviewPictureDataUrl={overviewPictureDataUrl}
      preview={false}
      prismData={prismData}
      volumeWithAsset={volumeWithAsset}
    />
  );

  const result = pdf(component);

  // TODO: Thread blocking action, look into options for moving this to a webworker.
  const blob = await result.toBlob();

  downloadBlob(
    blob,
    `${normalizeString(volumeWithAsset.volume.name ?? 'prism_volume_report')}`,
    'pdf',
  );
}
