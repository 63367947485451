import usePrevious from '@react-hook/previous';
import {
  addDays,
  eachWeekOfInterval,
  endOfMonth,
  format,
  getDate,
  getISOWeek,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isWeekend,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { lighten } from 'polished';
import React from 'react';
import styled from 'styled-components';
import _ from 'underscore';

import { getDateFnsLocale } from '../../../../js/utils/dateUtils';
import { DateOnly } from '../../../utils/date/date-only';
import { MonthOnly } from '../../../utils/date/month-only';
import { reset } from '../../../utils/styled-reset';
import { itemDimension, SelectableItem } from '../styles/selectable-item';

const isDayEnabled = (date: DateOnly, minDate?: DateOnly, maxDate?: DateOnly) => {
  // Important that time is not set. Only compare date.
  if (minDate && isBefore(date.toDate(), minDate.toDate())) {
    return false;
  }

  if (maxDate && isAfter(date.toDate(), maxDate.toDate())) {
    return false;
  }

  return true;
};

const generateWeekDayNames = () => {
  const weekStart = startOfWeek(new Date(), { locale: getDateFnsLocale() });
  return _.range(7).map((e, i) =>
    format(addDays(weekStart, i), 'eeeeee', { locale: getDateFnsLocale() }),
  );
};

const generateWeeks = (
  currentMonth: MonthOnly,
  selectedDate: DateOnly,
  minDate?: DateOnly,
  maxDate?: DateOnly,
) => {
  const currentMonthDate = currentMonth.toDate();

  // Get start date of all weeks for current month.
  const startDateWeeks = eachWeekOfInterval(
    {
      start: startOfMonth(currentMonthDate),
      end: endOfMonth(currentMonthDate),
    },
    { locale: getDateFnsLocale() },
  );

  // Generate week days and meta data for each day.
  return startDateWeeks.map((startDate) => ({
    number: getISOWeek(startDate),
    days: [...Array(7)].map((_, i) => {
      const date = addDays(startDate, i);

      return {
        date,
        isEnabled: isDayEnabled(DateOnly.fromDate(date), minDate, maxDate),
        isWeekend: isWeekend(date),
        isCurrentMonth: isSameMonth(date, currentMonthDate),
        isSelected: isSameDay(date, selectedDate.toDate()),
      };
    }),
  }));
};

interface Week {
  number: number;
  days: WeekDay[];
}

interface WeekDay {
  date: Date;
  isEnabled: boolean;
  isWeekend: boolean;
  isCurrentMonth: boolean;
  isSelected: boolean;
}

interface Props {
  currentMonth: MonthOnly;
  selectedDate: DateOnly;
  minDate?: DateOnly;
  maxDate?: DateOnly;
  onNumWeeksChanged: (numWeeks: number) => void;
  onDateSelected: (date: DateOnly) => void;
}

type CalendarState = Week[];
type CalendarAction = { type: 'INIT'; weeks: Week[] } | { type: 'SELECT'; date: Date };

const Calendar = (props: Props) => {
  const [currentMonth, setCurrentMonth] = React.useState(props.currentMonth);
  const [selectedDate, setSelectedDate] = React.useState(props.selectedDate);
  const [weekDayNames, setWeekDayNames] = React.useState<string[]>([]);
  const [weeks, dispatchWeeks] = React.useReducer(weeksReducer, []);
  const numWeeksPrevious = usePrevious(weeks.length, 0);

  const { onNumWeeksChanged: propsOnNumWeeksChanged } = props;

  // Generate week day names only once.
  React.useEffect(() => {
    setWeekDayNames(generateWeekDayNames());
  }, []);

  React.useEffect(() => {
    setCurrentMonth(props.currentMonth);
  }, [props.currentMonth]);

  React.useEffect(() => {
    if (weeks.length !== numWeeksPrevious) {
      propsOnNumWeeksChanged(weeks.length);
    }
  }, [propsOnNumWeeksChanged, numWeeksPrevious, weeks]);

  React.useEffect(() => {
    dispatchWeeks({
      type: 'INIT',
      weeks: generateWeeks(currentMonth, props.selectedDate, props.minDate, props.maxDate),
    });
  }, [currentMonth, props.selectedDate, props.minDate, props.maxDate]);

  /**
   * Selects the specified date. If date is not in current month
   * specified month will be rendered.
   *
   * @param date Date to set as selected.
   */
  function selectDate(date: Date) {
    if (isSameMonth(selectedDate.toDate(), date)) {
      dispatchWeeks({
        type: 'SELECT',
        date,
      });
    } else {
      setCurrentMonth(MonthOnly.fromDate(date));
      setSelectedDate(DateOnly.fromDate(date));
    }

    props.onDateSelected(DateOnly.fromDate(date));
  }

  function weeksReducer(state: CalendarState, action: CalendarAction) {
    switch (action.type) {
      case 'INIT':
        return action.weeks;

      case 'SELECT': {
        const weeks = [...state];

        // Unselect previous selected if any.
        let stop = false;
        for (const week of weeks) {
          if (stop) {
            break;
          }
          week.days.forEach((day, index) => {
            if (day.isSelected) {
              week.days[index] = {
                ...week.days[index],
                isSelected: false,
              };
              stop = true;
              return false;
            }
          });
        }

        // Set specified date as selected.
        const weekIndex = weeks.findIndex(
          (x) => x.days.findIndex((day) => isSameDay(day.date, action.date)) !== -1,
        );
        const dayIndex = weeks[weekIndex].days.findIndex((x) => isSameDay(x.date, action.date));
        weeks[weekIndex].days[dayIndex] = {
          ...weeks[weekIndex].days[dayIndex],
          isSelected: true,
        };

        return weeks;
      }
    }
  }

  return (
    <Component>
      <thead>
        <tr>
          <th>
            <HeaderCell>v</HeaderCell>
          </th>
          {weekDayNames.map((x) => (
            <th key={x}>
              <WeekDayHeaderCell>{x}</WeekDayHeaderCell>
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {weeks.map((week) => (
          <tr key={week.number}>
            <WeekNumberCell>{week.number}</WeekNumberCell>

            {week.days.map((day) => (
              <td key={day.date.getTime()}>
                <SelectableItem
                  boldText={day.isWeekend}
                  enabled={day.isEnabled}
                  lightColor={!day.isCurrentMonth}
                  selected={day.isSelected}
                  onClick={() => {
                    if (day.isEnabled) {
                      selectDate(day.date);
                    }
                  }}
                >
                  {getDate(day.date)}
                </SelectableItem>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Component>
  );
};

const Component = styled.table`
  ${reset}

  font-size: 12px;
  border-spacing: 0px;
  border-collapse: separate;
  user-select: none;
`;

const HeaderCell = styled.div`
  ${reset}
  ${itemDimension}
`;

const WeekDayHeaderCell = styled(HeaderCell)`
  font-weight: 600;
`;

const WeekNumberCell = styled.td`
  ${reset}
  ${itemDimension}

  font-weight: 400;

  background-color: ${(props) => lighten(0.03, props.theme.color.gray.lightest)};
  pointer-events: none;
`;

Calendar.styled = Component;

export { Calendar };
