import PubSub from 'pubsub-js';
import React from 'react';
import styled from 'styled-components';
import _ from 'underscore';

import {
  CoordinateSystemModel,
  CountryCoordinateSystemModel,
  GeoidModel,
  GetCoordinateSystemListResponse,
} from '../../../../typings/api/skymap/rest/v1/.common';
import { SkyMapAxiosServiceFactory } from '../../../js/services/axios/skymap-axios-service-factory';
import { ProjectStore } from '../../../js/stores/project-store';
import { UserStore } from '../../../js/stores/user-store';
import { isDefined } from '../../../js/utils/variables';
import { useDialog } from '../../hooks/use-dialog';
import { Button, ButtonStyled } from '../button/button';
import { Hyperlink } from '../hyperlink/hyperlink';
import { InfoBox } from '../info-box/info-box';
import { LabelledContainer } from '../labelled-container/labelled-container';
import { Select } from '../select/select';
import { Stack } from '../stack/stack';
import { AddGeoidDialog } from './dialogs/add-geoid-dialog';
import { SectionTitle } from './styles/styles';

interface Country {
  id: string;
  name: string;
}

interface Item {
  id: string;
  name: string;
  countryId: string | null;
  sourceName?: string;
  sourceUrl?: string;
  licenseName?: string;
  licenseUrl?: string;
}

/**
 * Option for displaying all coordinate systems and geoids regardless of country.
 */
const countryAll: Country = {
  id: 'all',
  name: 'Alla',
};

/**
 * Placeholder item for coordinate system or geoid.
 */
const placeholder: Item = {
  id: 'placeholder',
  countryId: null,
  name: 'Välj',
};

function getUniqueCountries(response: GetCoordinateSystemListResponse) {
  let fullCountryList: Country[] = response.data
    .filter((x) => x.countryCoordinateSystems?.length === 1)
    .map(mapCountry);
  fullCountryList = _.uniq(fullCountryList, (x) => x.id);
  fullCountryList.push(countryAll);

  return fullCountryList;
}

const mapGeoid = (model: GeoidModel): Item => {
  return {
    countryId: model.countryGeoids?.[0]?.countryId ?? null,
    id: model.id,
    name: model.countryGeoids?.[0]?.displayName ?? model.name,
    sourceName: model.sourceName,
    sourceUrl: model.sourceUrl,
    licenseName: model.licenseName,
    licenseUrl: model.licenseUrl,
  };
};

const mapCountry = (model: CoordinateSystemModel): Country => {
  return {
    id: model.countryCoordinateSystems![0].countryId,
    name: model.countryCoordinateSystems![0].country!.name,
  };
};

const mapCoordinateSystem = (model: CoordinateSystemModel): Item[] => {
  const getName = (ccs?: CountryCoordinateSystemModel) =>
    `${ccs?.displayName ?? model.name} (${model.authority ?? ''})`;

  const noCountryCrs: Item = {
    countryId: null,
    id: model.id,
    name: getName(),
  };

  if (!isDefined(model.countryCoordinateSystems) || model.countryCoordinateSystems.length === 0) {
    return [noCountryCrs];
  }

  return [
    noCountryCrs,
    ...model.countryCoordinateSystems.map((ccs) => {
      return {
        countryId: ccs.countryId,
        id: model.id,
        name: getName(ccs),
      };
    }),
  ];
};

async function getGeoidList() {
  const getGeoidsResponse = await SkyMapAxiosServiceFactory.instance
    .createGeoidServiceV1()
    .getGeoids({});

  return getGeoidsResponse.data.map(mapGeoid);
}

async function getCoordinateSystemsAndCountries() {
  const response = await SkyMapAxiosServiceFactory.instance
    .createCoordinateSystemServiceV1()
    .getCoordinateSystems({});

  const fullCountryList = getUniqueCountries(response);

  /**
   * A flat list of coordinate systems based on country.
   *
   * For example, if a coordinate system exists for 2 countries this list
   * will contain 3 entries that represents that coordinate system.
   * One for each country.
   */
  const fullCoordinateSystemList = response.data.flatMap(mapCoordinateSystem);

  return { fullCoordinateSystemList, fullCountryList };
}

/**
 * Gets the selected coordinate system based on what coordinate system set in the
 * project store.
 *
 * TODO: Add countryId to Project. Then when every project is associated with a country
 * we can preselect the correct country coordinate system based on that country id.
 *
 * For now. If a coordinate system is available for two or more countries we will select
 * the entry with { countryId: null }. This will ensure that no specific country is
 * preselected in the country dropdown in the component.
 */
function getSelectedCoordinateSystem(fullCoordinateSystemList: Item[]) {
  if (!isDefined(ProjectStore.instance.coordinateSystem)) {
    return undefined;
  }

  const matchingCoordinateSystems = fullCoordinateSystemList.filter(
    (x) => x.id === ProjectStore.instance.coordinateSystem?.id,
  );

  /**
   * If the coordinate system doesnt exist in the coordinate list then we append it.
   * This can for example happen when the organization admin has set a coordinate system
   * for project that the company admin does not have access to.
   */
  if (matchingCoordinateSystems?.length === 0) {
    const unknownCrs = mapCoordinateSystem(ProjectStore.instance.coordinateSystem)[0];
    fullCoordinateSystemList.push(unknownCrs);
    return unknownCrs;
  }

  /**
   * Note:
   * The list will always contain one item without countryId. i.e. { countryId: null }
   * Any other entries will have countryId set.
   */
  return matchingCoordinateSystems?.length === 2
    ? matchingCoordinateSystems.find((x) => isDefined(x.countryId))
    : matchingCoordinateSystems?.find((x) => !isDefined(x.countryId));
}

const Projection = () => {
  const [countries, setCountries] = React.useState<Country[]>([]);
  const [country, setCountry] = React.useState<Country>();

  const [allCoordinateSystems, setAllCoordinateSystems] = React.useState<Item[]>([]);
  const [coordinateSystems, setCoordinateSystems] = React.useState<Item[]>([]);
  const [coordinateSystem, setCoordinateSystem] = React.useState<Item>();

  const [allGeoids, setAllGeoids] = React.useState<Item[]>([]);
  const [geoids, setGeoids] = React.useState<Item[]>([]);
  const [geoid, setGeoid] = React.useState<Item>();

  const addGeoidDialog = useDialog();

  const filterByCountry = (items: Item[], selectedItem?: Item, countryId?: string) => {
    const filteredResult =
      countryId && countryId !== countryAll.id
        ? items.filter((x) => x.countryId === countryId)
        : items.filter((x) => !isDefined(x.countryId));

    if (!selectedItem) {
      filteredResult.unshift(placeholder);
    } else if (!filteredResult.find((x) => x.id === selectedItem.id)) {
      filteredResult.unshift(selectedItem);
    }

    return filteredResult;
  };

  const onCountryChanged = (id: string) => {
    if (country?.id === id) {
      return;
    }

    const newCountry = countries.find((x) => x.id === id);
    if (newCountry) {
      setCountry(newCountry);

      setCoordinateSystems(filterByCountry(allCoordinateSystems, coordinateSystem, id));
      setCoordinateSystem(coordinateSystem ?? placeholder);

      setGeoids(filterByCountry(allGeoids, geoid, id));
      setGeoid(geoid ?? placeholder);
    }
  };

  const onCoordinateSystemChanged = async (id: string) => {
    if (coordinateSystem?.id === id || id === placeholder.id) {
      return;
    }

    const newCoordinateSystem = allCoordinateSystems.find((x) => x.id === id);
    if (newCoordinateSystem) {
      try {
        await ProjectStore.instance.setCoordinateSystem(newCoordinateSystem.id);
        setCoordinateSystem(newCoordinateSystem);
      } catch {
        PubSub.publish('notify', { code: 'err0320' });
      }
    } else {
      setCoordinateSystem(newCoordinateSystem);
    }
  };

  const onGeoidChanged = async (id: string) => {
    if (geoid?.id === id || id === placeholder.id) {
      return;
    }

    const newGeoid = allGeoids.find((x) => x.id === id);
    if (newGeoid) {
      try {
        await ProjectStore.instance.setGeoid(newGeoid.id);
        setGeoid(newGeoid);
      } catch {
        PubSub.publish('notify', { code: 'err0319' });
      }
    } else {
      setGeoid(newGeoid);
    }
  };

  React.useEffect(() => {
    const load = async () => {
      const { fullCoordinateSystemList, fullCountryList } =
        await getCoordinateSystemsAndCountries();
      const selectedCoordinateSystem = getSelectedCoordinateSystem(fullCoordinateSystemList);
      const fullGeoidList = await getGeoidList();

      setAllGeoids(fullGeoidList);
      setAllCoordinateSystems(fullCoordinateSystemList);
      setCountries(fullCountryList);

      const selectedGeoid = isDefined(ProjectStore.instance.geoid)
        ? mapGeoid(ProjectStore.instance.geoid)
        : undefined;

      const selectedCountry = selectedCoordinateSystem
        ? fullCountryList.find((x) => x.id === selectedCoordinateSystem?.countryId)
        : fullCountryList.find((x) => x.name === 'Sweden');

      const csList = filterByCountry(
        fullCoordinateSystemList,
        selectedCoordinateSystem,
        selectedCountry?.id,
      );
      const geoidList = filterByCountry(fullGeoidList, selectedGeoid, selectedCountry?.id);

      setCountry(selectedCountry ?? countryAll);

      setCoordinateSystems(csList);
      setCoordinateSystem(selectedCoordinateSystem ?? placeholder);

      setGeoids(geoidList);
      setGeoid(selectedGeoid ?? placeholder);
    };

    void load();
  }, []);

  const renderInfoAboutSelectedGeoid = () => {
    if (!isDefined(geoid)) {
      return;
    }

    const items: {
      title: string;
      url: string;
      name: string;
    }[] = [];

    // Source.
    if (isDefined(geoid.sourceName) && isDefined(geoid.sourceUrl)) {
      items.push({
        title: 'Källa:',
        url: geoid.sourceUrl,
        name: geoid.sourceName,
      });
    }

    // Licence.
    if (isDefined(geoid.licenseName) && isDefined(geoid.licenseUrl)) {
      items.push({
        title: 'Licens:',
        url: geoid.licenseUrl,
        name: geoid.licenseName,
      });
    }

    return (
      items.length > 0 && (
        <InfoBox color="yellow" topMargin={true}>
          <table>
            {items.map((item) => (
              <tr key={item.title}>
                <td>{item.title}</td>
                <td>
                  <Hyperlink target="_blank" url={item.url}>
                    {item.name}
                  </Hyperlink>
                </td>
              </tr>
            ))}
          </table>
        </InfoBox>
      )
    );
  };

  return (
    <Component>
      <SectionTitle>KOORDINATSYSTEM</SectionTitle>

      <Stack spacing={1}>
        <LabelledContainer text="Land">
          {(formElementId) => (
            <Select
              id={formElementId()}
              options={countries}
              placeholderText={'-- Laddar --'}
              value={country?.id}
              onChange={(e) => onCountryChanged(e.target.value)}
            />
          )}
        </LabelledContainer>

        <LabelledContainer text="Koordinatsystem">
          {(formElementId) => (
            <Select
              id={formElementId()}
              options={coordinateSystems}
              placeholderText={'-- Laddar --'}
              value={coordinateSystem?.id}
              onChange={(e) => onCoordinateSystemChanged(e.target.value)}
            />
          )}
        </LabelledContainer>

        <LabelledContainer text="Geoidmodell">
          {(formElementId) => (
            <>
              <Select
                id={formElementId()}
                options={geoids}
                placeholderText={'-- Laddar --'}
                value={geoid?.id}
                onChange={(e) => onGeoidChanged(e.target.value)}
              />

              {renderInfoAboutSelectedGeoid()}

              {UserStore.instance.isOrganizationAdmin() && (
                <Button variant="contained" onClick={() => addGeoidDialog.show()}>
                  Lägg till ny geoidmodell
                </Button>
              )}
            </>
          )}
        </LabelledContainer>
      </Stack>

      {addGeoidDialog.render(
        <AddGeoidDialog
          onClose={(newGeoid) => {
            if (newGeoid) {
              setAllGeoids([mapGeoid(newGeoid), ...allGeoids]);
              setGeoids([mapGeoid(newGeoid), ...geoids]);
            }
            addGeoidDialog.hide();
          }}
        />,
      )}
    </Component>
  );
};

const Component = styled.div`
  table {
    border-collapse: collapse;
    border-collapse: separate;
    border-spacing: 0.2em;

    td:first-child {
      padding-right: 0.5em;
    }
  }

  ${ButtonStyled} {
    margin-top: 1em;
  }
`;

Projection.styled = Component;

export { Projection };
