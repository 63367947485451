import { addMonths, format, isAfter, isBefore, isSameDay } from 'date-fns';
import { t } from 'i18next';
import React from 'react';
import styled, { css } from 'styled-components';

import { getDateFnsLocale } from '../../../js/utils/dateUtils';
import { useNonce } from '../../hooks/use-nonce';
import { DateOnly } from '../../utils/date/date-only';
import { MonthOnly } from '../../utils/date/month-only';
import { Button } from '../button/button';
import { Dialog } from '../dialog/dialog';
import { LanguageProvider } from '../language-provider/language-provider';
import { Calendar } from './components/calendar';
import { CalendarHeader } from './components/calendar-header';
import { TimeHeader } from './components/time-header';
import { TimeScrollBars } from './components/time-scroll-bars';

type Props = {
  startDate: Date;
  minDate?: Date;
  maxDate?: Date;
  displayTime: boolean;
  onDateChoosen: (date: Date) => void;
  onCancel: () => void;
};

const DateTimePickerDialog = (props: Props) => {
  // For some reason useTranslation() yields:
  // "React has detected a change in the order of Hooks called by DateTimePickerDialog."
  // Importing t from 'i18next' solves the problem for now.
  // todo: Use useTranslation when Angular is removed and translations are loaded on login.
  const [currentMonth, setCurrentMonth] = React.useState(MonthOnly.fromDate(props.startDate));
  const [selectedDate, setSelectedDate] = React.useState(DateOnly.fromDate(props.startDate));
  const [hour, setHour] = React.useState(props.startDate.getHours());
  const [minute, setMinute] = React.useState(props.startDate.getMinutes());
  const [numWeeks, setNumWeeks] = React.useState(0);
  const [scrollIntoViewNonce, refreshScrollIntoViewNonce] = useNonce();

  const getSelectedDateTime = () => {
    return props.displayTime
      ? new Date(selectedDate.year, selectedDate.month, selectedDate.day, hour, minute)
      : selectedDate.toDate();
  };

  /**
   * Strips time from date if props.displayTime is false.
   */
  const stripTime = (date: Date) => {
    return props.displayTime ? date : DateOnly.fromDate(date).toDate();
  };

  const isSelectedDateTimeValid = () => {
    const selectedDate = getSelectedDateTime();

    if (props.minDate && isBefore(selectedDate, stripTime(props.minDate))) {
      return false;
    }

    if (props.maxDate && isAfter(selectedDate, stripTime(props.maxDate))) {
      return false;
    }

    return true;
  };

  const getHeaderText = () => {
    return format(getSelectedDateTime(), props.displayTime ? 'PPPPp' : 'PPPP', {
      locale: getDateFnsLocale(),
    });
  };

  const getCalendarTitle = () => {
    const monthName = format(currentMonth.toDate(), 'MMMM', { locale: getDateFnsLocale() });
    return `${monthName} ${currentMonth.year}`;
  };

  const onPrevMonth = () => {
    setCurrentMonth(MonthOnly.fromDate(addMonths(currentMonth.toDate(), -1)));
  };

  const onNextMonth = () => {
    setCurrentMonth(MonthOnly.fromDate(addMonths(currentMonth.toDate(), 1)));
  };

  const onTimeChanged = (hour: number, minute: number) => {
    setHour(hour);
    setMinute(minute);
  };

  const onToday = () => {
    const now = new Date();
    setCurrentMonth(MonthOnly.fromDate(now));
    setSelectedDate(DateOnly.fromDate(now));
    setHour(now.getHours());
    setMinute(now.getMinutes());

    refreshScrollIntoViewNonce();
  };

  const onChoose = () => {
    props.onDateChoosen(getSelectedDateTime());
  };

  const resolveMinHour = () => {
    return props.minDate && isSameDay(props.minDate, selectedDate.toDate())
      ? props.minDate.getHours()
      : undefined;
  };

  const resolveMaxHour = () => {
    return props.maxDate && isSameDay(props.maxDate, selectedDate.toDate())
      ? props.maxDate.getHours()
      : undefined;
  };

  const resolveMinMinute = () => {
    return props.minDate &&
      isSameDay(props.minDate, selectedDate.toDate()) &&
      hour === resolveMinHour()
      ? props.minDate.getMinutes()
      : undefined;
  };

  const resolveMaxMinute = () => {
    return props.maxDate &&
      isSameDay(props.maxDate, selectedDate.toDate()) &&
      hour === resolveMaxHour()
      ? props.maxDate.getMinutes()
      : undefined;
  };

  return (
    <LanguageProvider>
      <Dialog closeIcon={true} closeOnDimmerClick={true} onClose={props.onCancel}>
        {{
          header: getHeaderText(),
          content: (
            <Content displayTime={props.displayTime}>
              <CalendarHeader
                title={getCalendarTitle()}
                onNextMonth={onNextMonth}
                onPrevMonth={onPrevMonth}
              />

              <Calendar
                currentMonth={currentMonth}
                maxDate={props.maxDate && DateOnly.fromDate(props.maxDate)}
                minDate={props.minDate && DateOnly.fromDate(props.minDate)}
                selectedDate={selectedDate}
                onDateSelected={(date) => {
                  setSelectedDate(date);
                  setCurrentMonth(MonthOnly.fromDateOnly(date));
                }}
                onNumWeeksChanged={(numWeeks) => setNumWeeks(numWeeks)}
              />

              {props.displayTime && (
                <>
                  <TimeHeader hour={hour} minute={minute} />

                  <TimeScrollBars
                    hour={hour}
                    maxHour={resolveMaxHour()}
                    maxMinute={resolveMaxMinute()}
                    minHour={resolveMinHour()}
                    minMinute={resolveMinMinute()}
                    minute={minute}
                    numVisibleItems={numWeeks + 1}
                    scrollIntoViewNonce={scrollIntoViewNonce}
                    onTimeChanged={onTimeChanged}
                  />
                </>
              )}
            </Content>
          ),
          footer: {
            left: (
              <Button variant="contained" onClick={onToday}>
                {t('today', { ns: 'common' })}
              </Button>
            ),
            right: (
              <>
                <Button
                  color="primary"
                  disabled={!isSelectedDateTimeValid()}
                  variant="contained"
                  onClick={onChoose}
                >
                  {t('choose', { ns: 'common' })}
                </Button>
                <Button variant="text" onClick={props.onCancel}>
                  {t('close', { ns: 'common' })}
                </Button>
              </>
            ),
          },
        }}
      </Dialog>
    </LanguageProvider>
  );
};

const Content = styled.div<Pick<Props, 'displayTime'>>`
  display: grid;
  gap: 15px;

  ${(props) =>
    props.displayTime
      ? css`
          grid-template-areas:
            'calendar-header time-header'
            'calendar time-scroll-bars';
        `
      : css`
          grid-template-areas:
            'calendar-header'
            'calendar';
        `}

  ${CalendarHeader.styled} {
    grid-area: calendar-header;
  }
  ${Calendar.styled} {
    grid-area: calendar;
  }
  ${TimeHeader.styled} {
    grid-area: time-header;
  }
  ${TimeScrollBars.styled} {
    grid-area: time-scroll-bars;
  }
`;

export { DateTimePickerDialog, Props as DateTimePickerDialogProps };
