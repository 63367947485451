import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import type * as CSS from 'csstype';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { Icon } from '../../../../icon/icon';

export type VolumeType = 'cellGrid' | 'prism';

type Props = {
  disabled: boolean;
  visible: boolean;
  backgroundColorVisible: {
    default: CSS.Properties['backgroundColor'];
    hover: CSS.Properties['backgroundColor'];
  };
  backgroundColorHidden: {
    default: CSS.Properties['backgroundColor'];
    hover: CSS.Properties['backgroundColor'];
  };
  iconVisible: {
    default: FontAwesomeIconProps;
    hover: FontAwesomeIconProps;
  };
  iconHidden: {
    default: FontAwesomeIconProps;
    hover: FontAwesomeIconProps;
  };
  volumeType: VolumeType;
  onClick: () => void;
};

const CutFillBox = (props: Props) => {
  const { t } = useTranslation();
  const [hover, setHover] = React.useState(false);

  // Recreate props and set default style to hover style if box is hovered.
  const iconVisible = {
    ...props.iconVisible,
    default: hover ? props.iconVisible.hover : props.iconVisible.default,
  };
  const iconHidden = {
    ...props.iconHidden,
    default: hover ? props.iconHidden.hover : props.iconHidden.default,
  };

  // A cell grid should enable the button if it is not disabled.
  // A prism volume has the button disabled (not clickable) always,
  // but as the only available state for cut & fill for prisms, the button should still be 'enabled',
  // displaying the correct icon and color, if the volume is visible.
  const buttonEnabled = props.volumeType === 'cellGrid' ? !props.disabled : props.visible;
  const buttonVisible =
    props.volumeType === 'cellGrid' ? props.visible && !props.disabled : props.visible;

  // Figure out which one to use.
  const iconProps = buttonVisible ? iconVisible.default : iconHidden.default;

  return (
    <Component
      defaultBackgroundColor={
        buttonVisible ? props.backgroundColorVisible.default : props.backgroundColorHidden.default
      }
      disabled={props.disabled}
      hoverBackgroundColor={
        props.visible
          ? props.backgroundColorVisible.hover
          : props.backgroundColorHidden.hover ?? undefined
      }
      title={
        props.disabled
          ? t('volumes.icons.volumeHidden', { ns: 'skyviewAssetMenu' })
          : props.visible
            ? t('hide', { ns: 'common' })
            : t('show', { ns: 'common' })
      }
      onClick={() => !props.disabled && props.onClick()}
      onMouseEnter={() => !props.disabled && setHover(true)}
      onMouseLeave={() => !props.disabled && setHover(false)}
    >
      <Icon
        {...iconProps}
        disabled={!buttonEnabled}
        fixedWidth={true}
        onHoverStyle={props.visible ? iconVisible.hover : iconHidden.hover}
      />
    </Component>
  );
};

type ComponentProps = {
  disabled: boolean;
  defaultBackgroundColor: CSS.Properties['backgroundColor'];
  hoverBackgroundColor?: CSS.Properties['backgroundColor'];
};

const Component = styled.div<ComponentProps>`
  background-color: ${(props) => props.defaultBackgroundColor};
  border: 1px solid ${(props) => props.hoverBackgroundColor ?? props.defaultBackgroundColor};
  height: 100%;
  border-radius: 0.5em;
  display: grid;
  place-content: center;
  padding: 0 0.5em;

  ${(props) =>
    !props.disabled &&
    css<ComponentProps>`
      :hover {
        background-color: ${(props) => props.hoverBackgroundColor};
        cursor: pointer;
      }
    `}
`;

export { CutFillBox, Props as CutFillBoxProps };
