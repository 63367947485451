import React from 'react';
import styled from 'styled-components';

interface Props {
  url?: string;
}

const IFrame = (props: Props) => {
  return (
    <Component>
      {props.url ? (
        <iframe
          allowFullScreen={true}
          data-testid="session-link-iframe"
          //@ts-ignore
          mozallowfullscreen="true"
          sandbox="allow-same-origin allow-scripts allow-popups allow-top-navigation-by-user-activation"
          src={props.url}
          style={{ width: '100%', height: '100%' }}
          webkitallowfullscreen="true"
        />
      ) : (
        <NoAccessToken>
          <img src="/static/assets/img/skymap.png" />
        </NoAccessToken>
      )}
    </Component>
  );
};

const Component = styled.div`
  border: 1px solid #888;
  flex: 1;
`;

const NoAccessToken = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eee;
  height: 100%;

  img {
    width: 50%;
    opacity: 0.5;
  }
`;

export { IFrame };
