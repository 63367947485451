import React, { useState } from 'react';
import styled from 'styled-components';

import { UnregisterDongleParameters } from '../../../../typings/api/skymap/rest/v1/dongle';
import { SkyMapAxiosServiceFactory } from '../../../js/services/axios/skymap-axios-service-factory';
import { RequestParamsToApiPaths } from '../../../js/utils/typescript-utils';
import { useErrorHandling } from '../../hooks/use-error-handling';
import { ProjectContext } from '../../state/project-state';
import { Button } from '../button/button';
import { Dialog } from '../dialog/dialog';
import { Content } from '../integrations/providers/style';
import { IntegrationItem } from '../integrations/state/integrations-state';
import { RegisteredDongle } from './dongle-list-company-admin';

interface Props {
  selectedDongle: RegisteredDongle | undefined;
  onClose: () => void;
  loadDonglesConnection: (
    skyMapProjectId: string,
    companyId: string | null,
  ) => Promise<IntegrationItem | undefined>;
}

const unregisterDongle = async (dongleId: string, projectId: string) => {
  const dongleService = SkyMapAxiosServiceFactory.instance.createDongleServiceV1();
  await dongleService.unregisterDongle({
    path: { dongleId },
    body: { projectId },
  });
};

export const UnregisterDongleDialog: React.FC<Props> = ({
  selectedDongle,
  onClose,
  loadDonglesConnection,
}) => {
  const { project } = React.useContext(ProjectContext);
  const [unregisteringInProgress, setUnregisteringInProgress] = useState(false);

  const unregisterDongleAndCloseConfirmationForm = async () => {
    setUnregisteringInProgress(true);
    if (selectedDongle?.id && selectedDongle?.projectId) {
      try {
        await UnregisterDongleFunctions.unregisterDongle(
          selectedDongle.id,
          selectedDongle.projectId,
        );
        await loadDonglesConnection(selectedDongle?.projectId, selectedDongle?.companyId);
      } catch (error) {
        handleError(error);
        setUnregisteringInProgress(false);
      }
    }
    onClose();
  };

  const { handleError, buildErrorList, includeErrorPaths } =
    useErrorHandling<RequestParamsToApiPaths<UnregisterDongleParameters>>();

  return (
    <Dialog
      closeIcon={true}
      closeOnDimmerClick={true}
      useGoogleTranslate={true}
      width={500}
      onClose={onClose}
    >
      {{
        header: 'Ta bort enhet',
        content: (
          <Content>
            {buildErrorList({
              filter: includeErrorPaths(['path.dongleId', 'body.projectId']),
              infoBoxProps: {
                bottomMargin: true,
              },
            })}
            <span>
              Vill du ta bort enheten <Strong>{selectedDongle?.name ?? selectedDongle?.id}</Strong>{' '}
              från projektet <Strong>{project.name}</Strong>?
            </span>
          </Content>
        ),
        footer: {
          right: (
            <>
              <Button
                color={'error'}
                loading={unregisteringInProgress}
                variant={'contained'}
                onClick={unregisterDongleAndCloseConfirmationForm}
              >
                Ta bort
              </Button>
              <Button color={'secondary'} variant={'contained'} onClick={onClose}>
                Avbryt
              </Button>
            </>
          ),
        },
      }}
    </Dialog>
  );
};

const Strong = styled.strong`
  font-weight: 600;
`;

export type UnregisterDongleDialogProps = Props;

export const UnregisterDongleFunctions = {
  unregisterDongle,
};
