import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { toUpperFirst } from '../../../../../js/utils/text/text';
import { isDefined } from '../../../../../js/utils/variables';
import { Stack } from '../../../stack/stack';

type Props = {
  item: { name: string; municipality?: string };
  children: ReactNode;
  disabled: boolean;
};

export const RealPropertyCard = (props: Props) => {
  return (
    <Component disabled={props.disabled}>
      <Stack flex={1} spacing={0.2}>
        {props.item.name}
        {isDefined(props.item.municipality) && (
          <strong>{toUpperFirst(props.item.municipality)}</strong>
        )}
      </Stack>
      {props.children}
    </Component>
  );
};

const Component = styled.div<{ disabled: boolean }>`
  align-items: center;
  position: relative;
  display: flex;
  width: 100%;
  background-color: #f5f5f5;
  padding: 0.4em;

  label {
    flex: 1;
    line-height: 1.3em;
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}

  // Background color on hover
  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        cursor: pointer;
        background-color: #f8f8f8;
      }
    `}

  border-left: 0.4em solid ${(props) =>
    props.disabled ? props.theme.color.gray.light : props.theme.color.brand.dark};
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.06),
    0 1px 2px rgba(0, 0, 0, 0.12);
  margin-right: 1px;

  strong {
    font-weight: 500;
  }
`;
