import type * as CSS from 'csstype';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { ToTransientProps } from '../../utils/styled-helpers';
import { Icon } from '../icon/icon';

interface Props {
  children: ReactNode;
  visible: boolean;
  backgroundColor?: CSS.Properties['backgroundColor'];
  showIcon?: boolean;
  height?: CSS.Properties['height'];
}

const OverlayLoader = ({ backgroundColor = '#0001', showIcon = true, ...props }: Props) => {
  return (
    <Component $height={props.height}>
      {props.children}

      {props.visible && (
        <Overlay backgroundColor={backgroundColor}>
          {showIcon && <Icon data-testid="icon" icon="spinner" spin={true} />}
        </Overlay>
      )}
    </Component>
  );
};

const Component = styled.div<ToTransientProps<Pick<Props, 'height'>>>`
  position: relative;
  height: ${(props) => props.$height};
`;

const Overlay = styled.div<Pick<Props, 'backgroundColor'>>`
  content: '';

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;

  background-color: ${(props) => props.backgroundColor};
  display: grid;
  place-content: center;
`;

export { OverlayLoader };
