import React from 'react';
import styled from 'styled-components';

import { reset } from '../../utils/styled-reset';
import { TableBody } from './table-body';
import { TableCell } from './table-cell';
import { TableHead } from './table-head';
import { TableHeaderCell } from './table-header-cell';
import { TableRow } from './table-row';
import { TableState } from './table-state';

export interface Props {
  renderBorderBetweenRows?: boolean;
  children: React.ReactNode;
  fixedLayout?: boolean;
}

const Table = (props: Props) => {
  return (
    <TableState renderBorderBetweenRows={props.renderBorderBetweenRows ?? true}>
      <Component fixedLayout={props.fixedLayout}>{props.children}</Component>
    </TableState>
  );
};

type ComponentProps = Pick<Props, 'fixedLayout'>;

const Component = styled.table<ComponentProps>`
  ${reset}

  // Important to set width if set to 'fixed'.
  table-layout: ${(props) => (props.fixedLayout ? 'fixed' : 'auto')};
  width: 100%;

  border-collapse: separate;
  border-spacing: 0;
`;

Table.Head = TableHead;
Table.HeaderCell = TableHeaderCell;
Table.Body = TableBody;
Table.Row = TableRow;
Table.Cell = TableCell;

export { Table };
