import React, { MouseEvent, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { reset } from '../../../../utils/styled-reset';
import { Button } from '../../../button/button';
import { Icon } from '../../../icon/icon';

export type Props = {
  onClick: () => void;
  title: string;
  selected: boolean;
  children: ReactNode;
  disabled?: boolean;
};

const Card = (props: Props) => {
  const [expanded, setExpanded] = React.useState(false);

  const onExpandButtonClicked = (e: MouseEvent) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  return (
    <Component
      disabled={props.disabled ?? false}
      expanded={expanded}
      selected={props.selected}
      onClick={props.onClick}
    >
      <Button variant="contained" onClick={onExpandButtonClicked}>
        <Icon fixedWidth={true} icon={expanded ? 'angle-down' : 'angle-right'} />
      </Button>

      <div>
        <label
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <input
            checked={props.selected}
            disabled={props.disabled ?? false}
            type="checkbox"
            onClick={(e) => {
              props.onClick();
              e.stopPropagation();
            }}
          />
          <span>{props.title}</span>
        </label>

        {expanded && <Content>{props.children}</Content>}
      </div>
    </Component>
  );
};

const Component = styled.div<{ disabled: boolean; selected: boolean; expanded: boolean }>`
  ${reset}

  display: flex;
  ${(props) =>
    props.disabled === false &&
    css`
      cursor: pointer;
    `}
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 5px;
  margin: 0.5em;
  background-color: #fafafa;
  user-select: none;

  ${(props) =>
    props.selected === false &&
    css`
      label,
      p,
      ul,
      > span {
        opacity: 0.75;
      }
    `}

  button {
    background-color: ${(props) => props.theme.color.gray.lightest};

    ${(props) =>
      props.expanded &&
      css`
        background-color: ${props.theme.color.brand.light};
      `}
    border-radius: 3px 0 0 3px;

    &:hover {
      background-color: ${(props) => props.theme.color.brand.light} !important;
    }
  }

  > div {
    > label {
      ${(props) =>
        props.disabled === false &&
        css`
          cursor: pointer;
        `}
      padding: 0.75em;
      display: flex;
      align-items: baseline;

      span {
        font-weight: 500;
        font-size: 14pt;
        margin-top: 0;
      }

      input {
        ${(props) =>
          props.disabled === false &&
          css`
            cursor: pointer;
          `}
        margin-right: 1em;
      }
    }
  }

  &:hover {
    box-shadow:
      0 3px 6px rgba(0, 0, 0, 0.16),
      0 3px 6px rgba(0, 0, 0, 0.23);
    background-color: #f6f6f6;
  }
`;

const Content = styled.div`
  padding: 0 1em 1em 1em;
  display: flex;
  flex-direction: column;
`;

export const ContentList = styled.ul`
  margin-left: 2em;
  list-style-type: none;

  li {
    span {
      position: relative;
      left: 1em;
    }

    margin: 0.1em;
    font-size: 11pt;
  }

  li::marker {
    content: '\f00c';
    font-family: 'FontAwesome';
    color: ${(props) => props.theme.color.green};
  }

  li:first-child {
    margin-bottom: 1em;
  }

  li:first-child::marker {
    content: '\f05a';
    color: ${(props) => props.theme.color.blue};
  }
`;

export { Card };
