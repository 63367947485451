import React, { useCallback, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { v4 } from 'uuid';

import { ServiceType } from '../../../../typings/api/skymap/rest/v0/access-token';
import {
  CerbosDynamicAssetResource,
  CerbosLicenseAttribute,
  CerbosPrincipalAttributes,
  CerbosRequestData,
  CerbosResourceAttributes,
  CerbosResourceKind,
  CerbosViewScope,
} from '../../../js/services/cerbos/cerbos-api';
import { cerbosControlledEndpoints } from '../../../js/services/cerbos/endpoints';
import { cerbosControlledFeatures } from '../../../js/services/cerbos/features';
import { cerbosControlledViews } from '../../../js/services/cerbos/views';
import { PermissionStore } from '../../../js/stores/permission-store';
import { assertNever, isDefined } from '../../../js/utils/variables';
import { DataTable, DataTableColumns, DataTableSortType } from '../data-table/data-table';
import { Icon } from '../icon/icon';
import { LabelledContainer } from '../labelled-container/labelled-container';
import { Select } from '../select/select';
import { Stack } from '../stack/stack';
import { Switch } from '../switch/switch';
import { TextArea } from '../text-area/text-area';
import { AccessTokenAttribute } from './cerbos-request-resource/access-token-attribute';
import { DynamicAssetResourceAttribute } from './cerbos-request-resource/dynamic-asset-resource-attribute';
import { LicenseResourceAttribute } from './cerbos-request-resource/license-resource-attribute';

type CerbosRoles =
  | 'notAuthenticated'
  | 'hasProjectUserRole'
  | 'isProjectUser'
  | 'hasCompanyAdminRole'
  | 'isCompanyAdmin'
  | 'isOrganizationAdmin'
  | 'isSharedView';

// A JWT token that only contains the "isOrganizationAdmin" variable.
const organizationAdminToken =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc09yZ2FuaXphdGlvbkFkbWluIjp0cnVlfQ.cQCd5zHQhX2kph9jhJv0sw2S0UrIAka4hpB1fo7eO-Y';
const anyToken =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIyYWY5YWEwOS0yYWQ0LTQ3ODgtOTJiNy1hYzViNGUyNWI1MDUiLCJ1c2VyRW1haWwiOiJ0ZXN0QHRlc3Quc2UiLCJpc09yZ2FuaXphdGlvbkFkbWluIjpmYWxzZSwiaWF0IjoxNzIxNzI4MzUzfQ.5fvT4ykJTN6JC8z5uGdsgDzpPZwArblwMQAdl07sB44';

const buildCerbosPrincipal = (
  principalAttributes?: Partial<CerbosPrincipalAttributes>,
): CerbosPrincipalAttributes => ({
  accountKeys: [],
  companyUsers: [],
  projects: [],
  hasDesignSeat: false,
  licenses: [],
  ...principalAttributes,
});
const buildCerbosResources = (
  resourceAttributes?: Partial<CerbosResourceAttributes>,
): CerbosResourceAttributes => ({
  accessTokenServiceType: undefined,
  accountKey: undefined,
  companyId: undefined,
  dynamicAsset: undefined,
  projectId: undefined,
  ...resourceAttributes,
});

const projectId = v4();
const companyId = v4();

const roleAttributeMap = new Map<
  CerbosRoles,
  {
    principalAttributes: CerbosPrincipalAttributes;
    resourceAttributes: CerbosResourceAttributes;
    jwtToken?: string;
  }
>([
  [
    'notAuthenticated',
    {
      principalAttributes: buildCerbosPrincipal(),
      resourceAttributes: buildCerbosResources(),
    },
  ],
  [
    'hasProjectUserRole',
    {
      principalAttributes: buildCerbosPrincipal({ projects: [{ id: projectId, role: 'user' }] }),
      resourceAttributes: buildCerbosResources(),
      jwtToken: anyToken,
    },
  ],
  [
    'isProjectUser',
    {
      principalAttributes: buildCerbosPrincipal({ projects: [{ id: projectId, role: 'user' }] }),
      resourceAttributes: buildCerbosResources({ projectId }),
      jwtToken: anyToken,
    },
  ],
  [
    'hasCompanyAdminRole',
    {
      principalAttributes: buildCerbosPrincipal({ companyUsers: [companyId] }),
      resourceAttributes: buildCerbosResources(),
      jwtToken: anyToken,
    },
  ],
  [
    'isCompanyAdmin',
    {
      principalAttributes: buildCerbosPrincipal({
        projects: [{ id: projectId, role: 'admin' }],
        companyUsers: [companyId],
      }),
      resourceAttributes: buildCerbosResources({ projectId, companyId }),
      jwtToken: anyToken,
    },
  ],
  [
    'isOrganizationAdmin',
    {
      principalAttributes: buildCerbosPrincipal(),
      resourceAttributes: buildCerbosResources(),
      jwtToken: organizationAdminToken,
    },
  ],
  [
    'isSharedView',
    {
      principalAttributes: buildCerbosPrincipal(),
      resourceAttributes: buildCerbosResources(),
    },
  ],
]);

interface CerbosActionResponseItem {
  action: string;
  accessState: React.ReactElement;
  response: string;
}

const CerbosPermissionRequester = () => {
  const theme = useTheme();
  const [role, setRole] = useState<CerbosRoles>('notAuthenticated');
  const [requestPayload, setRequestPayload] = useState<any>();
  const [responsePayload, setResponsePayload] = useState<any>();

  const [resourceKind, setResourceKind] = useState<CerbosResourceKind>('view');
  const [scope, setScope] = useState<CerbosViewScope>('skymap');
  const [hasDesignSeat, setHasDesignSeat] = useState(false);
  const [accessTokenService, setAccessTokenService] = useState<ServiceType | undefined>();
  const [licenseAttribute, setLicenseAttributes] = useState<CerbosLicenseAttribute | undefined>();
  const [dynamicAsset, setDynamicAsset] = useState<CerbosDynamicAssetResource | undefined>();

  const [endpointApiVersion, setEndpointApiVersion] = useState<string>('v0');
  const [endpointApiLambda, setEndpointApiLambda] = useState<string>('auth');

  const getPrincipalAttributes = (
    cerbosPrincipalAttributes: CerbosPrincipalAttributes,
    hasDesignSeat: boolean,
  ): CerbosPrincipalAttributes => ({
    ...cerbosPrincipalAttributes,
    hasDesignSeat,
  });

  const resolveCerbosActions = useCallback(() => {
    switch (resourceKind) {
      case 'view':
        return Array.from(cerbosControlledViews);
      case 'feature':
        return Array.from(cerbosControlledFeatures);
      case 'endpoint':
        return Array.from(cerbosControlledEndpoints).filter((endpoint) =>
          endpoint.includes(`${endpointApiVersion}:${endpointApiLambda}`),
        );
      default:
        assertNever(resourceKind);
    }
  }, [resourceKind, endpointApiLambda, endpointApiVersion]);

  const getCerbosActionResponseColumns = (): DataTableColumns<CerbosActionResponseItem> => ({
    accessState: {
      sortableBy: { type: DataTableSortType.STRING, columnKey: 'response' },
      title: 'Access',
      unfilterable: true,
      alignment: 'left',
      width: '64px',
    },
    action: {
      sortableBy: { type: DataTableSortType.STRING },
      title: 'Action',
      alignment: 'left',
      width: '512px',
    },
    response: { hidden: true },
  });

  const getCerbosActionItems = (actionObject?: any): CerbosActionResponseItem[] => {
    if (!isDefined(actionObject)) {
      return [];
    }

    return Object.entries(actionObject as object).map(([action, response]) => ({
      action,
      accessState: (
        <Icon
          color={response === 'EFFECT_ALLOW' ? theme.color.green : theme.color.red}
          icon={['fas', 'circle']}
          title={response === 'EFFECT_ALLOW' ? 'Access' : 'Denied'}
        />
      ),
      response,
    }));
  };

  useEffect(() => {
    const fetchProjectAccess = async () => {
      const roleAttributes = roleAttributeMap.get(role)!;
      const cerbosRequestPayload = {
        requestId: v4(),
        principal: {
          attributes: getPrincipalAttributes(
            {
              ...roleAttributes.principalAttributes,
              licenses: licenseAttribute ? [licenseAttribute] : undefined,
            },
            hasDesignSeat,
          ),
        },
        resource: {
          attributes: {
            ...roleAttributes.resourceAttributes,
            accessTokenService,
            dynamicAsset,
          },
          kind: resourceKind,
          scope,
        },
        actions: resolveCerbosActions(),
        jwtToken: roleAttributes.jwtToken,
      };
      const cerbosResponses = await PermissionStore.instance.executeCerbosAccessRequest(
        cerbosRequestPayload as CerbosRequestData,
      );
      delete (cerbosRequestPayload as any).actions;
      setRequestPayload(cerbosRequestPayload);
      setResponsePayload(cerbosResponses);
    };

    void fetchProjectAccess();
  }, [
    role,
    resourceKind,
    scope,
    hasDesignSeat,
    accessTokenService,
    resolveCerbosActions,
    licenseAttribute,
    dynamicAsset,
  ]);

  const resolveEndpointApiLambdaOptions = () => {
    const versionedEndpoints = Array.from(cerbosControlledEndpoints).filter((endpoint) =>
      endpoint.includes(endpointApiVersion),
    );

    const availableLambdas = versionedEndpoints.map((endpoint) => endpoint.split(':')[3]);
    return Array.from(new Set(availableLambdas)).map((endpoint) => ({
      id: endpoint,
      name: endpoint,
    }));
  };

  const kindValues: { id: CerbosResourceKind; name: string }[] = [
    { id: 'view', name: 'Views' },
    { id: 'feature', name: 'Features' },
    { id: 'endpoint', name: 'Endpoints' },
  ];

  const scopeValues: { id: CerbosViewScope; name: string }[] = [
    { id: 'skymap', name: 'SkyMap' },
    { id: 'moba', name: 'MOBA' },
    { id: 'zynka', name: 'Zynka' },
  ];

  const roleValues: { id: string; name: string }[] = [
    { id: 'notAuthenticated', name: 'Inte autentiserad' },
    { id: 'hasProjectUserRole', name: 'Har rollen projektanvändare' },
    { id: 'isProjectUser', name: 'Projektanvändare' },
    { id: 'hasCompanyAdminRole', name: 'Har rollen förtagsadmin' },
    { id: 'isCompanyAdmin', name: 'Förtagsadmin' },
    { id: 'isOrganizationAdmin', name: 'Organisationsadmin' },
    { id: 'isSharedView', name: 'Gäst i delad viewer' },
  ];

  const resolveAvailableVersions = () => {
    return Array.from(
      new Set(Array.from(cerbosControlledEndpoints).map((endpoint) => endpoint.split(':')[2])),
    ).map((version) => ({ id: version, name: version }));
  };

  return (
    <Component direction="column" spacing={0.5}>
      <ResourceType spacing={0.5}>
        <LabelledContainer text={'Resurstyp'}>
          {(formElementId) => (
            <Select
              id={formElementId()}
              options={kindValues}
              value={resourceKind}
              onChange={(e) => setResourceKind(e.target.value as CerbosResourceKind)}
            />
          )}
        </LabelledContainer>
        {resourceKind === 'endpoint' && (
          <Stack direction="row" spacing={0.5}>
            <LabelledContainer text={'Version'}>
              {(formElementId) => (
                <Select
                  id={formElementId()}
                  options={resolveAvailableVersions()}
                  value={endpointApiVersion}
                  onChange={(e) => setEndpointApiVersion(e.target.value)}
                />
              )}
            </LabelledContainer>
            <LabelledContainer text={'Lambda'}>
              {(formElementId) => (
                <Select
                  id={formElementId()}
                  options={resolveEndpointApiLambdaOptions()}
                  value={endpointApiLambda}
                  onChange={(e) => setEndpointApiLambda(e.target.value)}
                />
              )}
            </LabelledContainer>
          </Stack>
        )}
      </ResourceType>

      <PrincipalConfig direction="row" spacing={0.5}>
        <LabelledContainer text={'Tema'}>
          {(formElementId) => (
            <Select
              id={formElementId()}
              options={scopeValues}
              value={scope}
              onChange={(e) => setScope(e.target.value as CerbosViewScope)}
            />
          )}
        </LabelledContainer>

        <LabelledContainer text={'Roll'}>
          {(formElementId) => (
            <Select
              id={formElementId()}
              options={roleValues}
              value={role}
              onChange={(e) => setRole(e.target.value as CerbosRoles)}
            />
          )}
        </LabelledContainer>

        <LabelledContainer text={'Designsäte'}>
          {(formElementId) => (
            <Switch
              checked={hasDesignSeat}
              id={formElementId()}
              onChange={() => setHasDesignSeat(!hasDesignSeat)}
            />
          )}
        </LabelledContainer>
      </PrincipalConfig>
      <ResourceConfig direction="column" spacing={0.5}>
        <AccessTokenAttribute onChange={setAccessTokenService} />
        <DynamicAssetResourceAttribute onChange={setDynamicAsset} />
        <LicenseResourceAttribute companyId={companyId} onChange={setLicenseAttributes} />
        <TextArea
          rows={JSON.stringify(requestPayload, null, 2)?.split(/\r\n|\r|\n/).length ?? 1}
          value={JSON.stringify(requestPayload, null, 2)}
        />
      </ResourceConfig>
      <ActionsConfig>
        <DataTable
          columns={getCerbosActionResponseColumns()}
          fixedLayout={true}
          items={getCerbosActionItems(responsePayload?.actions)}
          pageSize={25}
        />
      </ActionsConfig>
    </Component>
  );
};

const Component = styled(Stack)`
  display: grid;
  grid-template-areas:
    'header header action action action'
    'principal principal action action action'
    'resource resource action action action'
    '- - action action action';
  grid-gap: 16px;
  padding: 16px;

  @media (max-width: 1024px) {
    grid-template-areas:
      'header'
      'principal'
      'resource'
      'action';
  }
`;

const ResourceType = styled(Stack)`
  grid-area: header;
`;

const PrincipalConfig = styled(Stack)`
  grid-area: principal;
`;

const ResourceConfig = styled(Stack)`
  grid-area: resource;
`;

const ActionsConfig = styled.div`
  grid-area: action;
`;

export { CerbosPermissionRequester };
