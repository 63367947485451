import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { Color } from 'three';
import _ from 'underscore';

import { copyMap, isDefined } from '../../../../../../../../js/utils/variables';
import { designColors } from '../../../../../../../../js/viewer/elements/design/colors';
import { ValueId } from '../../../../../../../../js/viewer/elements/design/skymap-extras';
import { useDialog } from '../../../../../../../hooks/use-dialog';
import { Button } from '../../../../../../button/button';
import { ColorPickerDialog } from '../../../../../../color-picker-dialog/color-picker-dialog';
import { Dialog } from '../../../../../../dialog/dialog';
import { Icon } from '../../../../../../icon/icon';
import { InputDialog } from '../../../../../../input-dialog/input-dialog';
import { LabelledContainer } from '../../../../../../labelled-container/labelled-container';
import { NoTranslateSpan } from '../../../../../../no-translate/no-translate';
import { RadioButtons } from '../../../../../../radio-buttons/radio-buttons';
import { Select } from '../../../../../../select/select';
import { Stack } from '../../../../../../stack/stack';
import { TextBox } from '../../../../../../text-box/text-box';

export type RemoveEnumValueAction =
  | { action: 'delete' }
  | { action: 'update'; newValueId?: ValueId };
type OnValueRemovedCallback = (id: ValueId, action?: RemoveEnumValueAction) => void;
type RgbArray = [number, number, number];

type Props = {
  valueMap: Map<ValueId, string>;
  colorMap: Map<ValueId, Color>;
  nonce?: number;
  onColorUpdated?: (valueIndex: ValueId, color: Color) => void;
  onRename?: (valueIndex: ValueId, newName: string) => void;
  onValueAdded: (id: ValueId, value: string) => void;
  onValueRemoved: OnValueRemovedCallback;
};

export const EnumValues = (props: Props) => {
  const { t } = useTranslation();
  const [colorMap, setColorMap] = useState<Map<number, Color>>(new Map<number, Color>());
  const [valueIds, setValueIds] = useState<ValueId[]>([]);
  const [activeValueIndex, setActiveEnumIndex] = useState<ValueId>();
  const [newValue, setNewValue] = useState<string>('');

  const renameDialog = useDialog();
  const colorDialog = useDialog();
  const deleteDialog = useDialog();

  const listRef = useRef<HTMLDivElement>(null);
  const newValueTextBox = useRef<HTMLInputElement>(null);

  const getEnumColor = (index?: number) => {
    const color = isDefined(index) ? colorMap.get(index) : undefined;
    return color ?? designColors.defaultAttributeColor;
  };

  useEffect(() => {
    const keys: ValueId[] = [];
    for (const key of props.valueMap.keys()) {
      keys.push(key);
    }

    setColorMap(copyMap(props.colorMap));
    setValueIds(keys);
  }, [props]);

  const onAddEnumValue = () => {
    if (
      newValue.length === 0 ||
      isDefined(valueIds.find((x) => props.valueMap.get(x) === newValue))
    ) {
      return;
    }

    const id = (valueIds.length > 0 ? _.max(valueIds) + 1 : 0) as ValueId;
    props.onValueAdded(id, newValue);
    setNewValue('');
    newValueTextBox.current?.focus();

    // Timeout so ref has time to refresh with new elements before scroll is performed.
    setTimeout(() => {
      listRef.current?.scrollTo?.({ top: Number.MAX_SAFE_INTEGER });
    }, 50);
  };

  const activeName = isDefined(activeValueIndex) ? props.valueMap.get(activeValueIndex) : undefined;
  const activeColor = getEnumColor(activeValueIndex).toArray() as RgbArray;

  return (
    <LabelledContainer
      text={t('section.attributes.attributeColorTypeSection.title', { ns: 'skyviewDesign' })}
    >
      <NoTranslateSpan>
        <Container ref={listRef}>
          {valueIds.map((id) => (
            <ValueRow aria-labelledby={`value-${id.toString()}`} key={id}>
              <ColorIcon
                data-color={getEnumColor(id).toArray() as RgbArray}
                title={t('section.attributes.attributeColorTypeSection.changeColorIcon', {
                  ns: 'skyviewDesign',
                })}
                onClick={() => {
                  setActiveEnumIndex(id);
                  colorDialog.show();
                }}
              />

              <ValueLabel id={`value-${id.toString()}`} title={props.valueMap.get(id)}>
                {props.valueMap.get(id)}
              </ValueLabel>

              <Icon
                fixedWidth={true}
                icon={['fal', 'pencil']}
                mouseDownStyle={{ opacity: 0.5 }}
                title={t('section.attributes.attributeColorTypeSection.editNameIcon', {
                  ns: 'skyviewDesign',
                })}
                onClick={() => {
                  setActiveEnumIndex(id);
                  renameDialog.show();
                }}
                onHoverStyle={{ icon: ['fad', 'pencil'] }}
              />

              <Icon
                fixedWidth={true}
                icon={['fal', 'trash-alt']}
                mouseDownStyle={{ opacity: 0.5 }}
                title={t('section.attributes.attributeColorTypeSection.deleteIcon', {
                  ns: 'skyviewDesign',
                })}
                onClick={() => {
                  setActiveEnumIndex(id);
                  deleteDialog.show();
                }}
                onHoverStyle={{ icon: ['fad', 'trash-alt'] }}
              />
            </ValueRow>
          ))}
        </Container>
      </NoTranslateSpan>

      <Stack alignItems={'center'} direction="row" padding="0.3em" spacing={0.2}>
        <TextBox
          padding={0.2}
          placeholder={t('section.attributes.attributeColorTypeSection.newValuePlaceholder', {
            ns: 'skyviewDesign',
          })}
          ref={newValueTextBox}
          value={newValue}
          onChange={(e) => setNewValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onAddEnumValue();
              e.stopPropagation();
            }
          }}
        />
        <Icon
          fixedWidth={true}
          icon={['fal', 'plus']}
          mouseDownStyle={{ opacity: 0.5 }}
          title={t('section.attributes.attributeColorTypeSection.addNewValueIcon', {
            ns: 'skyviewDesign',
          })}
          onClick={onAddEnumValue}
          onHoverStyle={{ icon: ['fad', 'plus'] }}
        />
      </Stack>

      {colorDialog.render(
        <ColorDialog
          dialog={colorDialog}
          value={activeColor}
          onConfirm={(newColor) => {
            if (!isDefined(activeValueIndex) || activeValueIndex < 0) {
              throw new Error('Ett fel uppstod. Färgen uppdaterades inte.');
            }

            props.onColorUpdated?.(activeValueIndex, new Color(...newColor));
          }}
        />,
      )}

      {renameDialog.render(
        <RenameDialog
          dialog={renameDialog}
          value={activeName}
          onConfirm={(newName) => {
            if (
              !isDefined(activeValueIndex) ||
              isDefined(valueIds.find((valueId) => props.valueMap.get(valueId) === newName))
            ) {
              throw Error(
                t(
                  'section.attributes.attributeColorTypeSection.renameValueDialog.valueAlreadyExistsError',
                  { ns: 'skyviewDesign' },
                ) as string,
              );
            }

            props.onRename?.(activeValueIndex, newName);
          }}
        />,
      )}

      {deleteDialog.render(
        <DeleteDialog
          dialog={deleteDialog}
          value={activeValueIndex}
          valueMap={props.valueMap}
          onValueRemoved={props.onValueRemoved}
        />,
      )}
    </LabelledContainer>
  );
};

function ColorDialog(props: {
  dialog: ReturnType<typeof useDialog>;
  value: RgbArray;
  onConfirm: (color: [number, number, number]) => void;
}) {
  return (
    <ColorPickerDialog
      initialValue={props.value}
      width={300}
      onCancel={props.dialog.hide}
      onConfirm={(newColor) => {
        props.onConfirm(newColor);
        props.dialog.hide();
      }}
    />
  );
}

function RenameDialog(props: {
  dialog: ReturnType<typeof useDialog>;
  value?: string;
  onConfirm: (value: string) => void;
}) {
  const { t } = useTranslation();

  return (
    <InputDialog
      initialValue={props.value}
      title={t('section.attributes.attributeColorTypeSection.renameValueDialog.title', {
        ns: 'skyviewDesign',
      })}
      width={300}
      onCancel={props.dialog.hide}
      onConfirm={(newName) => {
        props.onConfirm(newName);
        props.dialog.hide();
      }}
    />
  );
}

const deleteEnumValueActions = ['update', 'delete'] as const;

function DeleteDialog(props: {
  dialog: ReturnType<typeof useDialog>;
  value?: ValueId;
  onValueRemoved: OnValueRemovedCallback;
  valueMap: Map<ValueId, string>;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [action, setAction] = React.useState<(typeof deleteEnumValueActions)[number]>('update');
  const disableInput = action === 'delete';
  const [selectedItem, setSelectedItem] = useState<{ name: string; id: string }>();
  const items = useRef(
    [...props.valueMap.entries()]
      .map((x) => {
        return {
          id: x[0].toString(),
          name: x[1],
        };
      })
      .filter((x) => x.id !== props.value?.toString()),
  );

  if (!isDefined(props.value)) {
    return null;
  }

  return (
    <Dialog closeIcon={true} onClose={props.dialog.hide}>
      {{
        header: t('section.attributes.deleteDialog.title', { ns: 'skyviewDesign' }),
        content: (
          <Stack spacing={1}>
            <span>{t('section.attributes.deleteDialog.info', { ns: 'skyviewDesign' })}</span>

            <LabelledContainer text={t('options', { ns: 'common' })}>
              <RadioButtons
                items={[
                  {
                    value: deleteEnumValueActions['0'],
                    name: t('update', { ns: 'common' }),
                  },
                  {
                    value: deleteEnumValueActions['1'],
                    name: t('delete', { ns: 'common' }),
                  },
                ]}
                onChange={(x) => setAction(x)}
              />
            </LabelledContainer>

            <LabelledContainer
              color={disableInput ? theme.color.gray.light : undefined}
              text={t('section.attributes.deleteDialog.newValue', { ns: 'skyviewDesign' })}
            >
              <Select
                disabled={disableInput}
                options={items.current}
                placeholderText={t('section.attributes.deleteDialog.noValue', {
                  ns: 'skyviewDesign',
                })}
                value={selectedItem?.id}
                onChange={(e) => {
                  setSelectedItem(items.current.find((x) => x.id === e.target.value));
                }}
              />
            </LabelledContainer>
          </Stack>
        ),
        footer: {
          right: (
            <>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  const newValueId = selectedItem
                    ? (Number(selectedItem.id) as ValueId)
                    : undefined;
                  props.onValueRemoved(
                    props.value!,
                    action === 'update'
                      ? {
                          action: 'update',
                          newValueId,
                        }
                      : {
                          action: 'delete',
                        },
                  );

                  props.dialog.hide();
                }}
              >
                {t('confirm', { ns: 'common' })}
              </Button>

              <Button variant="text" onClick={props.dialog.hide}>
                {t('close', { ns: 'common' })}
              </Button>
            </>
          ),
        },
      }}
    </Dialog>
  );
}

const Container = styled.div`
  max-height: 200px;
  overflow-y: auto;
`;

const ValueRow = styled.div`
  padding: 0.3em;
  align-items: center;
  display: flex;
  gap: 0.2em;
  flex-direction: row;
  border-bottom: 1px solid ${(props) => props.theme.color.gray.lightest};
`;

const ColorIcon = styled.div<{ 'data-color': RgbArray }>`
  margin: auto;
  width: 1em;
  height: 1em;
  cursor: pointer;
  border-radius: 0.2em;
  border: 0.1em solid ${(props) => props.theme.color.gray.darkest};
  background-color: ${(props) =>
    `rgb(${props['data-color'].map((x) => Math.floor(x * 255)).join(',')})`};

  &:hover {
    border: 0.18em solid black;
  }
`;

const ValueLabel = styled.label`
  flex-grow: 1;
  padding: 0.25em;
  width: min-content;
  word-break: break-all;
`;
