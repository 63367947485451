import type * as CSS from 'csstype';
import React from 'react';
import styled from 'styled-components';

import { nameof } from '../../utils/nameof';
import { ToTransientProps } from '../../utils/styled-helpers';

interface Props {
  alignItems?: CSS.Properties['alignItems'];
  as?: React.ComponentType<any>;
  backgroundColor?: CSS.Properties['backgroundColor'];
  children: React.ReactNode;
  className?: string;
  direction?: CSS.Properties['flexDirection'];
  flex?: CSS.Properties['flex'];
  flexWrap?: CSS.Properties['flexWrap'];
  inline?: boolean;
  justifyContent?: CSS.Properties['justifyContent'];
  margin?: CSS.Properties['margin'];
  padding?: CSS.Properties['padding'];
  spacing?: CSS.Properties['gap'] | number;
  onClick?: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const Stack = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      spacing = 0,
      direction = 'column',
      margin = 0,
      flexWrap = 'nowrap',
      padding = 0,
      backgroundColor = 'none',
      inline = false,
      ...props
    }: Props,
    ref,
  ) => {
    return (
      <Component
        $alignItems={props.alignItems}
        $backgroundColor={backgroundColor}
        $direction={direction}
        $flex={props.flex}
        $flexWrap={flexWrap}
        $inline={inline}
        $justifyContent={props.justifyContent}
        $margin={margin}
        $padding={padding}
        $spacing={spacing}
        as={props.as}
        className={props.className}
        ref={ref}
        onClick={props.onClick}
      >
        {props.children}
      </Component>
    );
  },
);

Stack.displayName = nameof({ Stack });

const Component = styled.div<
  // Required
  Required<
    ToTransientProps<
      Pick<
        Props,
        'padding' | 'spacing' | 'direction' | 'flexWrap' | 'margin' | 'backgroundColor' | 'inline'
      >
    >
  > &
    // Optional
    ToTransientProps<Pick<Props, 'alignItems' | 'flex' | 'justifyContent'>>
>`
  display: ${(props) => (props.$inline ? 'inline-flex' : 'flex')};
  padding: ${(props) => props.$padding};
  flex: ${(props) => props.$flex};
  flex-wrap: ${(props) => props.$flexWrap};
  flex-direction: ${(props) => props.$direction};
  gap: ${(props) => (typeof props.$spacing === 'number' ? `${props.$spacing}em` : props.$spacing)};
  align-items: ${(props) => props.$alignItems};
  justify-content: ${(props) => props.$justifyContent};
  background-color: ${(props) => props.$backgroundColor};

  margin: ${(props) => props.$margin};
`;

export { Stack, Component as StackStyled };
