import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import type * as CSS from 'csstype';
import { darken } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';

import { ToTransientProps } from '../../utils/styled-helpers';
import { reset } from '../../utils/styled-reset';
import { Icon } from '../icon/icon';
import { Stack } from '../stack/stack';

type Props = {
  /**
   * @default white
   */
  backgroundColor?: CSS.Properties['backgroundColor'];
  deleteIcon?: FontAwesomeIconProps;
  /**
   * @default false
   */
  disabled?: boolean;
  leftArea?: {
    icon?: FontAwesomeIconProps;
    backgroundColor?: CSS.Properties['backgroundColor'];
    text?: string;
  };
  onClick?: () => void;
  onDelete?: () => Promise<void>;
  padding?: 'small' | 'medium';
  title?: string;
  /**
   * @default false
   */
  shadow?: boolean;
  text: string;
};

const Chip = ({
  backgroundColor = 'white',
  disabled = false,
  padding = 'small',
  shadow = false,
  ...props
}: Props) => {
  return (
    <Component
      $backgroundColor={backgroundColor}
      $disabled={disabled}
      $leftArea={props.leftArea}
      $onClick={props.onClick}
      $onDelete={props.onDelete}
      $padding={padding}
      $shadow={shadow}
      role="button"
      title={props.title}
      onClick={disabled ? undefined : props.onClick}
    >
      <Stack alignItems="center" direction="row" spacing={0.5}>
        {(props.leftArea?.icon || props.leftArea?.text) && (
          <LeftArea
            $disabled={disabled}
            $leftArea={props.leftArea}
            $onClick={props.onClick}
            direction="row"
            spacing={0.2}
          >
            {props.leftArea.text && <Text>{props.leftArea.text}</Text>}
            {props.leftArea.icon && <Icon {...props.leftArea.icon} />}
          </LeftArea>
        )}
        <Text>{props.text}</Text>
        {props.onDelete && (
          <DeleteIcon
            $disabled={disabled}
            icon={['fad', 'times-circle']}
            onClick={disabled ? undefined : props.onDelete}
            {...props.deleteIcon}
          />
        )}
      </Stack>
    </Component>
  );
};

type ComponentProps = ToTransientProps<
  Required<Pick<Props, 'backgroundColor' | 'disabled' | 'padding'>> &
    Pick<Props, 'onClick' | 'onDelete' | 'shadow' | 'leftArea'>
>;

const Component = styled.div<ComponentProps>`
  ${reset}

  display: inline-flex;
  align-items: center;
  overflow: hidden;
  padding: ${(props) => (props.$padding === 'small' ? '0.4em 0.7em' : '0.6em 1.0em')};
  border-radius: 1000px; // Something large
  background-color: ${(props) => props.$backgroundColor};

  // Disabled?
  ${(props) =>
    props.$disabled &&
    css`
      opacity: 0.5;
    `}

  // Shadow or border?
  ${(props) =>
    props.$shadow
      ? css`
          box-shadow:
            0 0.1em 0.3em rgba(0, 0, 0, 0.12),
            0 0.1em 0.2em rgba(0, 0, 0, 0.24);
        `
      : css`
          border: 0.05em solid #aaa;
        `}

  // If a delete icon exists, decrease padding right.
  ${(props) =>
    props.$onDelete &&
    css`
      padding-right: 0.4em;
    `}

  // Clickable?
  ${(props) =>
    props.$onClick &&
    !props.$disabled &&
    css<ComponentProps>`
      cursor: pointer;

      &:hover {
        background-color: ${(props) => darken(0.05, props.$backgroundColor)};
      }

      &:active {
        background-color: ${(props) => darken(0.1, props.$backgroundColor)};
      }
    `}
`;

const Text = styled.span`
  white-space: nowrap;
`;

type LeftAreaProps = ToTransientProps<Pick<Props, 'disabled' | 'leftArea' | 'onClick'>>;
const LeftArea = styled(Stack)<LeftAreaProps>`
  position: relative;
  z-index: 0;

  ${(props) =>
    props.$leftArea?.backgroundColor &&
    css`
      padding-right: 0.5em;
    `}

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    width: calc(100% + 1000px);
    height: 1000px;
    top: -500px;
    left: -1000px;
    background-color: ${(props) => props.$leftArea?.backgroundColor};
    pointer-events: none; // To prevent trigger hover from outside the border
  }

  // If background color is set for the icon area, handle hover and active pseudo classes for the
  // main component and darken icon area backgroud color.
  ${(props) =>
    !props.$disabled &&
    props.$onClick &&
    props.$leftArea?.backgroundColor &&
    css<LeftAreaProps>`
      ${Component}:hover &&::before {
        background-color: ${(props) => darken(0.05, props.$leftArea!.backgroundColor!)};
      }

      ${Component}:active &&::before {
        background-color: ${(props) => darken(0.1, props.$leftArea!.backgroundColor!)};
      }
    `}
`;

const DeleteIcon = styled(Icon)<ToTransientProps<Pick<Props, 'disabled'>>>`
  --fa-secondary-opacity: 0.2;
  font-size: 1.3em;

  ${(props) =>
    !props.$disabled &&
    css`
      cursor: pointer;

      &:hover {
        --fa-secondary-opacity: 0.3;
      }
      &:active {
        --fa-secondary-opacity: 0.4;
      }
    `}
`;

export { Chip, Props as ChipProps, Component as ChipStyled };
