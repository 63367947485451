import useResizeObserver from '@react-hook/resize-observer';
import React from 'react';
import { useDrop } from 'react-dnd';
import styled from 'styled-components';

import { FolderModel } from '../../../../../typings/api/skymap/rest/v1/.common';
import { isElementOverflowed } from '../../../../js/utils/dom/element';
import { AppContext } from '../../../state/app-state';
import { Icon } from '../../icon/icon';
import { BlockIcon } from '../folder-list-view/block-icon';
import { DropIcon } from '../folder-list-view/drop-icon';
import { FolderIcon } from '../folder-list-view/folder-icon';
import { DraggableItem } from '../move-file-folder-helper';
import { TreeContext } from '../state/folder-tree-state';

interface Props {
  ancestorFoldersIds: string[];
  folder: FolderModel;
  hasFiles: boolean;
  hasSubFolders: boolean;
  id: string;
  leftIndentPx: number;
}

interface StyledComponentProps extends Props {
  isOver: boolean;
}

const FolderTreeItem = (props: Props) => {
  const nameRef = React.useRef<HTMLSpanElement>(null);
  const [title, setTitle] = React.useState('');

  const { currentUser } = React.useContext(AppContext);
  const {
    selectedFolderId,
    selectFolder: setSelectedFolderId,
    isFolderOpened,
    openFolder: setFolderOpened,
    closeFolder: setFolderClosed,
    hasWriteAccessToFolder,
  } = React.useContext(TreeContext);

  useResizeObserver(nameRef, (entry) => {
    setTitle(isElementOverflowed(entry.target) ? props.folder.name : '');
  });

  const [{ canDrop, isOver }, dropRef] = useDrop(() => ({
    accept: ['File', 'Folder'],
    drop: (item: DraggableItem) => {
      item.setTargetFolder({ id: props.id, name: props.folder.name });
      item.dialog.show();
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: (item: DraggableItem) =>
      !item.selectedEntities.some(
        (entity) =>
          entity.id === props.id ||
          entity.parentId === props.id ||
          props.ancestorFoldersIds.find((id) => id === entity.id),
      ) && hasWriteAccessToFolder(currentUser, props.folder),
  }));

  const toggleFolder = (event: React.MouseEvent) => {
    // Prevent folder from being selected when folder
    // is toggled clicking on the arrow icon.
    event.stopPropagation();

    if (props.hasSubFolders) {
      if (isFolderOpened(props.id)) {
        setFolderClosed(props.id);
      } else {
        setFolderOpened(props.id);
      }
    }
  };

  const onFolderClick = () => {
    if (props.id === selectedFolderId) {
      return;
    }

    setSelectedFolderId(props.id);
  };

  const getArrowIcon = () => {
    if (props.hasSubFolders) {
      return isFolderOpened(props.id) ? (
        <ArrowIcon
          data-testid="arrow-down"
          fixedWidth={true}
          icon={['fas', 'angle-down']}
          onClick={toggleFolder}
        />
      ) : (
        <ArrowIcon
          data-testid="arrow-right"
          fixedWidth={true}
          icon={['fas', 'angle-right']}
          onClick={toggleFolder}
        />
      );
    }

    // Preserves space without an icon.
    // Any icon can be used. Icon is set to hidden with CSS.
    return <EmptyArrowIcon data-testid="no-arrow" fixedWidth={true} icon={'empty-set'} />;
  };

  const getFolderIcon = () => {
    return isOver ? (
      canDrop ? (
        <DropFolderIcon />
      ) : (
        <StyledBlockIcon />
      )
    ) : (
      <StyledFolderIcon folder={props.folder} opened={isFolderOpened(props.id)} />
    );
  };

  return (
    <Component
      ref={dropRef}
      {...props}
      data-selected={selectedFolderId === props.id}
      isOver={isOver}
      onClick={onFolderClick}
      onDoubleClick={toggleFolder}
    >
      {getArrowIcon()}
      {getFolderIcon()}
      <span title={title}>{props.folder.name}</span>
    </Component>
  );
};

const Component = styled.div<Pick<StyledComponentProps, 'leftIndentPx' | 'isOver'>>`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;

  // Make element expand full width width of parent.
  // This is needed also to trigger hovering "outside" the item left
  // to the arrow for level 2nd, 3th etc.
  padding: 0 0 0 ${(props) => `${props.leftIndentPx}px`};
  margin: 0 0 0 ${(props) => `-${props.leftIndentPx}px`};
  ${(props) => props.isOver && `background-color: ${props.theme.color.brand.lightest};`}

  &:hover,
  &[data-selected='true'] {
    background-color: #f5f5f5;
  }

  // Yellow left bar for selected item.
  &[data-selected='true']::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 3px;
    background-color: #fed530;
  }

  > * {
    // Center all children vertically.
    display: flex;
    align-items: center;
  }

  // Name
  span {
    line-height: 2em;
    margin-left: 0.5em;
    user-select: none;

    // Render "..." if text overflows container.
    display: inline;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const DropFolderIcon = styled(DropIcon)`
  position: relative;
  margin-left: 0.4em;
`;

const StyledBlockIcon = styled(BlockIcon)`
  position: relative;
  margin-left: 0.4em;
`;

const StyledFolderIcon = styled(FolderIcon)`
  position: relative;
  margin-left: 0.4em;
`;

const ArrowIcon = styled(Icon)`
  position: relative;
  margin-left: 0.6em;
`;

const EmptyArrowIcon = styled(ArrowIcon)`
  visibility: hidden;
`;

export { FolderTreeItem };
