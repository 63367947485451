import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useNonce } from '../../../../hooks/use-nonce';
import { Button } from '../../../button/button';
import { Dialog } from '../../../dialog/dialog';
import { FileSelectionButton } from '../../../file-selection-button/file-selection-button';
import { Icon, IconStyled } from '../../../icon/icon';
import { InfoBox } from '../../../info-box/info-box';
import { FileManagerOptionsContext } from '../../state/file-manager-options-state';
import { defaultSummary, Summary, UploadFilesTable } from './upload-files-table';

interface Props {
  onClose: (filesUploaded: boolean) => void;
}

enum DialogStatus {
  // Items has been uploaded and no item upload errors.
  // Or no items has been started.
  Idle,

  // Upload has started.
  Started,
}

const UploadFilesDialog = (props: Props) => {
  const { t } = useTranslation();
  const fileManagerOptionsContext = React.useContext(FileManagerOptionsContext);

  // dragCount holds number of elements we have currently dragged into.
  // As long as dragCounts > 0 we are dragging over the droppable area.
  const dragCount = React.useRef(0);
  const [isDropable, setIsDropable] = React.useState(false);
  const { getRootProps: getDropzoneRootProps } = useDropzone({
    noClick: true, // Prevents file picker from beeing displayed when clicking on inner elements.
    onDrop: React.useCallback((acceptedFiles: File[]) => {
      dragCount.current = 0;
      setIsDropable(false);
      setFilesToAdd(acceptedFiles);
    }, []),
    onDragEnter: (e) => {
      e.preventDefault();
      dragCount.current++;
      setIsDropable(true);
    },
    onDragLeave: () => {
      dragCount.current--;
      if (dragCount.current === 0) {
        setIsDropable(false);
      }
    },
  });

  const [dialogStatus, setDialogStatus] = React.useState(DialogStatus.Idle);

  const [startUploadNonce, refreshStartUploadNonce] = useNonce();
  const [cancelAllNonce, refreshCancelAllNonce] = useNonce();

  const [filesToAdd, setFilesToAdd] = React.useState<File[]>([]);
  const [summary, setSummary] = React.useState<Summary>(defaultSummary);
  const [filesUploaded, setFilesUploaded] = React.useState(false);

  // Empty files to add once it has been set (added to table).
  React.useEffect(() => {
    if (filesToAdd.length > 0) {
      setFilesToAdd([]);
    }
  }, [filesToAdd]);

  const cancelCloseButtonClick = React.useCallback(() => {
    setDialogStatus(DialogStatus.Idle);
    refreshCancelAllNonce();

    if (!summary.anyFilesInProgress) {
      props.onClose(filesUploaded);
    }
  }, [filesUploaded, props, refreshCancelAllNonce, summary.anyFilesInProgress]);

  //
  // Listen for changes in files array (all changes) and set dialog status
  // back to idle when all files are deleted or uploaded (and no errors).
  //
  React.useEffect(() => {
    if (
      summary.numFiles === 0 ||
      (summary.numFiles === summary.numUploaded && summary.numErrors === 0)
    ) {
      setDialogStatus(DialogStatus.Idle);
    }

    if (summary.numFiles > 0 && summary.numFiles === summary.numUploaded) {
      cancelCloseButtonClick();
    }
  }, [cancelCloseButtonClick, summary]);

  const saveButtonClick = () => {
    setDialogStatus(DialogStatus.Started);
    refreshStartUploadNonce();
  };

  const renderInfoBox = () => {
    if (dialogStatus === DialogStatus.Idle) {
      return;
    }

    return (
      summary.numErrors > 0 && (
        <InfoBox
          bottomMargin={true}
          color="red"
          key="error"
          leftIcon={{ icon: 'exclamation' }}
          padding="medium"
        >
          {t('fileManager.uploadFilesDialog.errorFilesNotUploaded', {
            ns: 'components',
            count: summary.numErrors,
          })}
        </InfoBox>
      )
    );
  };

  const selectFilesButton = (
    <FileSelectionButton
      accept={
        fileManagerOptionsContext?.defaultFileUploadExtensions
          ? fileManagerOptionsContext.defaultFileUploadExtensions.join(',')
          : undefined
      }
      color="secondary"
      disabled={dialogStatus !== DialogStatus.Idle}
      multiple={true}
      text={t('fileManager.uploadFilesDialog.chooseFilesButton', { ns: 'components' })}
      variant="contained"
      onFilesSelected={setFilesToAdd}
    />
  );

  return (
    <Dialog
      {...getDropzoneRootProps()}
      closeIcon={!summary.anyFilesInProgress}
      closeOnDimmerClick={false}
      width={1000}
      onClose={cancelCloseButtonClick}
    >
      {{
        header: t('fileManager.uploadFilesDialog.title', { ns: 'components' }),
        content: (
          <Content>
            {renderInfoBox()}
            {summary.numFiles === 0 && (
              <DragAndDropInfo>
                <Icon color="gray" icon={['fad', 'upload']} size={'5x'} />
                <Trans
                  components={{ or: <span />, selectFilesButton: selectFilesButton }}
                  i18nKey="fileManager.uploadFilesDialog.dragAndDropOr"
                  ns="components"
                />
              </DragAndDropInfo>
            )}

            <UploadFilesTable
              addFiles={filesToAdd}
              cancelAllNonce={cancelAllNonce}
              startUploadNonce={startUploadNonce}
              onSummaryChanged={(summary) => {
                setSummary(summary);

                if (summary.numUploaded > 0) {
                  setFilesUploaded(true);
                }
              }}
            />

            {isDropable && (
              <DropOverlay>
                <Icon color="green" icon={['fad', 'check']} size="5x" />
                {t('fileManager.uploadFilesDialog.dragAndDropDropToAdd', { ns: 'components' })}
              </DropOverlay>
            )}
          </Content>
        ),
        footer: {
          left: summary.numFiles > 0 && selectFilesButton,
          right: (
            <>
              <Button
                color="primary"
                disabled={
                  summary.numFiles === 0 ||
                  summary.anyFilesInProgress ||
                  summary.numFiles === summary.numUploaded ||
                  dialogStatus !== DialogStatus.Idle
                }
                variant="contained"
                onClick={saveButtonClick}
              >
                {t('fileManager.uploadFilesDialog.startUploadButton', { ns: 'components' })}
              </Button>

              {dialogStatus === DialogStatus.Started && summary.anyFilesInProgress ? (
                <Button variant="text" onClick={cancelCloseButtonClick}>
                  {t('cancel', { ns: 'common' })}
                </Button>
              ) : (
                <Button variant="text" onClick={cancelCloseButtonClick}>
                  {t('close', { ns: 'common' })}
                </Button>
              )}
            </>
          ),
        },
      }}
    </Dialog>
  );
};

const DropOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  gap: 1em;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fffe;
  z-index: 1;
`;

const Content = styled.div``;

const DragAndDropInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #000;
  padding: 2em 0;

  ${IconStyled} {
    margin-bottom: 1rem;
  }

  span {
    color: #666;
    margin: 0.5em 0;
  }
`;

export { UploadFilesDialog };
