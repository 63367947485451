import type * as CSS from 'csstype';
import React from 'react';
import styled, { css } from 'styled-components';

import { ToTransientProps } from '../../../../../utils/styled-helpers';
import { Tooltip } from '../../../../tooltip/tooltip';

type Props = {
  value: number;
  selected?: boolean;
  separators: {
    width: CSS.Properties['width'];
    leftVisible: boolean;
    rightVisible: boolean;
  };
  tooltip: {
    title: string;
    text?: string;
  };
  onClick: () => void | Promise<void>;
};

const TimelineItem = ({ selected = false, ...props }: Props) => {
  return (
    <Tooltip content={props.tooltip.text} mainAxisOffset={20} title={props.tooltip.title}>
      <Component $selected={selected} $separators={props.separators} onClick={props.onClick}>
        <Value $value={props.value}>{props.value}</Value>
      </Component>
    </Tooltip>
  );
};

const Component = styled.li<ToTransientProps<Required<Pick<Props, 'selected' | 'separators'>>>>`
  --separator-width: ${(props) => props.$separators.width};
  --border-width: 0.1em;
  --line-tickness: 0.4em;

  position: relative;
  display: grid;
  place-items: center;
  width: 2em;
  aspect-ratio: 1/1;
  font-size: 0.9em;
  font-family: 'Montserrat';
  font-feature-settings: 'tnum';
  color: #000;
  background-color: #d1d4d3;
  box-shadow: 0 0 0 var(--border-width) #37424a;
  border-radius: 50%;
  user-select: none;
  cursor: pointer;

  // Common for both left and right separator.
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    height: var(--line-tickness);
    width: calc(var(--separator-width) + 50%);
    background-color: #37424a;
    z-index: -1;
    pointer-events: none;
  }

  // Left separator.
  ${(props) =>
    props.$separators.leftVisible &&
    css`
      &::before {
        transform: translate(-100%, -50%);
        left: calc(-1 * var(--border-width) + 50%);
      }
    `}

  // Right separator.
  ${(props) =>
    props.$separators.rightVisible &&
    css`
      &::after {
        transform: translate(100%, -50%);
        right: calc(-1 * var(--border-width) + 50%);
      }
    `}

  // Style for selected item and hover over others.
  ${(props) =>
    props.$selected
      ? css`
          background-color: #37424a;
          color: #fff;
          font-weight: 600;
          box-shadow:
            0 0 0 var(--border-width) #fff,
            0 0 0 calc(2 * var(--border-width)) #37424a;
        `
      : css`
          &:hover {
            background-color: #a5acaf;
            font-weight: 600;
          }
        `}
`;

const Value = styled.span<{ $value: number }>`
  font-size: ${(props) => (props.$value >= 100 ? '0.8em' : '1em')};
`;

export { TimelineItem };
