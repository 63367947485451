import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import styled, { css } from 'styled-components';

import { Icon } from '../../icon/icon';

interface Props {
  title: string;
  icon: IconProp;
  onClick: () => void;
  big?: boolean;
  disableGoogleTranslate?: boolean;
}

const Card = (props: Props) => {
  return (
    <Component big={props.big ?? false} onClick={props.onClick}>
      <Icon icon={props.icon} size="4x" />
      <span className={props.disableGoogleTranslate ? 'notranslate' : undefined}>
        {props.title}
      </span>
    </Component>
  );
};

const Component = styled.button<{ big: boolean }>`
  border: unset;
  display: flex;
  flex-direction: column;
  flex: 0 1 150px;
  align-items: center;
  background-color: #fafafa;
  padding: 30px;
  border-radius: 5px;
  border-bottom: 5px solid ${(props) => props.theme.color.brand.light};

  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
  cursor: pointer;

  span {
    margin-top: 0.5em;
    font-size: 0.9em;
    white-space: nowrap;
  }

  &:hover {
    box-shadow:
      0 3px 6px rgba(0, 0, 0, 0.16),
      0 3px 6px rgba(0, 0, 0, 0.23);
    background-color: #f6f6f6;
    border-bottom: 5px solid ${(props) => props.theme.color.brand.dark};
  }

  .fas {
    margin-bottom: 10px;
  }

  ${(props) =>
    props.big &&
    css`
      flex: 0 1 315px;
    `}
`;

export { Card };
