import p from 'path';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { unzip } from 'unzipit';
import { v4 } from 'uuid';

import { CreateSubFolderParameters } from '../../../../../../typings/api/skymap/rest/v1/folder';
import { CreateRootFolderParameters } from '../../../../../../typings/api/skymap/rest/v1/project';
import { iiafe } from '../../../../../../utilities/async';
import { SkyMapAxiosServiceFactory } from '../../../../../js/services/axios/skymap-axios-service-factory';
import { toHumanReadableFileSize } from '../../../../../js/utils/io/file';
import { RequestParamsToApiPaths } from '../../../../../js/utils/typescript-utils';
import {
  errorUtils,
  getResponseErrorParameters,
  handleError,
  TranslatedError,
} from '../../../../api/error-handling';
import { usePromisePool } from '../../../../hooks/use-promise-pool';
import { ProjectContext } from '../../../../state/project-state';
import { Button } from '../../../button/button';
import { Dialog } from '../../../dialog/dialog';
import { FileSelectionButton } from '../../../file-selection-button/file-selection-button';
import { Icon } from '../../../icon/icon';
import { InfoBox } from '../../../info-box/info-box';
import { TreeView } from '../../../tree-view/tree-view';
import { TreeViewItemModel } from '../../../tree-view/tree-view-item';
import { TreeContext } from '../../state/folder-tree-state';
import { FolderPath } from './folder-path';
import { FolderStructureTreeViewItem } from './folder-structure-tree-view-content';

interface Props {
  maxFileSizeBytes: number;
  onClose: (canceled: boolean) => void;
}

enum Status {
  AllCreated,
  Canceled,
  Creating,
  UnknownError,
  ValidationError,
  ZipNotSelected,
  ZipReadFailed,
  ZipRead,
  ZipTooLarge,
}

export enum ItemStatus {
  /**
   * Set when an unknown error occurred, not related to a specific folder.
   */
  Aborted,

  /**
   * Folder already existed
   */
  AlreadyExists,

  /**
   * User cancelled the operation.
   */
  Canceled,

  /**
   * Folder successfully created.
   */
  Created,

  /**
   * Ongoing create request.
   */
  Creating,

  /**
   * Initial status.
   */
  Idle,

  /**
   * Pending, waiting to be created.
   */
  Pending,

  /**
   * Validation error on folder name.
   */
  ValidationError,
}

export interface FolderTreeViewItemModel extends Omit<TreeViewItemModel, 'parent'> {
  remoteId?: string;
  parent: FolderTreeViewItemModel | null; // Redeclare parent type.
  name: string;
  status: ItemStatus;
  errorMessage?: string;
}

type ItemsState = FolderTreeViewItemModel[];
type ItemsAction =
  | { type: 'CANCEL_ALL' }
  | { type: 'CLEAR_ITEMS' }
  | { type: 'CLEAR_VALIDATION_ERRORS' }
  | { type: 'INIT_ITEMS'; items: FolderTreeViewItemModel[] }
  | { type: 'SET_NAME'; itemId: string; name: string }
  | { type: 'SET_STATUS_ALL_ABORTED' }
  | { type: 'SET_STATUS_ALL_PENDING' }
  | { type: 'SET_STATUS_ALREADY_EXISTS'; itemId: string; remoteId: string }
  | { type: 'SET_STATUS_CREATED'; itemId: string; remoteId: string }
  | { type: 'SET_STATUS_CREATING'; itemId: string }
  | { type: 'SET_STATUS_VALIDATION_ERROR'; itemId: string; errorMessage: string };

type ApiRequestPath = RequestParamsToApiPaths<
  CreateRootFolderParameters | CreateSubFolderParameters
>;

const CreateFolderStructureDialog = (props: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { project } = React.useContext(ProjectContext);
  const { selectedFolderId } = React.useContext(TreeContext);

  const namesChecksum = React.useRef<string>();
  const [items, dispatchItems] = React.useReducer(itemsReducer, []);
  const [status, setStatus] = React.useState(Status.ZipNotSelected);

  const load = async (file: File) => {
    dispatchItems({ type: 'CLEAR_ITEMS' });

    if (file.size > props.maxFileSizeBytes) {
      setStatus(Status.ZipTooLarge);
      return;
    }

    try {
      const items = await unzipToTreeViewItems(file);
      dispatchItems({ type: 'INIT_ITEMS', items });
    } catch (err) {
      setStatus(Status.ZipReadFailed);
    }
  };

  function itemsReducer(state: ItemsState, action: ItemsAction) {
    switch (action.type) {
      case 'CANCEL_ALL':
        return state.map((x) =>
          x.status === ItemStatus.Pending
            ? {
                ...x,
                status: ItemStatus.Canceled,
              }
            : { ...x },
        );

      case 'CLEAR_ITEMS':
        return [];

      case 'CLEAR_VALIDATION_ERRORS':
        return state.map((x) =>
          x.status === ItemStatus.ValidationError
            ? {
                ...x,
                status: ItemStatus.Idle,
                errorMessage: undefined,
              }
            : { ...x, errorMessage: undefined },
        );

      case 'INIT_ITEMS':
        return action.items;

      case 'SET_NAME':
        return state.map((x) =>
          x.id === action.itemId
            ? {
                ...x,
                name: action.name,
              }
            : { ...x },
        );

      case 'SET_STATUS_ALL_ABORTED':
        return state.map((x) =>
          [ItemStatus.Pending, ItemStatus.Creating].includes(x.status)
            ? {
                ...x,
                status: ItemStatus.Aborted,
              }
            : { ...x },
        );

      case 'SET_STATUS_ALL_PENDING':
        return state.map((x) =>
          [ItemStatus.Aborted, ItemStatus.Canceled, ItemStatus.Idle].includes(x.status)
            ? {
                ...x,
                status: ItemStatus.Pending,
                errorMessage: undefined,
              }
            : { ...x },
        );

      case 'SET_STATUS_ALREADY_EXISTS':
        return state.map((x) =>
          x.id === action.itemId
            ? {
                ...x,
                remoteId: action.remoteId,
                status: ItemStatus.AlreadyExists,
              }
            : { ...x },
        );

      case 'SET_STATUS_CREATED':
        return state.map((x) =>
          x.id === action.itemId
            ? {
                ...x,
                remoteId: action.remoteId,
                status: ItemStatus.Created,
              }
            : { ...x },
        );

      case 'SET_STATUS_CREATING':
        return state.map((x) =>
          x.id === action.itemId
            ? {
                ...x,
                status: ItemStatus.Creating,
              }
            : { ...x },
        );

      case 'SET_STATUS_VALIDATION_ERROR':
        return state.map((x) => {
          // Item to set validation error for.
          if (
            x.id === action.itemId &&
            ![ItemStatus.AlreadyExists, ItemStatus.Created].includes(x.status)
          ) {
            return {
              ...x,
              status: ItemStatus.ValidationError,
              errorMessage: action.errorMessage,
            };
          }

          // Other pending items sets to Idle (to display textbox again).
          if (
            x.id !== action.itemId &&
            [ItemStatus.Pending, ItemStatus.Creating].includes(x.status)
          ) {
            return { ...x, status: ItemStatus.Idle };
          }

          // Else, don't change object.
          return { ...x };
        });
    }
  }

  /**
   * Returns a value indicating whether the specified folder is a hidden macOS folder.
   * @param folderName Name of folder.
   */
  const isHiddenMacOsFolder = (folderName: string): boolean => {
    return ['__macosx', '.ds_store', '._.ds_store'].includes(folderName.toLowerCase());
  };

  /**
   * Returns a list of all paths in zip file in format 'a/b/c'.
   * @param file ZIP file to unzip.
   */
  const getFoldersFromZip = async (file: File): Promise<string[]> => {
    const { entries } = await unzip(new Uint8Array(await file.arrayBuffer()));

    // Get list of all ZIP-entry folders. Exclude macOS folders.
    // Normalize file names as NFC (makes validation easier, åäö comparison for instance).
    //
    // Example:
    // [
    //   'a',
    //   'a/b/c'
    //   'b'
    // ]
    const folders = Object.entries(entries)
      .filter(([_, entry]) => entry.isDirectory)
      .map(([_, entry]) => entry.name.normalize('NFC').replace(/\/$/, ''))
      .filter((name) => !isHiddenMacOsFolder(p.basename(name)));

    // Add paths to folders not represented as a leaf.
    //
    // Example:
    // [
    //   'a',
    //   'a/b'   <-- Added
    //   'a/b/c'
    //   'b'
    // ]
    const paths = new Set<string>(folders);
    for (const path of folders) {
      let index = 0;
      while (path.indexOf('/', index) !== -1) {
        index = path.indexOf('/', index) + 1;
        paths.add(path.substring(0, index - 1));
      }
    }

    // Return sorted paths (parent folder, sub folder).
    return [...paths].sort((a, b) => a.localeCompare(b));
  };

  /**
   * Unzips file and returns a list of tree view items.
   * @param file ZIP file.
   */
  const unzipToTreeViewItems = async (file: File): Promise<FolderTreeViewItemModel[]> => {
    const orderedPaths = await getFoldersFromZip(file);
    const lookup = new Map<string, FolderTreeViewItemModel>();

    // Iteration relies on ordered items by [parent folder, sub folder] in order
    // to correctly fetch parent.
    return orderedPaths.map((path) => {
      const item: FolderTreeViewItemModel = {
        id: v4(),
        parent: lookup.get(p.dirname(path)) ?? null,
        renderOpenIcon,
        renderCloseIcon,
        renderCollapseIcon,
        renderExpandIcon,
        renderContent,

        // Extended
        name: p.basename(path),
        status: ItemStatus.Idle,
      };

      lookup.set(path, item);
      return item;
    });
  };

  const resolveFolderColor = (item: FolderTreeViewItemModel): string => {
    switch (item.status) {
      case ItemStatus.AlreadyExists:
        return theme.color.brand.dark;
      case ItemStatus.Created:
        return theme.color.green;
      case ItemStatus.Aborted:
      case ItemStatus.Idle:
      case ItemStatus.Canceled:
      case ItemStatus.Pending:
      case ItemStatus.Creating:
        return theme.color.gray.dark;
      case ItemStatus.ValidationError:
        return theme.color.red;
    }
  };

  const resolveFolderIconTitle = (item: FolderTreeViewItemModel): string | undefined => {
    switch (item.status) {
      case ItemStatus.AlreadyExists:
        return t('fileManager.createFolderStructureDialog.folderAlreadyExists', {
          ns: 'components',
        });
      case ItemStatus.Created:
        return t('fileManager.createFolderStructureDialog.folderCreated', {
          ns: 'components',
        });
    }

    return undefined;
  };

  const wrapWithSpinnerIcon = (icon: JSX.Element): JSX.Element => {
    return (
      <IconStack>
        {icon}
        <LoaderIcon
          data-testid="folder-open"
          fixedWidth={true}
          icon={['fad', 'spinner']}
          size="xs"
          spin={true}
        />
      </IconStack>
    );
  };

  const renderOpenIcon = (item: TreeViewItemModel): JSX.Element => {
    const folderItem = item as FolderTreeViewItemModel;
    const color = resolveFolderColor(folderItem);

    const icon = (
      <Icon
        data-testid="folder-open"
        fixedWidth={true}
        icon={['fad', 'folder-open']}
        style={{
          ['--fa-primary-color' as any]: color,
          ['--fa-secondary-color' as any]: color,
          ['--fa-secondary-opacity' as any]: 0.5,
        }}
        title={resolveFolderIconTitle(folderItem)}
      />
    );

    return [ItemStatus.Pending, ItemStatus.Creating].includes(folderItem.status)
      ? wrapWithSpinnerIcon(icon)
      : icon;
  };

  const renderCloseIcon = (item: TreeViewItemModel): JSX.Element => {
    const folderItem = item as FolderTreeViewItemModel;

    const icon = (
      <Icon
        color={resolveFolderColor(folderItem)}
        data-testid="folder-closed"
        fixedWidth={true}
        icon={['fas', 'folder']}
        opacity={0.5}
        title={resolveFolderIconTitle(folderItem)}
      />
    );

    return [ItemStatus.Pending, ItemStatus.Creating].includes(folderItem.status)
      ? wrapWithSpinnerIcon(icon)
      : icon;
  };

  const renderCollapseIcon = (
    item: TreeViewItemModel,
    onClick: (e: React.MouseEvent) => void,
  ): JSX.Element => {
    return (
      <Icon
        data-testid="arrow-down"
        fixedWidth={true}
        icon={['fas', 'angle-down']}
        onClick={onClick}
      />
    );
  };

  const renderExpandIcon = (
    item: TreeViewItemModel,
    onClick: (e: React.MouseEvent) => void,
  ): JSX.Element => {
    return (
      <Icon
        data-testid="arrow-right"
        fixedWidth={true}
        icon={['fas', 'angle-right']}
        onClick={onClick}
      />
    );
  };

  const renderContent = (item: TreeViewItemModel): JSX.Element => {
    return (
      <FolderStructureTreeViewItem
        item={item as FolderTreeViewItemModel}
        onNameChanged={onNameChanged}
      />
    );
  };

  /**
   * Returns a value indicating whether folder has a sibling with the same name.
   */
  const isDuplicateName = React.useCallback(
    (item: FolderTreeViewItemModel) => {
      const siblings = items.filter((x) => x.parent?.id === item.parent?.id && x.id !== item.id);
      return siblings.some((x) => x.name.trim().toLowerCase() === item.name.trim().toLowerCase());
    },
    [items],
  );

  /**
   * Checks whether there is any validation errors in all folder names.
   */
  const validateNames = React.useCallback(() => {
    dispatchItems({ type: 'CLEAR_VALIDATION_ERRORS' });
    let errorsFound = false;

    for (const item of items) {
      if (item.name.trim() === '') {
        errorsFound = true;
        dispatchItems({
          type: 'SET_STATUS_VALIDATION_ERROR',
          itemId: item.id,
          errorMessage: t('fileManager.createFolderStructureDialog.validationNameIsMissing', {
            ns: 'components',
          }),
        });
      } else if (isDuplicateName(item)) {
        errorsFound = true;
        dispatchItems({
          type: 'SET_STATUS_VALIDATION_ERROR',
          itemId: item.id,
          errorMessage: t('fileManager.createFolderStructureDialog.validationNameDuplication', {
            ns: 'components',
          }),
        });
      }
    }

    return errorsFound;
  }, [isDuplicateName, items, t]);

  const onNameChanged = React.useCallback((itemId: string, value: string) => {
    dispatchItems({ type: 'SET_NAME', itemId, name: value });
  }, []);

  /**
   * Creates a folder in specified parent folder and returns id of created folder (or id of folder
   * if folder already exists).
   */
  const createFolder = React.useCallback(
    async (item: FolderTreeViewItemModel, parentId?: string) => {
      // Return if folder already exists.
      if ([ItemStatus.AlreadyExists, ItemStatus.Created].includes(item.status)) {
        return item.id;
      }

      // Create sub folder?
      if (parentId) {
        const folderService = SkyMapAxiosServiceFactory.instance.createFolderServiceV1();
        const { data } = await folderService.createSubFolder({
          path: { folderId: parentId },
          body: { name: item.name },
        });
        return data.id;
      }

      // Create root folder.
      const projectService = SkyMapAxiosServiceFactory.instance.createProjectServiceV1();
      const { data } = await projectService.createRootFolder({
        path: { projectId: project.id },
        body: { name: item.name },
      });

      return data.id;
    },
    [project.id],
  );

  const handleCreateFolderError = (err: unknown, folder: FolderTreeViewItemModel) => {
    if (errorUtils.isResponseError(err, 'validation_failed', 'project:folder_name_exists')) {
      const parameters = getResponseErrorParameters(
        err,
        'validation_failed',
        'project:folder_name_exists',
      );

      // Parameter "id" should exist for this error code.
      const remoteFolderId = parameters!.find((x) => x.name === 'id')!.value as string;
      dispatchItems({
        type: 'SET_STATUS_ALREADY_EXISTS',
        itemId: folder.id,
        remoteId: remoteFolderId,
      });
    } else if (errorUtils.isResponseError(err, 'validation_failed', 'folder:name_exists')) {
      const parameters = getResponseErrorParameters(err, 'validation_failed', 'folder:name_exists');

      // Parameter "id" should exist for this error code.
      const remoteFolderId = parameters!.find((x) => x.name === 'id')!.value as string;
      dispatchItems({
        type: 'SET_STATUS_ALREADY_EXISTS',
        itemId: folder.id,
        remoteId: remoteFolderId,
      });
    } else {
      handleError<ApiRequestPath>(
        err,
        ['body.name'],
        (errors: TranslatedError<ApiRequestPath>[]) => {
          dispatchItems({
            type: 'SET_STATUS_VALIDATION_ERROR',
            itemId: folder.id,
            errorMessage: errors.map((x) => x.errorMessage).join('-'),
          });
        },
        (otherErrors?: TranslatedError<ApiRequestPath>[]) => {
          dispatchItems({
            type: 'SET_STATUS_ALL_ABORTED',
          });
        },
      );
    }
  };

  const onCreateButtonClick = () => {
    const errorsFound = validateNames();
    if (errorsFound) {
      return;
    }

    dispatchItems({
      type: 'SET_STATUS_ALL_PENDING',
    });

    startPromisePool();
  };

  const onCancelButtonClick = () => {
    dispatchItems({
      type: 'CANCEL_ALL',
    });
  };

  const onCloseButtonClick = () => {
    const foldersAdded = items.some((x) => x.status === ItemStatus.Created);
    props.onClose(foldersAdded);
  };

  const promiseGenerator = React.useCallback(() => {
    const folder = items.find((item) => item.status === ItemStatus.Pending);

    // No more folders?
    if (!folder) {
      return null;
    }

    dispatchItems({
      type: 'SET_STATUS_CREATING',
      itemId: folder.id,
    });

    return new Promise<void>((resolve) => {
      iiafe(async () => {
        try {
          // todo: Fix and replace with "? folder.parent.remoteId".
          const parentId = folder.parent
            ? items.find((x) => x.id === folder.parent!.id)!.remoteId
            : selectedFolderId;
          const remoteId = await createFolder(folder, parentId);

          dispatchItems({
            type: 'SET_STATUS_CREATED',
            itemId: folder.id,
            remoteId,
          });
        } catch (err) {
          handleCreateFolderError(err, folder);
        } finally {
          resolve();
        }
      });
    });
  }, [createFolder, items, selectedFolderId]);

  const { start: startPromisePool } = usePromisePool(1, promiseGenerator);

  /**
   * Renders info box depending on current status.
   */
  const renderInfoBox = () => {
    switch (status) {
      case Status.Canceled:
        return (
          <InfoBox color="red" leftIcon={{ icon: 'info' }}>
            <Trans
              components={{ br: <br /> }}
              i18nKey="fileManager.createFolderStructureDialog.canceled"
              ns="components"
            />
          </InfoBox>
        );

      case Status.Creating:
        return (
          <InfoBox color="yellow" leftIcon={{ icon: 'spinner', spin: true }}>
            {t('fileManager.createFolderStructureDialog.inProgress', {
              ns: 'components',
            })}
          </InfoBox>
        );

      case Status.AllCreated:
        return (
          <InfoBox color="yellow" leftIcon={{ icon: 'check' }}>
            {t('fileManager.createFolderStructureDialog.allCreated', {
              ns: 'components',
            })}
          </InfoBox>
        );

      case Status.ZipReadFailed:
        return (
          <InfoBox color="red">
            <Trans
              components={{ br: <br /> }}
              i18nKey="fileManager.createFolderStructureDialog.readZipError"
              ns="components"
            />
          </InfoBox>
        );

      case Status.UnknownError:
        return (
          <InfoBox color="red" leftIcon={{ icon: 'exclamation' }}>
            {t('unexpectedError', {
              ns: 'common',
            })}
          </InfoBox>
        );

      case Status.ValidationError:
        return (
          <InfoBox color="red" leftIcon={{ icon: 'exclamation' }}>
            {t('fileManager.createFolderStructureDialog.validationError', {
              ns: 'components',
            })}
          </InfoBox>
        );

      case Status.ZipNotSelected:
        return (
          <InfoBox bottomMargin={false} color="yellow">
            {t('fileManager.createFolderStructureDialog.initial', {
              ns: 'components',
              size: toHumanReadableFileSize(props.maxFileSizeBytes),
            })}
          </InfoBox>
        );

      case Status.ZipRead:
        return (
          <InfoBox color="yellow" leftIcon={{ icon: 'info' }}>
            <Trans
              components={{ br: <br />, folderPath: <FolderPath /> }}
              i18nKey="fileManager.createFolderStructureDialog.readZipSuccessSubFolder"
              ns="components"
            />
          </InfoBox>
        );

      case Status.ZipTooLarge:
        return (
          <InfoBox color="red" leftIcon={{ icon: 'info' }}>
            {t('fileManager.createFolderStructureDialog.zipFileTooLarge', {
              ns: 'components',
              maxFileSize: toHumanReadableFileSize(props.maxFileSizeBytes),
            })}
          </InfoBox>
        );
    }
  };

  /**
   * Iterates through all items and updates overall status based on status on items.
   */
  const updateStatus = React.useCallback(() => {
    if (items.length === 0) {
      return;
    }

    // Initialize map with count 0 for all statuses.
    const enumKeys = Object.keys(ItemStatus) as (keyof typeof ItemStatus)[];
    const statusCount = new Map<ItemStatus, number>(
      enumKeys.reduce<[ItemStatus, number][]>((a, b) => {
        a.push([ItemStatus[b], 0]);
        return a;
      }, []),
    );

    // Count each status.
    for (const item of items) {
      statusCount.set(item.status, statusCount.get(item.status)! + 1);
    }

    // Folder validation error?
    if (statusCount.get(ItemStatus.ValidationError)! > 0) {
      setStatus(Status.ValidationError);
      return;
    }

    // Creation requests cancelled by user?
    if (statusCount.get(ItemStatus.Aborted)! > 0) {
      setStatus(Status.UnknownError);
      return;
    }

    // Creation requests cancelled by user?
    if (statusCount.get(ItemStatus.Canceled)! > 0) {
      setStatus(Status.Canceled);
      return;
    }

    // File read and folders ready to be created?
    if (statusCount.get(ItemStatus.Idle)! > 0) {
      setStatus(Status.ZipRead);
      return;
    }

    // Creation requests active/pending?
    if (statusCount.get(ItemStatus.Pending)! + statusCount.get(ItemStatus.Creating)! > 0) {
      setStatus(Status.Creating);
      return;
    }

    // All done?
    if (
      statusCount.get(ItemStatus.AlreadyExists)! + statusCount.get(ItemStatus.Created)! ===
      items.length
    ) {
      setStatus(Status.AllCreated);
    }
  }, [items]);

  /**
   * Returns all names concatenated with '_' to represent a unique "check sum".
   */
  const calcNamesChecksum = React.useCallback(() => items.map((x) => x.name).join('_'), [items]);

  /**
   * Listens for changes in items and revalidates all items when any name has been changed.
   */
  React.useEffect(() => {
    updateStatus();

    const currentChecksum = calcNamesChecksum();
    if (currentChecksum !== namesChecksum.current) {
      validateNames();
      namesChecksum.current = currentChecksum;
    }
  }, [calcNamesChecksum, updateStatus, validateNames]);

  const createButtonEnabled = [
    Status.Canceled,
    Status.UnknownError,
    Status.ValidationError,
    Status.ZipRead,
  ].includes(status);

  return (
    <Dialog
      closeIcon={status !== Status.Creating}
      closeOnDimmerClick={true}
      width={400}
      onClose={onCloseButtonClick}
    >
      {{
        header: t('fileManager.createFolderStructureDialog.title', { ns: 'components' }),
        content: (
          <Content>
            {items.length === 0 ? (
              renderInfoBox()
            ) : (
              <>
                <StickyInfoBoxContainer>{renderInfoBox()}</StickyInfoBoxContainer>
                <TreeView expandAll={true} items={items} />
              </>
            )}
          </Content>
        ),
        footer: {
          left: (
            <FileSelectionButton
              accept=".zip"
              color="secondary"
              disabled={status === Status.Creating}
              text={t('fileManager.createFolderStructureDialog.chooseFileButton', {
                ns: 'components',
              })}
              variant="contained"
              onFilesSelected={async (files) => {
                await load(files[0]);
              }}
            />
          ),
          right: (
            <>
              {status === Status.Creating ? (
                <Button color="primary" variant="contained" onClick={onCancelButtonClick}>
                  {t('cancel', { ns: 'common' })}
                </Button>
              ) : (
                <Button
                  color="primary"
                  disabled={!createButtonEnabled}
                  variant="contained"
                  onClick={onCreateButtonClick}
                >
                  {[Status.Canceled, Status.UnknownError, Status.ValidationError].includes(status)
                    ? t('continue', { ns: 'common' })
                    : t('create', { ns: 'common' })}
                </Button>
              )}

              <Button
                disabled={status === Status.Creating}
                variant="text"
                onClick={onCloseButtonClick}
              >
                {t('close', { ns: 'common' })}
              </Button>
            </>
          ),
        },
      }}
    </Dialog>
  );
};

const Content = styled.div`
  strong {
    font-weight: 600;
  }
`;

const StickyInfoBoxContainer = styled.div`
  position: sticky;
  top: 0;
  padding-bottom: 1em;
  background-color: #fff;
  z-index: 1;
`;

const IconStack = styled.div`
  position: relative;
`;

const LoaderIcon = styled(Icon)`
  position: absolute;
  left: -0.5em;
  top: -0.5em;
`;

export { CreateFolderStructureDialog };
