import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { CompanyAdminRequestResponse } from '../../../../typings/api/skymap/rest/v0/company';
import { iiafe } from '../../../../utilities/async';
import { SkyMapAxiosServiceFactory } from '../../../js/services/axios/skymap-axios-service-factory';
import { getHttpStatusCode } from '../../api/error-handling';
import { useNonce } from '../../hooks/use-nonce';
import { useRouter } from '../../hooks/use-router';
import { reset } from '../../utils/styled-reset';
import { Button } from '../button/button';
import { FullPageBox } from '../full-page-box/full-page-box';
import { InfoBox } from '../info-box/info-box';
import { Stack } from '../stack/stack';
import { ExistingCompanyAdminForm } from './forms/existing-company-admin-form';
import { NewCompanyAdminForm } from './forms/new-company-admin-form';

enum Status {
  Idle,
  InProgress,
  NotFound,
  Found,
  Error,
}

type Props = {
  requestId: string;
};

const NewCompanyAdmin = (props: Props) => {
  const { t } = useTranslation();
  const [refreshNonce, refresh] = useNonce();
  const { goToLoginPage } = useRouter();
  const [status, setStatus] = React.useState(Status.Idle);
  const [result, setResult] = React.useState<CompanyAdminRequestResponse['data']>();

  React.useEffect(() => {
    iiafe(async () => {
      try {
        setStatus(Status.InProgress);
        const companyService = SkyMapAxiosServiceFactory.instance.createCompanyServiceV0();
        const result = await companyService.getCompanyAdminRequest({
          query: { requestId: props.requestId },
        });
        setResult(result.data);
        setStatus(Status.Found);
      } catch (err) {
        setStatus(getHttpStatusCode(err) === 404 ? Status.NotFound : Status.Error);
      }
    });
  }, [props.requestId, refreshNonce]);

  const renderContent = () => {
    switch (status) {
      case Status.Error:
        return (
          <>
            <Stack spacing={2}>
              <InfoBox color="red" leftIcon={{ icon: ['fad', 'exclamation'] }}>
                {t('unexpectedError', { ns: 'common' })}
              </InfoBox>
              <Button
                alignSelf="flex-start"
                color="secondary"
                variant="contained"
                onClick={refresh}
              >
                {t('retry', { ns: 'common' })}
              </Button>
            </Stack>
          </>
        );
      case Status.Found:
        // When found, we always have a result.
        return !result!.user ? (
          <NewCompanyAdminForm
            companyName={result!.company.name}
            requestId={props.requestId}
            userEmail={result!.email}
          />
        ) : (
          <ExistingCompanyAdminForm
            companyName={result!.company.name}
            requestId={props.requestId}
          />
        );

      case Status.Idle:
      case Status.InProgress:
        return (
          <InfoBox color="yellow" leftIcon={{ icon: ['fad', 'spinner'], spin: true }}>
            {t('oneMoment', { ns: 'common' })}
          </InfoBox>
        );
      case Status.NotFound:
        return (
          <Stack direction="column" spacing={2}>
            <InfoBox color="yellow" leftIcon={{ icon: ['fad', 'info'] }}>
              {t('newCompanyAdmin.common.invitationNotFound', { ns: 'components' })}
            </InfoBox>

            <Button
              alignSelf="flex-end"
              color="primary"
              variant="contained"
              onClick={goToLoginPage}
            >
              {t('newCompanyAdmin.common.goToLoginPageButton', { ns: 'components' })}
            </Button>
          </Stack>
        );
    }
  };

  return (
    <FullPageBox width={'600px'}>
      <Component>
        <Stack direction="column" spacing={1}>
          <Header>{t('newCompanyAdmin.common.header', { ns: 'components' })}</Header>
          {renderContent()}
        </Stack>
      </Component>
    </FullPageBox>
  );
};

const Component = styled.div`
  ${reset}
  font-size: 14px;
`;

const Header = styled.h1`
  ${reset}
  font-weight: 400;
  font-size: 1.3em;
`;

NewCompanyAdmin.propTypes = {
  wrapWithLanguageProvider: PropTypes.any,
  requestId: PropTypes.string,
};

export { NewCompanyAdmin };
