import React, { useContext } from 'react';
import styled from 'styled-components';

import { SkyMapAxiosServiceFactory } from '../../../js/services/axios/skymap-axios-service-factory';
import { Button } from '../button/button';
import { Dialog } from '../dialog/dialog';
import { InfoBox } from '../info-box/info-box';
import { Content } from '../integrations/providers/style';
import { LabelledContainer } from '../labelled-container/labelled-container';
import { Select } from '../select/select';
import { Stack } from '../stack/stack';
import { TextBox } from '../text-box/text-box';
import DongleViewStateContext from './state/dongle-view-state';

interface Props {
  onClose: () => void;
}

export const AddDongleDialog = ({ onClose }: Props) => {
  const [dongleId, setDonlgeId] = React.useState('');
  const [donglePassword, setDonglePassword] = React.useState('');
  const [companyId, setCompanyId] = React.useState('');
  const [isUpdating, setIsUpdating] = React.useState(false);

  const { companies, dongles } = useContext(DongleViewStateContext);
  const isIdDuplicated = dongles.some((dongle) => dongle.id === dongleId);

  const validateDongleId = (dongleId: string): boolean => {
    const regex = /^[A-Z0-9]*$/;
    return regex.test(dongleId);
  };

  const companiesOptions = companies
    ? companies.map((company) => ({ id: company.id, name: company.name }))
    : [];

  const onDongleIdChanged = (newValue: string) => {
    validateDongleId(newValue) ? setDonlgeId(newValue) : setDonlgeId(dongleId);
  };

  const registerDongle = async () => {
    setIsUpdating(true);
    if (!dongleId || !donglePassword) {
      return;
    }

    try {
      const dongleService = SkyMapAxiosServiceFactory.instance.createDongleServiceV1();
      const companyService = SkyMapAxiosServiceFactory.instance.createCompanyServiceV1();

      const dongleCreated = await dongleService.createDongle({
        body: { dongleId, password: donglePassword, name: dongleId },
      });
      if (companyId && dongleCreated) {
        await companyService.attachDongleToCompany({
          body: { dongleId },
          path: { companyId },
        });
      }

      onClose();
    } catch (ex) {
      throw new Error('Could not register dongle');
    }
  };

  return (
    <Dialog
      closeIcon={true}
      closeOnDimmerClick={true}
      useGoogleTranslate={true}
      width={500}
      onClose={onClose}
    >
      {{
        header: 'NY ENHET',
        content: (
          <Content>
            <Stack direction="column" spacing={1.5}>
              <LabelledContainer text={'ID'}>
                {isIdDuplicated && (
                  <InfoBox color="yellow" topMargin={true}>
                    En enhet med detta ID existerar redan.
                  </InfoBox>
                )}
                <TextBox value={dongleId} onChange={(e) => onDongleIdChanged(e.target.value)} />
              </LabelledContainer>
              <LabelledContainer text={`Lösenord`}>
                <TextBox
                  data-testid="password-input"
                  id={'password'}
                  type={'password'}
                  value={donglePassword}
                  onChange={(e) => setDonglePassword(e.target.value)}
                />
              </LabelledContainer>
              <LabelledContainer text={'Företag'}>
                <Dropdown
                  options={companiesOptions}
                  placeholderText="-- Välj företag --"
                  value={companyId}
                  onChange={(e) => setCompanyId(e.target.value)}
                />
              </LabelledContainer>
            </Stack>
          </Content>
        ),
        footer: {
          right: (
            <>
              <Button
                color={'primary'}
                disabled={isIdDuplicated || !donglePassword || !dongleId}
                loading={isUpdating}
                variant={'contained'}
                onClick={registerDongle}
              >
                Registrera enhet
              </Button>
              <Button
                color={'secondary'}
                disabled={isUpdating}
                variant={'contained'}
                onClick={onClose}
              >
                Avbryt
              </Button>
            </>
          ),
        },
      }}
    </Dialog>
  );
};

const Dropdown = styled(Select)`
  border: 1px solid ${(props) => props.theme.color.gray.medium};
`;
