import React from 'react';
import styled from 'styled-components';

import { reset } from '../../utils/styled-reset';
import { FolderBreadCrumbs } from './folder-bread-crumbs';
import { FolderListView } from './folder-list-view/folder-list-view';

const ContentArea = () => {
  return (
    <Component>
      <FolderBreadCrumbs />
      <FolderListView />
    </Component>
  );
};

const Component = styled.main`
  ${reset}
  font-size: 14px;

  padding: 1em;
  display: flex;
  flex-direction: column;
  flex: 1;

  overflow: hidden;
  isolation: isolate;

  ${FolderListView.styled} {
    flex: 1;
    overflow-y: auto;
  }
`;

ContentArea.styled = Component;

export { ContentArea };
