import React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { FileModel, FolderModel } from '../../../../../typings/api/skymap/rest/v1/.common';
import { filenameToFontAwesomeIcon } from '../../../../js/utils/io/file';
import { nameof } from '../../../utils/nameof';
import { reset } from '../../../utils/styled-reset';
import { Icon } from '../../icon/icon';
import { Stack } from '../../stack/stack';
import { EntityModel } from '../move-file-folder-helper';
import { FolderIcon } from './folder-icon';

type Props = {
  isDragging: boolean;
  item: { folder: FolderModel } | { file: FileModel };
  draggedItems?: EntityModel[];
};

const DragPreview = React.forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
  const buildIcon = () => {
    if ('folder' in props.item) {
      return <FolderIcon folder={props.item.folder} opened={false} />;
    }

    const { icon, color } = filenameToFontAwesomeIcon(props.item.file.name);
    return <Icon color={color} data-testid="icon-file" fixedWidth={true} icon={['fad', icon]} />;
  };

  const getDragPreviewContent = () => {
    if (!props.draggedItems || props.draggedItems.length === 0) {
      return null;
    }

    return (
      <Stack direction="row" spacing={0.5}>
        {buildIcon()}
        <span>{getText(props.draggedItems)}</span>
      </Stack>
    );
  };

  const getText = (draggedItems: EntityModel[]) => {
    const itemName = 'folder' in props.item ? props.item.folder.name : props.item.file.name;

    return draggedItems.length === 1 ? (
      itemName
    ) : (
      <Trans
        components={{ bold: <strong /> }}
        i18nKey="fileManager.dragDropPreviewInfo"
        ns="components"
        values={{ draggedItemName: itemName, countOther: draggedItems.length - 1 }}
      />
    );
  };

  return (
    <Component
      ref={ref}
      style={{
        // Hide preview by setting opacity to a low value so it isn't noticable, until it's being
        // dragged. Preview isn't appearing with opacity 0.
        // TODO: There must be a better way of handling the preview visibility.
        opacity: props.isDragging ? '1' : '0.01',
      }}
    >
      {getDragPreviewContent()}
    </Component>
  );
});

DragPreview.displayName = nameof({ DragPreview });

const Component = styled.div`
  ${reset}

  // Positioning is relying in "position: relative" in component TableBody.
  position: absolute;
  padding: 0.5em;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0.5em;

  strong {
    font-weight: 500;
  }
`;

export { DragPreview };
