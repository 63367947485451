import React from 'react';
import styled from 'styled-components';

import { FolderTreeSection } from './folder-tree/folder-tree-section';

const LeftArea = () => {
  return (
    <Component>
      <FolderTreeSection />
    </Component>
  );
};

const Component = styled.aside`
  background-color: #f5f5f5;
  background: repeating-linear-gradient(-45deg, #fafafc, #fafafc 10px, #fff 10px, #fff 20px);
  overflow: auto;
  height: 100%;
  border-right: 1px solid #ddd;
  overflow: auto;
`;

LeftArea.styled = Component;

export { LeftArea };
