import { useMergeRefs } from '@floating-ui/react';
import { IconProp, library } from '@fortawesome/fontawesome-svg-core';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fat } from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { fasr } from '@fortawesome/sharp-regular-svg-icons';
import { fass } from '@fortawesome/sharp-solid-svg-icons';
import React from 'react';
import styled, { css } from 'styled-components';

import { useHover } from '../../hooks/use-hover';

library.add(fas);
library.add(far);
library.add(fal);
library.add(fad);
library.add(fat);
library.add(fasr);
library.add(fass);

// & Partial<FontAwesomeIconProps['icon'] generates TypeScript error "Expression produces a union
// type that is too complex to represent.ts(2590)". Might work in newer version of TypeScript.
type IconPropsWithIconOptional = Omit<FontAwesomeIconProps, 'icon'> & { icon?: IconProp };

interface Props extends FontAwesomeIconProps {
  disabled?: boolean;
  marginLeft?: string;
  mouseDownStyle?: IconPropsWithIconOptional;
  onHoverStyle?: IconPropsWithIconOptional;
  opacityhover?: number;
  stopPropagationOnClick?: boolean;
}

const Icon = React.forwardRef<SVGSVGElement, Props>(function Icon(props, propRef) {
  const onClick = props.disabled || !props.onClick ? undefined : props.onClick;

  const { hovering, ref: hoverRef } = useHover<SVGSVGElement>();
  const ref = useMergeRefs([props.ref, hoverRef, propRef]);
  const [mouseDown, setMouseDown] = React.useState(false);

  const handleOnMouseDown = React.useCallback(() => {
    setMouseDown(true);
    document.addEventListener('mouseup', () => setMouseDown(false), { once: true });
  }, []);

  const iconProps = { ...props };

  // Remove props that does not belong in the <i> element.
  delete iconProps.marginLeft, delete iconProps.mouseDownStyle;
  delete iconProps.onHoverStyle;
  delete iconProps.stopPropagationOnClick;

  return (
    <Component
      ref={ref}
      {...iconProps}
      {...(hovering && !props.disabled && props.onHoverStyle)}
      {...(hovering && !props.disabled && mouseDown && props.mouseDownStyle)}
      onClick={
        onClick
          ? (e) => {
              if (props.stopPropagationOnClick) {
                e.stopPropagation();
              }
              onClick?.(e);
            }
          : undefined
      }
      onMouseDown={() => !props.disabled && handleOnMouseDown()}
    />
  );
});

const Component = styled(FontAwesomeIcon)<Pick<Props, 'opacityhover' | 'disabled' | 'marginLeft'>>`
  display: initial;

  ${(props) =>
    !props.size &&
    css`
      font-size: 1.1em;
    `}

  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}

  ${(props) =>
    props.marginLeft &&
    css`
      margin-left: ${props.marginLeft};
    `}

  // Don't allow icon to shrink its margins.
  flex-shrink: 0;

  // Opacity
  ${(props) =>
    props.opacityhover &&
    !props.disabled &&
    css`
      &:hover {
        opacity: ${props.opacityhover};
      }
    `}

  // Cursor
  cursor: ${(props) => (props.onClick && !props.disabled ? 'pointer' : 'inherit')};

  // Disabled
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.25;
    `}
`;

export { Icon, Props as IconProps, Component as IconStyled };
