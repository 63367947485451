import React from 'react';
import styled from 'styled-components';

import { FeatureFlagsManager } from '../../../../../js/core/feature-flags-manager';
import { CompanyStore } from '../../../../../js/stores/company.store';
import { ThemeManager } from '../../../../../js/theming/theme-manager';
import { t } from '../../../../../js/utils/i18-next';
import { isDefined } from '../../../../../js/utils/variables';
import { AppContext, UserRole } from '../../../../state/app-state';
import { ProjectContext } from '../../../../state/project-state';
import { Button } from '../../../button/button';
import { Dialog } from '../../../dialog/dialog';
import { InfoBox } from '../../../info-box/info-box';
import {
  buildDroneHarmonyIntegration,
  getDonglesIntegration,
  getLeicaIntegration,
  getMobaXsiteIntegration,
  IntegrationItem,
  IntegrationsContext,
  IntegrationStatus,
} from '../../state/integrations-state';
import { AcceptSkyMapConnectLicenseAgreementDialog } from '../accept-skymap-connect-license-agreement-dialog/accept-skymap-connect-license-agreement-dialog';
import { DisabledState, IntegrationCard } from './integration-card';

interface Props {
  onClose: () => void;
}

const NewIntegrationDialog = (props: Props) => {
  const { addIntegration } = React.useContext(IntegrationsContext);
  const { integrations, agreementAccepted, setAgreementAccepted } =
    React.useContext(IntegrationsContext);
  const { project } = React.useContext(ProjectContext);
  const { currentUser } = React.useContext(AppContext);

  const [agreementDialogVisible, setAgreementDialogVisible] = React.useState(false);
  const [integrationItem, setIntegrationItem] = React.useState<IntegrationItem | undefined>(
    undefined,
  );

  const onSelect = (integrationItem: IntegrationItem) => {
    return () => {
      if (
        !agreementAccepted &&
        ThemeManager.instance.theme.themeName !== 'moba' &&
        integrationItem.requireConnectAgreement
      ) {
        setAgreementDialogVisible(true);
        setIntegrationItem(integrationItem);
      } else {
        addSelectedIntegration(integrationItem);
        props.onClose();
      }
    };
  };

  const addSelectedIntegration = (integrationItem: IntegrationItem) => {
    addIntegration(integrationItem);
  };

  const onAgreementDialogClosed = (accepted: boolean) => {
    if (accepted && isDefined(integrationItem)) {
      addSelectedIntegration(integrationItem);
      setAgreementAccepted(true);
      props.onClose();
    } else {
      setAgreementDialogVisible(false);
    }
  };

  const resolveIntegrationDisabled = (
    key: 'leica_conx' | 'xsite_manage' | 'dongles' | 'drone_harmony',
  ): DisabledState | undefined => {
    const userIsOrganizationAdmin = currentUser?.selectedRole === UserRole.OrganizationAdmin;
    const integrationExists = isDefined(integrations.find((x) => x.key === key));
    const integrationNotAvailableInCountry =
      key === 'leica_conx' &&
      isDefined(project.companyId) &&
      CompanyStore.instance.getCompanyCountry(project.companyId)?.alpha2Code !== 'SE';

    if (userIsOrganizationAdmin) {
      return {
        title: t('connection.item.disabled.isOrganizationAdmin.title', {
          ns: 'integrations',
        }),
        description: t('connection.item.disabled.isOrganizationAdmin.description', {
          ns: 'integrations',
        }),
        color: 'yellow',
        icon: ['far', 'exclamation-triangle'],
      };
    }
    if (integrationExists) {
      return {
        title: t('connection.item.disabled.alreadyConnected.title', {
          ns: 'integrations',
        }),
        color: 'green',
        icon: ['fad', 'check'],
      };
    }
    if (integrationNotAvailableInCountry) {
      return {
        title: t('connection.item.disabled.isNotAvailableOutsideOfSweden.title', {
          ns: 'integrations',
        }),
        description: t('connection.item.disabled.isNotAvailableOutsideOfSweden.description', {
          ns: 'integrations',
        }),
        color: 'yellow',
        icon: ['far', 'exclamation-triangle'],
      };
    }
  };

  return !agreementDialogVisible ? (
    <Dialog
      closeIcon={true}
      closeOnDimmerClick={true}
      useGoogleTranslate={true}
      onClose={props.onClose}
    >
      {{
        header: 'Lägg till integration',
        content: (
          <Content>
            <InfoBox color="yellow" padding="medium">
              Förenkla vardagen med våra smarta integrationer nedan.
            </InfoBox>

            <Cards>
              {ThemeManager.instance.theme.themeName !== 'moba' && (
                <IntegrationCard
                  description={
                    <>
                      Synkronisera filer mellan ditt projekt och{' '}
                      <span className="notranslate">Leica ConX</span>.
                    </>
                  }
                  disabled={resolveIntegrationDisabled('leica_conx')}
                  logo="/static/integrations/leica/logotype.png"
                  title="Leica ConX"
                  onSelect={onSelect(getLeicaIntegration(IntegrationStatus.Setup))}
                />
              )}

              <IntegrationCard
                description={
                  <>
                    Synkronisera filer mellan ditt projekt och{' '}
                    <span className="notranslate">Moba Xsite Manage</span>.
                  </>
                }
                disabled={resolveIntegrationDisabled('xsite_manage')}
                logo="/static/integrations/xsite/logotype.png"
                title="Xsite Manage"
                onSelect={onSelect(getMobaXsiteIntegration(IntegrationStatus.Setup))}
              />
              {ThemeManager.instance.theme.themeName !== 'moba' && (
                <IntegrationCard
                  description={
                    <>
                      Importera drönarflygningar från <span className="notranslate">DJI Dock</span>{' '}
                      via <span className="notranslate">Drone Harmony</span> och skapa nya
                      molnprocesseringar automatiskt.
                    </>
                  }
                  disabled={resolveIntegrationDisabled('drone_harmony')}
                  logo="/static/integrations/drone-harmony/logotype.png"
                  title="Drone Harmony för DJI Dock"
                  onSelect={onSelect(
                    buildDroneHarmonyIntegration(project.id, {
                      status: IntegrationStatus.Setup,
                    }),
                  )}
                />
              )}
              {FeatureFlagsManager.instance.isEnabled('SK-3938-skymap-dongles') && (
                <IntegrationCard
                  description={
                    <>
                      Synkronisera filer mellan ditt projekt i{' '}
                      <span className="notranslate">SkyMap Portal</span> och{' '}
                      <span className="notranslate">SkyMap Dongles</span>.
                    </>
                  }
                  disabled={resolveIntegrationDisabled('dongles')}
                  logo="/static/themes/default/images/logotype.png"
                  title="SkyMap Dongle"
                  useBorderRadius={false}
                  onSelect={onSelect(getDonglesIntegration(IntegrationStatus.Setup, project.id))}
                />
              )}
            </Cards>
          </Content>
        ),
        footer: {
          right: (
            <Button variant="text" onClick={props.onClose}>
              Stäng
            </Button>
          ),
        },
      }}
    </Dialog>
  ) : (
    <AcceptSkyMapConnectLicenseAgreementDialog onClose={onAgreementDialogClosed} />
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  align-items: center;
`;

const Cards = styled.div`
  display: flex;
  gap: 1em;
`;

export { NewIntegrationDialog };
