import React from 'react';
import styled from 'styled-components';

import { reset } from '../../utils/styled-reset';
import { Button, Props as ButtonProps } from '../button/button';

interface Props
  extends Pick<ButtonProps, 'variant' | 'color' | 'disabled' | 'leftIcon' | 'noWrap'> {
  onFilesSelected: (files: File[]) => void;
  accept?: string;
  multiple?: boolean;
  text: string;
}

const FileSelectionButton = (props: Props) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const onSelect = () => {
    if (inputRef.current?.files) {
      props.onFilesSelected([...inputRef.current.files]);
      resetFileSelection();
    }
  };

  const resetFileSelection = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  return (
    <>
      <Button
        color={props.color ?? 'primary'}
        disabled={props.disabled}
        leftIcon={props.leftIcon}
        noWrap={props.noWrap}
        variant={props.variant ?? 'contained'}
        onClick={() => {
          inputRef.current?.click();
        }}
      >
        {props.text}
      </Button>

      <FileInput
        accept={props.accept}
        data-testid="file-selection"
        multiple={props.multiple ?? false}
        ref={inputRef}
        onChange={onSelect}
      />
    </>
  );
};

const FileInput = styled.input.attrs(() => ({
  type: 'file',
}))`
  // Bump up specificity.
  // "display:none;" from global css used otherwise.
  && {
    ${reset}

    display: none;
  }
`;

export { FileSelectionButton };
