import { useMutation } from '@tanstack/react-query';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React, { ReactNode, useEffect, useReducer, useRef, useState } from 'react';
import styled, { DefaultTheme, useTheme } from 'styled-components';

import { UserModel } from '../../../../typings/api/skymap/rest/v0/user';
import { FeatureFlagsManager } from '../../../js/core/feature-flags-manager';
import { SkyMapAxiosServiceFactory } from '../../../js/services/axios/skymap-axios-service-factory';
import { ProjectStore } from '../../../js/stores/project-store';
import { UserStore } from '../../../js/stores/user-store';
import { sessionStorageUtils } from '../../../js/utils/session-storage-utils';
import { useDialog } from '../../hooks/use-dialog';
import { useErrorHandling } from '../../hooks/use-error-handling';
import { Button } from '../button/button';
import { DataTable, DataTableColumns, DataTableSortType } from '../data-table/data-table';
import { Dialog } from '../dialog/dialog';
import { Icon } from '../icon/icon';
import { LabelledContainer } from '../labelled-container/labelled-container';
import { OverlayLoader } from '../overlay-loader/overlay-loader';
import { ProjectUserTags } from '../project-user-tags/project-user-tags';
import { Stack } from '../stack/stack';
import { TextBox } from '../text-box/text-box';
import { AddProjectUsersDialog } from './add-project-users-dialog';

type Item = {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  role: 'project_attendee' | 'company_admin';
  editIcon?: ReactNode;
};

type DispatchEvent = { type: 'remove'; item: Item } | { type: 'init'; items: Item[] };

function usersReducer(users: Item[], action: DispatchEvent) {
  if (action.type === 'remove') {
    users.splice(users.indexOf(action.item), 1);
    return [...users];
  } else if (action.type === 'init') {
    return action.items;
  }

  return users;
}

function getUsers(theme: DefaultTheme) {
  const users: Item[] = [];

  const project = ProjectStore.instance.project!;

  for (const user of project.users) {
    const userModel = user as UserModel;
    users.push({
      id: userModel.id,
      name: `${userModel.firstName} ${userModel.lastName}`,
      email: userModel.email,
      phoneNumber: userModel.phoneNumber ?? '-',
      role: 'project_attendee',
      editIcon: (
        <Icon
          color={theme.color.gray.dark}
          icon={['fad', 'pen']}
          size="lg"
          title={'Redigera'}
          onHoverStyle={{ icon: ['fas', 'pen'] }}
        />
      ),
    });
  }

  for (const user of project.companyAdmins) {
    const userModel = user as UserModel;
    users.push({
      id: userModel.id,
      name: `${userModel.firstName} ${userModel.lastName}`,
      email: userModel.email,
      phoneNumber: userModel.phoneNumber ?? '-',
      role: 'company_admin',
      editIcon: (
        <Icon
          color={theme.color.gray.dark}
          icon={['fad', 'pen']}
          size="lg"
          title={'Redigera'}
          onHoverStyle={{ icon: ['fas', 'pen'] }}
        />
      ),
    });
  }

  return users;
}

function getColumns(): DataTableColumns<Item> {
  const columns: DataTableColumns<Item> = {
    name: {
      sortableBy: { type: DataTableSortType.STRING },
      title: t('name', { ns: 'common' }),
      alignment: 'left',
      unfilterable: true,
      shrink: true,
    },
    email: {
      sortableBy: { type: DataTableSortType.STRING },
      title: t('newCompanyAdmin.newUser.form.email', { ns: 'components' }),
      alignment: 'left',
      unfilterable: true,
      shrink: true,
    },
    role: {
      sortableBy: { type: DataTableSortType.STRING },
      title: t('role', { ns: 'common' }),
      formatter: (value) =>
        value === 'project_attendee'
          ? t('roles.projectAttendee', { ns: 'common' })
          : t('roles.companyAdmin', { ns: 'common' }),
      alignment: 'left',
      unfilterable: true,
      shrink: true,
    },
  };

  return columns;
}

function useUserListSearchFilter() {
  const [searchText, setSearchText] = useState(
    sessionStorageUtils.getUserItemOrDefault('userListSearchFilter', ''),
  );

  useEffect(() => {
    sessionStorageUtils.setUserItem('userListSearchFilter', searchText);
  }, [searchText]);

  return { searchText, setSearchText };
}

const searchColumns = ['name', 'email', 'role'] as const;
function filterItems(items: Item[], searchText: string) {
  return items.filter((item) => {
    const searchValue = searchText.trim().toLowerCase();
    return searchColumns.some((x) => (item[x] ?? '').toLowerCase().includes(searchValue));
  });
}

export const UserList = () => {
  const { searchText, setSearchText } = useUserListSearchFilter();
  const addUsersDialog = useDialog();
  const theme = useTheme();
  const editUserDialog = useDialog();
  const hasAddUsersAccess = useRef(
    ProjectStore.instance.hasCompanyAdmin(UserStore.instance.user.id) ||
      UserStore.instance.isOrganizationAdmin(),
  );
  const [item, setItem] = useState<Item>();
  const [items, dispatchItems] = useReducer(usersReducer, []);

  const [filteredItems, setFilteredItems] = useState<Item[]>([]);

  useEffect(() => {
    setFilteredItems(filterItems(items, searchText));
  }, [items, searchText]);

  useEffect(() => {
    dispatchItems({
      type: 'init',
      items: getUsers(theme),
    });
  }, [theme]);

  return (
    <>
      <Stack alignItems="baseline" direction="row" justifyContent="space-between" spacing={0.5}>
        <SearchFilterContainer direction="row" spacing={0.5}>
          <TextBox
            placeholder={t('search', { ns: 'common' })}
            value={searchText}
            width={250}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </SearchFilterContainer>

        {hasAddUsersAccess.current &&
          FeatureFlagsManager.instance.isEnabled('SK-5698-add-project-users-react') && (
            <Button color="primary" variant="contained" onClick={() => addUsersDialog.show()}>
              {t('userList.addProjectUsersDialog.title', { ns: 'components' })}
            </Button>
          )}
      </Stack>

      <DataTable
        columns={getColumns()}
        fixedLayout={true}
        items={filteredItems}
        pageSize={10}
        tableStyle={{
          rowCell: {
            fontSize: '14px',
          },
          headerCell: {
            fontSize: '14px',
          },
        }}
        onRowClicked={(item) => {
          setItem(item);
          editUserDialog.show();
        }}
      />
      {item &&
        editUserDialog.render(
          <EditUserDialog
            user={item}
            onClose={() => editUserDialog.hide()}
            onRemoveUser={(item) => {
              dispatchItems({
                type: 'remove',
                item,
              });
            }}
          />,
        )}
      {addUsersDialog.render(<AddProjectUsersDialog dialog={addUsersDialog} />)}
    </>
  );
};

function useRemoveProjectAttendeeMutation(onSuccess: () => void) {
  const { handleError, buildErrorList } = useErrorHandling();

  const { mutate, isPending } = useMutation({
    mutationFn: (userId: string) =>
      SkyMapAxiosServiceFactory.instance.createProjectServiceV0().removeUserFromProject({
        path: {
          projectId: ProjectStore.instance.project!.id,
          userId,
        },
      }),
    onError: (error) => handleError(error),
    onSuccess: () => {
      onSuccess();
    },
    retry: false,
    networkMode: 'always',
  });

  return {
    removeUserFromProject: mutate,
    buildErrorList,
    isPending,
  };
}

const EditUserDialog = (props: {
  user: Item;
  onRemoveUser: (item: Item) => void;
  onClose: () => void;
}) => {
  const hasEditAccess =
    ProjectStore.instance.hasCompanyAdmin(UserStore.instance.user.id) ||
    UserStore.instance.isOrganizationAdmin();
  const isEditable = hasEditAccess && props.user.role === 'project_attendee';

  const { removeUserFromProject, buildErrorList, isPending } = useRemoveProjectAttendeeMutation(
    () => {
      props.onClose();
      props.onRemoveUser(props.user);
    },
  );

  return (
    <Dialog
      closeIcon={true}
      closeOnDimmerClick={true}
      maxHeight="expand"
      width={500}
      onClose={() => {
        if (isPending) {
          return;
        }

        props.onClose();
      }}
    >
      {{
        header:
          props.user.role === 'project_attendee'
            ? t('roles.projectAttendee', { ns: 'common' })
            : t('roles.companyAdmin', { ns: 'common' }),
        content: (
          <OverlayLoader visible={isPending}>
            <Stack spacing={1}>
              <LabelledContainer fontWeight={600} text={t('name', { ns: 'common' })}>
                {props.user.name}
              </LabelledContainer>
              <LabelledContainer
                fontWeight={600}
                text={t('newCompanyAdmin.newUser.form.email', { ns: 'components' })}
              >
                {props.user.email}
              </LabelledContainer>
              <LabelledContainer
                fontWeight={600}
                text={t('newCompanyAdmin.newUser.form.phone', { ns: 'components' })}
              >
                {props.user.phoneNumber}
              </LabelledContainer>
              {isEditable && (
                <LabelledContainer
                  fontWeight={600}
                  text={t('projectUserTags.title', { ns: 'components' })}
                >
                  <ProjectUserTags user={props.user} />
                </LabelledContainer>
              )}
              {buildErrorList()}
            </Stack>
          </OverlayLoader>
        ),
        footer: {
          left: (
            <>
              {isEditable && (
                <Button
                  color="error"
                  leftIcon={{ icon: ['fad', 'trash-alt'] }}
                  loading={isPending}
                  variant="contained"
                  onClick={() => {
                    removeUserFromProject(props.user.id);
                  }}
                >
                  {t('userList.removeProjectAttendee', { ns: 'components' })}
                </Button>
              )}
            </>
          ),
          right: (
            <Button loading={isPending} variant="text" onClick={() => props.onClose()}>
              {t('close', { ns: 'common' })}
            </Button>
          ),
        },
      }}
    </Dialog>
  );
};

const SearchFilterContainer = styled(Stack)`
  width: 450px;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

UserList.propTypes = {
  wrapWithLanguageProvider: PropTypes.any,
};
