import React from 'react';
import styled from 'styled-components';

import { SubscriptionTopic } from '../../../../../../js/messaging/pubsub';
import { ElementObj } from '../../../../../../js/viewer/elements/element-obj';
import { useHover } from '../../../../../hooks/use-hover';
import { useSubscribe } from '../../../../../hooks/use-subscribe';
import { LabelledContainer } from '../../../../labelled-container/labelled-container';
import { OverlayLoader } from '../../../../overlay-loader/overlay-loader';
import { AssetContext } from '../../../state/asset-state';
import { CadGroupVisibilityContext } from '../../../state/cad-group-visibility-state';
import { CategoryContext } from '../../../state/category-state';
import { CategoryVisibilityContext } from '../../../state/category-visibility-state';
import { AssetHeaderProps } from './asset-header';

type Props = {
  children: {
    header: React.ReactElement<AssetHeaderProps>;
    content: React.ReactNode;
  };
  asset: ElementObj;
};

const Asset = React.memo((props: Props) => {
  const categoryContext = React.useContext(CategoryContext);
  const { height: categoryHeight, setExpanded } = React.useContext(CategoryVisibilityContext);
  const assetContext = React.useContext(AssetContext);
  const cadGroupContext = React.useContext(CadGroupVisibilityContext);
  const { hovering, ref } = useHover<HTMLDivElement>();
  const scrollIntoViewRef = React.useRef(false);

  const assetSelected = categoryContext.expandedAssetId === props.asset.uuid;

  React.useEffect(() => {
    assetContext.setIsMouseInsideAsset(hovering);
  }, [assetContext, hovering]);

  useSubscribe(SubscriptionTopic.NewSelection, (asset) => {
    if (asset.uuid === props.asset.uuid && ref.current) {
      // Expand category.
      setExpanded(true);

      // If CAD group context exists, the asset is a CAD asset, so expand CAD group.
      if (cadGroupContext) {
        cadGroupContext.setExpanded(true);
      }

      // Scroll into view on next refresh to make sure asset has been fully rendered before
      // scrolling it into view.
      scrollIntoViewRef.current = true;
    }
  });

  React.useLayoutEffect(() => {
    if (scrollIntoViewRef.current && ref.current) {
      // Scroll asset into view including extra margin specified with CSS `scroll-margin`.
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      scrollIntoViewRef.current = false;
    }
  });

  let overlayLoaderVisible = props.asset.loadingStatus === 'loading';

  // Design models loads automatically on expand.
  // In order to render overlay loader at once, take into account also the idle state.
  if (props.asset.objType === 'Design') {
    overlayLoaderVisible ||= props.asset.loadingStatus === 'idle';
  }

  return (
    <Component categoryHeight={categoryHeight} data-expanded={assetSelected} ref={ref}>
      {props.children.header}
      {assetSelected && (
        <OverlayLoader
          backgroundColor="#FFFA"
          showIcon={props.asset.loadingStatus !== 'error'}
          visible={overlayLoaderVisible}
        >
          <Content>{props.children.content}</Content>
        </OverlayLoader>
      )}
    </Component>
  );
});

Asset.displayName = 'Asset';

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.2em;

  ${LabelledContainer.styledLabel} {
    font-weight: 500;
    font-size: 1em;
  }

  ${LabelledContainer.styledContent} {
    font-weight: 300;
  }
  padding: 0.8em;
  font-size: 0.9em;
`;

const Component = styled.div<{ categoryHeight: number }>`
  position: relative;

  // Used to make asset scroll into view correctly.
  // The top margin represents the height of the category so asset scrolls into view and positions
  // exactly below the category header (without scroll margin it placed behind the sticky category).
  // The bottom margin represents the height (plus some pixels) of the bottom box shadow, to make it
  // visible as well.
  scroll-margin-top: ${(props) => `${props.categoryHeight}px`};
  scroll-margin-bottom: 5px;

  &[data-expanded='true']::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 3px;
    height: 100%;
    background-color: #715f6a;
    z-index: 1;
  }

  &[data-expanded='true'] {
    box-shadow:
      0 1px 3px rgb(0 0 0 / 12%),
      0 1px 2px rgb(0 0 0 / 24%);
    z-index: 1;
  }
`;

export { Asset };
