import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Coordinates } from '../../../../js/viewer/utilities/math-helpers';
import { useFloatingPosition } from '../../../hooks/use-floating-position';
import { AreaProperties, AreaProps } from './components/area-properties';
import { AsbuiltGeometryProps, AsbuiltProperties } from './components/asbuilt-properties';
import { CoordinateProperties, CoordinateProps } from './components/coordinate-properties';
import { DesignHoverProperties, DesignHoverProps } from './components/design-hover-properties';
import {
  DesignMultiselectProperties,
  DesignMultiselectProps,
} from './components/design-multiselect-properties';
import { LineProperties, LineProps } from './components/line-properties';
import {
  VolumeInspectorProperties,
  VolumeInspectorProps,
} from './components/volume-inspector-properties';

export interface SkyviewAssetPositionProps {
  properties:
    | CoordinateProps
    | AreaProps
    | LineProps
    | DesignHoverProps
    | DesignMultiselectProps
    | AsbuiltGeometryProps
    | VolumeInspectorProps;
  coordinateSystem?: string;
  coordinate: Coordinates;
  screenPoint: {
    x: number;
    y: number;
  };
}

const SkyviewAssetPositionTooltip = (props: SkyviewAssetPositionProps) => {
  const { ref, floatingStyles, floatingProps } = useFloatingPosition({
    middleware: 'flip',
    offset: {
      mousePointer: 10,
      viewportEdge: 5,
    },
    followMousePointer: true,
    startPos: {
      x: props.screenPoint.x,
      y: props.screenPoint.y,
    },
    strategy: 'absolute',
  });

  return (
    <Component ref={ref} style={floatingStyles} {...floatingProps}>
      {props.properties.type === 'coordinates' && (
        <CoordinateProperties
          coordinate={props.coordinate}
          coordinateSystem={props.coordinateSystem}
        />
      )}
      {props.properties.type === 'asbuilt-geometry' && (
        <AsbuiltProperties data={props.properties.data} />
      )}
      {props.properties.type === 'area' && (
        <AreaProperties
          coordinate={props.coordinate}
          coordinateSystem={props.coordinateSystem}
          properties={props.properties}
        />
      )}
      {props.properties.type === 'line' && (
        <LineProperties
          coordinate={props.coordinate}
          coordinateSystem={props.coordinateSystem}
          properties={props.properties}
        />
      )}
      {props.properties.type === 'design' && props.properties.isMultiselect && (
        <DesignMultiselectProperties
          coordinate={props.coordinate}
          coordinateSystem={props.coordinateSystem}
          properties={props.properties}
        />
      )}
      {props.properties.type === 'design' && !props.properties.isMultiselect && (
        <DesignHoverProperties
          coordinate={props.coordinate}
          coordinateSystem={props.coordinateSystem}
          properties={props.properties}
        />
      )}
      {props.properties.type === 'volume-inspector' && (
        <VolumeInspectorProperties
          coordinate={props.coordinate}
          coordinateSystem={props.coordinateSystem}
          properties={props.properties}
        />
      )}
    </Component>
  );
};

const Component = styled.div`
  position: absolute;
  z-index: 1000000000;

  min-width: 250px;
  width: min-content;

  padding: 0.5em;
  border-radius: 0.5em;

  background-color: rgba(64, 64, 64, 0.9);
  color: #fff;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  font-size: 10pt;

  font-family: 'Montserrat', sans-serif;

  pointer-events: none;
`;

SkyviewAssetPositionTooltip.propTypes = {
  wrapWithLanguageProvider: PropTypes.any,
  properties: PropTypes.object,
  coordinateSystem: PropTypes.string.isRequired,
  coordinate: PropTypes.object.isRequired,
  screenPoint: PropTypes.object.isRequired,
};

export { SkyviewAssetPositionTooltip };
