import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import useResizeObserver from '@react-hook/resize-observer';
import React from 'react';
import styled, { css } from 'styled-components';

import { isDefined } from '../../../js/utils/variables';
import { Center } from '../../styles/center';
import { Icon } from '../icon/icon';
import { Stack } from '../stack/stack';

type StaticSize = {
  compressed: boolean;
};

type ResponsiveSize = {
  componentPxHeightBreak: number;
};

type Props = {
  header: { icon: FontAwesomeIconProps } | React.ReactElement;
  responsiveness: StaticSize | ResponsiveSize;
  alignContent?: React.CSSProperties['alignContent'];
  children: {
    title: React.ReactNode;
    info?: React.ReactNode;
  };
};

const IconPanel = (props: Props) => {
  const resolveStaticCompressedState = () => (props.responsiveness as StaticSize).compressed;

  const resolveResponsiveCompressedState = (componentHeight?: number) =>
    isDefined((props.responsiveness as ResponsiveSize).componentPxHeightBreak)
      ? (props.responsiveness as ResponsiveSize).componentPxHeightBreak >
        (componentHeight ?? Number.MAX_SAFE_INTEGER)
      : undefined;

  const resolveComponentCompressedState = (componentHeight?: number) =>
    resolveStaticCompressedState() ?? resolveResponsiveCompressedState(componentHeight);

  const [useCompressedSize, setUseCompressedSize] = React.useState<boolean>(
    resolveComponentCompressedState(),
  );

  const componentRef = React.createRef<HTMLDivElement>();

  useResizeObserver(componentRef, (entry) => {
    setUseCompressedSize(resolveComponentCompressedState(entry.contentRect.height));
  });

  const renderHeader = () => {
    return 'icon' in props.header ? (
      <Icon
        {...props.header.icon}
        size={useCompressedSize ? '5x' : props.header.icon.size ?? '10x'}
      />
    ) : (
      props.header
    );
  };

  return (
    <Component
      alignContentProp={props.alignContent}
      ref={componentRef}
      useCompressedSize={useCompressedSize}
    >
      <Stack alignItems={'center'} direction={useCompressedSize ? 'row' : 'column'} spacing={1.5}>
        {renderHeader()}

        <Stack
          alignItems={useCompressedSize ? 'start' : 'center'}
          direction={'column'}
          spacing={0.5}
        >
          {<h3>{props.children.title}</h3>}
          {props.children.info && <span>{props.children.info}</span>}
        </Stack>
      </Stack>
    </Component>
  );
};

const Component = styled(Center)<{
  useCompressedSize: boolean;
  alignContentProp: React.CSSProperties['alignContent'];
}>`
  h3 {
    font-size: 1.5em;
    font-weight: 200;
    text-align: ${(props) => (props.useCompressedSize ? 'start' : 'center')};
  }

  strong {
    font-weight: 500;
  }

  span {
    font-weight: 300;
    line-height: 1.5em;

    text-align: ${(props) => (props.useCompressedSize ? 'start' : 'center')};
  }

  ${({ alignContentProp }) =>
    isDefined(alignContentProp)
      ? css`
          align-content: ${alignContentProp};
        `
      : undefined}
`;

export { IconPanel };
