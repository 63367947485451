import React from 'react';
import styled from 'styled-components';

import { reset } from '../../utils/styled-reset';
import { TreeViewItem, TreeViewItemModel } from './tree-view-item';
import { TreeViewContext } from './tree-view-state';

interface Props {
  items: TreeViewItemModel[];
  parent: TreeViewItemModel | null;
  level: number;
}

const listItemLeftIndentPx = 10;

const TreeViewLevel = (props: Props) => {
  const { isItemExpanded } = React.useContext(TreeViewContext);

  const items = props.items.filter((item) => item.parent?.id === props.parent?.id);

  return items.length === 0 ? null : (
    <Component listItemLeftIndentPx={listItemLeftIndentPx}>
      {items.map((item) => (
        <li key={item.id}>
          <TreeViewItem item={item} leftIndentPx={props.level * listItemLeftIndentPx} />

          {isItemExpanded(item.id) && (
            <TreeViewLevel items={props.items} level={props.level + 1} parent={item} />
          )}
        </li>
      ))}
    </Component>
  );
};

const Component = styled.ul<{ listItemLeftIndentPx: number }>`
  ${reset}
  list-style: none;

  &:first-child {
    padding: 0.2em 0;
  }

  li {
    ${reset}

    position: relative;
    padding: 0;

    // Padding left for all levels but first.
    li {
      padding-left: ${(props) => props.listItemLeftIndentPx}px;
    }
  }
`;

TreeViewLevel.styled = Component;

export { TreeViewLevel };
