import { sum } from 'd3';
import { t } from 'i18next';
import React, {
  Dispatch,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import styled, { DefaultTheme, useTheme } from 'styled-components';

import { publish, SubscriptionTopic } from '../../../../js/messaging/pubsub';
import { CloudFrontSigner } from '../../../../js/utils/cloud-front-signer/cloud-front-signer';
import { getJpegExifData, jpegExifDataHasGpsInformation } from '../../../../js/utils/file-utils';
import { toHumanReadableFileSize } from '../../../../js/utils/io/file';
import { normalizeString } from '../../../../js/utils/text/text';
import { isDefined, isIncluded } from '../../../../js/utils/variables';
import { GeodataImageUpdatedMessage } from '../../../../js/utils/websocket/websocket-manager';
import { useSubscribe } from '../../../hooks/use-subscribe';
import { DataTable, DataTableSortType } from '../../data-table/data-table';
import { FileSelectionIcon } from '../../file-selection-icon/file-selection-icon';
import { Icon } from '../../icon/icon';
import { IconPanel } from '../../icon-panel/icon-panel';
import { OverlayLoader } from '../../overlay-loader/overlay-loader';
import { ProgressBar } from '../../progress-bar/progress-bar';
import { Stack } from '../../stack/stack';
import { TextBox } from '../../text-box/text-box';
import { useGeodataCloudFrontSigner } from '../use-geodata-cloudfront-signer';
import { GeodataImageStatusIcon } from './geodata-image-status-icon';
import { LocalGeodataImage, LocalGeodataImageStatus } from './types';
import { useGetGeodataImageListQuery } from './use-get-geodata-image-list-query';
import { UploadImageParameters, useUploadImageQueue } from './use-image-upload-queue';

const pageSize = 20;

type Item = {
  imageIdentifier: string;
  statusIcon: ReactNode;
  displayName: string;
  status: LocalGeodataImageStatus;
  file?: File;
};

type DispatchItemAction = {
  type: 'UPDATE';
  theme: DefaultTheme;
  items: LocalGeodataImage[];
};

function mapImage(image: LocalGeodataImage, theme: DefaultTheme): Item {
  return {
    imageIdentifier: image.name,
    displayName: image.name.substring(image.name.indexOf('_') + 1),
    status: image.status,
    statusIcon: GeodataImageStatusIcon(image, theme),
    file: image.file,
  };
}

function useSubscribeToGeodataImageUpdatedMessage(
  geodataId: string,
  callback: (message: GeodataImageUpdatedMessage['data']) => void,
) {
  useSubscribe(SubscriptionTopic.WebsocketMessageRecieved, (event) => {
    if (event.message === 'GeodataImageUpdated' && event.data.geodataId === geodataId) {
      callback(event.data);
    }
  });
}

const searchKeys = ['displayName', 'status'] as const;

function filterItems(items: Item[], searchText: string): Item[] {
  const text = searchText.trim().toLowerCase();
  if (text.length === 0) {
    return items;
  }

  return items.filter((item) =>
    searchKeys.some((x) => item[x]?.toLocaleLowerCase().includes(text)),
  );
}

function itemsReducer(state: Item[], action: DispatchItemAction): Item[] {
  if (action.type === 'UPDATE') {
    const newState: Item[] = [];

    state.forEach((existingItem) => {
      const updatedItem = action.items.find(
        (newItem) => newItem.name === existingItem.imageIdentifier,
      );

      // Make sure locally attached file remains attached.
      if (isDefined(updatedItem) && isDefined(existingItem.file)) {
        updatedItem.file = existingItem.file;
      }

      newState.push(isDefined(updatedItem) ? mapImage(updatedItem, action.theme) : existingItem);
    });

    action.items.forEach((newItem) => {
      if (!state.some((existingItem) => newItem.name === existingItem.imageIdentifier)) {
        newState.push(mapImage(newItem, action.theme));
      }
    });

    return newState;
  }

  return state;
}

function useGeodataImageProgress(items: Item[]) {
  return useMemo(() => {
    const pending = items.filter((x) =>
      isIncluded(['PENDING', 'UPLOADING', 'FAILED', 'UPLOAD_FAILED'], x.status),
    );
    const uploaded = items.filter(
      (x) => !isIncluded(['PENDING', 'UPLOADING', 'UPLOAD_FAILED'], x.status),
    );

    const totalSize = sum(items.map((x) => x.file?.size ?? 0));
    const uploadedSize = sum(uploaded.map((x) => x.file?.size ?? 0));

    return {
      text: `${items.length - pending.length} / ${items.length.toString()} (${toHumanReadableFileSize(uploadedSize)} / ${toHumanReadableFileSize(totalSize)})`,
      value: 1 - pending.length / items.length,
    };
  }, [items]);
}

/**
 * Gets a unique identifier for the image by combining date time original with the file name.
 */
async function parseImage(
  file: File,
): Promise<{ imageIdentifier: string; errorStatus?: LocalGeodataImageStatus }> {
  const normalizedFileName = normalizeString(file.name);
  try {
    const exifData = await getJpegExifData(file);

    if (!isDefined(exifData.tags?.DateTimeOriginal)) {
      return {
        imageIdentifier: `${file.lastModified}_${normalizedFileName}`,
        errorStatus: 'FAILED_MISSING_CAPTURE_DATE',
      };
    }

    if (!jpegExifDataHasGpsInformation(exifData)) {
      return {
        imageIdentifier: `${file.lastModified}_${normalizedFileName}`,
        errorStatus: 'FAILED_MISSING_GPS_DATA',
      };
    }

    return {
      imageIdentifier: `${exifData.tags.DateTimeOriginal}_${normalizedFileName}`,
    };
  } catch {
    return {
      imageIdentifier: `${file.lastModified}_${normalizedFileName}`,
      errorStatus: 'FAILED',
    };
  }
}

function useUploadImageCallback(
  items: Item[],
  dispatchItems: Dispatch<DispatchItemAction>,
  queueUpload: (params: UploadImageParameters) => void,
  signer: CloudFrontSigner | undefined,
  theme: DefaultTheme,
) {
  return useCallback(
    async (file: File) => {
      if (!isDefined(signer)) {
        throw Error('Unable to upload image. CloudFrontSigner is not initiated.');
      }

      const { imageIdentifier, errorStatus } = await parseImage(file);

      const matchedImage = items.find(
        (x) => x.imageIdentifier === imageIdentifier && x.status !== 'UPLOAD_FAILED',
      );

      if (!isDefined(matchedImage?.file)) {
        dispatchItems({
          items: [
            {
              name: imageIdentifier,
              status: errorStatus ?? matchedImage?.status ?? 'UPLOADING',
              file,
            },
          ],
          theme,
          type: 'UPDATE',
        });
      }

      // Don't queue upload if there is a matched image or if there was an error parsing it.
      if (isDefined(matchedImage) || isDefined(errorStatus)) {
        return;
      }

      queueUpload({
        imageIdentifier,
        file,
        signer: signer,
        onError: () => {
          dispatchItems({
            items: [
              {
                name: imageIdentifier,
                status: 'UPLOAD_FAILED',
                file,
              },
            ],
            theme,
            type: 'UPDATE',
          });
        },
        onSuccess: () => {
          dispatchItems({
            items: [
              {
                name: imageIdentifier,
                status: 'PENDING',
                file,
              },
            ],
            theme,
            type: 'UPDATE',
          });
        },
      });
    },
    [items, dispatchItems, queueUpload, signer, theme],
  );
}

/**
 * Links local images up with the uploaded images to speed up the loading on slow connections.
 */
function useLinkLocalImagesCallback(
  items: Item[],
  dispatchItems: Dispatch<DispatchItemAction>,
  theme: DefaultTheme,
) {
  return useCallback(
    async (files: File[]) => {
      const imagesToLink: LocalGeodataImage[] = [];
      for (const file of files) {
        const { imageIdentifier, errorStatus } = await parseImage(file);
        const matchingImage = items.find((x) => x.imageIdentifier === imageIdentifier);
        if (isDefined(matchingImage) && !isDefined(matchingImage.file) && !isDefined(errorStatus)) {
          imagesToLink.push({
            name: imageIdentifier,
            status: matchingImage.status,
            file,
          });
        }
      }

      dispatchItems({
        items: imagesToLink,
        theme,
        type: 'UPDATE',
      });
      publish(SubscriptionTopic.ToastrMessage, {
        type: imagesToLink.length > 0 ? 'success' : 'info',
        message:
          imagesToLink.length > 0
            ? t('imageList.imagesLinked', { ns: 'cloudProcessing' })
            : t('imageList.noImagesLinked', { ns: 'cloudProcessing' }),
      });
    },
    [items, dispatchItems, theme],
  );
}

export const NoImagesPanel = () => {
  return (
    <NoImagesPanelStyle>
      <IconPanel
        header={{ icon: { icon: ['fad', 'magnifying-glass'], opacity: '50%', size: '4x' } }}
        responsiveness={{ compressed: false }}
      >
        {{
          title: t('imageList.noImagesTitle', { ns: 'cloudProcessing' }),
          info: t('imageList.noImagesInfo', { ns: 'cloudProcessing' }),
        }}
      </IconPanel>
    </NoImagesPanelStyle>
  );
};

const NoImagesPanelStyle = styled.div`
  display: flex;
  padding: 1em;
`;

/**
 * Lists the images related to the Geodata with the option to upload or remove existing images.
 * Adding or removing images will be disabled when the process has failed or finished.
 */
export function GeodataImageList(props: { geodataId: string }) {
  const theme = useTheme();
  const signer = useGeodataCloudFrontSigner(props.geodataId);
  const { images, isPendingImages, hasError, refetchImages, buildErrorListImages } =
    useGetGeodataImageListQuery(props.geodataId);

  const queueUpload = useUploadImageQueue();
  const [items, dispatchItems] = useReducer(itemsReducer, []);
  const [filteredItems, setFilteredItems] = useState<Item[]>([]);
  const [searchText, setSearchText] = useState('');
  const progress = useGeodataImageProgress(items);
  const uploadImage = useUploadImageCallback(items, dispatchItems, queueUpload, signer, theme);
  const linkLocalImages = useLinkLocalImagesCallback(items, dispatchItems, theme);
  const renderNoImagesPanel = !isPendingImages && items.length === 0;

  useEffect(() => {
    dispatchItems({
      type: 'UPDATE',
      items: images,
      theme,
    });
  }, [images, theme]);

  useSubscribeToGeodataImageUpdatedMessage(props.geodataId, (data) => {
    dispatchItems({
      type: 'UPDATE',
      items: [
        {
          name: data.imageName,
          status: data.status,
        },
      ],
      theme,
    });
  });

  useEffect(() => {
    setFilteredItems(filterItems(items, searchText));
  }, [items, searchText]);

  if (hasError()) {
    return <>{buildErrorListImages()}</>;
  }

  return (
    <OverlayLoader visible={!isDefined(signer) || isPendingImages}>
      <Stack spacing={1}>
        {progress.value < 1 && (
          <ProgressBar max={1} min={0} text={progress.text} value={progress.value} />
        )}

        <Stack alignItems="center" direction="row" spacing={0.5}>
          <TextBox
            placeholder="Sök"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
          <FileSelectionIcon
            accept=".jpg,.jpeg"
            color={theme.color.gray.dark}
            fixedWidth={true}
            icon={['fad', 'images']}
            multiple={true}
            title={'Lägg till bilder.'}
            onFilesSelected={async (files) => {
              for (const file of files) {
                await uploadImage(file);
              }
            }}
            onHoverStyle={{ icon: ['fas', 'images'] }}
          />
          <FileSelectionIcon
            accept=".jpg,.jpeg"
            color={theme.color.gray.dark}
            fixedWidth={true}
            icon={['fad', 'link']}
            multiple={true}
            title={'Länka lokala bilder.'}
            onFilesSelected={(files) => linkLocalImages(files)}
            onHoverStyle={{ icon: ['fas', 'link'] }}
          />
          <Icon
            color={theme.color.gray.dark}
            fixedWidth={true}
            icon={['fad', 'refresh']}
            title={t('refetch', { ns: 'common' })}
            onClick={() => refetchImages()}
            onHoverStyle={{ icon: ['fas', 'refresh'] }}
          />
        </Stack>

        {renderNoImagesPanel ? (
          <NoImagesPanel />
        ) : (
          <DataTable
            columns={{
              statusIcon: {
                title: 'Status',
                alignment: 'center',
                width: '45px',
                sortableBy: { type: DataTableSortType.STRING, columnKey: 'status' },
                unfilterable: true,
              },
              displayName: {
                sortableBy: { type: DataTableSortType.STRING },
                unfilterable: true,
                title: 'Namn',
              },
            }}
            fixedLayout={true}
            items={filteredItems}
            pageSize={pageSize}
            tableStyle={{
              rowCell: {
                fontSize: '14px',
              },
              headerCell: {
                fontSize: '14px',
              },
            }}
            onRowClicked={async (item) => {
              if (item.status === 'UPLOAD_FAILED' && item.file) {
                await uploadImage(item.file);
              }
            }}
          />
        )}
      </Stack>
    </OverlayLoader>
  );
}
