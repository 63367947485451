import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PointClass } from '../../../../../../js/viewer/loader/cesium/content/pnts/types';
import { useNonce } from '../../../../../hooks/use-nonce';
import { reset } from '../../../../../utils/styled-reset';
import { Icon } from '../../../../icon/icon';
import { LabelledContainer } from '../../../../labelled-container/labelled-container';
import { usePointCloudClassifications } from './use-point-cloud-classifications';

export type PointCloudClassification = {
  displayName: string;
  pointClass: PointClass;
};

const PointCloudClassifications = () => {
  const { t } = useTranslation();
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const { loaded, getColor, isVisible, toggleVisibility } = usePointCloudClassifications(canvasRef);
  const [_, refresh] = useNonce();

  const classifications: PointCloudClassification[] = [
    {
      pointClass: PointClass.Unclassified,
      displayName: t('pointcloud.settings.classifications.unclassified', { ns: 'skyviewToolbar' }),
    },
    {
      pointClass: PointClass.Ground,
      displayName: t('pointcloud.settings.classifications.ground', { ns: 'skyviewToolbar' }),
    },
    {
      pointClass: PointClass.HighVegetation,
      displayName: t('pointcloud.settings.classifications.highVegetation', {
        ns: 'skyviewToolbar',
      }),
    },
    {
      pointClass: PointClass.Building,
      displayName: t('pointcloud.settings.classifications.building', { ns: 'skyviewToolbar' }),
    },
    {
      pointClass: PointClass.RoadSurface,
      displayName: t('pointcloud.settings.classifications.roadSurface', { ns: 'skyviewToolbar' }),
    },
    {
      pointClass: PointClass.Car,
      displayName: t('pointcloud.settings.classifications.car', { ns: 'skyviewToolbar' }),
    },
    {
      pointClass: PointClass.Manmade,
      displayName: t('pointcloud.settings.classifications.manmade', { ns: 'skyviewToolbar' }),
    },
  ];

  return (
    <Component>
      <HiddenCanvas ref={canvasRef} />

      {loaded ? (
        <LabelledContainer
          noWrap={true}
          text={t('pointcloud.settings.title', { ns: 'skyviewToolbar' })}
        >
          <ul>
            {classifications.map((x) => (
              <Class
                color={getColor(x)}
                key={x.displayName}
                onClick={() => {
                  toggleVisibility(x);
                  refresh();
                }}
              >
                <span>{x.displayName}</span>
                <Icon fixedWidth={true} icon={['fad', isVisible(x) ? 'eye' : 'eye-slash']} />
              </Class>
            ))}
          </ul>
        </LabelledContainer>
      ) : (
        <div>
          <Icon icon={['fad', 'spinner']} spin={true} />
        </div>
      )}
    </Component>
  );
};

const Component = styled.div`
  display: grid;
  place-content: center;
`;

const HiddenCanvas = styled.canvas`
  display: none;
`;

const Class = styled.li<{ color: string }>`
  ${reset}
  display: flex;
  flex-wrap: nowrap;
  padding: 0.25em;
  cursor: pointer;

  &:hover {
    background-color: #eee;
    border-radius: 0.2em;
  }

  > span:first-child {
    position: relative;
    flex: 1;
    white-space: nowrap;
    margin-right: 1.5em;
    padding-left: 1.5em;

    &::before {
      position: absolute;
      content: '';
      width: 0.8em;
      height: 0.8em;
      border-radius: 0.2em;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background-color: ${(props) => props.color};
    }
  }
`;

export { PointCloudClassifications };
