import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ImageNoFolders from '../../../../../www/static/no_folders.svg';
import { SkyMapAxiosServiceFactory } from '../../../../js/services/axios/skymap-axios-service-factory';
import { ProjectContext } from '../../../state/project-state';
import { Button } from '../../button/button';
import { InfoBox } from '../../info-box/info-box';
import { OverlayLoader } from '../../overlay-loader/overlay-loader';
import { TreeContext } from '../state/folder-tree-state';
import { FolderTree } from './folder-tree';

enum Status {
  Loading,
  Loaded,
  Error,
}

const FolderTreeContainer = () => {
  const { t } = useTranslation();
  const { project, sharedFolderId } = React.useContext(ProjectContext);
  const { refreshNonce, setRefreshNonce, folders, setFolders } = React.useContext(TreeContext);
  const [status, setStatus] = React.useState(Status.Loading);

  const getFolders = React.useCallback(async () => {
    const cancelTokenSource = axios.CancelToken.source();

    try {
      setStatus(Status.Loading);

      if (sharedFolderId) {
        const sharedFolderService =
          SkyMapAxiosServiceFactory.instance.createSharedFolderServiceV1();
        const response = await sharedFolderService.getRootFolders({
          path: { sharedFolderId },
          query: { includeSubFolders: true },
        });
        setFolders(response.data);
      } else {
        const projectService = SkyMapAxiosServiceFactory.instance.createProjectServiceV1();
        const response = await projectService.getRootFolders(
          {
            path: { projectId: project.id },
            query: {
              includeSubFolders: true,
              include: ['folder.sharedFolder', 'folder.tags', 'folder.skyMapConnectFolder'],
            },
          },
          cancelTokenSource.token,
        );
        setFolders(response.data);
      }

      setStatus(Status.Loaded);
    } catch (err) {
      setStatus(Status.Error);

      // Clear folders so next render (retry) does not show previous folders.
      setFolders([]);
    }
  }, [project, sharedFolderId, setFolders]);

  React.useEffect(() => void getFolders(), [getFolders, refreshNonce]);

  const getContent = () => {
    switch (status) {
      case Status.Loading:
        return folders.length === 0 ? (
          <Loading>
            <FontAwesomeIcon icon="spinner" spin={true} />
          </Loading>
        ) : (
          <OverlayLoader visible={true}>
            <FolderTree folders={folders} level={0} parentId={null} />
          </OverlayLoader>
        );
      case Status.Error:
        return (
          <LoadingError>
            <InfoBox color="red">
              {t('fileManager.folderTree.fetchFoldersError', { ns: 'components' })}
            </InfoBox>
            <Button color="primary" variant="contained" onClick={setRefreshNonce}>
              {t('retry', { ns: 'common' })}
            </Button>
          </LoadingError>
        );
      case Status.Loaded:
        return folders.length === 0 ? (
          <Empty>
            <ImageNoFolders />
          </Empty>
        ) : (
          <FolderTree folders={folders} level={0} parentId={null} />
        );
    }
  };

  return <Component>{getContent()}</Component>;
};

const Component = styled.nav`
  margin: 0;
  line-height: 1em;
  background-color: #fff;
  overflow: auto;
`;

const Loading = styled.div`
  display: flex;
  margin: 1em;
  justify-content: center;
`;

const Empty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  padding: 1.5em;
`;

const LoadingError = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em;

  button {
    margin: 0;
  }
`;

export { FolderTreeContainer };
