import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { ProjectModel } from '../../../../typings/api/skymap/rest/v0/.common';
import { CoordinateSystemModel } from '../../../../typings/api/skymap/rest/v1/.common';
import { ProjectStore } from '../../../js/stores/project-store';
import { useNonce } from '../../hooks/use-nonce';
import { reset } from '../../utils/styled-reset';
import { Button } from '../button/button';
import { InfoBox } from '../info-box/info-box';
import { Projection } from '../project-settings/projection';
import { GeodataList } from './geodata-list/geodata-list';
import { ManualUploads } from './manual-uploads/manual-uploads';

interface Props {
  project: ProjectModel;
  onCoordinateSystemSet: (coordinateSystem?: CoordinateSystemModel) => void;
}

enum View {
  Geodata,
  ManualUpload,
}

export const translatedProcessType = (processType: string) => {
  switch (processType) {
    case 'ODM':
      return 'ODM';
    case 'FULL_HIGH_QUALITY':
      return 'PREMIUM';
    case 'FULL':
      return 'STANDARD';
    case 'ORTHO_ONLY':
      return 'ORTOFOTO';
    case 'ORTHO_TIF':
      return 'ORTOFOTO FRÅN TIF';
    case 'POINTCLOUD_ONLY':
      return 'PUNKTMOLN';
  }
};

const Geodata = (props: Props) => {
  const [view, setView] = React.useState(View.Geodata);
  const [coordinateSystem, setCoordinateSystem] = React.useState<
    CoordinateSystemModel | undefined
  >();
  const [refreshNonce, setRefreshNonce] = useNonce();
  const onCoordinateSystemSet = props.onCoordinateSystemSet;

  React.useEffect(() => {
    setCoordinateSystem(ProjectStore.instance.coordinateSystem);
    onCoordinateSystemSet(ProjectStore.instance.coordinateSystem);
  }, [refreshNonce, onCoordinateSystemSet]);

  if (!coordinateSystem) {
    return (
      <Component>
        <InfoBox bottomMargin={true} color="yellow" topMargin={true}>
          Vänligen välj koordinatsystem och geoid för projektet. Du kan ändra detta senare under
          inställningar.
        </InfoBox>
        <Projection />
        <Button color="primary" variant="contained" onClick={() => setRefreshNonce()}>
          Bekräfta
        </Button>
      </Component>
    );
  }

  return (
    <Component>
      <Header>
        <Title selected={view === View.Geodata} onClick={() => setView(View.Geodata)}>
          Molnprocesseringar
        </Title>
        <Title selected={view === View.ManualUpload} onClick={() => setView(View.ManualUpload)}>
          Uppladdningar
        </Title>
        <Border />
      </Header>

      <Content>
        {view === View.Geodata && <GeodataList project={props.project} />}
        {view === View.ManualUpload && <ManualUploads project={props.project} />}
      </Content>
    </Component>
  );
};

const Component = styled.div`
  ${reset}

  display: flex;
  flex-direction: column;
  flex: 1;

  > button {
    margin-top: 2em;
    width: 10em;
  }
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  gap: 2em;
  width: 100%;
  justify-content: space-evenly;
`;

const Title = styled.div<{ selected: boolean }>`
  font-weight: 500;
  line-height: 25px;

  padding-right: 0.5em;

  &:not(:first-child) {
    padding-left: 0.5em;
  }

  cursor: pointer;

  border-bottom: 4px solid
    ${(props) => (props.selected ? props.theme.color.brand.dark : props.theme.color.gray.medium)};

  &:hover {
    background-color: ${(props) => props.theme.color.gray.lightest};
    border-bottom: 4px solid ${(props) => props.theme.color.brand.medium};
  }
`;

const Border = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.color.gray.medium};
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
`;

Geodata.propTypes = {
  project: PropTypes.object.isRequired,
  onCoordinateSystemSet: PropTypes.func.isRequired,
};

export { Geodata };
