import styled from 'styled-components';

import { reset } from '../../../utils/styled-reset';

export const MenuItemButton = styled.button`
  ${reset};
  font-size: 14px;

  display: flex;
  align-items: center;
  background: none;
  border: none;
  border-radius: 0.2em;
  text-align: left;
  margin: 0;
  outline: 0;
  padding: 0.6em 0.4em;
  width: 100%;

  // Focused item (child item).
  &:focus,
  &[data-nested][data-open]:not([data-focus-inside]) {
    background: #d1d4d3;
  }

  // Previously focused parent item (focus is on child item).
  &:not(:focus)[data-focus-inside][data-open] {
    background: #eee;
  }
`;
