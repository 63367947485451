import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { reset } from '../../utils/styled-reset';
import { InfoBox } from '../info-box/info-box';
import { List } from '../list/list';
import { Stack } from '../stack/stack';
import { StepperContext } from './state/stepper-provider';

type Props = {
  licenseType: 'Portal' | 'Design';
};

const RegisterCompanyStepper = (props: Props) => {
  const { t } = useTranslation();
  const { steps, currentStepIndex } = React.useContext(StepperContext);

  return (
    <Component>
      <Stack direction="column" spacing={1}>
        <Stack direction="column" spacing={0.5}>
          <Header>{t('registerCompany.header', { ns: 'components' })}</Header>

          {props.licenseType === 'Design' && (
            <span>
              <Trans
                components={{ bold: <strong /> }}
                i18nKey="registerCompany.skyMapDesignDemoInfo"
                ns="components"
              />
            </span>
          )}

          <InfoBox
            bottomMargin={true}
            color="yellow"
            leftIcon={{ icon: ['fad', 'list-check'] }}
            topMargin={true}
          >
            <List
              currentIndex={currentStepIndex}
              items={steps.map((x, i) =>
                currentStepIndex === i ? (
                  <span key={x.title}>{x.title}</span>
                ) : (
                  { value: <Gray key={x.title}>{x.title}</Gray>, marker: { color: '#AAA' } }
                ),
              )}
              marker="number"
            />
          </InfoBox>
        </Stack>

        {steps[currentStepIndex].component}
      </Stack>
    </Component>
  );
};

const Component = styled.div`
  strong {
    font-weight: 500;
  }
`;

const Header = styled.h1`
  ${reset}
  font-weight: 400;
  font-size: 1.3em;
`;

const Gray = styled.span`
  color: #aaa;
`;

export { RegisterCompanyStepper };
