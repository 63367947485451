import React, { useEffect } from 'react';
import styled from 'styled-components';

import { CesiumPointsMaterial } from '../../../../../js/viewer/geodata/point-clouds/cesium-point-cloud/points/material/cesium-point-material';
import { PointCloudHandle } from '../../../../../js/viewer/geodata/point-clouds/point-cloud-handle';
import { PointSizeInterpolationType } from '../../../../../js/viewer/geodata/point-clouds/types';
import { reset } from '../../../../utils/styled-reset';
import { LabelledContainer } from '../../../labelled-container/labelled-container';
import { Select } from '../../../select/select';
import { Slider } from '../../../slider/slider';
import { Stack } from '../../../stack/stack';

type SliderMapping = {
  [key in PointSizeInterpolationType]: { min: number; max: number };
};

type ClassificationOption = 'Yes' | 'No';

const PointCloudSettings = () => {
  const showClassificationOptions: {
    id: ClassificationOption;
    name: string;
  }[] = [
    { id: 'Yes', name: 'Ja' },
    { id: 'No', name: 'Nej' },
  ];

  const pointSizeInterpolationTypes: {
    id: PointSizeInterpolationType;
    name: string;
  }[] = [
    { id: 'Dynamic', name: 'Dynamisk' },
    { id: 'Fixed', name: 'Fast' },
  ];

  const sliderValues: SliderMapping = {
    ['Dynamic']: { min: 0.1, max: 1.0 },
    ['Fixed']: { min: 1.0, max: 5.0 },
  };

  const activePointSizeInterpolationSettings =
    PointCloudHandle.instance.pointSizeInterpolationSettings;

  const [pointCloudPointSizeInterpolationType, setPointCloudPointSizeInterpolationType] =
    React.useState<PointSizeInterpolationType>(
      activePointSizeInterpolationSettings.pointSizeInterpolationType,
    );
  const [pointCloudPointSize, setPointCloudPointSize] = React.useState<number>(
    activePointSizeInterpolationSettings.size,
  );
  const [showPointCloudClasses, setShowPointcloudClasses] = React.useState<ClassificationOption>(
    CesiumPointsMaterial.instance.displayClasses ? 'Yes' : 'No',
  );

  const onSetShowPointCloudClasses = (value: ClassificationOption) => {
    CesiumPointsMaterial.instance.displayClasses = value === 'Yes';
    setShowPointcloudClasses(value);
  };

  const setPointSizeInterpolationType = (value: PointSizeInterpolationType) => {
    // Calculate current slider percent to map to same slider position for the other range.
    const percent =
      (pointCloudPointSize - sliderValues[pointCloudPointSizeInterpolationType].min) /
      (sliderValues[pointCloudPointSizeInterpolationType].max -
        sliderValues[pointCloudPointSizeInterpolationType].min);

    setPointCloudPointSize(
      sliderValues[value].min + (sliderValues[value].max - sliderValues[value].min) * percent,
    );

    setPointCloudPointSizeInterpolationType(value);
  };

  useEffect(() => {
    PointCloudHandle.instance.setPointSizeInterpolationSettings({
      pointSizeInterpolationType: pointCloudPointSizeInterpolationType,
      size: pointCloudPointSize,
    });
  }, [pointCloudPointSize, pointCloudPointSizeInterpolationType]);

  return (
    <Component onClick={(e) => e.stopPropagation()}>
      <Stack direction="column" spacing={1}>
        <LabelledContainer text="Visa klassifiering">
          {(formElementId) => (
            <Select
              id={formElementId()}
              options={showClassificationOptions}
              value={showPointCloudClasses}
              onChange={(e) => onSetShowPointCloudClasses(e.target.value as ClassificationOption)}
            />
          )}
        </LabelledContainer>
        <LabelledContainer text="Punktmolnsläge">
          {(formElementId) => (
            <Select
              id={formElementId()}
              options={pointSizeInterpolationTypes}
              value={pointCloudPointSizeInterpolationType}
              onChange={(e) =>
                setPointSizeInterpolationType(e.target.value as PointSizeInterpolationType)
              }
            />
          )}
        </LabelledContainer>
        <LabelledContainer text="Punktstorlek">
          <SliderContainer>
            <Slider
              max={sliderValues[pointCloudPointSizeInterpolationType].max}
              min={sliderValues[pointCloudPointSizeInterpolationType].min}
              step={0.02}
              value={pointCloudPointSize}
              onChange={setPointCloudPointSize}
            />
            <h6>{pointCloudPointSize.toFixed(2)}</h6>
          </SliderContainer>
        </LabelledContainer>
      </Stack>
    </Component>
  );
};

const Component = styled.div`
  ${reset}

  color: #000;
  font-size: 14px;
  padding: 0.5em;

  position: absolute;
  user-select: none;

  width: 200px;
  right: 0;
  transform: translateY(-100%);
  top: -17px;

  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: white;
  border-radius: 5px;
  z-index: 3;
`;

const SliderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > input {
    flex: auto;
    margin: 1em;
  }

  & > h6 {
    flex: 32px;
  }
`;

export { PointCloudSettings };
