import React from 'react';
import styled, { css } from 'styled-components';

import { ProjectContext } from '../../state/project-state';
import { ActionBar } from './action-bar/action-bar';
import { ContentArea } from './content-area';
import { FileInfoPanel } from './file-info-panel/file-info-panel';
import { FolderListViewContext } from './state/folder-list-view-state';

const RightArea = () => {
  const { sharedFolderId } = React.useContext(ProjectContext);
  const { selectedFile } = React.useContext(FolderListViewContext);

  return (
    <Component sidebarVisible={selectedFile !== undefined}>
      {!sharedFolderId && <ActionBar />}
      <ContentArea />

      {selectedFile && <FileInfoPanel file={selectedFile} />}
    </Component>
  );
};

const Component = styled.div<{ sidebarVisible: boolean }>`
  display: grid;
  background-color: #fff;
  overflow: hidden;

  ${(props) =>
    props.sidebarVisible
      ? // Sidebar visible
        css`
          grid-template-columns: 1fr clamp(300px, 25%, 500px);
          grid-template-rows: auto 1fr;
          grid-template-areas:
            'actionbar sidebar'
            'content sidebar';
        `
      : // Sidebar not visible
        css`
          grid-template-columns: 1fr;
          grid-template-rows: auto 1fr;
          grid-template-areas:
            'actionbar'
            'content';
        `}

  ${ActionBar.styled} {
    grid-area: actionbar;
  }

  ${ContentArea.styled} {
    grid-area: content;
  }

  ${FileInfoPanel.styled} {
    grid-area: sidebar;
  }
`;

RightArea.styled = Component;

export { RightArea };
