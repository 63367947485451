import React from 'react';
import styled from 'styled-components';

import { reset } from '../../../utils/styled-reset';
import { ScrollableNumberList } from './scrollable-number-list';

interface Props {
  numVisibleItems: number;
  hour: number;
  minute: number;
  minHour?: number;
  maxHour?: number;
  minMinute?: number;
  maxMinute?: number;
  scrollIntoViewNonce: number;
  onTimeChanged: (hour: number, minute: number) => void;
}

const TimeScrollBars = (props: Props) => {
  const [hour, setHour] = React.useState(props.hour);
  const [minute, setMinute] = React.useState(props.minute);

  React.useEffect(() => {
    setHour(props.hour);
  }, [props.hour]);

  React.useEffect(() => {
    setMinute(props.minute);
  }, [props.minute]);

  return (
    <Component>
      <ScrollableNumberList
        maxSelectableValue={props.maxHour}
        maxValue={23}
        minSelectableValue={props.minHour}
        minValue={0}
        numVisibleItems={props.numVisibleItems}
        scrollIntoViewNonce={props.scrollIntoViewNonce}
        selectedValue={hour}
        onNumberChanged={(number) => {
          setHour(number);
          props.onTimeChanged(number, minute);
        }}
      />
      <ScrollableNumberList
        maxSelectableValue={props.maxMinute}
        maxValue={59}
        minSelectableValue={props.minMinute}
        minValue={0}
        numVisibleItems={props.numVisibleItems}
        scrollIntoViewNonce={props.scrollIntoViewNonce}
        selectedValue={minute}
        onNumberChanged={(number) => {
          setMinute(number);
          props.onTimeChanged(hour, number);
        }}
      />
    </Component>
  );
};

const Component = styled.div`
  ${reset}

  font-size: 12px;
  display: flex;
  gap: 0.2em;
`;

TimeScrollBars.styled = Component;

export { TimeScrollBars };
