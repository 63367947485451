import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { FileModel, FileVersionModel } from '../../../../../typings/api/skymap/rest/v1/.common';
import { formattedDate, formattedDateTime } from '../../../../js/utils/dateUtils';
import { toHumanReadableFileSize } from '../../../../js/utils/io/file';
import { useDialog } from '../../../hooks/use-dialog';
import { AppContext } from '../../../state/app-state';
import { ProjectContext } from '../../../state/project-state';
import { canFileBeOpenedInline } from '../../../utils/file';
import { reset } from '../../../utils/styled-reset';
import { Icon } from '../../icon/icon';
import { DeleteFileVersionDialog } from '../dialogs/delete-file-version-dialog/delete-file-version-dialog';
import { DownloadFileIcon } from '../download-file-icon';
import { FolderListViewContext } from '../state/folder-list-view-state';
import { TreeContext } from '../state/folder-tree-state';

interface Props {
  file: FileModel;
  version: FileVersionModel;
  versionNumber: number;
}

const FileVersion = (props: Props) => {
  const { t } = useTranslation();

  const { setRefreshNonce, hasWriteAccessToCurrentFolder } = React.useContext(TreeContext);
  const { setSelectedFile } = React.useContext(FolderListViewContext);
  const { currentUser } = React.useContext(AppContext);
  const { sharedFolderId } = React.useContext(ProjectContext);
  const deleteDialog = useDialog();

  return (
    <Component>
      <Header>
        <Item>
          <label>
            {t('fileManager.fileInfoPanel.title', {
              ns: 'components',
              versionNumber: props.versionNumber,
            }).toUpperCase()}
          </label>
          <span>{formattedDateTime(props.version.createdAt)}</span>
        </Item>
        <ActionIcons>
          {canFileBeOpenedInline(props.file.name) && (
            <DownloadFileIcon
              contentDisposition="inline"
              source={{ fileVersion: props.version, fileName: props.file.name }}
            />
          )}
          <DownloadFileIcon
            contentDisposition="attachment"
            source={{ fileVersion: props.version, fileName: props.file.name }}
          />
          {!sharedFolderId && hasWriteAccessToCurrentFolder(currentUser) && (
            <Icon
              icon={['fad', 'trash']}
              title={t('fileManager.fileInfoPanel.deleteVersionIcon', {
                ns: 'components',
              })}
              onClick={() => deleteDialog.show()}
            />
          )}
        </ActionIcons>
      </Header>

      <Content>
        <Item span={2}>
          <label>
            {t('fileManager.fileInfoPanel.uploadedBy', {
              ns: 'components',
            })}
          </label>
          <span>
            {props.version.createdBy
              ? `${props.version.createdBy.firstName} ${props.version.createdBy.lastName}`
              : t('fileManager.fileInfoPanel.uploadedByUnknown', {
                  ns: 'components',
                })}
          </span>
        </Item>

        <Item>
          <label>
            {t('fileManager.fileInfoPanel.size', {
              ns: 'components',
            })}
          </label>
          <span>{toHumanReadableFileSize(props.version.size)}</span>
        </Item>

        <Item>
          <label>
            {t('fileManager.fileInfoPanel.scale', {
              ns: 'components',
            })}
          </label>
          <span>{props.version.scale ?? '-'}</span>
        </Item>

        <Item>
          <label>
            {t('fileManager.fileInfoPanel.revision', {
              ns: 'components',
            })}
          </label>
          <span>{props.version.revision ?? '-'}</span>
        </Item>

        <Item>
          <label>
            {t('fileManager.fileInfoPanel.revisionDate', {
              ns: 'components',
            })}
          </label>
          <span>{formattedDate(props.version.revisionDate) || '-'}</span>
        </Item>

        <Item span={2}>
          <label>
            {t('fileManager.fileInfoPanel.description', {
              ns: 'components',
            })}
          </label>
          <span style={{ whiteSpace: 'pre-wrap' }}>{props.version.description ?? '-'}</span>
        </Item>
      </Content>

      {deleteDialog.render(
        <DeleteFileVersionDialog
          file={props.file}
          fileVersion={props.version}
          fileVersionNumber={props.versionNumber}
          onClose={(deleted) => {
            if (deleted) {
              setRefreshNonce();

              // If last version is deleted, unselect file (hides file info panel).
              if (props.file.versions.length === 1) {
                setSelectedFile(undefined);
              }
            }
            deleteDialog.hide();
          }}
        />,
      )}
    </Component>
  );
};

const Component = styled.div`
  ${reset}
  font-size: 14px;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  padding: 1em;
`;

const Item = styled.div<{ span?: number }>`
  ${(props) =>
    props.span &&
    css`
      grid-column: 1 / ${props.span + 1};
    `}

  span {
    display: block;
    font-weight: 300;
  }

  label {
    font-weight: 500;
    font-size: 0.9em;
  }
`;

const ActionIcons = styled.div`
  display: flex;
  gap: 0.75em;
`;

const Header = styled.div`
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: space-between;

  background-color: #fff7d0;
  padding: 0.5em 1em;

  ${Component}:not(:first-of-type) & {
    border-top: 1px solid #fed530;
  }

  ${Item} {
    label {
      font-size: 1em;
    }
    span {
      font-size: 0.9em;
    }
  }
`;

FileVersion.styled = Component;

export { FileVersion };
