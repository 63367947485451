import { extname } from 'path';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FileModel, FolderModel } from '../../../../../typings/api/skymap/rest/v1/.common';
import { SkyMapAxiosServiceFactory } from '../../../../js/services/axios/skymap-axios-service-factory';
import { findLastFileVersion } from '../../../../js/utils/models/file-version';
import { permissionChecks } from '../../../../js/utils/permission-check';
import { isDefined } from '../../../../js/utils/variables';
import { useDialog } from '../../../hooks/use-dialog';
import { AppContext } from '../../../state/app-state';
import { ProjectContext } from '../../../state/project-state';
import {
  isLastFileVersionReadyForSkyView,
  lastFileVersionHasDxfOrGlb,
  validSkyViewFileExtensions,
} from '../../../utils/file';
import { isIframedByExactStudio } from '../../../utils/iframe';
import { Button } from '../../button/button';
import {
  IntegrationListStatus,
  IntegrationsContext,
} from '../../integrations/state/integrations-state';
import { SplitButton } from '../../split-button/split-button';
import { AddFilesToSkyViewDialog } from '../dialogs/add-files-to-skyview-dialog/add-files-to-skyview-dialog';
import { CreateFolderDialog } from '../dialogs/create-folder-dialog/create-folder-dialog';
import { CreateFolderStructureDialog } from '../dialogs/create-folder-structure-dialog/create-folder-structure-dialog';
import { DeleteDialog } from '../dialogs/delete-dialog/delete-dialog';
import { FolderTagsDialog } from '../dialogs/folder-tags-dialog/folder-tags-dialog';
import { RenameFileDialog } from '../dialogs/rename-file-dialog/rename-file-dialog';
import { RenameFolderDialog } from '../dialogs/rename-folder-dialog/rename-folder-dialog';
import { ShareFolderDialog } from '../dialogs/share-folder-dialog/share-folder-dialog';
import { SkyMapConnectDialog } from '../dialogs/skymap-connect-dialog/skymap-connect-dialog';
import { UploadFilesDialog } from '../dialogs/upload-files-dialog/upload-files-dialog';
import { FolderListViewContext } from '../state/folder-list-view-state';
import { TreeContext } from '../state/folder-tree-state';
import { BatchDownloadButton } from './batch-download-button';
import { FileDownloadButton } from './file-download-button';

const ActionBar = () => {
  const { t } = useTranslation();
  const { sharedFolderId } = React.useContext(ProjectContext);
  const {
    actionBarEnabled,
    checkedFolders,
    checkedFiles,
    selectedFile,
    setSelectedFile,
    refresh: refreshFolderListView,
  } = React.useContext(FolderListViewContext);
  const { currentUser, hasAdminRights } = React.useContext(AppContext);
  const { integrations, integrationStatus } = React.useContext(IntegrationsContext);

  const createFolderDialog = useDialog();
  const createFolderStructureDialog = useDialog();
  const uploadFilesDialog = useDialog();
  const shareFolderDialog = useDialog();
  const deleteDialog = useDialog();
  const renameFolderDialog = useDialog();
  const renameFileDialog = useDialog();
  const folderTagsDialog = useDialog();
  const skyMapConnectDialog = useDialog();
  const addFilesToSkyViewDialog = useDialog();

  const { selectedFolderId, hasWriteAccessToCurrentFolder, setRefreshNonce, openFolder } =
    React.useContext(TreeContext);

  const anyItemsChecked = checkedFolders.length + checkedFiles.length > 0;
  const onlyOneFolderChecked = checkedFolders.length === 1 && checkedFiles.length === 0;
  const onlyOneFileChecked = checkedFolders.length === 0 && checkedFiles.length === 1;

  const openSelectedFileInNewTab = async (file: FileModel) => {
    if (!file.versions) {
      return;
    }

    const fileVersionId = findLastFileVersion(file).id;

    const result = sharedFolderId
      ? await SkyMapAxiosServiceFactory.instance.createSharedFolderServiceV1().getFileVersionUrl({
          path: { sharedFolderId, fileVersionId },
          query: { contentDisposition: 'inline' },
        })
      : await SkyMapAxiosServiceFactory.instance.createFileVersionServiceV1().getFileVersionUrl({
          path: { fileVersionId },
          query: { contentDisposition: 'inline' },
        });

    window.open(result.data.url, '_blank');
  };

  // Temporary solutions to manage folder operations on sync folder.
  // Current MVP 1 solution will be based on one static folder that cannot be removed nor renamed if connection is active.
  //
  // Will be reworked when switching sync-folder or multiple sync folders is on the table.
  const isSyncActive = () => {
    return integrationStatus === IntegrationListStatus.Success && (integrations?.length ?? 0) > 0;
  };

  const isSyncFolder = (folderModel: FolderModel) => {
    return isSyncActive() && isDefined(folderModel.skyMapConnectFolder);
  };

  const userHasWriteAccess = hasWriteAccessToCurrentFolder(currentUser);

  const createFolderButton = userHasWriteAccess && !anyItemsChecked && (
    <SplitButton
      disabled={!actionBarEnabled}
      items={[
        {
          name: t('fileManager.actionBar.createFolder', { ns: 'components' }),
          icon: ['fad', 'plus'],
        },
        {
          name: t('fileManager.actionBar.createFolderStructure', { ns: 'components' }),
          icon: ['fad', 'folder-tree'],
        },
      ]}
      selectedIndex={0}
      onClick={(index) => {
        switch (index) {
          case 0:
            createFolderDialog.show();
            return;
          case 1:
            createFolderStructureDialog.show();
            return;
        }
      }}
    />
  );

  const uploadFilesButton = userHasWriteAccess && !anyItemsChecked && (
    <Button
      disabled={!actionBarEnabled}
      leftIcon={{ icon: ['fad', 'upload'] }}
      variant="text"
      onClick={() => uploadFilesDialog.show()}
    >
      {t('fileManager.actionBar.uploadFiles', { ns: 'components' })}
    </Button>
  );

  const shareFolderButton = permissionChecks.hasAccessToFeature(
    'feature:file_manager:share_folder',
    [hasAdminRights],
  ) &&
    selectedFolderId &&
    !anyItemsChecked && (
      <Button
        disabled={!actionBarEnabled}
        leftIcon={{ icon: ['fad', 'share-nodes'] }}
        variant="text"
        onClick={() => shareFolderDialog.show()}
      >
        {t('fileManager.actionBar.shareFolder', { ns: 'components' })}
      </Button>
    );

  // Checking write access should be refactored to Cerbos.
  // Adding in another ticket.
  // TODO: https://skymapinnovations.atlassian.net/browse/SK-4694
  const deleteButton = userHasWriteAccess && anyItemsChecked && (
    <Button
      disabled={!actionBarEnabled}
      leftIcon={{ icon: ['fad', 'trash'] }}
      variant="text"
      onClick={() => deleteDialog.show()}
    >
      {t('fileManager.actionBar.delete', { ns: 'components' })}
    </Button>
  );

  const addToSkyViewButtonEnabled =
    checkedFolders.length === 0 && checkedFiles.every(isLastFileVersionReadyForSkyView);
  const addToSkyView = userHasWriteAccess && checkedFiles.some(lastFileVersionHasDxfOrGlb) && (
    <Button
      disabled={!actionBarEnabled || !addToSkyViewButtonEnabled}
      leftIcon={{ icon: ['fad', 'drone'] }}
      title={
        addToSkyViewButtonEnabled
          ? undefined
          : (t('fileManager.actionBar.addToSkyView.fileSelectionInvalid', {
              ns: 'components',
              validSkyViewFileExtensions: validSkyViewFileExtensions.join(', '),
            }) as string)
      }
      variant="text"
      onClick={() => {
        addFilesToSkyViewDialog.show();
      }}
    >
      {t('fileManager.actionBar.addToSkyView.text', { ns: 'components' })}
    </Button>
  );

  const renameButton =
    (userHasWriteAccess && onlyOneFolderChecked && (
      <Button
        disabled={!actionBarEnabled || isSyncFolder(checkedFolders[0])}
        leftIcon={{ icon: ['fad', 'pencil'] }}
        variant="text"
        onClick={() => renameFolderDialog.show()}
      >
        {t('fileManager.actionBar.rename', { ns: 'components' })}
      </Button>
    )) ||
    (userHasWriteAccess && onlyOneFileChecked && (
      <Button
        disabled={!actionBarEnabled}
        leftIcon={{ icon: ['fad', 'pencil'] }}
        variant="text"
        onClick={() => renameFileDialog.show()}
      >
        {t('fileManager.actionBar.rename', { ns: 'components' })}
      </Button>
    ));

  const showPdfButton = onlyOneFileChecked &&
    checkedFiles[0]?.name &&
    extname(checkedFiles[0].name)?.toLowerCase() === '.pdf' && (
      <Button
        disabled={!actionBarEnabled}
        leftIcon={{ icon: ['fad', 'arrow-up-right-from-square'] }}
        variant="text"
        onClick={() => openSelectedFileInNewTab(checkedFiles[0])}
      >
        {t('fileManager.actionBar.showPdf', { ns: 'components' })}
      </Button>
    );

  const downloadButton =
    (onlyOneFileChecked && (
      <FileDownloadButton disabled={!actionBarEnabled} file={checkedFiles[0]} />
    )) ||
    ((checkedFolders.length > 0 || checkedFiles.length > 1) && !isIframedByExactStudio() && (
      <BatchDownloadButton
        disabled={!actionBarEnabled}
        files={checkedFiles}
        folders={checkedFolders}
      />
    ));

  const tagsButton = permissionChecks.hasAccessToFeature('feature:file_manager:tags', [
    hasAdminRights,
  ]) &&
    onlyOneFolderChecked &&
    !checkedFolders[0].parentId && (
      <Button
        disabled={!actionBarEnabled}
        leftIcon={{ icon: ['fad', 'tag'] }}
        variant="text"
        onClick={() => folderTagsDialog.show()}
      >
        {t('fileManager.actionBar.tags', { ns: 'components' })}
      </Button>
    );

  // Because dongles does not count towards integrations,
  // it is not possible to toggle connect folder if only dongles are connected to the project.
  // TODO: Return integration check when dongles are counted towards existing integrations
  // https://skymapinnovations.atlassian.net/browse/SK-4274
  const connectButton = (isSyncActive() || true) &&
    permissionChecks.hasAccessToFeature('feature:file_manager:connect_folder', [hasAdminRights]) &&
    onlyOneFolderChecked &&
    !checkedFolders[0].parentId && (
      <Button
        disabled={!actionBarEnabled}
        leftIcon={{ icon: ['fad', 'puzzle-piece'] }}
        variant="text"
        onClick={() => skyMapConnectDialog.show()}
      >
        Connect
      </Button>
    );

  return (
    <Component data-testid="action-bar">
      {createFolderButton}
      {uploadFilesButton}
      {!isIframedByExactStudio() && shareFolderButton}

      {downloadButton}
      {renameButton}
      {!isIframedByExactStudio() && tagsButton}
      {showPdfButton}
      {!isIframedByExactStudio() && connectButton}
      {deleteButton}
      {addToSkyView}

      {createFolderDialog.render(
        <CreateFolderDialog
          onClose={(canceled) => {
            createFolderDialog.hide();

            if (!canceled) {
              if (selectedFolderId) {
                openFolder(selectedFolderId);
              }

              setRefreshNonce();
            }
          }}
        />,
      )}

      {createFolderStructureDialog.render(
        <CreateFolderStructureDialog
          maxFileSizeBytes={1 * 1024 * 1024}
          onClose={(foldersAdded) => {
            createFolderStructureDialog.hide();

            if (foldersAdded) {
              setRefreshNonce();
            }
          }}
        />,
      )}

      {uploadFilesDialog.render(
        <UploadFilesDialog
          onClose={(filesUploaded: boolean) => {
            uploadFilesDialog.hide();

            if (filesUploaded) {
              setRefreshNonce();
            }
          }}
        />,
      )}

      {shareFolderDialog.render(
        <ShareFolderDialog
          onClose={(wasUpdated) => {
            shareFolderDialog.hide();

            if (wasUpdated) {
              setRefreshNonce();
            }
          }}
        />,
      )}

      {renameFolderDialog.render(
        <RenameFolderDialog
          folder={checkedFolders[0]}
          onClose={(canceled) => {
            renameFolderDialog.hide();

            if (!canceled) {
              setRefreshNonce();
            }
          }}
        />,
      )}

      {renameFileDialog.render(
        <RenameFileDialog
          file={checkedFiles[0]}
          onClose={(canceled) => {
            renameFileDialog.hide();

            if (!canceled) {
              setRefreshNonce();
            }
          }}
        />,
      )}

      {folderTagsDialog.render((pos) => (
        <FolderTagsDialog
          folder={checkedFolders[0]}
          onClose={(tagsUpdated) => {
            folderTagsDialog.hide();

            if (tagsUpdated) {
              setRefreshNonce();
            }
          }}
        />
      ))}

      {skyMapConnectDialog.render(
        <SkyMapConnectDialog
          folder={checkedFolders[0]}
          onClose={(action) => {
            skyMapConnectDialog.hide();

            if (action !== 'canceled') {
              setRefreshNonce();
            }
          }}
        />,
      )}

      {deleteDialog.render(
        <DeleteDialog
          files={checkedFiles}
          folders={checkedFolders}
          onClose={(result) => {
            deleteDialog.hide();

            // This refreshes the folder tree, that also the folder list view.
            //
            // todo: Update folder tree "content state" (whether folders has content or not)
            // todo: manually instead by passing information from the folder list view that no
            // todo: that last item file (including sub files for any folders) was removed. Then
            // todo: we can update folder "content state" without fetching folders from API.
            // todo: We also don't need to fetch files from API (as long as we display all items
            // todo: and not having pagination), but can instead remove item directly from state.
            if (result.succeeded.folderIds.length + result.succeeded.fileIds.length > 0) {
              setRefreshNonce();
            }

            // If selected file has been removed, unselect file.
            // This closes file info panel instantly.
            if (selectedFile && result.succeeded.fileIds.includes(selectedFile.id)) {
              setSelectedFile(undefined);
            }
          }}
        />,
      )}

      {addFilesToSkyViewDialog.render(
        <AddFilesToSkyViewDialog
          files={checkedFiles}
          onClose={(filesAdded) => {
            addFilesToSkyViewDialog.hide();

            if (filesAdded) {
              refreshFolderListView();
            }
          }}
        />,
      )}
    </Component>
  );
};

const Component = styled.div`
  background-color: #fafafa;
  padding: 0.5em;
  border-bottom: 1px solid #ddd;

  display: flex;
  flex-wrap: wrap;
  gap: 5px;

  // Min height set to keep action bar height when no items exist.
  // Matches height of tree folder project name area.
  // todo: Try to remove fixed height.
  min-height: 46px;
`;

ActionBar.styled = Component;

export { ActionBar };
