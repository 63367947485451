import React from 'react';
import styled, { css } from 'styled-components';

import { hasElements } from '../../../../js/utils/variables';
import { reset } from '../../../utils/styled-reset';
import { Icon } from '../../icon/icon';
import { Stack } from '../../stack/stack';
import { IntegrationItem } from '../state/integrations-state';

interface Props {
  item: IntegrationItem;
  selected: boolean;
  onClick: () => void;
}

const IntegrationListItem = (props: Props) => {
  return (
    <Component {...props} onClick={props.onClick}>
      <Stack direction="row" justifyContent="space-between" spacing={0.5}>
        <IntegrationName className="notranslate">{props.item.name}</IntegrationName>
        {hasElements(props.item.issues) && <Icon color="red" icon="circle-exclamation" />}
      </Stack>
      <CompanyName className="notranslate">{props.item.provider}</CompanyName>
    </Component>
  );
};

const IntegrationName = styled.span`
  display: block;
  font-weight: 500;
`;
const CompanyName = styled.span`
  font-weight: 300;
`;

const Component = styled.li<Pick<Props, 'selected'>>`
  ${reset}

  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.2em;

  width: 100%;
  font-size: 14px;
  padding: 0.7em;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? props.theme.color.brand.light : '#f5f5f5')};

  border: 1px solid ${(props) => props.theme.color.gray.light};
  border-left: 4px solid ${(props) => props.theme.color.brand.dark};
  &:not(:first-child) {
    border-top: none;
  }

  ${(props) =>
    !props.selected &&
    css`
      &:hover {
        background-color: #e5e5e5;
      }
    `}
`;

export { IntegrationListItem };
