import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Trans, useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

import { FolderModel } from '../../../../../typings/api/skymap/rest/v1/.common';
import { formattedDateTime } from '../../../../js/utils/dateUtils';
import { toHumanReadableFileSize } from '../../../../js/utils/io/file';
import { AppContext } from '../../../state/app-state';
import { ProjectContext } from '../../../state/project-state';
import { nameof } from '../../../utils/nameof';
import { ToTransientProps, truncateText } from '../../../utils/styled-helpers';
import { CheckBox } from '../../check-box/check-box';
import { Icon } from '../../icon/icon';
import { IntegrationsContext } from '../../integrations/state/integrations-state';
import { translateDbTagName } from '../../project-user-tags/project-user-tags';
import { Table } from '../../table/table';
import { combineFoldersFiles, DraggableItem } from '../move-file-folder-helper';
import { FolderListViewContext } from '../state/folder-list-view-state';
import { TreeContext } from '../state/folder-tree-state';
import { BlockIcon } from './block-icon';
import { DragPreview } from './drag-preview';
import { DropIcon } from './drop-icon';
import { FolderIcon } from './folder-icon';
import { WrapWithColor } from './wrap-with-color';

interface Props {
  folder: FolderModel;
  displayCheckboxCell?: boolean;
  displayCheckbox?: boolean;
  checked: boolean;
  displayFolderTags: boolean;
  displayCanBeAddedToSkyViewColumn?: boolean;
  displaySkyMapConnectColumn: boolean;
  displaySharedColumn: boolean;
  displayTagColumn: boolean;
  checkVolatileName: boolean;
  onClick?: (folder: FolderModel) => void;
  onSelectionChanged?: (selected: boolean, folder: FolderModel) => void;
  moveFileDialog?: { show: () => void; hide: () => void; visible: boolean };
  onTargetFolderChanged?: (targetFolder: { id: string; name: string }) => void;
}

const FolderRow = React.memo(
  ({ displayCheckboxCell = true, displayCheckbox = true, ...props }: Props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const checkBoxRef = React.useRef<HTMLInputElement>(null);
    const { currentUser } = React.useContext(AppContext);
    const { hasWriteAccessToFolder, hasWriteAccessToCurrentFolder } = React.useContext(TreeContext);
    const { sharedFolderId } = React.useContext(ProjectContext);
    const { extractVolatileCharacters } = React.useContext(IntegrationsContext);
    const { onSelectionChanged: propsOnSelectionChanged } = props;

    const onCheckBoxCellClick = () => {
      checkBoxRef.current?.click();
    };

    const { checkedFiles, checkedFolders } = React.useContext(FolderListViewContext);

    const [{ isDragging, item: draggedItem }, dragRef, dragPreviewRef] = useDrag(
      () => ({
        type: 'Folder',
        item: {
          selectedEntities: combineFoldersFiles(checkedFolders, checkedFiles),
          dialog: props.moveFileDialog,
          setTargetFolder: props.onTargetFolderChanged,
        } as DraggableItem,
        collect: (monitor) => ({
          opacity: monitor.isDragging() ? 0.5 : 1,
          isDragging: monitor.isDragging(),
          item: monitor.getItem(),
        }),
        canDrag: () => hasWriteAccessToCurrentFolder(currentUser),
      }),
      [checkedFiles, checkedFolders],
    );

    const [{ isDraggingOver }, dropRef] = useDrop({
      accept: ['File', 'Folder'],
      drop: (item: DraggableItem, monitor) => {
        item.setTargetFolder({ id: props.folder.id, name: props.folder.name });
        item.dialog.show();
      },
      collect: (monitor) => ({
        isDraggingOver: monitor.isOver(),
      }),
      canDrop: () => hasWriteAccessToFolder(currentUser, props.folder),
    });

    const onCheckBoxChange = React.useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        propsOnSelectionChanged?.(e.target.checked, props.folder);
      },
      [props.folder, propsOnSelectionChanged],
    );

    const resolveIcon = () => {
      return isDraggingOver ? (
        hasWriteAccessToFolder(currentUser, props.folder) ? (
          <DropIcon />
        ) : (
          <BlockIcon />
        )
      ) : (
        <FolderIcon folder={props.folder} opened={false} />
      );
    };

    const volatileCharacter = extractVolatileCharacters(props.folder.name);

    const totalSize = props.folder.sizeFiles + props.folder.sizeFilesSubFolders;
    const totalNumFiles = props.folder.numFiles + props.folder.numFilesSubFolders;

    return (
      <>
        <DragPreview
          draggedItems={draggedItem?.selectedEntities}
          isDragging={isDragging}
          item={{ folder: props.folder }}
          ref={dragPreviewRef}
        />
        <Component
          $backgroundColor={isDraggingOver ? theme.color.brand.lightest : undefined}
          checked={props.checked}
          ref={props.checked ? dragRef : dropRef}
          onClick={() => props.onClick?.(props.folder)}
        >
          {displayCheckboxCell && !sharedFolderId && (
            <Table.Cell
              padding={{ left: 0.7, right: 0.7 }}
              onClick={displayCheckbox ? onCheckBoxCellClick : undefined}
              onHoverBackgroundColor={
                displayCheckbox ? (props.checked ? theme.color.brand.medium : '#F0F0F0') : undefined
              }
            >
              {displayCheckbox && (
                <CheckBox checked={props.checked} ref={checkBoxRef} onChange={onCheckBoxChange} />
              )}
            </Table.Cell>
          )}

          <Table.Cell padding={{ left: 0.4 }}>
            <NameContainer>
              {resolveIcon()}
              <div>
                <Name>{props.folder.name}</Name>

                {props.displayFolderTags && (
                  <Tags>
                    {props.folder.tags?.map((tag) => (
                      <span key={tag.id}>{translateDbTagName(tag.name)}</span>
                    ))}
                  </Tags>
                )}
              </div>
            </NameContainer>
          </Table.Cell>

          {props.displayCanBeAddedToSkyViewColumn && <Table.Cell shrink={true} />}

          {props.checkVolatileName && (
            <Table.Cell wrapping={'none'}>
              {volatileCharacter.length > 0 && (
                <Icon
                  icon={['fad', 'exclamation-triangle']}
                  style={{ color: theme.color.red }}
                  swapOpacity={true}
                  title={t('fileManager.folderListView.invalidSkyMapConnectCharacters', {
                    ns: 'components',
                    volatileCharacters: volatileCharacter.join(' '),
                  }).replaceAll('\\n', '\n')}
                />
              )}
            </Table.Cell>
          )}

          {props.displaySkyMapConnectColumn && (
            <Table.Cell wrapping={'none'}>
              {props.folder.skyMapConnectFolder && <Icon icon={['fal', 'check']} />}
            </Table.Cell>
          )}

          {props.displaySharedColumn && (
            <Table.Cell wrapping={'none'}>
              {props.folder.sharedFolder && <Icon icon={['fal', 'check']} />}
            </Table.Cell>
          )}

          {props.displayTagColumn && (
            <Table.Cell wrapping={'none'}>
              {(props.folder.tags ?? []).length > 0 && <Icon icon={['fal', 'check']} />}
            </Table.Cell>
          )}

          <Table.Cell wrapping={'none'}>
            {t('fileManager.folderListView.folderType', { ns: 'components' })}
          </Table.Cell>

          <Table.Cell monospaced="montserrat" textAlign="right" wrapping={'none'}>
            {toHumanReadableFileSize(totalSize)}
          </Table.Cell>

          <Table.Cell wrapping={'none'}>
            <Trans
              components={{ color: <WrapWithColor /> }}
              i18nKey="fileManager.folderListView.numFiles"
              ns="components"
              values={{ count: totalNumFiles }}
            />
          </Table.Cell>

          <Table.Cell monospaced="montserrat" wrapping={'none'}>
            {formattedDateTime(props.folder.updatedAt)}
          </Table.Cell>
        </Component>
      </>
    );
  },
);

FolderRow.displayName = nameof({ FolderRow });

const Component = styled(Table.Row)<ToTransientProps<{ backgroundColor?: string }>>`
  background-color: ${(props) => props.$backgroundColor};
`;

const NameContainer = styled.div`
  display: flex;
  gap: 0.75em;
  align-items: center;
`;

const Name = styled.span`
  ${truncateText(3)}
`;

const Tags = styled.div`
  ${truncateText(1)}

  margin-top: 0.2em;
  font-size: 0.8em;
  font-weight: 300;

  span:not(:last-child)::after {
    content: '|';
    margin: 0 0.4em;
    opacity: 0.5;
  }
`;

export { FolderRow };
