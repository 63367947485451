import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import styled, { css } from 'styled-components';

import { Button, ButtonStyled } from '../../../button/button';
import { InfoBox, InfoBoxProps } from '../../../info-box/info-box';

export interface DisabledState {
  title: string;
  description?: string;
  color: InfoBoxProps['color'];
  icon: IconProp;
}

interface Props {
  disabled?: DisabledState;
  logo: string;
  useBorderRadius?: boolean;
  title: string;
  description: React.ReactNode;
  onSelect: () => void;
}

const IntegrationCard = ({ useBorderRadius = true, ...props }: Props) => (
  <Component {...props}>
    <Logo src={props.logo} useBorderRadius={useBorderRadius} />

    <Content>
      <h4 className="notranslate">{props.title}</h4>
      <p>{props.description}</p>
    </Content>

    <Footer>
      {props.disabled ? (
        <InfoBox
          color={props.disabled.color}
          leftIcon={{ icon: props.disabled.icon }}
          title={props.disabled.title}
        >
          {props.disabled.description}
        </InfoBox>
      ) : (
        <Button color="primary" variant="contained" onClick={props.onSelect}>
          Lägg till
        </Button>
      )}
    </Footer>
  </Component>
);

const Component = styled.article<Pick<Props, 'disabled'>>`
  display: flex;
  flex-direction: column;
  gap: 1em;

  height: 320px;
  width: 250px;
  padding: 1em;

  align-items: center;
  text-align: center;

  pointer-events: all;
  border-radius: 5px;

  box-shadow:
    0 1px 3px rgb(0 0 0 / 12%),
    0 1px 2px rgb(0 0 0 / 24%);
  background-color: #fafafa;

  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        box-shadow:
          0 3px 6px rgba(0, 0, 0, 0.16),
          0 3px 6px rgba(0, 0, 0, 0.23);
        background-color: #f6f6f6;
      }
    `}
`;

const Logo = styled.img<Pick<Props, 'useBorderRadius'>>`
  height: 60px;
  max-width: 100%;
  max-height: 100%;
  ${(props) =>
    props.useBorderRadius &&
    css`
      border-radius: 1em;
    `}
`;

const Content = styled.div`
  flex: 1;

  h4 {
    font-size: 1.1rem;
  }

  p {
    line-height: 1.3em;
  }
`;

const Footer = styled.footer`
  width: 100%;
  display: flex;
  justify-content: center;

  ${InfoBox.styled} {
    background-color: white;
  }

  ${ButtonStyled} {
    min-width: 50%;
  }
`;

export { IntegrationCard };
