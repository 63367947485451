import styled from 'styled-components';

import { TextBoxStyled } from '../../text-box/text-box';

export const SectionTitle = styled.h5`
  margin: 0 0 1em 0;
  padding: 0.5em 0;
  border-bottom: 2px solid #555;
`;

// todo: Extract to component (use in upload files dialog for instance).
export const TextBoxWithRightButton = styled.div`
  display: flex;
  border: 1px solid #888;

  ${TextBoxStyled} {
    border: none;
  }

  // todo: Figure out why \${Button.styled} isn't working.
  button {
    border-left-color: #bbb;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;
