import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Icon, IconProps } from '../../../icon/icon';
import { AssetContext } from '../../state/asset-state';

type Props = {
  icons: React.ReactElement<IconProps>[];
};

const ExpandableIconsContainer = (props: Props) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(false);
  const { isMouseInsideAsset } = React.useContext(AssetContext);

  // When mouse is no longer inside the asset, collapse additional icons.
  React.useEffect(() => {
    if (!isMouseInsideAsset) {
      setExpanded(false);
    }
  }, [isMouseInsideAsset]);

  return (
    <Component>
      {expanded ? (
        props.icons
      ) : (
        <Icon
          fixedWidth={true}
          icon={['fal', 'ellipsis-vertical']}
          stopPropagationOnClick={true}
          title={t('common.assetHeaderIcons.showMoreIcons', { ns: 'skyviewAssetMenu' })}
          onClick={() => setExpanded(true)}
          onHoverStyle={{ icon: ['fad', 'ellipsis-vertical'] }}
        />
      )}
    </Component>
  );
};

const Component = styled.div`
  display: flex;
  gap: 0.5em;
  align-items: center;
`;

ExpandableIconsContainer.styled = Component;

export { ExpandableIconsContainer };
