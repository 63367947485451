import React, { useState } from 'react';
import styled from 'styled-components';

import { Switch } from '../../switch/switch';

type Props = {
  title: string;
  children: React.ReactNode;

  onToggle: (state: boolean) => void;
};

const AttributeContainer = ({ title, children, onToggle }: Props) => {
  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
    onToggle(!isToggled);
  };

  return (
    <Container>
      <Header>
        <Title>{title}</Title>

        <Switch checked={isToggled} onChange={handleToggle} />
      </Header>
      <Content isToggled={isToggled}>{children}</Content>
    </Container>
  );
};

const Container = styled.div`
  border: 1px solid #ddd;
  padding: 10px;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 1em;
`;

const Content = styled.div<{ isToggled: boolean }>`
  overflow: hidden;
  max-height: ${({ isToggled }) => (isToggled ? '1000px' : '0px')};
  transition: all 1s;
`;

export default AttributeContainer;
