import { t } from 'i18next';
import { Map } from 'ol';
import React, { useState } from 'react';
import styled from 'styled-components';

import { publish, SubscriptionTopic } from '../../../js/messaging/pubsub';
import { isDefined } from '../../../js/utils/variables';
import { WmsState } from '../../../js/viewer/wms-state';
import { useSubscribe } from '../../hooks/use-subscribe';

export const useWmsAttributions = () => {
  const [attributions, setAttributions] = useState(WmsState.instance.attributions);
  useSubscribe(SubscriptionTopic.WmsAttributionsChanged, (data) => setAttributions(data));
  return attributions;
};

export const WmsAttributions = () => {
  const attributions = useWmsAttributions();

  if (attributions.length === 0) {
    return null;
  }

  // Setting the InnerHTML is generally not recommended. But we trust the WMS sources we have currently.
  // But if we ever let the user pick their own WMS source this becomes a security issue.
  return (
    <Component>
      {attributions.map((item, idx) => (
        <Attribution dangerouslySetInnerHTML={{ __html: item }} key={idx} />
      ))}
    </Component>
  );
};

/**
 * Extracts the attributions for all the added layers and publishes it with PubSub.
 */
export function updateWmsAttributions(olMap: Map | null) {
  try {
    if (!isDefined(olMap)) {
      WmsState.instance.attributions = [];
      publish(SubscriptionTopic.WmsAttributionsChanged, []);
      return;
    }

    const viewState = olMap.getView().getState();
    const extent = olMap.getView().calculateExtent(olMap.getSize());

    const attributions = olMap
      .getAllLayers()
      .flatMap((x) => x.getSource()?.getAttributions())
      .filter(isDefined)
      .flatMap((getAttributions) => {
        const attributions = getAttributions({ viewState, extent });
        return Array.isArray(attributions) ? attributions : [attributions];
      });

    WmsState.instance.attributions = attributions;
    publish(SubscriptionTopic.WmsAttributionsChanged, attributions);
  } catch {
    publish(SubscriptionTopic.ToastrMessage, {
      message: t('wms.tool.failedToFetchAttributions', { ns: 'skyview' }),
      type: 'error',
    });
  }
}

const Attribution = styled.div`
  color: white;

  // Adust the color for hyperlinks agains the dark background
  a {
    &:link {
      color: #11f7ff;
    }
    &:visited {
      color: #ea84ec;
    }
  }
`;

const Component = styled.div`
  position: absolute;
  left: 0.4em;
  top: 0.4em;

  min-width: max-content;
  display: flex;
  font-size: 0.5em;
  flex-direction: row;
  gap: 0.5em;
  padding: 0.2em;
  background: rgba(0, 0, 0, 0.75);
`;
