import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import styled, { css } from 'styled-components';

import { isDefined } from '../../../../js/utils/variables';
import { nameof } from '../../../utils/nameof';
import { Icon } from '../../icon/icon';
import { Tooltip } from '../../tooltip/tooltip';

export type ToolIconProps = {
  active: boolean;
  /**
   * @default false
   */
  disabled?: boolean;
  callback?: () => void | Promise<void>;
  tooltip?: {
    text: React.ReactNode;
    title?: string;
  };
  fixedWidth?: boolean;
} & ({ icon: IconProp } | { icons: IconProp[] });

const ToolIcon = React.forwardRef<SVGSVGElement, ToolIconProps>(
  ({ disabled = false, fixedWidth = false, ...props }: ToolIconProps, ref) => {
    const icons = 'icons' in props ? props.icons : [props.icon];

    const result = (
      <Styled
        $active={props.active}
        disabled={disabled}
        style={{ position: 'relative' }}
        onClick={disabled || !isDefined(props.callback) ? undefined : props.callback}
      >
        {icons.map((x, index) => (
          <IconStyled
            $active={props.active}
            data-active={props.active}
            data-disabled={disabled}
            disabled={disabled}
            fixedWidth={fixedWidth}
            key={index}
            ref={ref}
            size="2x"
            {...props}
            icon={x}
          />
        ))}
      </Styled>
    );

    return isDefined(props.tooltip) ? (
      <Tooltip content={props.tooltip.text} mainAxisOffset={20} title={props.tooltip.title}>
        {result}
      </Tooltip>
    ) : (
      result
    );
  },
);

ToolIcon.displayName = nameof({ ToolIcon });

const Styled = styled.span<{ $active: boolean; disabled: boolean }>`
  display: grid;
  place-items: center;
  > * {
    grid-area: 1 / 1;
  }

  padding: 5px;
  color: ${(props) => props.theme.color.gray.darkest};
  border-radius: 4px;
  cursor: pointer;

  ${(props) =>
    props.$active &&
    css`
      color: white;
      background-color: ${(props) => props.theme.color.gray.darkest};
    `}

  ${(props) =>
    !props.disabled &&
    !props.$active &&
    css`
      &:hover {
        background-color: ${(props) => props.theme.color.gray.light};
      }
    `}
  
  &[disabled] {
    color: ${(props) => props.theme.color.gray.medium};
    cursor: default;
  }
`;

const IconStyled = styled(Icon)<{ $active: boolean }>``;

export { ToolIcon };
