import React from 'react';
import styled from 'styled-components';

import { getOneMomentText } from '../../../../../locales/temp';
import { Button } from '../../../button/button';
import { Dialog } from '../../../dialog/dialog';
import { InfoBox } from '../../../info-box/info-box';
import {
  DonglesIntegrationItem,
  DroneHarmonyIntegrationItem,
  IntegrationsContext,
  LeicaIntegrationItem,
  XsiteIntegrationItem,
} from '../../state/integrations-state';
import { getDeleteIntegrationDialogService } from './delete-integration-dialog-factory';

enum DeleteIntegrationStatus {
  Idle,
  Pending,
  Error,
}

interface Props {
  item:
    | XsiteIntegrationItem
    | LeicaIntegrationItem
    | DonglesIntegrationItem
    | DroneHarmonyIntegrationItem;
  onClose: () => void;
}

const DeleteIntegrationDialog = (props: Props) => {
  const { removeIntegration } = React.useContext(IntegrationsContext);
  const [status, setStatus] = React.useState(DeleteIntegrationStatus.Idle);
  const deleteIntegrationService = getDeleteIntegrationDialogService(props.item);

  const cancelCloseButtonClick = () => {
    props.onClose();
  };

  const deleteIntegration = async () => {
    try {
      setStatus(DeleteIntegrationStatus.Pending);

      await deleteIntegrationService?.deleteIntegration();

      removeIntegration(props.item);
      cancelCloseButtonClick();
    } catch {
      setStatus(DeleteIntegrationStatus.Error);
    }
  };

  return (
    <Dialog
      closeIcon={status !== DeleteIntegrationStatus.Pending}
      closeOnDimmerClick={true}
      useGoogleTranslate={true}
      width={500}
      onClose={cancelCloseButtonClick}
    >
      {{
        header: 'Ta bort',
        content: (
          <Content>
            <Text>
              Är du säker på att du vill ta bort integrationen mellan projektet och{' '}
              <strong>{props.item.name}</strong> ({props.item.provider}) ?
            </Text>

            {status === DeleteIntegrationStatus.Error ? (
              <InfoBox color="red" leftIcon={{ icon: ['fad', 'exclamation'] }}>
                Det gick inte att ta bort integrationen.
                <br />
                <br />
                Kontakta vår support om felet kvarstår.
              </InfoBox>
            ) : status === DeleteIntegrationStatus.Pending ? (
              <InfoBox color="yellow" leftIcon={{ icon: 'spinner', spin: true }} topMargin={true}>
                {getOneMomentText()}
              </InfoBox>
            ) : (
              <>
                <InfoBox color="red" leftIcon={{ icon: ['fad', 'exclamation'] }}>
                  Observera att synkronisering av filer mellan plattformarna kommer att upphöra.
                  Existerande filer ligger kvar efter att integrationen tagits bort.
                </InfoBox>
                {props.item.key === 'dongles' && (
                  <InfoBox color="red" leftIcon={{ icon: ['fad', 'exclamation'] }}>
                    Observera att alla registrerade enheter kommer att avregistreras.
                  </InfoBox>
                )}
              </>
            )}
          </Content>
        ),
        footer: {
          right: (
            <>
              <Button
                color="error"
                loading={status === DeleteIntegrationStatus.Pending}
                variant="contained"
                onClick={deleteIntegration}
              >
                Ta bort
              </Button>
              <Button
                disabled={status === DeleteIntegrationStatus.Pending}
                variant="text"
                onClick={cancelCloseButtonClick}
              >
                Stäng
              </Button>
            </>
          ),
        },
      }}
    </Dialog>
  );
};

const Text = styled.span<{ topMargin?: boolean; bottomMargin?: boolean }>`
  display: block;
  line-height: 1.3em;

  margin-top: ${(props) => (props.topMargin ? '0.5em' : undefined)};
  margin-bottom: ${(props) => (props.bottomMargin ? '0.5em' : undefined)};

  strong {
    font-weight: 500;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

export { DeleteIntegrationDialog };
