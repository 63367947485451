import type * as CSS from 'csstype';
import React from 'react';
import styled, { css } from 'styled-components';

import { Props as TableCellProps, TableCell } from './table-cell';

interface Props extends TableCellProps {
  whiteSpace?: CSS.Properties['whiteSpace'];
  fontWeight?: CSS.Properties['fontWeight'];
  /**
   * @default true
   */
  borderBottom?: boolean;
  width?: CSS.Properties['width'];
}

const TableHeaderCell = ({ borderBottom = true, backgroundColor = 'white', ...props }: Props) => {
  return (
    <Component as="th" {...{ borderBottom, backgroundColor, ...props }} clickable={!!props.onClick}>
      {props.children}
    </Component>
  );
};

const Component = styled(TableCell.styled)<
  Pick<Props, 'whiteSpace' | 'borderBottom' | 'fontWeight' | 'width'>
>`
  position: sticky;
  top: 0;
  z-index: 1;
  text-align: left;
  font-weight: 500;

  width: ${(props) => props.width};

  ${(props) =>
    props.fontWeight &&
    css`
      font-weight: ${props.fontWeight};
    `}

  ${(props) =>
    props.borderBottom &&
    css`
      border-bottom: 2px solid #555;
    `}

  ${(props) =>
    props.whiteSpace &&
    css`
      white-space: ${props.whiteSpace};
    `}
`;

export { Props as HeaderCellProps, TableHeaderCell };
