import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { NoTranslateSpan } from '../no-translate/no-translate';
import LogotypeWhite from './powered_by_skymap_logo_white.png';

type Props = {
  position: 'relative-right' | 'absolute-bottom-right';
  dx?: number;
  dy?: number;
};

const PoweredBy = (props: Props) => {
  // Don't render component if default theme.
  if (document.documentElement.getAttribute('data-theme') === 'default') {
    return null;
  }

  return (
    <Component dx={props.dx} dy={props.dy} position={props.position}>
      <NoTranslateSpan>POWERED BY</NoTranslateSpan>
      <img src={LogotypeWhite} />
    </Component>
  );
};

const Component = styled.div<{
  position: Props['position'];
  dx: Props['dx'];
  dy: Props['dy'];
}>`
  opacity: 0.4;
  font-size: 10px;
  color: #fff;

  img {
    height: 20px;
    margin-left: 5px;
  }

  // Position
  ${(props) =>
    props.position === 'relative-right' &&
    css`
      text-align: right;
    `}
  ${(props) =>
    props.position === 'absolute-bottom-right' &&
    css<Props>`
      position: absolute;
      right: ${(props) => props.dx ?? 0}px;
      bottom: ${(props) => props.dy ?? 0}px;
    `}
`;

PoweredBy.propTypes = {
  position: PropTypes.string.isRequired,
  dx: PropTypes.number,
  dy: PropTypes.number,
};

export { PoweredBy };
