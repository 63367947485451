import PubSub from 'pubsub-js';
import React from 'react';
import styled from 'styled-components';

import { DeleteGeodataParameters } from '../../../../../typings/api/skymap/rest/v0/geodata';
import { SkyMapAxiosServiceFactory } from '../../../../js/services/axios/skymap-axios-service-factory';
import { RequestParamsToApiPaths } from '../../../../js/utils/typescript-utils';
import { useErrorHandling } from '../../../hooks/use-error-handling';
import { Button } from '../../button/button';
import { Dialog } from '../../dialog/dialog';
import { Icon } from '../../icon/icon';
import { InfoBox } from '../../info-box/info-box';
import { TextBox } from '../../text-box/text-box';

interface Props {
  item: { id: string; name: string };
  onClose: (wasDeleted: boolean) => void;
}

enum Status {
  Idle,
  DryRunInProgress,
  Pending,
  Error,
}

const DeleteGeodataDialog = (props: Props) => {
  const [status, setStatus] = React.useState(Status.DryRunInProgress);
  const [confirmName, setConfirmName] = React.useState<string>('');
  const { handleError, buildErrorList, includeErrorPaths } =
    useErrorHandling<RequestParamsToApiPaths<DeleteGeodataParameters>>();

  React.useEffect(() => {
    const dryRun = async () => {
      try {
        // TODO: When moving from API 0 to API 1, remove X-DRY-RUN and let client determine
        // TODO: when to call the delete function. Check if connected to timeline with other
        // TODO: endpoints.
        await SkyMapAxiosServiceFactory.instance.createGeodataServiceV0().deleteGeodata({
          headers: { 'x-dry-run': true },
          path: { geodataId: props.item.id },
        });

        setStatus(Status.Idle);
      } catch (err) {
        handleError(err);
        setStatus(Status.Error);
      }
    };

    void dryRun();
  }, [props, handleError]);

  const onDelete = async () => {
    setStatus(Status.Pending);

    try {
      // TODO: When moving from API 0 to API 1, remove X-DRY-RUN and let client determine
      // TODO: when to call the delete function. Check if connected to timeline with other
      // TODO: endpoints.
      await SkyMapAxiosServiceFactory.instance.createGeodataServiceV0().deleteGeodata({
        headers: { 'x-dry-run': false },
        path: { geodataId: props.item.id },
      });

      PubSub.publish('notify', { code: 'succ0503', data: [props.item.name] });
      props.onClose(true);
    } catch (err: unknown) {
      PubSub.publish('notify', { code: 'GEOerr009' });
      handleError(err);
      setStatus(Status.Error);
    }
  };

  return (
    <>
      <Dialog
        closeIcon={status !== Status.Pending}
        closeOnDimmerClick={status !== Status.Pending}
        useGoogleTranslate={true}
        width={500}
        onClose={() => props.onClose(false)}
      >
        {{
          header: 'Bekräfta borttagning',
          content: (
            <Content>
              <Text topMargin={false}>
                Vill du ta bort molnprocesseringen <b>{props.item.name}</b>?
              </Text>
              {status === Status.DryRunInProgress && (
                <PendingMessage color="yellow">
                  <Icon icon="spinner" spin={true} />
                  <Text>Ett ögonblick...</Text>
                </PendingMessage>
              )}
              {status !== Status.DryRunInProgress && status !== Status.Error && (
                <InfoBox color="yellow">
                  <Text>
                    Skriv <strong>{props.item.name}</strong> för att att bekräfta
                  </Text>
                  <TextBox
                    disabled={status === Status.Pending}
                    value={confirmName}
                    onChange={(e) => setConfirmName(e.target.value)}
                  />
                </InfoBox>
              )}

              {buildErrorList({
                filter: includeErrorPaths(['path.geodataId']),
                defaultMessage: 'Ett fel uppstod. Molnprocesseringen togs ej bort.',
              })}
            </Content>
          ),
          footer: {
            right: (
              <>
                <Button
                  color="error"
                  disabled={status !== Status.Idle || props.item.name !== confirmName}
                  loading={status === Status.Pending}
                  variant="contained"
                  onClick={onDelete}
                >
                  Ta bort
                </Button>

                <Button
                  disabled={status === Status.Pending}
                  variant="text"
                  onClick={() => props.onClose(false)}
                >
                  Stäng
                </Button>
              </>
            ),
          },
        }}
      </Dialog>
    </>
  );
};

const Text = styled.span<{ topMargin?: boolean; bottomMargin?: boolean }>`
  display: block;

  strong {
    font-weight: 600;
  }

  margin-top: ${(props) => (props.topMargin ? '0.5em' : undefined)};
  margin-bottom: ${(props) => (props.bottomMargin ? '0.5em' : undefined)};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const PendingMessage = styled(InfoBox)`
  div {
    display: flex;
    flex-direction: row;
    gap: 1em;
  }
`;

export { DeleteGeodataDialog };
