import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { iiafe } from '../../../../utilities/async';
import { SkyMapAxiosServiceFactory } from '../../../js/services/axios/skymap-axios-service-factory';
import { useParams } from '../../hooks/use-params';
import { useRouter } from '../../hooks/use-router';
import { reset } from '../../utils/styled-reset';
import { Button } from '../button/button';
import { FullPageBox } from '../full-page-box/full-page-box';
import { InfoBox } from '../info-box/info-box';
import { Stack } from '../stack/stack';
import { VerifyForm } from './verify-form';

enum Status {
  Idle,
  InProgress,
  TokenFound,
  Error,
}

const VerifyAccount = () => {
  const { t } = useTranslation();
  const [status, setStatus] = React.useState(Status.Idle);
  const { goToLoginPage } = useRouter();
  const { token, company } = useParams();

  React.useEffect(() => {
    iiafe(async () => {
      try {
        setStatus(Status.InProgress);

        if (company === 'true') {
          const companyService = SkyMapAxiosServiceFactory.instance.createCompanyServiceV0();
          await companyService.headRegistrationToken({
            path: {
              token: token,
            },
          });
        } else {
          const userService = SkyMapAxiosServiceFactory.instance.createUserServiceV0();
          const { data: isVerified } = await userService.isVerified({
            path: {
              token: token,
            },
          });

          if (isVerified) {
            throw new Error('User is already verified.');
          }
        }
        setStatus(Status.TokenFound);
      } catch (err) {
        setStatus(Status.Error);
      }
    });
  }, [company, token]);

  const render = () => {
    switch (status) {
      case Status.Error:
        return (
          <Stack direction="column" spacing={2}>
            <InfoBox color="red" leftIcon={{ icon: ['fad', 'exclamation'] }}>
              {t('verifyAccount.registrationTokenNotFound', { ns: 'components' })}
            </InfoBox>
            <Button
              alignSelf="flex-end"
              color="primary"
              variant="contained"
              onClick={goToLoginPage}
            >
              {t('verifyAccount.goToLoginPageButton', { ns: 'components' })}
            </Button>
          </Stack>
        );
      case Status.Idle:
      case Status.InProgress:
        return (
          <InfoBox color="yellow" leftIcon={{ icon: ['fad', 'spinner'], spin: true }}>
            {t('oneMoment', { ns: 'common' })}
          </InfoBox>
        );
      case Status.TokenFound:
        return <VerifyForm token={token} userCreatedViaRegisterCompany={company === 'true'} />;
    }
  };

  return (
    <FullPageBox width={'500px'}>
      <Component>
        <Stack direction="column" spacing={1}>
          <Header>{t('verifyAccount.header', { ns: 'components' })}</Header>
          {render()}
        </Stack>
      </Component>
    </FullPageBox>
  );
};

const Component = styled.div`
  ${reset}
  font-size: 14px;
`;

const Header = styled.h1`
  ${reset}
  font-weight: 400;
  font-size: 1.3em;
`;

VerifyAccount.propTypes = {
  wrapWithLanguageProvider: PropTypes.any,
};

export { VerifyAccount };
