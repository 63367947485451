import type * as CSS from 'csstype';
import React from 'react';
import styled, { css } from 'styled-components';
import { v4 } from 'uuid';

import { nameof } from '../../utils/nameof';
import { Icon } from '../icon/icon';
import { Stack } from '../stack/stack';
import { Tooltip } from '../tooltip/tooltip';

interface Props {
  children:
    | React.ReactNode
    | ((
        /**
         * Use this function to generate a value to use as ID for the form element. When <label> is
         * clicked on this element is focused.
         */
        formElementId: () => string,
      ) => React.ReactNode);
  // Required optional as this is passed in by Styled Components when using the styled(Component)
  // notation. Class name must be attached to the Styled Component in order for styles in
  // styled(Component)`<styles>` to be applied.
  className?: string;
  text: NonNullable<React.ReactNode>;
  noWrap?: boolean;
  fontSize?: CSS.Properties['fontSize'];
  fontWeight?: CSS.Properties['fontWeight'];
  color?: CSS.Properties['color'];
  required?: boolean;
  infoTooltip?: React.ReactNode;
}

const LabelledContainer = ({ required = false, ...props }: Props) => {
  // If children function formElementId() is executed, <label for> is used.
  // Otherwise, <label id> is used along with content 'aria-labelledby'.
  //
  // Doing this, we can use `getByLabelText(...).toHaveTextContent(...) in tests.
  //
  // todo: Consider using <span> or something else, instead of <label> when content is not a form
  // todo: element.
  // todo: Add <CollapsableSectionGroup> hat is responsible for rendering these separators,
  // todo: instead of passing in a boolean to the section itself.
  let formElementId = React.useMemo<string | undefined>(() => undefined, []);
  let labelledById = React.useMemo<string | undefined>(v4, []);

  const content =
    typeof props.children === 'function'
      ? props.children(() => {
          formElementId = v4();
          labelledById = undefined;
          return formElementId;
        })
      : props.children;

  return (
    <Component
      className={props.className}
      color={props.color}
      fontSize={props.fontSize}
      fontWeight={props.fontWeight}
      noWrap={props.noWrap}
    >
      <Stack spacing={0.25}>
        <Label htmlFor={formElementId} id={labelledById}>
          {props.text}
          {required && <Asterix aria-hidden={true}>*</Asterix>}
          {props.infoTooltip && (
            <Tooltip content={props.infoTooltip}>
              <Icon color="#00a9e0" icon={['fas', 'circle-info']} style={{ marginLeft: '1em' }} />
            </Tooltip>
          )}
        </Label>
        <Content aria-labelledby={labelledById}>{content}</Content>
      </Stack>
    </Component>
  );
};

LabelledContainer.displayName = nameof({ LabelledContainer });

const Component = styled.div<{
  noWrap?: boolean;
  fontSize?: CSS.Properties['fontSize'];
  fontWeight?: CSS.Properties['fontWeight'];
  color?: CSS.Properties['color'];
}>`
  label {
    font-size: ${(props) => props.fontSize ?? '0.95em'};
    ${(props) =>
      props.noWrap &&
      css`
        white-space: nowrap;
      `}
    ${(props) =>
      props.fontWeight &&
      css`
        font-weight: ${props.fontWeight};
      `}
      ${(props) =>
      props.color &&
      css`
        color: ${props.color};
      `}
  }
`;

const Asterix = styled.span`
  color: crimson;
  margin-left: 0.2em;
`;

const Label = styled.label``;
const Content = styled.div``;

LabelledContainer.styled = Component;
LabelledContainer.styledLabel = Label;
LabelledContainer.styledContent = Content;

export { LabelledContainer };
