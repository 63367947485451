import { t } from 'i18next';
import React from 'react';
import styled, { css, useTheme } from 'styled-components';

import { GeodataModel } from '../../../../../typings/api/skymap/rest/v1/.common';
import { FeatureFlagsManager } from '../../../../js/core/feature-flags-manager';
import { UserStore } from '../../../../js/stores/user-store';
import { useDialog } from '../../../hooks/use-dialog';
import { reset } from '../../../utils/styled-reset';
import { Icon, IconStyled } from '../../icon/icon';
import { Stack } from '../../stack/stack';
import { translatedProcessType } from '../geodata';
import { DeleteGeodataDialog } from './delete-geodata-dialog';
import { goToGcpPage } from './geodata-list-content';

interface Props {
  item: GeodataModel;
  selected: boolean;
  onClick: () => void;
  onDeleteDialogClosed: (wasDeleted: boolean) => void;
}

const GeodataListItem = (props: Props) => {
  const theme = useTheme();
  const deleteGeodataDialog = useDialog();

  return (
    <Component selected={props.selected} onClick={props.onClick}>
      <div>
        <GeodataName>{props.item.name}</GeodataName>
        <Geodatatype>{translatedProcessType(props.item.processType)}</Geodatatype>
      </div>

      <Stack direction="row" spacing={0.5}>
        {(UserStore.instance.isOrganizationAdmin() ||
          props.item.processType === 'ODM' ||
          FeatureFlagsManager.instance.isEnabled('SK-5515-new-gcp-page')) && (
          <Icon
            color={theme.color.gray.dark}
            data-testid="settings-icon"
            icon={['fad', 'drone']}
            size="lg"
            title={t('goToGcpPage', { ns: 'cloudProcessing' })}
            onClick={() => goToGcpPage(props.item)}
            onHoverStyle={{ icon: ['fas', 'drone'] }}
          />
        )}

        <Icon
          color={theme.color.gray.dark}
          data-testid="delete-icon"
          icon={['fal', 'trash-can']}
          size="lg"
          title={t('delete', { ns: 'common' })}
          onClick={() => deleteGeodataDialog.show()}
          onHoverStyle={{ icon: ['fas', 'trash-can'] }}
        />
      </Stack>

      {deleteGeodataDialog.render(
        <DeleteGeodataDialog
          item={props.item}
          onClose={(wasDeleted) => {
            deleteGeodataDialog.hide();
            props.onDeleteDialogClosed(wasDeleted);
          }}
        />,
      )}
    </Component>
  );
};

const GeodataName = styled.span`
  display: block;
  font-weight: 500;
`;
const Geodatatype = styled.span`
  font-weight: 300;
`;

const Component = styled.li<Pick<Props, 'selected'>>`
  ${reset}

  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.2em;

  width: 100%;
  font-size: 14px;
  padding: 0.7em;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? props.theme.color.brand.light : '#f5f5f5')};

  border: 1px solid ${(props) => props.theme.color.gray.light};
  border-left: 4px solid ${(props) => props.theme.color.brand.dark};
  &:not(:first-child) {
    border-top: none;
  }

  ${(props) =>
    !props.selected &&
    css`
      &:not(:hover) {
        ${IconStyled} {
          display: none;
        }
      }

      &:hover {
        background-color: #e5e5e5;
      }
    `}
`;

export { GeodataListItem };
