import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import PubSub from 'pubsub-js';
import React from 'react';
import styled, { useTheme } from 'styled-components';

import { iiafe } from '../../../../../utilities/async';
import { fontAwesomeIconColorMap } from '../../../../js/utils/io/file';
import { isDefined } from '../../../../js/utils/variables';
import { Icon } from '../../icon/icon';

interface Props {
  title: string;
  items: ArtifactItem[];
}

export interface ArtifactItem {
  icon: FontAwesomeIconProps['icon'];
  title: string;
  url: string;
  downloadProcess?: (url: string) => Promise<void>;
}

const ArtifactFolder = (props: Props) => {
  const [expanded, setExpanded] = React.useState(false);
  const theme = useTheme();

  const downloadArtifact = (url: string) => {
    try {
      window.location.assign(url);
    } catch (err: unknown) {
      console.error(err);
      PubSub.publish('notify', { code: 'err1206' });
    }
  };

  return (
    <Component>
      <ListItem onClick={() => setExpanded(!expanded)}>
        {expanded ? (
          <ArrowIcon data-testid="arrow-down" fixedWidth={true} icon={['fas', 'angle-down']} />
        ) : (
          <ArrowIcon data-testid="arrow-right" fixedWidth={true} icon={['fas', 'angle-right']} />
        )}
        <Icon color={theme.color.gray.dark} icon={expanded ? 'folder-open' : 'folder'} />
        <span>{props.title}</span>
      </ListItem>

      <Content>
        {expanded &&
          props.items.map((x) => (
            <>
              <ListItem
                onClick={() => {
                  iiafe(async () =>
                    isDefined(x.downloadProcess)
                      ? await x.downloadProcess(x.url)
                      : downloadArtifact(x.url),
                  );
                }}
              >
                <Icon
                  color={fontAwesomeIconColorMap.get(x.icon as IconName) ?? theme.color.brand.dark}
                  fixedWidth={true}
                  icon={x.icon}
                />
                <span>{x.title}</span>
                <FloatRightIcon>
                  <Icon color={theme.color.gray.medium} icon={'download'} />
                </FloatRightIcon>
              </ListItem>
            </>
          ))}
      </Content>
    </Component>
  );
};

const Component = styled.div``;

const FloatRightIcon = styled.div`
  margin-left: auto;
  margin-right: 1em;
  display: none;
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  cursor: pointer;
  user-select: none;
  padding: 0.2em;

  &:hover {
    ${FloatRightIcon} {
      display: unset;
    }
    background-color: ${(props) => props.theme.color.gray.lightest};
  }

  &:active {
    background-color: ${(props) => props.theme.color.gray.medium};
  }
`;

const Content = styled.div`
  ${ListItem} {
    padding-left: 3.5em;
  }
`;

const ArrowIcon = styled(Icon)`
  position: relative;
  margin-left: 0.6em;
`;

export { ArtifactFolder };
