import { css } from 'styled-components';

/**
 * Truncates content of current container to the specified number of lines.
 * Trailing ellipsis (...) added if needed.
 */
export const truncateText = (maxLines: number) => css`
  display: -webkit-box;
  -webkit-line-clamp: ${maxLines};
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
`;

/**
 * From T, pick all of properties and add dollar sign as prefix to each key (transient props).
 */
export type ToTransientProps<T> = { [K in keyof T as K extends string ? `$${K}` : K]: T[K] };
