import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Coordinates } from '../../../../../../../js/viewer/utilities/math-helpers';
import { InfoBox } from '../../../../../info-box/info-box';
import { NoTranslateSpan } from '../../../../../no-translate/no-translate';
import { Stack } from '../../../../../stack/stack';
import { CollapsableSection } from '../../../components/collapsable-section';

type Props = { coordinateA?: Coordinates; coordinateB?: Coordinates };

export const ReferencePointSection = (props: Props) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(true);

  return (
    <CollapsableSection
      expanded={expanded}
      title={t('section.referencePoints.text', { ns: 'skyviewScale' })}
      onSetExpanded={(state) => setExpanded(state)}
    >
      <Stack spacing={0.2}>
        <InfoBox
          color="blue"
          leftIcon={{ icon: 'map-pin' }}
          title={t('section.referencePoints.pointAInfo.title', { ns: 'skyviewScale' })}
        >
          <Stack spacing={0.5}>
            {props.coordinateA ? (
              <>
                <NoTranslateSpan>
                  <strong>N:</strong> <Value>{props.coordinateA.northing.toFixed(2)}</Value>
                </NoTranslateSpan>
                <NoTranslateSpan>
                  <strong>E:</strong> <Value>{props.coordinateA.easting.toFixed(2)}</Value>
                </NoTranslateSpan>
              </>
            ) : (
              <PlaceHolder>
                {t('section.referencePoints.pointAInfo.text', { ns: 'skyviewScale' })}
              </PlaceHolder>
            )}
          </Stack>
        </InfoBox>

        <InfoBox
          color="green"
          leftIcon={{ icon: 'map-pin' }}
          title={t('section.referencePoints.pointBInfo.title', { ns: 'skyviewScale' })}
        >
          <Stack spacing={0.5}>
            {props.coordinateB ? (
              <>
                <NoTranslateSpan>
                  <strong>N:</strong> <Value>{props.coordinateB.northing.toFixed(2)}</Value>
                </NoTranslateSpan>
                <NoTranslateSpan>
                  <strong>E:</strong> <Value>{props.coordinateB.easting.toFixed(2)}</Value>
                </NoTranslateSpan>
              </>
            ) : (
              <PlaceHolder>
                {t('section.referencePoints.pointBInfo.text', { ns: 'skyviewScale' })}
              </PlaceHolder>
            )}
          </Stack>
        </InfoBox>
      </Stack>
    </CollapsableSection>
  );
};

const PlaceHolder = styled.span`
  color: ${(props) => props.theme.color.gray.dark};
  font-style: italic;
`;

const Value = styled.span`
  color: ${(props) => props.theme.color.gray.dark};
`;
