import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { includes } from '../../../js/utils/array-utils';
import { reset } from '../../utils/styled-reset';
import { Stack } from '../stack/stack';
import { FetchRequestTokenError } from './components/fetch-request-token-error';
import { Form } from './components/form';
import { NotFoundOrUsed } from './components/not-found-or-used';
import { OneMoment } from './components/one-moment';
import { usePasswordRequestTokenExistsQuery } from './hooks/use-password-request-token-exists-query';

const PasswordResetForm = () => {
  const { t } = useTranslation();
  const fetchToken = usePasswordRequestTokenExistsQuery();

  const renderContent = () => {
    if (fetchToken.isPending) {
      return <OneMoment />;
    }

    if (fetchToken.isError) {
      return <FetchRequestTokenError />;
    }

    if (includes(['USED', 'NOT_FOUND'], fetchToken.data.status)) {
      return <NotFoundOrUsed />;
    }

    return <Form />;
  };

  return (
    <Component>
      <Stack spacing={1.5}>
        <h1>{t('passwordResetForm.title', { ns: 'components' })}</h1>
        {renderContent()}
      </Stack>
    </Component>
  );
};

const Component = styled.div`
  ${reset}
  font-size: 14px;

  h1 {
    font-size: 1.2em;
    font-weight: 500;
    margin: 0;
  }
`;

export { PasswordResetForm };
