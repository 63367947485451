import type * as CSS from 'csstype';
import React, { ChangeEvent } from 'react';
import styled, { css } from 'styled-components';

import { isDefined } from '../../../js/utils/variables';
import { nameof } from '../../utils/nameof';
import { ToTransientProps } from '../../utils/styled-helpers';
import { InfoBox } from '../info-box/info-box';
import { Stack } from '../stack/stack';

interface Props {
  alignSelf?: CSS.Properties['alignSelf'];
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;

  /**
   * When undefined, checkbox is set to state indeterminate.
   */
  checked?: boolean;
  disabled?: boolean;
  title?: string;
  children?: React.ReactNode;
  errorMessage?: string;
  expandClickableArea?: boolean;
}

const CheckBox = React.forwardRef<HTMLInputElement, Props>(
  ({ expandClickableArea = false, ...props }, ref) => {
    const defaultRef = React.useRef<HTMLInputElement>(null);
    const resolvedRef = (ref as React.RefObject<HTMLInputElement>) || defaultRef;

    React.useEffect(() => {
      if (resolvedRef.current) {
        if (props.checked === undefined) {
          resolvedRef.current.indeterminate = true;
          resolvedRef.current.checked = false;
        } else {
          resolvedRef.current.indeterminate = false;
          resolvedRef.current.checked = props.checked;
        }
      }
    }, [props.checked, resolvedRef]);

    return (
      <Stack direction="column" spacing={0.5}>
        <Component
          $alignSelf={props.alignSelf}
          $errorMessage={props.errorMessage}
          $expandClickableArea={expandClickableArea}
          title={props.title}
        >
          <Stack alignItems={'center'} direction="row" spacing={0.5}>
            <input
              aria-invalid={isDefined(props.errorMessage)}
              disabled={props.disabled}
              ref={resolvedRef}
              type="checkbox"
              onChange={props.onChange}
              onClick={(event) => event.stopPropagation()}
            />

            {props.children && <Children disabled={props.disabled}>{props.children}</Children>}
          </Stack>
        </Component>
        {isDefined(props.errorMessage) && (
          <InfoBox color="red" fontSize="small" padding="small" width="fit-content">
            {props.errorMessage}
          </InfoBox>
        )}
      </Stack>
    );
  },
);

CheckBox.displayName = nameof({ CheckBox });

const Children = styled.div<{ disabled?: boolean }>`
  ${(props) =>
    props.disabled &&
    css`
      color: grey;
    `}
`;

const Component = styled.label<
  ToTransientProps<Pick<Props, 'errorMessage' | 'alignSelf' | 'expandClickableArea'>>
>`
  --border-radius: 1em;
  --size: 0.95em;

  align-self: ${(props) => props.$alignSelf} !important;
  gap: 0.5em;

  // Slight padding that makes it easier to click the checkbox
  ${(props) =>
    props.$expandClickableArea &&
    css`
      box-sizing: border-box;
      padding: 0.5em;
      margin: -0.5em;
    `}

  cursor: pointer;
  font-size: var(--size);

  // Optional text.
  span {
    // Just to vertically align text better.
    // Not exactly aligned with align-items: center.
    // todo: Try to remove this line.
    margin-bottom: 0.05em;
  }

  // Checkbox
  // Default apperance removed.
  input {
    all: revert;
    display: grid;
    place-content: center;
    flex-shrink: 0;

    position: relative;
    appearance: none;
    outline: 0;

    margin: 0;
    padding: 0;
    width: var(--size);
    height: var(--size);

    cursor: pointer;
  }

  // Applies to not checked and indeterminate.
  input:not(:checked) {
    border: 1px solid ${(props) => (isDefined(props.$errorMessage) ? '#b22222' : '#888')};
    border-radius: calc(0.2 * var(--border-radius));
    background-color: white;
  }

  input:not(:checked)::before {
    content: '';
    border-radius: calc(0.2 * var(--border-radius));
    background-color: white;
  }

  // Centered "dot" for indeterminate state.
  input:indeterminate {
    background-color: ${(props) => props.theme.color.gray.dark};
    box-shadow: 0 0 0 calc(0.15 * var(--size)) inset white;
  }

  // Background color for checked state.
  input:checked::before {
    content: '';
    position: absolute;
    display: block;
    background-color: ${(props) => props.theme.color.blue};
    inset: 0;
    border: 1px solid ${(props) => props.theme.color.blue};
    border-radius: calc(0.2 * var(--border-radius));
  }

  // Check icon for checked state.
  input:checked::after {
    content: '';
    width: calc(0.65 * var(--size));
    height: calc(0.65 * var(--size));
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    background-color: #fff;
  }

  // Checkbox disabled.
  input:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export { CheckBox };
