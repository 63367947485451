import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingList,
  FloatingPortal,
  offset,
  safePolygon,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useFloatingTree,
  useHover,
  useInteractions,
  useListItem,
  useListNavigation,
  useMergeRefs,
  useRole,
  useTypeahead,
} from '@floating-ui/react';
import React from 'react';
import styled from 'styled-components';

import { nameof } from '../../../utils/nameof';
import { LanguageProvider } from '../../language-provider/language-provider';
import { CheckBoxContainerWidth } from '../components/menu-item';
import { MenuContext } from '../context/menu-context';

type Props = {
  children: React.ReactNode;
};

const useContextMenu = (props: Props) => {
  const [hasFocusInside, setHasFocusInside] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);

  const elementsRef = React.useRef<(HTMLButtonElement | null)[]>([]);
  const labelsRef = React.useRef<(string | null)[]>([]);
  const parent = React.useContext(MenuContext);

  const tree = useFloatingTree();
  const nodeId = useFloatingNodeId();
  const parentId = useFloatingParentNodeId();
  const item = useListItem();

  const isNested = parentId != null;
  const [isOpen, setIsOpen] = React.useState(!isNested);

  const { floatingStyles, refs, context } = useFloating<HTMLButtonElement>({
    nodeId,
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: isNested ? 'right-start' : 'bottom-start',
    middleware: [
      offset({
        mainAxis: isNested ? 0 : 0,
        // Align nested menu so first item aligns vertically with parent item.
        alignmentAxis: isNested ? -6 : 0,
      }),
      flip(),
      shift(),
    ],
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context, {
    enabled: isNested,
    delay: {
      open: 75,
    },
    handleClose: safePolygon({
      blockPointerEvents: true,
    }),
  });
  const click = useClick(context, {
    event: 'mousedown',
    toggle: !isNested,
    ignoreMouse: isNested,
  });
  const role = useRole(context, {
    role: 'menu',
  });
  const dismiss = useDismiss(context, {
    bubbles: true,
  });
  const listNavigation = useListNavigation(context, {
    listRef: elementsRef,
    activeIndex,
    nested: isNested,
    onNavigate: setActiveIndex,
    loop: true,
  });
  const typeahead = useTypeahead(context, {
    listRef: labelsRef,
    onMatch: isOpen ? setActiveIndex : undefined,
    activeIndex,
    resetMs: 0,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    hover,
    click,
    role,
    dismiss,
    listNavigation,
    typeahead,
  ]);

  // Event emitter allows you to communicate across tree components. This effect closes all menus
  // when an item gets clicked anywhere in the tree.
  React.useEffect(() => {
    if (!tree) {
      return;
    }

    function handleTreeClick() {
      setIsOpen(false);
    }

    function onSubMenuOpen(event: { nodeId: string; parentId: string }) {
      if (event.nodeId !== nodeId && event.parentId === parentId) {
        setIsOpen(false);
      }
    }

    tree.events.on('click', handleTreeClick);
    tree.events.on('menuopen', onSubMenuOpen);

    return () => {
      tree.events.off('click', handleTreeClick);
      tree.events.off('menuopen', onSubMenuOpen);
    };
  }, [tree, nodeId, parentId, isNested]);

  React.useEffect(() => {
    if (isOpen && tree) {
      tree.events.emit('menuopen', {
        parentId,
        nodeId,
      });
    }
  }, [tree, isOpen, nodeId, parentId]);

  const mergedRefs = useMergeRefs([refs.setReference, item.ref]);

  return {
    getReferenceProps,
    hasFocusInside,
    isNested,
    isOpen,
    mergedRefs,
    nodeId,
    parent,
    refs,
    setHasFocusInside,
    renderList: () => {
      return (
        isOpen && (
          <MenuContext.Provider
            value={{
              activeIndex,
              setActiveIndex,
              getItemProps,
              setHasFocusInside,
              isOpen,
            }}
          >
            <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
              <FloatingPortal>
                <FloatingFocusManager
                  context={context}
                  initialFocus={isNested ? -1 : 0}
                  modal={false}
                  returnFocus={!isNested}
                >
                  <LanguageProvider>
                    <MenuContainer
                      ref={refs.setFloating}
                      style={floatingStyles}
                      {...getFloatingProps()}
                    >
                      {props.children}
                    </MenuContainer>
                  </LanguageProvider>
                </FloatingFocusManager>
              </FloatingPortal>
            </FloatingList>
          </MenuContext.Provider>
        )
      );
    },
  };
};

useContextMenu.displayName = nameof({ useContextMenu });

const MenuContainer = styled.div`
  position: fixed;
  z-index: 1;
  background: #fff;
  padding: 0.4em;
  border-radius: 0.2em;
  box-shadow:
    2px 4px 12px rgba(0, 0, 0, 0.1),
    0 0 0 1px rgba(0, 0, 0, 0.1);
  outline: 0;

  // For all items that has no checkbox as first child, set margin-left to the same width as the
  // container containing the checkbox in order to align labels vertically.
  > :not(:has(input[type='checkbox'])) {
    > :first-child {
      margin-left: ${CheckBoxContainerWidth}px;
    }
  }
`;

export { useContextMenu };
