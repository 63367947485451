import React from 'react';
import styled from 'styled-components';

import { permissionChecks } from '../../../../js/utils/permission-check';
import { hasElements } from '../../../../js/utils/variables';
import { ProjectGeodataManager } from '../../../../js/viewer/project-geodata-manager';
import { SkyView } from '../../../../js/viewer/skyview';
import { ToolbarGroup } from './toolbar-group';
import { CommentToolIcon } from './tools/comment-tool-icon';
import { CompareOrthophotosToolIcon } from './tools/compare-orthophotos-tool-icon';
import { DesignToolIcon } from './tools/design-tool-icon';
import { FocusToolIcon } from './tools/focus-tool-icon';
import { InspectOriginalPhotosToolIcon } from './tools/inspect-original-photos-tool-icon';
import { LightToolIcon } from './tools/light/light-tool-icon';
import { MeasurementAdvancedToolIcon } from './tools/measurement-advanced-tool-icon';
import { MeasurementToolIcon } from './tools/measurement-tool-icon';
import { OcclusionCullingToolIcon } from './tools/occlusion-culling-tool-icon';
import { OrthophotoTool } from './tools/orthophoto-tool';
import { PerspectiveTool } from './tools/perspective-tool';
import { PointCloudTool } from './tools/point-cloud/point-cloud-tool';
import { ReferencePlaneToolIcon } from './tools/reference-plane/reference-plane-tool-icon';
import { ReportToolIcon } from './tools/report-tool-icon';
import { RotateToolIcon } from './tools/rotate-tool-icon';
import { ScaleToolIcon } from './tools/scale-tool-icon';
import { SelectToolIcon } from './tools/select-tool-icon';
import { Timeline } from './tools/timeline/timeline';
import { TranslateToolIcon } from './tools/translate-tool-icon';
import { VolumeCalculationToolIcon } from './tools/volume-calculation-tool-icon';
import { WmsToolIcon } from './tools/wms-tool-icon';

function getCurrentContainer() {
  return ProjectGeodataManager.instance().getActiveTimelineContainer();
}

type Props = {
  skyView: SkyView;
};

const Toolbar = (props: Props) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const timelineTools = [
    permissionChecks.hasAccessToFeature('feature:viewer:toolbar:timeline') &&
      getCurrentContainer() && (
        <Timeline
          currentTimelineContainer={getCurrentContainer()!}
          maxVisibleItems={5}
          timelineContainers={ProjectGeodataManager.instance().getTimelineContainers().reverse()}
        />
      ),
  ].filter(Boolean);

  const isNotSharedView = () => props.skyView?.sharedView !== true;

  const viewTools = [
    permissionChecks.hasAccessToFeature('feature:viewer:toolbar:tiled_model_scene') && (
      <PerspectiveTool key="perspectiveTool" />
    ),
    permissionChecks.hasAccessToFeature('feature:viewer:toolbar:ortho_tile_scene') && (
      <OrthophotoTool key="orthophotoTool" />
    ),
    permissionChecks.hasAccessToFeature('feature:viewer:toolbar:point_cloud_scene') && (
      <PointCloudTool key="pointCloudTool" toolbarRef={ref} />
    ),
  ].filter(Boolean);

  const transformationTools = [
    permissionChecks.hasAccessToFeature('feature:viewer:toolbar:select') && (
      <SelectToolIcon key="select-tool-icon" />
    ),
    permissionChecks.hasAccessToFeature('feature:viewer:toolbar:translate', [isNotSharedView]) && (
      <TranslateToolIcon key="translate-tool-icon" />
    ),
    permissionChecks.hasAccessToFeature('feature:viewer:toolbar:rotate', [isNotSharedView]) && (
      <RotateToolIcon key="rotate-tool-icon" />
    ),
    permissionChecks.hasAccessToFeature('feature:viewer:toolbar:scale', [isNotSharedView]) && (
      <ScaleToolIcon key="scale-tool-icon" />
    ),
    permissionChecks.hasAccessToFeature('feature:viewer:toolbar:focus', [
      () => !props.skyView.isCadViewerSceneActive(),
    ]) && <FocusToolIcon key="focus-tool-icon" />,
  ].filter(Boolean);

  const mainTools = [
    permissionChecks.hasAccessToFeature('feature:viewer:toolbar:comment', [isNotSharedView]) && (
      <CommentToolIcon key="comment-tool-icon" />
    ),
    permissionChecks.hasAccessToFeature('feature:viewer:toolbar:design', [isNotSharedView]) && (
      <DesignToolIcon key="design-tool-icon" />
    ),
    permissionChecks.hasAccessToFeature('feature:viewer:toolbar:measurement', [
      () => !props.skyView.isCadViewerSceneActive(),
    ]) && <MeasurementToolIcon key="measurement-tool-icon" />,
    permissionChecks.hasAccessToFeature('feature:viewer:toolbar:measurement:advanced', [
      () => !props.skyView.isCadViewerSceneActive(),
    ]) && <MeasurementAdvancedToolIcon key="measurement-tool-advanced-icon" />,
    permissionChecks.hasAccessToFeature('feature:viewer:toolbar:original_photos', [
      () => !props.skyView.isCadViewerSceneActive(),
      isNotSharedView,
    ]) && <InspectOriginalPhotosToolIcon key="inspect-original-photos-tool-icon" />,
    permissionChecks.hasAccessToFeature('feature:viewer:toolbar:compare_ortho_photos', [
      () => !props.skyView.isCadViewerSceneActive(),
      isNotSharedView,
    ]) && <CompareOrthophotosToolIcon key="compare-orthophotos-tool-advanced-icon" />,
  ].filter(Boolean);

  const volumeTools = [
    permissionChecks.hasAccessToFeature('feature:viewer:toolbar:volume_calculation', [
      isNotSharedView,
    ]) && <VolumeCalculationToolIcon key="volume-calculation-tool-icon" />,
    permissionChecks.hasAccessToFeature('feature:viewer:toolbar:volume_calculation', [
      isNotSharedView,
    ]) && <ReportToolIcon key="report-tool-icon" />,
  ].filter(Boolean);

  const wmsTools = [
    permissionChecks.hasAccessToFeature('feature:viewer:toolbar:wms') && (
      <WmsToolIcon key="wms-tool-icon" />
    ),
  ].filter(Boolean);

  const lightTools = [
    permissionChecks.hasAccessToFeature('feature:viewer:toolbar:occlusion_culling', [
      () => !props.skyView.isCadViewerSceneActive(),
    ]) && <OcclusionCullingToolIcon key="occlusion-culling-tool-icon" />,
    permissionChecks.hasAccessToFeature('feature:viewer:toolbar:light') && (
      <LightToolIcon key="light-tool-icon" toolbarRef={ref} />
    ),
    permissionChecks.hasAccessToFeature('feature:viewer:toolbar:reference_plane', [
      () => !props.skyView.isCadViewerSceneActive(),
    ]) && <ReferencePlaneToolIcon key="reference-plane-icon" toolbarRef={ref} />,
  ].filter(Boolean);

  return (
    <Component>
      <Positioned ref={ref}>
        {hasElements(timelineTools) && <ToolbarGroup>{timelineTools}</ToolbarGroup>}
        {hasElements(viewTools) && !props.skyView.isCadViewerSceneActive() && (
          <ToolbarGroup>{viewTools}</ToolbarGroup>
        )}
        {hasElements(transformationTools) && <ToolbarGroup>{transformationTools}</ToolbarGroup>}
        {hasElements(mainTools) && <ToolbarGroup>{mainTools}</ToolbarGroup>}
        {hasElements(volumeTools) && <ToolbarGroup>{volumeTools}</ToolbarGroup>}
        {hasElements(wmsTools) && <ToolbarGroup>{wmsTools}</ToolbarGroup>}
        {hasElements(lightTools) && <ToolbarGroup>{lightTools}</ToolbarGroup>}
      </Positioned>
    </Component>
  );
};

const Component = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Positioned = styled.div`
  --skyview-toolbar-margin: 16px;

  position: absolute;
  bottom: var(--skyview-toolbar-margin);

  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;

  margin: 0 var(--skyview-toolbar-margin);
  padding: 8px;

  background-color: #fff;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);

  html[data-intercom='true'] & {
    margin-right: 100px;
  }
`;

export { Toolbar, Component as ToolbarStyled };
