import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FileModel, FolderModel } from '../../../../../../typings/api/skymap/rest/v1/.common';
import { iiafe } from '../../../../../../utilities/async';
import { SkyMapAxiosServiceFactory } from '../../../../../js/services/axios/skymap-axios-service-factory';
import { getHttpStatusCode } from '../../../../api/error-handling';
import { useFocusElement } from '../../../../hooks/use-focus-element';
import { usePromisePool } from '../../../../hooks/use-promise-pool';
import { Button } from '../../../button/button';
import { Dialog } from '../../../dialog/dialog';
import { InfoBox } from '../../../info-box/info-box';
import { List } from '../../../list/list';
import { ProgressBar } from '../../../progress-bar/progress-bar';
import { TextBox } from '../../../text-box/text-box';

interface Props {
  files: FileModel[];
  folders: FolderModel[];
  onClose: (result: {
    succeeded: { folderIds: string[]; fileIds: string[] };
    failed: { folderIds: string[]; fileIds: string[] };
  }) => void;
}

enum DialogStatus {
  Canceled,
  Cancelling,
  Done,
  Idle,
  InProgress,
}

enum ItemStatus {
  Canceled,
  Deleted,
  Deleting,
  Failed,
  Idle,
  Pending,
}

type FileItem = {
  type: 'file';
  model: FileModel;
  status: ItemStatus;
};
type FolderItem = {
  type: 'folder';
  model: FolderModel;
  status: ItemStatus;
};

type Item = FolderItem | FileItem;
type ItemAction =
  | { type: 'INIT'; files: FileModel[]; folders: FolderModel[] }
  | { type: 'SET_STATUS'; fileId: string; status: ItemStatus }
  | { type: 'SET_ALL_TO_PENDING' }
  | { type: 'CANCEL_ALL' };

interface Summary {
  filesDeleted: FileModel[];
  filesFailed: FileModel[];
  foldersDeleted: FolderModel[];
  foldersFailed: FolderModel[];
  anyInProgress: boolean;
  anyDeleted: boolean;
  allDeleted: boolean;
}

const DeleteDialog = (props: Props) => {
  const { t } = useTranslation();
  const ref = useFocusElement<HTMLInputElement>();
  const itemIdsPickedByPromisePool = React.useRef<Set<string>>(new Set());

  const validConfirmText = React.useMemo(
    () => t('fileManager.deleteDialog.validConfirmText', { ns: 'components' }),
    [t],
  );
  const [items, setItems] = React.useReducer(itemsReducer, []);
  const [confirmText, setConfirmText] = React.useState<string>('');
  const [dialogStatus, setDialogStatus] = React.useState(DialogStatus.Idle);
  const [summary, setSummary] = React.useState<Summary>({
    filesDeleted: [],
    filesFailed: [],
    foldersDeleted: [],
    foldersFailed: [],
    allDeleted: false,
    anyDeleted: false,
    anyInProgress: false,
  });

  const closeButtonClicked = React.useCallback(() => {
    const folderIdsDeleted = items
      .filter((x) => x.type === 'folder' && x.status === ItemStatus.Deleted)
      .map((x) => x.model.id);
    const folderIdsNotDeleted = items
      .filter((x) => x.type === 'folder' && x.status !== ItemStatus.Deleted)
      .map((x) => x.model.id);
    const fileIdsDeleted = items
      .filter((x) => x.type === 'file' && x.status === ItemStatus.Deleted)
      .map((x) => x.model.id);
    const fileIdsNotDeleted = items
      .filter((x) => x.type === 'file' && x.status !== ItemStatus.Deleted)
      .map((x) => x.model.id);

    props.onClose?.({
      succeeded: { folderIds: folderIdsDeleted, fileIds: fileIdsDeleted },
      failed: { folderIds: folderIdsNotDeleted, fileIds: fileIdsNotDeleted },
    });
  }, [items, props]);

  React.useEffect(() => {
    setItems({
      type: 'INIT',
      files: props.files,
      folders: props.folders,
    });
  }, [props.files, props.folders]);

  // Update summary each time files changes.
  React.useEffect(() => {
    // Unfortunately there is no way to narrow down result from .filter to only
    // FolderItem[]/FileItem[] after filtering them out using discriminator "type",
    // without explicitly marking x as FolderItem/FileItem.
    const filesDeleted = items
      .filter((x): x is FileItem => x.type === 'file' && x.status === ItemStatus.Deleted)
      .map((x) => x.model);
    const filesFailed = items
      .filter((x): x is FileItem => x.type === 'file' && x.status === ItemStatus.Failed)
      .map((x) => x.model);
    const foldersDeleted = items
      .filter((x): x is FolderItem => x.type === 'folder' && x.status === ItemStatus.Deleted)
      .map((x) => x.model);
    const foldersFailed = items
      .filter((x): x is FolderItem => x.type === 'folder' && x.status === ItemStatus.Failed)
      .map((x) => x.model);

    const anyInProgress = items.length > 0 && items.some((x) => x.status === ItemStatus.Deleting);
    const allDeleted = items.length > 0 && items.every((x) => x.status === ItemStatus.Deleted);
    const anyDeleted = allDeleted || items.some((x) => x.status === ItemStatus.Deleted);

    if (allDeleted) {
      closeButtonClicked();
      return;
    }

    switch (dialogStatus) {
      case DialogStatus.Cancelling:
        if (!anyInProgress) {
          setDialogStatus(DialogStatus.Canceled);
        }
        break;

      case DialogStatus.InProgress: {
        const allFailedOrDeleted =
          items.length > 0 &&
          items.every((x) => [ItemStatus.Failed, ItemStatus.Deleted].includes(x.status));

        if (allFailedOrDeleted) {
          setDialogStatus(DialogStatus.Done);
        }
        break;
      }
    }

    setSummary({
      allDeleted,
      anyDeleted,
      anyInProgress,
      filesDeleted,
      filesFailed,
      foldersDeleted,
      foldersFailed,
    });
  }, [closeButtonClicked, dialogStatus, items]);

  function itemsReducer(state: Item[], action: ItemAction): Item[] {
    switch (action.type) {
      case 'INIT': {
        return [
          ...(action.files.map((x) => ({
            type: 'file',
            model: x,
            status: ItemStatus.Idle,
          })) as FileItem[]),
          ...(action.folders.map((x) => ({
            type: 'folder',
            model: x,
            status: ItemStatus.Idle,
          })) as FolderItem[]),
        ];
      }

      case 'SET_STATUS': {
        const list = [...state];

        const index = list.findIndex((x) => x.model.id === action.fileId);
        list[index] = {
          ...list[index],
          status: action.status,
        };

        return list;
      }

      case 'SET_ALL_TO_PENDING': {
        return state.map((x) => {
          // Only set items not yet deleted to pending.
          if ([ItemStatus.Canceled, ItemStatus.Failed, ItemStatus.Idle].includes(x.status)) {
            return {
              ...x,
              status: ItemStatus.Pending,
            };
          }

          return x;
        });
      }

      case 'CANCEL_ALL': {
        return state.map((x) => {
          // Skip items in progress. Those requests cannot be cancelled because the server
          // is already processing those deletions.
          if (x.status === ItemStatus.Pending) {
            return {
              ...x,
              status: ItemStatus.Canceled,
            };
          }

          return x;
        });
      }
    }
  }

  const promiseGenerator = React.useCallback(() => {
    const findPredicate = (item: Item) => {
      // Skip items that has already been processed/are processing.
      return (
        item.status === ItemStatus.Pending && !itemIdsPickedByPromisePool.current.has(item.model.id)
      );
    };

    // Get next item. Return null if no more items exist.
    const item = items?.find(findPredicate);
    if (!item) {
      return null;
    }

    // Add current item to list of items already picked up by the promise generator.
    // This list is used to determining the next item to process.
    // Needed in order to get next item before next render has occurred (when item status is updated).
    itemIdsPickedByPromisePool.current.add(item.model.id);

    return new Promise<void>((resolve) => {
      iiafe(async () => {
        try {
          setItems({
            type: 'SET_STATUS',
            fileId: item.model.id,
            status: ItemStatus.Deleting,
          });

          if (item.type === 'file') {
            await SkyMapAxiosServiceFactory.instance.createFileServiceV1().deleteFile({
              path: {
                fileId: item.model.id,
              },
            });
          } else {
            await SkyMapAxiosServiceFactory.instance.createFolderServiceV1().deleteFolder({
              path: {
                folderId: item.model.id,
              },
            });
          }

          setItems({
            type: 'SET_STATUS',
            fileId: item.model.id,
            status: ItemStatus.Deleted,
          });
        } catch (err) {
          setItems({
            type: 'SET_STATUS',
            fileId: item.model.id,
            status: getHttpStatusCode(err) === 404 ? ItemStatus.Deleted : ItemStatus.Failed,
          });
        } finally {
          // Always resolve promise.
          resolve();
        }
      });
    });
  }, [items]);

  const { start: startPromisePool } = usePromisePool(6, promiseGenerator);

  const countTotalFilesInFolders = (folders: FolderModel[]) =>
    folders.reduce((a, b) => a + b.numFiles + b.numFilesSubFolders, 0);

  const numFoldersToText = (folders: FolderModel[]) => {
    return (
      <Trans
        components={{
          bold: <strong />,
        }}
        i18nKey="fileManager.deleteDialog.folderCount"
        ns="components"
        values={{
          folders: folders.length,
          files: countTotalFilesInFolders(folders),
        }}
      />
    );
  };

  const numFilesToText = (files: FileModel[] | number) => {
    const numFiles = typeof files === 'number' ? files : files.length;
    return (
      <Trans
        components={{ bold: <strong /> }}
        i18nKey="fileManager.deleteDialog.fileCount"
        ns="components"
        values={{ count: numFiles }}
      />
    );
  };

  const render = (content: {
    question: React.ReactNode;
    cancelled: React.ReactNode;
    idle: React.ReactNode;
    inProgress: React.ReactNode;
    failed: React.ReactNode;
  }) => (
    <>
      {content.question}
      {dialogStatus === DialogStatus.Canceled && !summary.allDeleted && content.cancelled}
      {dialogStatus === DialogStatus.Idle && content.idle}
      {summary.anyInProgress && content.inProgress}
      {summary.filesFailed.length + summary.foldersFailed.length > 0 && content.failed}
    </>
  );

  const renderNoObjects = () => {
    return t('fileManager.deleteDialog.noObjectsSelected', { ns: 'components' });
  };

  const renderOneFile = () =>
    render({
      question: (
        <Text>
          <Trans
            components={{ bold: <strong /> }}
            i18nKey="fileManager.deleteDialog.oneFile.question"
            ns="components"
            values={{ fileName: props.files[0].name }}
          />
        </Text>
      ),
      idle: (
        <InfoBox color="red" leftIcon={{ icon: ['fad', 'exclamation'] }} topMargin={true}>
          {t('fileManager.deleteDialog.oneFile.idle', {
            ns: 'components',
          })}
        </InfoBox>
      ),
      inProgress: (
        <InfoBox color="yellow" leftIcon={{ icon: 'spinner', spin: true }} topMargin={true}>
          {t('fileManager.deleteDialog.oneFile.inProgress', {
            ns: 'components',
          })}
        </InfoBox>
      ),
      cancelled: (
        <>
          {t('fileManager.deleteDialog.oneFile.cancelled', {
            ns: 'components',
          })}
        </>
      ),
      failed: (
        <InfoBox color="red" topMargin={true}>
          <Trans
            components={{ bold: <strong />, br: <br /> }}
            i18nKey="fileManager.deleteDialog.oneFile.failed"
            ns="components"
          />
        </InfoBox>
      ),
    });

  const renderOneFolder = () =>
    render({
      question: (
        <Text>
          <Trans
            components={{
              bold: <strong />,
            }}
            i18nKey="fileManager.deleteDialog.oneFolder.question"
            ns="components"
            values={{
              folderName: props.folders[0].name,
              fileCount: countTotalFilesInFolders(props.folders),
            }}
          />
        </Text>
      ),
      idle: (
        <InfoBox color="red" leftIcon={{ icon: ['fad', 'exclamation'] }} topMargin={true}>
          {t('fileManager.deleteDialog.oneFolder.idle', {
            ns: 'components',
          })}
        </InfoBox>
      ),
      inProgress: (
        <InfoBox color="yellow" leftIcon={{ icon: 'spinner', spin: true }} topMargin={true}>
          {t('fileManager.deleteDialog.oneFolder.inProgress', {
            ns: 'components',
          })}
        </InfoBox>
      ),
      cancelled: (
        <>
          {t('fileManager.deleteDialog.oneFolder.cancelled', {
            ns: 'components',
          })}
        </>
      ),
      failed: (
        <InfoBox color="red" topMargin={true}>
          <Trans
            components={{ bold: <strong />, br: <br /> }}
            i18nKey="fileManager.deleteDialog.oneFolder.failed"
            ns="components"
          />
        </InfoBox>
      ),
    });

  const renderSeveralObjects = () =>
    render({
      question: (
        <>
          <Text bottomMargin={true}>
            {t('fileManager.deleteDialog.severalObjects.question', {
              ns: 'components',
            })}
          </Text>
          <List
            items={[
              (props.folders?.length ?? 0) > 0 && <li>{numFoldersToText(props.folders)}</li>,
              (props.files?.length ?? 0) > 0 && <li>{numFilesToText(props.files)}</li>,
            ].filter(Boolean)}
            marker="disc"
          />
        </>
      ),
      idle: (
        <>
          <InfoBox color="yellow" topMargin={true}>
            <Text bottomMargin={true}>
              <Trans
                components={{ bold: <strong /> }}
                i18nKey="fileManager.deleteDialog.severalObjects.idleConfirmation"
                ns="components"
                values={{ validConfirmText }}
              />
            </Text>

            <TextBox
              placeholder={validConfirmText}
              ref={ref}
              onChange={(e) => {
                setConfirmText(e.target.value);
              }}
            />
          </InfoBox>

          <InfoBox color="red" leftIcon={{ icon: ['fad', 'exclamation'] }} topMargin={true}>
            {t('fileManager.deleteDialog.severalObjects.idle', {
              ns: 'components',
            })}
          </InfoBox>
        </>
      ),
      inProgress: (
        <InfoBox color="yellow" leftIcon={{ icon: 'spinner', spin: true }} topMargin={true}>
          <Text bottomMargin={true}>
            {t('fileManager.deleteDialog.severalObjects.inProgress', {
              ns: 'components',
            })}
          </Text>
          <ProgressBar
            max={items.length}
            min={0}
            value={
              items.filter((x) => [ItemStatus.Deleted, ItemStatus.Failed].includes(x.status)).length
            }
          />
        </InfoBox>
      ),
      cancelled: (
        <InfoBox color="yellow" topMargin={true}>
          {summary.filesDeleted.length + summary.foldersDeleted.length === 0 ? (
            t('fileManager.deleteDialog.severalObjects.cancelledNoneDeleted', {
              ns: 'components',
            })
          ) : (
            <>
              <Text>
                <Trans
                  components={{ br: <br /> }}
                  i18nKey="fileManager.deleteDialog.severalObjects.cancelledSomeDeleted"
                  ns="components"
                />
              </Text>

              <List
                items={[
                  summary.foldersDeleted.length > 0 && (
                    <li>{numFoldersToText(summary.foldersDeleted)}</li>
                  ),
                  summary.filesDeleted.length > 0 && (
                    <li>{numFilesToText(summary.filesDeleted)}</li>
                  ),
                ].filter(Boolean)}
                marker="disc"
              />
            </>
          )}
        </InfoBox>
      ),
      failed: (
        <>
          <InfoBox color="red" topMargin={true}>
            <Text bottomMargin={true}>
              {t('fileManager.deleteDialog.severalObjects.failed', {
                ns: 'components',
              })}
            </Text>

            <List
              items={[
                summary.foldersFailed.length > 0 && (
                  <li>{numFoldersToText(summary.foldersFailed)}</li>
                ),
                summary.filesFailed.length > 0 && <li>{numFilesToText(summary.filesFailed)}</li>,
              ].filter(Boolean)}
              marker="disc"
            />

            <Text topMargin={true}>
              {t('fileManager.deleteDialog.severalObjects.failedSelection', {
                ns: 'components',
              })}
            </Text>

            {!summary.anyInProgress && (
              <Text topMargin={true}>
                <Trans
                  components={{ bold: <strong /> }}
                  i18nKey="fileManager.deleteDialog.severalObjects.failedRetry"
                  ns="components"
                />
              </Text>
            )}
          </InfoBox>
        </>
      ),
    });

  const deleteButtonClick = () => {
    itemIdsPickedByPromisePool.current.clear();

    setDialogStatus(DialogStatus.InProgress);
    setItems({ type: 'SET_ALL_TO_PENDING' });

    startPromisePool();
  };

  const cancelButtonClicked = () => {
    setDialogStatus(DialogStatus.Cancelling);
    setItems({
      type: 'CANCEL_ALL',
    });
  };

  const deleteButtonDisabled = () => {
    if (items.length === 0) {
      return true;
    }

    // Require confirmation text only when several files are selected.
    if (items.length > 1 && confirmText !== validConfirmText) {
      return true;
    }

    return summary.anyInProgress || summary.allDeleted;
  };

  const dialogClosable = () => !summary.anyInProgress;

  return (
    <Component
      closeIcon={dialogClosable()}
      closeOnDimmerClick={false}
      width={350}
      onClose={closeButtonClicked}
    >
      {{
        header: t('fileManager.deleteDialog.title', { ns: 'components' }),
        content: (
          <>
            {props.files.length === 0 && props.folders.length === 0
              ? renderNoObjects()
              : props.files.length === 1 && props.folders.length === 0
                ? renderOneFile()
                : props.files.length === 0 && props.folders.length === 1
                  ? renderOneFolder()
                  : renderSeveralObjects()}
          </>
        ),
        footer: {
          right: (
            <>
              <Button
                color="error"
                disabled={deleteButtonDisabled()}
                variant="contained"
                onClick={deleteButtonClick}
              >
                {t('delete', { ns: 'common' })}
              </Button>

              {[DialogStatus.InProgress, DialogStatus.Cancelling].includes(dialogStatus) ? (
                <Button
                  disabled={dialogStatus === DialogStatus.Cancelling}
                  loading={dialogStatus === DialogStatus.Cancelling}
                  variant="text"
                  onClick={cancelButtonClicked}
                >
                  {t('cancel', { ns: 'common' })}
                </Button>
              ) : (
                <Button disabled={!dialogClosable()} variant="text" onClick={closeButtonClicked}>
                  {t('close', { ns: 'common' })}
                </Button>
              )}
            </>
          ),
        },
      }}
    </Component>
  );
};

const Component = styled(Dialog)`
  strong {
    font-weight: 600;
  }
`;

const Text = styled.span<{ topMargin?: boolean; bottomMargin?: boolean }>`
  display: block;

  margin-top: ${(props) => (props.topMargin ? '0.5em' : undefined)};
  margin-bottom: ${(props) => (props.bottomMargin ? '0.5em' : undefined)};
`;

export { DeleteDialog };
