import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { publish, SubscriptionTopic } from '../../../../../js/messaging/pubsub';
import { SkyMapAxiosServiceFactory } from '../../../../../js/services/axios/skymap-axios-service-factory';
import { textUtils } from '../../../../../js/utils/text/text';
import { Button } from '../../../button/button';
import { Dialog } from '../../../dialog/dialog';
import { InfoBox } from '../../../info-box/info-box';
import { TreeContext } from '../../state/folder-tree-state';

interface Props {
  onClose: (wasUpdated: boolean) => void;
}

enum SharedFolderStatus {
  Idle,
  Loading,
  Error,
}

const ShareFolderDialog = (props: Props) => {
  const { t } = useTranslation();
  const { selectedFolderId, folders } = React.useContext(TreeContext);

  const [sharedFolderId, setSharedFolderId] = React.useState(
    folders.find((f) => f.id === selectedFolderId)?.sharedFolder?.id,
  );

  const [status, setStatus] = React.useState<SharedFolderStatus>(SharedFolderStatus.Idle);
  const [shared, setShared] = React.useState<boolean>(!!sharedFolderId);
  const [wasUpdated, setWasUpdated] = React.useState<boolean>(false);

  const cancelCloseButtonClick = () => {
    props.onClose(wasUpdated);
  };

  const copyLinkButtonClick = () => {
    const url = new URL(document.location.href);

    // TODO: This is old route, replace with new one once available
    url.pathname = `shared-folder/${sharedFolderId!}`;
    url.search = '';
    url.hash = '';

    textUtils.copyTextToClipboard(url.href);
    publish(SubscriptionTopic.ToastrMessage, {
      message: t('fileManager.shareFolderDialog.linkCopiedToClipboard', { ns: 'components' }),
      type: 'success',
    });
  };

  const toggleSharedFolder = async () => {
    setStatus(SharedFolderStatus.Loading);

    try {
      if (shared) {
        await disableSharedFolder();
      } else {
        await enableSharedFolder();
      }

      setWasUpdated(true);
      setStatus(SharedFolderStatus.Idle);
    } catch (err) {
      setStatus(SharedFolderStatus.Error);
    }
  };

  const disableSharedFolder = async () => {
    await SkyMapAxiosServiceFactory.instance.createSharedFolderServiceV1().deleteSharedFolder({
      path: {
        sharedFolderId: sharedFolderId!,
      },
    });
    setSharedFolderId(undefined);
    setShared(false);
  };

  const enableSharedFolder = async () => {
    const result = await SkyMapAxiosServiceFactory.instance
      .createSharedFolderServiceV1()
      .createSharedFolder({
        body: {
          folderId: selectedFolderId!,
        },
      });

    setSharedFolderId(result.data.id);
    setShared(true);
  };

  return (
    <>
      <Dialog
        closeIcon={status !== SharedFolderStatus.Loading}
        closeOnDimmerClick={true}
        width={500}
        onClose={() => {
          if (status !== SharedFolderStatus.Loading) {
            cancelCloseButtonClick();
          }
        }}
      >
        {{
          header: t('fileManager.shareFolderDialog.title', { ns: 'components' }),
          content: (
            <>
              {shared ? (
                <>
                  <Text>{t('fileManager.shareFolderDialog.sharedText', { ns: 'components' })}</Text>

                  <InfoBox color="yellow" padding="medium" topMargin={true}>
                    <Trans
                      components={{ bold: <strong /> }}
                      i18nKey="fileManager.shareFolderDialog.sharedInfo"
                      ns="components"
                    />
                  </InfoBox>

                  <InfoBox
                    color="red"
                    leftIcon={{ icon: ['fad', 'exclamation'] }}
                    padding="medium"
                    topMargin={true}
                  >
                    {t('fileManager.shareFolderDialog.sharedWarning', { ns: 'components' })}
                  </InfoBox>
                </>
              ) : (
                <>
                  <Text>
                    {t('fileManager.shareFolderDialog.notSharedText', { ns: 'components' })}
                  </Text>

                  <InfoBox color="yellow" padding="medium" topMargin={true}>
                    <Text>
                      <Trans
                        components={{ bold: <strong /> }}
                        i18nKey="fileManager.shareFolderDialog.notSharedInfo"
                        ns="components"
                      />
                    </Text>
                  </InfoBox>
                </>
              )}

              {status === SharedFolderStatus.Error && (
                <InfoBox
                  color="red"
                  key="error"
                  leftIcon={{ icon: 'exclamation' }}
                  padding="medium"
                  title={
                    shared
                      ? t('fileManager.shareFolderDialog.deleteLinkError', { ns: 'components' })
                      : t('fileManager.shareFolderDialog.createLinkError', { ns: 'components' })
                  }
                  topMargin={true}
                >
                  {t('errorContactSupport', { ns: 'common' })}
                </InfoBox>
              )}
            </>
          ),
          footer: {
            left: (
              <Button
                color="secondary"
                disabled={!shared}
                leftIcon={{ icon: ['fad', 'share-nodes'] }}
                variant="contained"
                onClick={copyLinkButtonClick}
              >
                {t('fileManager.shareFolderDialog.copyLinkButton', { ns: 'components' })}
              </Button>
            ),
            right: (
              <>
                <Button
                  color={shared ? 'error' : 'primary'}
                  loading={status === SharedFolderStatus.Loading}
                  variant="contained"
                  onClick={() =>
                    status !== SharedFolderStatus.Loading ? toggleSharedFolder() : undefined
                  }
                >
                  {shared
                    ? t('fileManager.shareFolderDialog.deleteLinkButton', { ns: 'components' })
                    : t('fileManager.shareFolderDialog.createLinkButton', { ns: 'components' })}
                </Button>

                <Button
                  disabled={status === SharedFolderStatus.Loading}
                  variant="text"
                  onClick={cancelCloseButtonClick}
                >
                  {t('close', { ns: 'common' })}
                </Button>
              </>
            ),
          },
        }}
      </Dialog>
    </>
  );
};

const Text = styled.span<{ topMargin?: boolean; bottomMargin?: boolean }>`
  display: block;

  strong {
    font-weight: 600;
  }

  margin-top: ${(props) => (props.topMargin ? '0.5em' : undefined)};
  margin-bottom: ${(props) => (props.bottomMargin ? '0.5em' : undefined)};
`;

export { ShareFolderDialog };
