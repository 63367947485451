import { t } from 'i18next';
import React, { useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ProjectUserTagV1Model } from '../../../../typings/api/skymap/rest/v0/.common';
import { publish, SubscriptionTopic } from '../../../js/messaging/pubsub';
import { SkyMapAxiosServiceFactory } from '../../../js/services/axios/skymap-axios-service-factory';
import { ProjectStore } from '../../../js/stores/project-store';
import { reset } from '../../utils/styled-reset';
import { Icon } from '../icon/icon';
import { InfoBox } from '../info-box/info-box';
import { OverlayLoader } from '../overlay-loader/overlay-loader';

enum Status {
  Idle,
  Loading,
  Saving,
  Error,
}

interface User {
  id: string;
  name: string;
}

interface Props {
  user: User;
}

const ProjectUserTags = (props: Props) => {
  const { t } = useTranslation();
  const project = useRef(ProjectStore.instance.project!);
  const [status, setStatus] = React.useState(Status.Idle);
  const [userTags, setUserTags] = React.useState<ProjectUserTagV1Model[]>([]);

  React.useEffect(() => {
    const load = async () => {
      try {
        setStatus(Status.Loading);
        setUserTags([]);

        const tags = await SkyMapAxiosServiceFactory.instance
          .createProjectServiceV0()
          .getProjectUserTagsV1({
            path: {
              projectId: project.current.id,
              userId: props.user.id,
            },
          });

        setUserTags(
          tags.data.map((x) => ({
            permission: x.permission,
            tagId: x.tagId,
            projectId: x.projectId,
          })),
        );

        setStatus(Status.Idle);
      } catch (err) {
        setStatus(Status.Error);
      }
    };

    if (props.user) {
      void load();
    }
  }, [props.user]);

  const onTagChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const tag = project.current.tags?.find((x) => x.id === e.target.value)!;

    const newTags: ProjectUserTagV1Model[] = [
      ...userTags,
      { permission: 'READ', tagId: tag.id, projectId: project.current.id },
    ];

    await updateTags(newTags);
  };

  const onDeleteIconClick = async (tagId: string) => {
    const newTags = userTags.filter((x) => x.tagId !== tagId);
    await updateTags(newTags);
  };

  const toggleTag = async (tagId: string) => {
    const newTags: ProjectUserTagV1Model[] = userTags.map((x) => {
      return x.tagId === tagId
        ? {
            ...x,
            permission: x.permission === 'READ' ? 'WRITE' : 'READ',
          }
        : x;
    });

    await updateTags(newTags);
  };

  const updateTags = async (tags: ProjectUserTagV1Model[]) => {
    try {
      setStatus(Status.Saving);

      await SkyMapAxiosServiceFactory.instance.createProjectServiceV0().putProjectUserTagsV1({
        path: {
          projectId: project.current.id,
          userId: props.user.id,
        },
        body: {
          data: tags,
        },
      });

      setUserTags(tags);
      setStatus(Status.Idle);
    } catch (err) {
      setStatus(Status.Error);
      publish(SubscriptionTopic.ToastrMessage, {
        message: t('projectUserTags.saveError', { ns: 'components' }),
        type: 'error',
      });
    }
  };

  const renderTagsContainerContent = () => {
    if (status === Status.Loading) {
      return t('projectUserTags.loading', { ns: 'components' });
    }

    if (status === Status.Error && userTags.length === 0) {
      return (
        <InfoBox color="red">{t('projectUserTags.loadingError', { ns: 'components' })}</InfoBox>
      );
    }

    const dropDownTags = project.current.tags!.filter(
      (x) => userTags.some((userTag) => userTag.tagId === x.id) === false,
    );

    const addedTags = project.current
      .tags!.filter((x) => userTags.some((userTag) => userTag.tagId === x.id))
      .sort((a, b) => a.name.localeCompare(b.name));

    return (
      <>
        {dropDownTags.length > 0 && (
          <select value={0} onChange={onTagChange}>
            <option key={0}>
              {t('projectUserTags.defaultDropdownOption', { ns: 'components' })}
            </option>

            {dropDownTags.map((x) => (
              <option key={x.id} value={x.id}>
                {translateDbTagName(x.name)}
              </option>
            ))}
          </select>
        )}
        {addedTags.length > 0 ? (
          <ul>
            {addedTags.map((x) => {
              const userTag = userTags.find((userTag) => userTag.tagId === x.id)!;

              return (
                <li key={x.id}>
                  <ItemHeadline>{translateDbTagName(x.name)}</ItemHeadline>
                  <ItemContent>
                    <div>
                      <label>
                        <input
                          checked={userTag.permission === 'READ'}
                          type="radio"
                          onChange={() => toggleTag(x.id)}
                        />
                        <span>
                          {t('projectUserTags.readPermission.title', { ns: 'components' })}
                        </span>
                      </label>
                      <label>
                        <input
                          checked={userTag.permission === 'WRITE'}
                          type="radio"
                          onChange={() => toggleTag(x.id)}
                        />
                        <span>
                          {t('projectUserTags.writePermission.title', { ns: 'components' })}
                        </span>
                      </label>
                    </div>
                    <Icon
                      icon={['fad', 'trash']}
                      title={t('projectUserTags.disconnect', { ns: 'components' })}
                      onClick={() => onDeleteIconClick(x.id)}
                    />
                  </ItemContent>
                </li>
              );
            })}
          </ul>
        ) : (
          <NoTags>{t('projectUserTags.noTags', { ns: 'components' })}</NoTags>
        )}
      </>
    );
  };

  return (
    <Component>
      <TagsExistContainer>
        <InfoBox color="yellow">
          <p>
            <Trans
              components={{
                bold: <strong />,
                br: <br />,
              }}
              i18nKey="projectUserTags.info"
              ns="components"
              values={{
                userName: props.user.name,
                projectName: project.current.name,
              }}
            />
          </p>

          <strong>{t('projectUserTags.permissionsTitle', { ns: 'components' })}</strong>

          <table>
            <tbody>
              <tr>
                <td>
                  <strong>{t('projectUserTags.readPermission.title', { ns: 'components' })}</strong>
                </td>
                <td>{t('projectUserTags.readPermission.text', { ns: 'components' })}</td>
              </tr>
              <tr>
                <td>
                  <strong>
                    {t('projectUserTags.writePermission.title', { ns: 'components' })}
                  </strong>
                </td>
                <td>
                  <Trans
                    components={{
                      bold: <strong />,
                    }}
                    i18nKey="projectUserTags.writePermission.text"
                    ns="components"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </InfoBox>

        <OverlayLoader visible={status === Status.Saving}>
          <TagsContainer>{renderTagsContainerContent()}</TagsContainer>
        </OverlayLoader>
      </TagsExistContainer>
    </Component>
  );
};

const Component = styled.div`
  ${reset}

  font-size: 0.9em;

  strong {
    font-weight: 500;
  }

  p {
    margin: 1em 0;

    &:first-child {
      margin-top: 0;
    }
  }
`;

const TagsExistContainer = styled.div`
  line-height: 1.2em;

  table {
    margin-top: 0.5em;
    font-size: 0.9em;
  }
  td:first-child {
    padding-right: 2em;
  }
  tr:not(:last-child) td {
    padding-bottom: 0.5em;
  }
`;

const TagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em;
  background-color: #fffa;
  margin-top: 1em;
  border-radius: 0.5em;

  li {
    display: flex;
    flex-direction: column;
    gap: 0.25em;

    &:not(:last-child) {
      padding-bottom: 1em;
    }
  }
`;

const ItemHeadline = styled.span`
  font-weight: 500;
`;

const ItemContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    flex-direction: column;
    gap: 0.25em;

    label {
      display: flex;
      align-items: center;
      gap: 0.25em;
      cursor: pointer;
    }
  }
`;

const NoTags = styled.div`
  font-style: italic;
  font-size: 0.9em;
`;

// TODO: Refactor database value.
// https://skymapinnovations.atlassian.net/browse/SK-5009
export const translateDbTagName = (tagName: string) => {
  switch (tagName.trim().toLowerCase()) {
    case 'beställare':
      return t('projectUserTags.tags.client', { ns: 'components' });
    case 'projektledare':
      return t('projectUserTags.tags.projectManager', { ns: 'components' });
    case 'projektör':
      return t('projectUserTags.tags.projectDesigner', { ns: 'components' });
    case 'entreprenör':
      return t('projectUserTags.tags.contractor', { ns: 'components' });
    case 'fastighetsägare':
      return t('projectUserTags.tags.propertyOwner', { ns: 'components' });
    case 'hyresgäst':
      return t('projectUserTags.tags.tenant', { ns: 'components' });
    case 'fastighetsskötare':
      return t('projectUserTags.tags.propertyManager', { ns: 'components' });
    case 'gäst':
      return t('projectUserTags.tags.guest', { ns: 'components' });
    case 'publik':
      return t('projectUserTags.tags.public', { ns: 'components' });
  }

  return '';
};

export { ProjectUserTags };
