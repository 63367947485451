import React, { ReactElement, useContext, useEffect } from 'react';
import styled from 'styled-components';

import { hasElements, isDefined } from '../../../../../js/utils/variables';
import { useDialog } from '../../../../hooks/use-dialog';
import { useErrorHandling } from '../../../../hooks/use-error-handling';
import { reset } from '../../../../utils/styled-reset';
import { Button } from '../../../button/button';
import {
  DataTable,
  DataTableColumns,
  DataTableProps,
  DataTableSortType,
} from '../../../data-table/data-table';
import { Icon } from '../../../icon/icon';
import { IconPanel } from '../../../icon-panel/icon-panel';
import { InfoBox } from '../../../info-box/info-box';
import { Stack } from '../../../stack/stack';
import { IntegrationTitle } from '../../integration-title';
import {
  DroneHarmonyIntegrationItem,
  IntegrationsContext,
  IntegrationStatus,
} from '../../state/integrations-state';
import { AddFlightPlanDialog } from './dialogs/add-flight-plan-dialog/add-flight-plan-dialog';
import { EditFlightPlanDialog } from './dialogs/edit-flight-plan-dialog';
import { useFetchFlightPlansQuery, useRemoveIntegrationQuery } from './hooks';
import NoFlightPlansAdded from './images/no_flight_plans_added.svg';

type FlightPlan = {
  id: string;
  guid: string;
  processingPackage: string;
  generateTerrainModel: boolean;
  gcpAvailable: boolean;
};

type FlightPlanDataRow = {
  guid: string;
  processingPackage: string;
  gcpStatus: ReactElement;
};

const resolvePacketName = (value: string): string => {
  switch (value) {
    case 'FULL':
      return 'Standard';
    case 'ORTHO_ONLY':
      return 'Ortofoto';
    default:
      return 'Okänd typ';
  }
};

const columns: DataTableColumns<FlightPlanDataRow> = {
  guid: {
    alignment: 'left',
    monospaced: 'roboto',
    sortableBy: { type: DataTableSortType.STRING },
    title: 'ID (guid)',
    unfilterable: true,
    width: '340px',
  },
  processingPackage: {
    sortableBy: { type: DataTableSortType.STRING },
    title: 'Paket',
    formatter: (value) => resolvePacketName(value),
    unfilterable: true,
  },
  gcpStatus: {
    title: 'GCP',
    alignment: 'left',
    unfilterable: true,
  },
};

type Props = {
  item: DroneHarmonyIntegrationItem;
};

const ActiveView = (props: Props) => {
  const [flightPlans, setFlightPlans] = React.useState<FlightPlan[]>([]);
  const [selectedFlightPlan, setSelectedFlightPlan] = React.useState<FlightPlan | undefined>();
  const addFlightPlanDialog = useDialog();
  const editFlightPlanDialog = useDialog();
  const { toggleIntegration, removeIntegration } = useContext(IntegrationsContext);
  const { handleError, buildErrorList } = useErrorHandling();

  if (props.item.status !== IntegrationStatus.Active) {
    throw new Error("Expected integration item status is not 'Active'.");
  }

  const dataTable: DataTableProps<FlightPlanDataRow> = {
    columns,
    items: flightPlans.map((flightPlan) => ({
      gcpStatus: flightPlan.gcpAvailable ? (
        <>
          <Icon icon={['fal', 'check']} title="GCP data" />
        </>
      ) : (
        <></>
      ),
      ...flightPlan,
    })),
  };

  const removeIntegrationMutation = useRemoveIntegrationQuery({
    projectId: props.item.projectId,
    onError: (err) => {
      handleError(err);
    },
    onMutate: () => {
      toggleIntegration(props.item);
      removeIntegration(props.item);
    },
  });

  const { mutate: fetchFlightPlans } = useFetchFlightPlansQuery({
    projectId: props.item.projectId,
    onError: (err) => handleError(err),
    onMutate: (result) => {
      setFlightPlans(
        result.data.map((flightPlan) => ({
          id: flightPlan.id,
          guid: flightPlan.missionId,
          processingPackage: flightPlan.geodataProcessType,
          generateTerrainModel: flightPlan.generateTerrainModel,
          gcpAvailable: hasElements(flightPlan.groundControlPoints),
        })) as FlightPlan[],
      );
    },
  });

  useEffect(() => {
    fetchFlightPlans();
  }, [fetchFlightPlans]);

  return (
    <>
      <Component>
        <Stack direction="column" spacing={2}>
          <Stack alignItems="start" direction="row" justifyContent="space-between" spacing={0.5}>
            <IntegrationTitle activationDate={props.item.createdAt}>
              {props.item.name}
            </IntegrationTitle>

            <Button
              color="error"
              variant={'text'}
              onClick={() => removeIntegrationMutation.mutate()}
            >
              Ta bort integration
            </Button>
          </Stack>

          <InfoBox color="yellow" leftIcon={{ icon: ['fad', 'info'] }}>
            Integrationen behöver information om vilka flygrutter i <strong>Drone Harmony</strong>{' '}
            molnprocesseringar skall skapas för. Klicka på knappen nedan för att lägga till en
            flygrutt.
            <br />
            <br />
            När en flygrutt har färdigställts hämtas bilder från drönaren och en molnprocessering
            skapas automatiskt utifrån det paket som är angivet för flygrutten.
          </InfoBox>

          <Stack spacing={0.5}>
            <Stack alignItems="center" direction="row" justifyContent="space-between" spacing={0.5}>
              <span style={{ fontWeight: 500 }}>Flygrutter</span>
              <Button
                color="secondary"
                variant={'contained'}
                onClick={() => {
                  addFlightPlanDialog.show();
                }}
              >
                Lägg till flygrutt
              </Button>
            </Stack>

            <DataTable
              {...dataTable}
              fixedLayout={true}
              pageSize={undefined}
              tableStyle={{
                rowCell: {
                  fontSize: '14px',
                },
                headerCell: {
                  fontSize: '14px',
                },
              }}
              onRowClicked={(selectedFlightPlan: FlightPlanDataRow) => {
                setSelectedFlightPlan(
                  flightPlans.find((flightPlan) => flightPlan.guid === selectedFlightPlan.guid),
                );
                editFlightPlanDialog.show();
              }}
            />

            {flightPlans.length === 0 && (
              <Stack alignItems="center" spacing={0.5}>
                <IconPanel
                  header={<StyledNoFlightPlansAdded />}
                  responsiveness={{ compressed: false }}
                >
                  {{
                    title: 'Inga flygrutter har lagts till',
                  }}
                </IconPanel>
              </Stack>
            )}
          </Stack>
        </Stack>

        {buildErrorList()}
      </Component>

      {addFlightPlanDialog.render(
        <AddFlightPlanDialog
          onClose={() => {
            addFlightPlanDialog.hide();
            fetchFlightPlans();
          }}
        />,
      )}

      {isDefined(selectedFlightPlan) &&
        editFlightPlanDialog.render(() => (
          <EditFlightPlanDialog
            flightPlan={selectedFlightPlan}
            onClose={() => {
              editFlightPlanDialog.hide();
              setSelectedFlightPlan(undefined);
              fetchFlightPlans();
            }}
          />
        ))}
    </>
  );
};

// @ts-ignore
const StyledNoFlightPlansAdded = styled(NoFlightPlansAdded)`
  margin-top: 4em;
  width: 300px;
`;

const Component = styled.div`
  ${reset}
  font-size: 14px;
`;

export { ActiveView };
