import { parseISO } from 'date-fns';
import React from 'react';
import styled from 'styled-components';

import { ProjectStatus, TagModel } from '../../../typings/api/skymap/rest/v0/.common';
import { SkyMapAxiosServiceFactory } from '../../js/services/axios/skymap-axios-service-factory';
import { ProjectStore } from '../../js/stores/project-store';
import { getOneMomentText } from '../../locales/temp';
import { Button } from '../components/button/button';
import { useNonce } from '../hooks/use-nonce';
import { getSelectedProjectId, getSharedFolderId } from '../routing/utils';
import { Center } from '../styles/center';
import { reset } from '../utils/styled-reset';

export interface Project {
  id: string;
  name: string;
  description: string;
  tags: TagModel[];
  companyId: string | null;
  status: ProjectStatus;
  validToDate: Date | null;
  companyAdmins: { [k: string]: any }[];
}

export interface ProjectContext {
  project: Project;
  sharedFolderId?: string;
  refreshProjecName(this: void, name: string): void;
  refreshProjectDescription(this: void, description: string): void;
  reloadProject(this: void): Promise<void>;
}

export const ProjectContext = React.createContext<ProjectContext>(undefined!);

type Props = {
  children: React.ReactNode;
  displayLoading?: boolean;
};

enum ProjectRequestStatus {
  RequestPending,
  Success,
  Failure,
}

/**
 * Renders children passed in via props only when current project (project id in URL)
 * has been successfully fetched from API. Displays loading text and failure text if
 * project could not be fetched from API.
 */
const ProjectState = (props: Props) => {
  const [projectStatus, setProjectStatus] = React.useState(ProjectRequestStatus.RequestPending);
  const [project, setProject] = React.useState<Project>();
  const [sharedFolderId, setSharedFolderId] = React.useState<string>();
  const [refreshNonce, setRefreshNonce] = useNonce();

  const loadSharedFolderData = async (sharedFolderId: string) => {
    setSharedFolderId(sharedFolderId);

    const project = await SkyMapAxiosServiceFactory.instance
      .createSharedFolderServiceV1()
      .getSharedFolder({ path: { sharedFolderId } });
    setProject({
      id: project.data.projectId,
      name: project.data.projectName,
      description: '',
      tags: [],
      companyId: null,
      status: 'ACTIVE',
      validToDate: null,
      companyAdmins: [],
    });

    setProjectStatus(ProjectRequestStatus.Success);
  };

  const loadProjectData = () => {
    const project = ProjectStore.instance.project!;
    setProject({
      id: project.id,
      name: project.name,
      description: project.description ?? '',
      tags: project.tags ?? [],
      companyId: project.companyId,
      status: project.status,
      validToDate: project.validToDate ? parseISO(project.validToDate) : null,
      companyAdmins: project.companyAdmins,
    });
    setProjectStatus(ProjectRequestStatus.Success);
  };

  React.useEffect(() => {
    const load = async () => {
      try {
        setProjectStatus(ProjectRequestStatus.RequestPending);

        const projectId = getSelectedProjectId();
        const sharedId = getSharedFolderId();

        if (projectId) {
          loadProjectData();
        } else if (sharedId) {
          await loadSharedFolderData(sharedId);
        } else {
          setProjectStatus(ProjectRequestStatus.Failure);
        }
      } catch (err) {
        setProjectStatus(ProjectRequestStatus.Failure);
      }
    };

    void load();
  }, [refreshNonce]);

  const reloadProject = async () => {
    if (!getSelectedProjectId()) {
      return;
    }

    try {
      await ProjectStore.instance.reload();
      loadProjectData();
    } catch (err) {
      setProjectStatus(ProjectRequestStatus.Failure);
    }
  };

  switch (projectStatus) {
    case ProjectRequestStatus.RequestPending:
      return props.displayLoading ?? true ? <Center>{getOneMomentText()}</Center> : null;

    case ProjectRequestStatus.Success:
      return (
        <ProjectContext.Provider
          value={{
            project: project!,
            sharedFolderId: sharedFolderId,
            refreshProjecName: (name) => {
              if (project) {
                setProject({
                  ...project,
                  name,
                });
                ProjectStore.instance.setName(name);
              }
            },
            refreshProjectDescription: (description) => {
              if (project) {
                setProject({
                  ...project,
                  description,
                });
                ProjectStore.instance.setDescription(description);
              }
            },
            reloadProject,
          }}
        >
          {props.children}
        </ProjectContext.Provider>
      );
    case ProjectRequestStatus.Failure:
      return (
        <Center>
          <Error>
            {!sharedFolderId && (
              <>
                <h3>Oväntat fel</h3>
                <span>Det gick inte att hämta information om projektet.</span>
              </>
            )}

            {sharedFolderId && (
              <>
                <h3>Oväntat fel</h3>
                <span>Mappen delas inte.</span>
              </>
            )}
            <Button color="primary" variant="contained" onClick={() => setRefreshNonce()}>
              Försök igen
            </Button>
          </Error>
        </Center>
      );
  }
};

export { ProjectState };

const Error = styled.div`
  ${reset}
  font-size: 14px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
`;
