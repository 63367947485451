import styled, { css } from 'styled-components';

import { CollapsableSectionHeaderStyled } from './collapsable-section';

export const RightPanelContent = styled.div<{ useTopPadding: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 1em;
  flex: 1;
  overflow: auto;
  padding: ${(props) => (props.useTopPadding ? '0.8em' : 0)} 0.8em 0.8em 0.8em;

  // Render below header on scroll.
  z-index: 0;

  // Because we have a top padding, and we want the collapsable sections to stick to the right
  // panel header and not at the padding (gap), we fix this here.
  ${(props) =>
    props.useTopPadding &&
    css`
      ${CollapsableSectionHeaderStyled} {
        top: -0.8em;
      }
    `}

  * {
    font-size: 14px;
  }
`;

export const RightPanelFooter = styled.div`
  margin: 0.8em;

  * {
    font-size: 14px;
  }
`;
