import React from 'react';
import styled from 'styled-components';

import { reset } from '../../../utils/styled-reset';
import { ChooseIntegration } from '../choose-integration';
import { DonglesIntegration } from '../providers/dongles-integration';
import { DroneHarmonyIntegration } from '../providers/drone-harmony/drone-harmony-integration';
import { LeicaIntegration } from '../providers/leica-integration';
import { XsiteIntegration } from '../providers/xsite-integration';
import { IntegrationItem, IntegrationsContext } from '../state/integrations-state';
import { IntegrationListItem } from './integration-list-item';

interface Props {
  items: IntegrationItem[];
  selectedItem?: IntegrationItem;
}

const IntegrationList = (props: Props) => {
  const { toggleIntegration } = React.useContext(IntegrationsContext);

  return (
    <Component>
      <Header>
        <Title>Integrationer</Title>
        <Border />
      </Header>

      <Content>
        <LeftSide>
          <ul>
            {props.items.map((item) => (
              <IntegrationListItem
                item={item}
                key={item.key}
                selected={props.selectedItem?.key === item.key}
                onClick={() => toggleIntegration(item)}
              />
            ))}
          </ul>
        </LeftSide>

        <RightSide>
          {props.selectedItem?.key === 'xsite_manage' ? (
            <XsiteIntegration item={props.selectedItem} />
          ) : props.selectedItem?.key === 'leica_conx' ? (
            <LeicaIntegration item={props.selectedItem} />
          ) : props.selectedItem?.key === 'drone_harmony' ? (
            <DroneHarmonyIntegration item={props.selectedItem} />
          ) : props.selectedItem?.key === 'dongles' ? (
            <DonglesIntegration item={props.selectedItem} />
          ) : (
            <ChooseIntegration />
          )}
        </RightSide>
      </Content>
    </Component>
  );
};

const Component = styled.div`
  ${reset}

  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  gap: 2em;
`;

const LeftSide = styled.div`
  min-width: 300px;
`;

const RightSide = styled.div`
  flex: 1;
`;

const Title = styled.div`
  font-weight: 500;
  line-height: 25px;
  border-bottom: 4px solid ${(props) => props.theme.color.brand.dark};
`;

const Border = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.color.gray.medium};
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
`;

export { IntegrationList };
