import {
  FloatingPortal,
  useMergeRefs,
  UseTransitionStatusProps,
  useTransitionStyles,
} from '@floating-ui/react';
import React from 'react';
import styled from 'styled-components';

import { nameof } from '../../../../../utils/nameof';
import { reset } from '../../../../../utils/styled-reset';
import { LanguageProvider } from '../../../../language-provider/language-provider';
import { useSettingsPanelState } from './settings-panel-context';

type Props = {
  children: React.ReactNode;
};

const defaultTransitionDelay: UseTransitionStatusProps['duration'] = {
  open: 250,
  close: 100,
};

const SettingsPanelContent = React.forwardRef<HTMLDivElement, Props>((props, propRef) => {
  const state = useSettingsPanelState();
  const ref = useMergeRefs([state.refs.setFloating, propRef]);

  const { isMounted, styles } = useTransitionStyles(state.context, {
    duration: defaultTransitionDelay,
  });

  return isMounted ? (
    <FloatingPortal>
      <LanguageProvider>
        <Component
          ref={ref}
          style={{
            ...state.floatingStyles,
            ...styles,
          }}
          {...state.getFloatingProps(props)}
        >
          {props.children}
        </Component>
      </LanguageProvider>
    </FloatingPortal>
  ) : null;
});

SettingsPanelContent.displayName = nameof({ SettingsPanelContent });

const Component = styled.div`
  ${reset}
  font-size: 14px;

  background-color: #fff;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 1em;
  border-radius: 0.5em;
  isolation: isolate;
  z-index: 1;
`;

export { Props, SettingsPanelContent };
