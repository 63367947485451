import React from 'react';
import styled, { useTheme } from 'styled-components';

import { reset } from '../../utils/styled-reset';
import { Icon } from '../icon/icon';
import { Stack } from '../stack/stack';

type Props = {
  status: 'ok' | 'invalid' | undefined;
  children: React.ReactNode;
};

const PasswordConstraintsItem = (props: Props) => {
  const theme = useTheme();

  const resolveIcon = (status: undefined | 'ok' | 'invalid') => {
    switch (status) {
      case 'invalid':
        return <Icon color={theme.color.red} fixedWidth={true} icon={['far', 'xmark']} />;
      case 'ok':
        return <Icon color={theme.color.green} fixedWidth={true} icon={['far', 'check']} />;
    }

    return <Icon color={theme.color.blue} fixedWidth={true} icon={['fas', 'circle-info']} />;
  };

  return (
    <Component status={props.status}>
      <Stack alignItems="center" direction="row" spacing={0.5}>
        {resolveIcon(props.status)}
        {props.children}
      </Stack>
    </Component>
  );
};

const Component = styled.li<{ status?: 'ok' | 'invalid' }>`
  ${reset}
  padding: 0.2em 0;
`;

export { PasswordConstraintsItem, Props as PasswordConstraintsItemProps };
