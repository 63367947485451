import React from 'react';
import styled from 'styled-components';

import * as common from '../../../../../typings/api/skymap/rest/v1/.common';
import { findAllAncestorFolderIds } from '../move-file-folder-helper';
import { TreeContext } from '../state/folder-tree-state';
import { FolderTreeItem } from './folder-tree-item';

interface Props {
  folders: common.FolderModel[];
  parentId: string | null;
  level: number;
}

const listItemLeftIndentPx = 10;

const FolderTree = (props: Props) => {
  const { isFolderOpened } = React.useContext(TreeContext);

  const items = props.folders.filter((item) => item.parentId === props.parentId);

  return items.length === 0 ? null : (
    <Component listItemLeftIndentPx={listItemLeftIndentPx}>
      {items.map((item) => (
        <li key={item.id}>
          <FolderTreeItem
            ancestorFoldersIds={findAllAncestorFolderIds(item.id, props.folders)}
            folder={item}
            hasFiles={item.numFiles + item.numFilesSubFolders > 0}
            hasSubFolders={props.folders.some((x) => x.parentId === item.id)}
            id={item.id}
            leftIndentPx={props.level * listItemLeftIndentPx}
          />

          {isFolderOpened(item.id) && (
            <FolderTree folders={props.folders} level={props.level + 1} parentId={item.id} />
          )}
        </li>
      ))}
    </Component>
  );
};

const Component = styled.ul<{ listItemLeftIndentPx: number }>`
  list-style: none;

  &:first-child {
    padding: 0.2em 0;
  }

  li {
    position: relative;
    padding: 0;

    // Padding left for all levels but first.
    li {
      padding-left: ${(props) => props.listItemLeftIndentPx}px;
    }
  }
`;

export { FolderTree };
