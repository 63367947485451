import { parse } from 'path';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FileModel, FolderModel } from '../../../../../../typings/api/skymap/rest/v1/.common';
import { PatchFileParameters } from '../../../../../../typings/api/skymap/rest/v1/file';
import { PatchFolderParameters } from '../../../../../../typings/api/skymap/rest/v1/folder';
import { iiafe } from '../../../../../../utilities/async';
import { SkyMapAxiosServiceFactory } from '../../../../../js/services/axios/skymap-axios-service-factory';
import { RequestParamsToApiPaths } from '../../../../../js/utils/typescript-utils';
import { getHttpStatusCode } from '../../../../api/error-handling';
import { useErrorHandling } from '../../../../hooks/use-error-handling';
import { usePromisePool } from '../../../../hooks/use-promise-pool';
import { truncateText } from '../../../../utils/styled-helpers';
import { Button } from '../../../button/button';
import { Dialog } from '../../../dialog/dialog';
import { InfoBox } from '../../../info-box/info-box';
import { LabelledContainer } from '../../../labelled-container/labelled-container';
import { ProgressBar } from '../../../progress-bar/progress-bar';
import { Stack } from '../../../stack/stack';
import { TextBox } from '../../../text-box/text-box';
import {
  combineFoldersFiles,
  EntityModel,
  EntityStatus,
  EntityType,
} from '../../move-file-folder-helper';
import { FolderListViewContext } from '../../state/folder-list-view-state';
import { TreeContext } from '../../state/folder-tree-state';

interface Props {
  onClose: () => void;
  targetFolder: { id: string | null; name: string; projectId?: string };
}

type EntityAction =
  | { type: 'INIT_ITEMS_TO_PENDING'; folders: FolderModel[]; files: FileModel[] }
  | { type: 'SET_STATUS'; entity: EntityModel; status: EntityStatus }
  | { type: 'SET_NAME'; entity: EntityModel; name: string }
  | { type: 'SET_NOT_DONE_ITEMS_TO_SKIP' };

const sendMoveFileRequest = (
  fileId: string,
  fileName: string,
  id: { targetFolderId: string | null } | { projectId: string },
) => {
  const fileService = SkyMapAxiosServiceFactory.instance.createFileServiceV1();

  return fileService.patchFile({
    path: { fileId: fileId },
    body: {
      name: fileName,
      projectId: 'projectId' in id ? id.projectId : undefined,
      folderId: 'targetFolderId' in id && id.targetFolderId ? id.targetFolderId : undefined,
    },
  });
};

const sendMoveFolderRequest = (
  folderId: string,
  folderName: string,
  id: { targetFolderId: string | null } | { projectId: string },
) => {
  const folderService = SkyMapAxiosServiceFactory.instance.createFolderServiceV1();
  return folderService.patchFolder({
    path: { folderId },
    body: {
      name: folderName,
      projectId: 'projectId' in id ? id.projectId : undefined,
      parentFolderId: 'targetFolderId' in id && id.targetFolderId ? id.targetFolderId : undefined,
    },
  });
};

const entityReducer = (state: EntityModel[], action: EntityAction) => {
  switch (action.type) {
    case 'INIT_ITEMS_TO_PENDING':
      return combineFoldersFiles(action.folders, action.files);

    case 'SET_STATUS':
      return state.map((entity) =>
        entity.id === action.entity.id
          ? {
              ...entity,
              status: action.status,
            }
          : entity,
      );

    case 'SET_NAME':
      return state.map((entity) =>
        entity.id === action.entity.id
          ? {
              ...entity,
              name: action.name,
            }
          : entity,
      );

    case 'SET_NOT_DONE_ITEMS_TO_SKIP':
      return state.map((entity) =>
        entity.status === EntityStatus.DONE || entity.status === EntityStatus.SKIP
          ? entity
          : {
              ...entity,
              status: EntityStatus.SKIP,
            },
      );
  }
};

export const MoveFileFolderDialog = (props: Props) => {
  const { t } = useTranslation();

  const { checkedFiles, checkedFolders, refresh } = React.useContext(FolderListViewContext);
  const [entityNewName, setEntityNewName] = React.useState('');
  const [selectedEntities, dispatch] = React.useReducer(entityReducer, [] as EntityModel[]);
  const { setRefreshNonce } = React.useContext(TreeContext);

  const { buildErrorList, handleError, clearErrors, includeErrorPaths } =
    useErrorHandling<RequestParamsToApiPaths<PatchFileParameters | PatchFolderParameters>>();
  const promiseGeneratorItemIds = React.useRef<Set<string>>(new Set());

  const closeAndRefresh = React.useCallback(() => {
    props.onClose();
    refresh();
    setRefreshNonce();
  }, [props, refresh, setRefreshNonce]);

  React.useEffect(() => {
    if (
      selectedEntities.length > 0 &&
      selectedEntities.every((entity) => entity.status === EntityStatus.SKIP)
    ) {
      props.onClose();
    } else if (
      selectedEntities.length > 0 &&
      selectedEntities.every(
        (entity) => entity.status === EntityStatus.DONE || entity.status === EntityStatus.SKIP,
      )
    ) {
      closeAndRefresh();
    }
  }, [selectedEntities, props, refresh, setRefreshNonce, closeAndRefresh]);

  const promiseGenerator = React.useCallback(() => {
    // Abort generator if there is an error or conflict.
    const hasErrorOrConflict = selectedEntities.some((entity) =>
      [EntityStatus.CONFLICT, EntityStatus.ERROR].includes(entity.status),
    );
    if (hasErrorOrConflict) {
      return null;
    }

    // Clear errors when no more errors or conflicts exist.
    clearErrors();

    // And abort generator if no more items to move.
    const entity = selectedEntities.find((x) => {
      // Skip files that has already been processed/are processing.
      if (promiseGeneratorItemIds.current.has(x.id)) {
        return false;
      }

      return x.status === EntityStatus.PENDING;
    });
    if (!entity) {
      return null;
    }

    // Add current item to list of items currently being processed or has been processed.
    // This list is used to determing the next item to process. Needed in order to get next item
    // before next render has occurred (when item status is updated. two prevent process of same
    // item twice.
    promiseGeneratorItemIds.current.add(entity.id);

    // Otherwise continue with the next item.
    return new Promise<void>((resolve) => {
      iiafe(async () => {
        try {
          switch (entity.type) {
            case EntityType.FILE:
              await sendMoveFileRequest(
                entity.id,
                entity.name,
                props.targetFolder.projectId
                  ? {
                      projectId: props.targetFolder.projectId,
                    }
                  : {
                      targetFolderId: props.targetFolder.id,
                    },
              );
              break;

            case EntityType.FOLDER:
              await sendMoveFolderRequest(
                entity.id,
                entity.name,
                props.targetFolder.projectId
                  ? {
                      projectId: props.targetFolder.projectId,
                    }
                  : {
                      targetFolderId: props.targetFolder.id,
                    },
              );
              break;
          }
          dispatch({
            type: 'SET_STATUS',
            entity,
            status: EntityStatus.DONE,
          });
        } catch (error) {
          promiseGeneratorItemIds.current.delete(entity.id);
          handleError(error);

          if (getHttpStatusCode(error) === 409) {
            dispatch({
              type: 'SET_STATUS',
              entity,
              status: EntityStatus.CONFLICT,
            });

            setEntityNewName(
              t('fileManager.moveFileFolderDialog.newFilenameSuggestionOnNameExists', {
                ns: 'components',
                name: parse(entity.name).name,
                extension: parse(entity.name).ext,
              }),
            );
          } else {
            dispatch({
              type: 'SET_STATUS',
              entity,
              status: EntityStatus.ERROR,
            });
          }
        } finally {
          resolve();
        }
      });
    });
  }, [
    clearErrors,
    handleError,
    props.targetFolder.id,
    props.targetFolder.projectId,
    selectedEntities,
    t,
  ]);

  const { start: runPromisePool } = usePromisePool(1, promiseGenerator);

  const startTransfer = () => {
    dispatch({
      type: 'INIT_ITEMS_TO_PENDING',
      files: checkedFiles,
      folders: checkedFolders,
    });
    runPromisePool();
  };

  const close = () => {
    if (selectedEntities.length === 0) {
      props.onClose();
    }
    dispatch({
      type: 'SET_NOT_DONE_ITEMS_TO_SKIP',
    });
  };

  const errorEntity = selectedEntities.find((x) =>
    [EntityStatus.CONFLICT, EntityStatus.ERROR].includes(x.status),
  );
  const doneOrSkippedCount = selectedEntities.filter((x) =>
    [EntityStatus.DONE, EntityStatus.SKIP].includes(x.status),
  ).length;
  const totalCount = selectedEntities.length;
  const currentItemName =
    selectedEntities.find((entity) =>
      [EntityStatus.PENDING, EntityStatus.CONFLICT, EntityStatus.ERROR].includes(entity.status),
    )?.name ?? '';

  return (
    <Dialog closeOnDimmerClick={true} width={400} onClose={close}>
      {{
        header: t('fileManager.moveFileFolderDialog.moveFilesFolders', { ns: 'components' }),
        content: (
          <Content spacing={1}>
            {selectedEntities.length === 0 && (
              <>
                <span>
                  <Trans
                    components={{ bold: <strong /> }}
                    i18nKey="fileManager.moveFileFolderDialog.confirmMove"
                    ns="components"
                    values={{
                      count: checkedFiles.length + checkedFolders.length,
                      targetFolderName: props.targetFolder.name,
                    }}
                  />
                </span>

                <InfoBox color="red" leftIcon={{ icon: ['fad', 'exclamation'] }}>
                  {t('fileManager.moveFileFolderDialog.actionCannotBeUndone', { ns: 'components' })}
                </InfoBox>
              </>
            )}

            {selectedEntities.length > 0 && (
              <>
                <Stack spacing={0.5}>
                  <CurrentItemInfo>{currentItemName}</CurrentItemInfo>
                  <ProgressBar
                    max={selectedEntities.length}
                    min={0}
                    text={`${doneOrSkippedCount} / ${totalCount}`}
                    value={doneOrSkippedCount}
                  />
                </Stack>

                {buildErrorList({
                  filter: includeErrorPaths([
                    'body.folderId',
                    'path.fileId',
                    'path.folderId',
                    'body.parentFolderId',
                    'body.name',
                  ]),
                  infoBoxProps: {
                    topMargin: undefined,
                  },
                })}
              </>
            )}

            {selectedEntities.some((entity) => entity.status === EntityStatus.CONFLICT) && (
              <LabelledContainer
                text={t('fileManager.moveFileFolderDialog.newFileName', { ns: 'components' })}
              >
                <TextBox
                  id={entityNewName}
                  value={entityNewName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setEntityNewName(e.target.value)
                  }
                />
              </LabelledContainer>
            )}
          </Content>
        ),
        footer: {
          left: errorEntity && (
            <Button
              color={'secondary'}
              variant={'contained'}
              onClick={() => {
                if (!errorEntity) {
                  return;
                }
                dispatch({
                  type: 'SET_STATUS',
                  entity: errorEntity,
                  status: EntityStatus.SKIP,
                });
                runPromisePool();
              }}
            >
              {t('skip', { ns: 'common' })}
            </Button>
          ),
          right: (
            <Stack direction={'row'} spacing={0.5}>
              {errorEntity?.status === EntityStatus.CONFLICT ? (
                <Button
                  color={'primary'}
                  variant={'contained'}
                  onClick={() => {
                    dispatch({
                      type: 'SET_STATUS',
                      entity: errorEntity,
                      status: EntityStatus.PENDING,
                    });
                    dispatch({
                      type: 'SET_NAME',
                      entity: errorEntity,
                      name: entityNewName,
                    });
                    runPromisePool();
                  }}
                >
                  {t('continue', { ns: 'common' })}
                </Button>
              ) : errorEntity?.status === EntityStatus.ERROR ? (
                <Button
                  color={'primary'}
                  variant={'contained'}
                  onClick={() => {
                    dispatch({
                      type: 'SET_STATUS',
                      entity: errorEntity,
                      status: EntityStatus.PENDING,
                    });
                    runPromisePool();
                  }}
                >
                  {t('retry', { ns: 'common' })}
                </Button>
              ) : (
                <Button
                  color={'primary'}
                  disabled={selectedEntities.length > 0}
                  variant={'contained'}
                  onClick={startTransfer}
                >
                  {t('start', { ns: 'common' })}
                </Button>
              )}
              <Button variant={'text'} onClick={close}>
                {t('cancel', { ns: 'common' })}
              </Button>
            </Stack>
          ),
        },
      }}
    </Dialog>
  );
};

const Content = styled(Stack)`
  strong {
    font-weight: 500;
  }
`;

const CurrentItemInfo = styled.span`
  ${truncateText(1)}
`;
