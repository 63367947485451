import type * as CSS from 'csstype';
import { darken } from 'polished';
import React from 'react';
import styled from 'styled-components';

import { ToTransientProps } from '../../../../../utils/styled-helpers';
import { Icon, IconStyled } from '../../../../icon/icon';

type Props = {
  color: NonNullable<CSS.Properties['color']>;
  selected?: boolean;
  onSelected: () => void;
};

const ColorItem = (props: Props) => {
  return (
    <Component $color={props.color} aria-current={props.selected} onClick={props.onSelected}>
      {props.selected && (
        <Icon
          icon={['fad', 'check-circle']}
          style={{
            ['--fa-secondary-color' as any]: '#FFF',
            ['--fa-secondary-opacity' as any]: 0.7,
          }}
        />
      )}
    </Component>
  );
};

type StyledProps = ToTransientProps<NonNullable<Pick<Props, 'color'>>>;

const Component = styled.li<StyledProps>`
  display: grid;
  place-content: center;
  background-color: ${(props) => props.$color};
  width: 2em;
  aspect-ratio: 1 / 1;
  border-radius: 0.4em;
  cursor: pointer;
  border: 1px solid ${(props) => darken(0.2, props.$color)};

  :hover {
    opacity: 1;
    border-color: #000;
    box-shadow: inset 0 0 0 2px #fff;
  }

  ${IconStyled} {
    color: #000;
  }
`;

export { ColorItem };
