import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { SubscriptionTopic } from '../../../../../js/messaging/pubsub';
import { isDefined } from '../../../../../js/utils/variables';
import { AssetManager } from '../../../../../js/viewer/asset-manager';
import { CellGridVolumeModel } from '../../../../../js/viewer/elements/volume/model/cell-grid-volume-model';
import { VolumeCalculationAsset } from '../../../../../js/viewer/elements/volume/types';
import { InputHandler } from '../../../../../js/viewer/input-handler';
import { useNonce } from '../../../../hooks/use-nonce';
import { useSubscribe } from '../../../../hooks/use-subscribe';
import { reset } from '../../../../utils/styled-reset';
import { CheckBox } from '../../../check-box/check-box';
import { InfoBox } from '../../../info-box/info-box';
import { RightPanelHeader } from '../../../skyview/right-menu/components/right-menu-header';
import { RightPanelContent, RightPanelFooter } from '../../../skyview/right-menu/components/styles';
import { Stack } from '../../../stack/stack';
import { ColorBar } from './color-bar';
import { IntervalInput } from './interval-input';

const CutFillMap = () => {
  const { t } = useTranslation();
  const [_, refresh] = useNonce();

  const asset = AssetManager.instance.getSelected() as VolumeCalculationAsset;
  const volumeHandler = asset.volumeHandler;
  const volumeModel = volumeHandler.volumeModel as CellGridVolumeModel | undefined;

  const [cutValue, setCutValue] = React.useState(0);
  const [fillValue, setFillValue] = React.useState(0);
  const [displayGradient, setDisplayGradient] = React.useState(
    volumeModel?.displayGradient ?? true,
  );

  const [cutHasDefaultValue, setCutHasDefaultValue] = React.useState(true);
  const [fillHasDefaultValue, setFillHasDefaultValue] = React.useState(true);

  // If volume is no longer visible and another one is not loading, hide right menu.
  if (volumeHandler?.isModelHidden()) {
    InputHandler.instance.setTool('select');
  }

  useEffect(() => {
    if (isDefined(volumeModel)) {
      volumeModel.displayGradient = displayGradient;
    }
  }, [displayGradient, volumeModel]);

  useEffect(() => {
    if (volumeHandler) {
      volumeHandler.onActiveVolumeChanged = refresh;
    }

    return () => {
      if (volumeHandler) {
        volumeHandler.onActiveVolumeChanged = undefined;
      }
    };
  }, [volumeHandler, refresh]);

  useSubscribe(SubscriptionTopic.ModelVolumeStateChanged, refresh);

  const resetIntervalCut = React.useCallback(
    (value?: number) => {
      if (isDefined(value)) {
        setCutValue(value);
        setCutHasDefaultValue(false);
      } else {
        setCutHasDefaultValue(true);
      }

      if (isDefined(volumeModel)) {
        volumeModel.resetIntervalCut(value);
        setDisplayGradient(true);
        volumeModel.calculatePlanarAreaCut();
        volumeModel.resetIntervalCut(value);
        volumeHandler.onActiveVolumeChanged?.();
      }
    },
    [volumeHandler, volumeModel],
  );

  const resetIntervalFill = React.useCallback(
    (value?: number) => {
      if (isDefined(value)) {
        setFillValue(value);
        setFillHasDefaultValue(false);
      } else {
        setFillHasDefaultValue(true);
      }

      if (isDefined(volumeModel)) {
        volumeModel.resetIntervalFill(value);
        setDisplayGradient(true);
        volumeModel.calculatePlanarAreaFill();
        volumeModel.resetIntervalFill(value);
        volumeHandler.onActiveVolumeChanged?.();
      }
    },
    [volumeHandler, volumeModel],
  );

  const onCutValueChanged = React.useCallback(
    (value: number) => {
      resetIntervalCut(value);
      refresh();
    },
    [refresh, resetIntervalCut],
  );

  const onFillValueChanged = React.useCallback(
    (value: number) => {
      resetIntervalFill(value);
      refresh();
    },
    [refresh, resetIntervalFill],
  );

  const onUseAutoValueCut = React.useCallback(() => {
    resetIntervalCut();
    refresh();
  }, [refresh, resetIntervalCut]);

  const onUseAutoValueFill = React.useCallback(() => {
    resetIntervalFill();
    refresh();
  }, [refresh, resetIntervalFill]);

  return (
    <Component spacing={0}>
      <RightPanelHeader
        icon={['fad', 'mountains']}
        name={t('cutFillMap.header', { ns: 'components' })}
      />

      {volumeHandler?.isModelVisible() && isDefined(volumeModel) ? (
        <>
          <RightPanelContent useTopPadding={true}>
            <ColorBarContainer>
              <ColorBar
                asset={asset}
                cut={{ isDefaultValue: cutHasDefaultValue, value: cutValue }}
                fill={{ isDefaultValue: fillHasDefaultValue, value: fillValue }}
              />
            </ColorBarContainer>
          </RightPanelContent>

          <RightPanelFooter>
            <Stack spacing={1}>
              <h2>{t('cutFillMap.interval.title', { ns: 'components' })}</h2>

              <CheckBox
                checked={displayGradient}
                onChange={() => setDisplayGradient((oldValue) => !oldValue)}
              >
                {t('cutFillMap.displayGradient', { ns: 'components' })}
              </CheckBox>

              <IntervalStack alignItems="center" direction="row" spacing={2}>
                <IntervalInput
                  defaultValue={volumeModel.calcIntervalCut()}
                  title={t('cutFillMap.cut', { ns: 'components' })}
                  onUseAutoValue={onUseAutoValueCut}
                  onValueChanged={onCutValueChanged}
                />
                <IntervalInput
                  defaultValue={Math.abs(volumeModel.calcIntervalFill())}
                  title={t('cutFillMap.fill', { ns: 'components' })}
                  onUseAutoValue={onUseAutoValueFill}
                  onValueChanged={onFillValueChanged}
                />
              </IntervalStack>
            </Stack>
          </RightPanelFooter>
        </>
      ) : (
        <RightPanelContent useTopPadding={true}>
          <InfoBox color="yellow" leftIcon={{ icon: ['fad', 'spinner'], spin: true }}>
            {t('cutFillMap.loading', { ns: 'components' })}
          </InfoBox>
        </RightPanelContent>
      )}
    </Component>
  );
};

const Component = styled(Stack)`
  height: 100%;
  font-size: 14px;

  h2 {
    ${reset}

    font-size: 1em;
    font-weight: 500;
    text-transform: uppercase;
  }
`;

const IntervalStack = styled(Stack)`
  > * {
    flex: 1;
  }
`;

const ColorBarContainer = styled.div`
  padding: 0 0.8em;
  font-size: 14px;
  height: 100%;
`;

export { CutFillMap };
