import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { roundToDecimals } from '../../../../../js/utils/math/number';
import {
  fractionFromSlopePercent,
  slopePercentToDegrees,
} from '../../../../../js/utils/math/slope';
import { isDefined } from '../../../../../js/utils/variables';
import { DesignHoverMetadata } from '../../../../../js/viewer/elements/design/design-node/utils/meta-data';
import { Coordinates } from '../../../../../js/viewer/utilities/math-helpers';
import { reset } from '../../../../utils/styled-reset';
import { LabelledContainer } from '../../../labelled-container/labelled-container';
import { Stack } from '../../../stack/stack';
import { CoordinatePropertiesStyled, PositionInfo } from './coordinate-properties';

export type DesignHoverProps = {
  type: 'design';
  isMultiselect: false;
  metaData?: DesignHoverMetadata;
};

export const DesignHoverProperties = (props: {
  coordinate: Coordinates;
  coordinateSystem?: string;
  properties: DesignHoverProps;
}) => {
  const hasAttributes = (props.properties.metaData?.attributes.length ?? 0) > 0;
  const data = props.properties.metaData?.data;

  return (
    <Component wide={hasAttributes}>
      <PositionInfo coordinate={props.coordinate} />
      {(data?.type === 'line' || data?.type === 'createline') && (
        <LineSegmentInfo
          length={data?.length}
          lengthPlanar={data?.planarLength}
          slope={data?.type === 'createline' && data?.slope ? data.slope : Math.abs(data.slope)}
        />
      )}
      {data?.type === 'face' && (
        <FaceInfo area={data?.area} areaPlanar={data?.planarArea} slope={data?.slope} />
      )}

      {hasAttributes && <AttributesInfo attributes={props.properties.metaData!.attributes} />}
    </Component>
  );
};

/**
 * Displays metadata of a line segment when using design tool
 */
const LineSegmentInfo = (props: { length: number; slope: number; lengthPlanar: number }) => {
  const { t } = useTranslation();
  return (
    <Stack spacing={0.5}>
      <LabelledContainer
        text={t('assetPositionTooltip.designHoverProperties.title.lineSegment', {
          ns: 'skyview',
        })}
      >
        <P>
          <Label>
            {t('assetPositionTooltip.designHoverProperties.length3d', { ns: 'skyview' })}
          </Label>
          <span>{props.length.toFixed(2)} m</span>
        </P>
        <P>
          <Label>
            {t('assetPositionTooltip.designHoverProperties.length2d', { ns: 'skyview' })}
          </Label>
          <span>{props.lengthPlanar.toFixed(2)} m</span>
        </P>
        <Slope slope={props.slope} />
      </LabelledContainer>
    </Stack>
  );
};

/**
 * Displays metadata of a face when using design tool
 */
const FaceInfo = (props: { area: number; areaPlanar: number; slope: number }) => {
  const { t } = useTranslation();
  return (
    <Stack spacing={0.5}>
      <LabelledContainer
        text={t('assetPositionTooltip.designHoverProperties.title.triangle', { ns: 'skyview' })}
      >
        <P>
          <Label>
            {t('assetPositionTooltip.designHoverProperties.surface3d', { ns: 'skyview' })}
          </Label>
          <span>{props.area.toFixed(2)} m²</span>
        </P>
        <P>
          <Label>
            {t('assetPositionTooltip.designHoverProperties.surface2d', { ns: 'skyview' })}
          </Label>
          <span>{props.areaPlanar.toFixed(2)} m²</span>
        </P>
        <Slope slope={Math.abs(props.slope)} />
      </LabelledContainer>
    </Stack>
  );
};

/**
 * Displays metadata of a face when using design tool
 */
const AttributesInfo = (props: { attributes: [string, string][] }) => {
  const { t } = useTranslation();
  return (
    <Stack spacing={0.5}>
      <LabelledContainer
        text={t('assetPositionTooltip.designHoverProperties.title.lineSegment', {
          ns: 'skyview',
        })}
      >
        <Table>
          {props.attributes.map((x, idx) => (
            <tr key={idx}>
              <AttributeName>{x[0]}</AttributeName>
              <AttributeValue>{x[1]}</AttributeValue>
            </tr>
          ))}
        </Table>
      </LabelledContainer>
    </Stack>
  );
};

export const Slope = (props: { slope: number; className?: string }) => {
  const { t } = useTranslation();
  const fraction = fractionFromSlopePercent(props.slope);
  fraction.x = roundToDecimals(fraction.x, 2);
  fraction.y = roundToDecimals(fraction.y, 2);
  const angleDegrees = roundToDecimals(slopePercentToDegrees(props.slope), 2);

  return (
    <P className={props.className} flex={!isDefined(props.className)}>
      <Label>
        {isDefined(props.className)
          ? t('assetPositionTooltip.designHoverProperties.slopeToNextPoint', { ns: 'skyview' })
          : t('assetPositionTooltip.designHoverProperties.slope', { ns: 'skyview' })}
      </Label>
      <span>
        {fraction.y.toString()} : {fraction.x.toString()} (
        {roundToDecimals(props.slope, 2).toString()} % / {angleDegrees}°)
      </span>
    </P>
  );
};

const P = styled.p<{ flex?: boolean }>`
  display: ${(props) => (props.flex ? 'flex' : 'block')};
  align-items: baseline;
`;

const Table = styled.table`
  ${reset}
  width: 100%;
  border-collapse: separate;
  border-spacing: 0.4em;
`;

const AttributeName = styled.td`
  width: 25%;
  color: ${(props) => props.theme.color.gray.medium};
  text-align: left;
`;

const AttributeValue = styled.td`
  text-align: left;
`;

const Label = styled.label<{ width?: number }>`
  width: ${(props) => props.width ?? 50}%;
`;

const Component = styled(CoordinatePropertiesStyled)<{ wide: boolean }>`
  min-width: ${(props) => (props.wide ? 450 : 160)}px;

  p {
    display: flex;
    align-items: baseline;

    label {
      align-items: baseline;
    }
  }

  > p {
    label {
      width: 2em;
    }
  }
`;
