import React from 'react';
import styled from 'styled-components';

import { TokenService } from '../../../../js/services/authentication/token.service';
import {
  FileUpload,
  UploadManuallyStoppedError,
} from '../../../../js/services/network/file-upload';
import { WebApi } from '../../../../js/webAPI/web-api';
import { useErrorHandling } from '../../../hooks/use-error-handling';
import { Button } from '../../button/button';
import { CheckBox } from '../../check-box/check-box';
import { Dialog } from '../../dialog/dialog';
import { FileSelectionButton } from '../../file-selection-button/file-selection-button';
import { ProgressBar } from '../../progress-bar/progress-bar';
import { Stack } from '../../stack/stack';
import { TooltipInfoIcon } from '../../tooltip-info-icon/tooltip-info-icon';
import { Item } from './skyview-data';

interface Props {
  accept: string;
  title: string;
  onClose: (item?: Item) => void;
  showZUpCheckBox?: boolean;
  onUploaded: (name: string, uploadId: string, zUp: boolean) => Promise<Item>;
}

enum Status {
  Idle,
  Pending,
  Error,
}

type ApiRequestPath = 'body.uploadId';

const NewUploadDialog = (props: Props) => {
  const [status, setStatus] = React.useState(Status.Idle);
  const [progress, setProgress] = React.useState(0);
  const [file, setFile] = React.useState<File>();
  const [zUp, setZUp] = React.useState<boolean>(false);
  const { handleError, clearErrors, buildErrorList, includeErrorPaths } =
    useErrorHandling<ApiRequestPath>();
  const upload = React.useRef<FileUpload>();

  const onFileAdded = async (file: File) => {
    try {
      setStatus(Status.Pending);
      clearErrors();
      setFile(file);

      upload.current = new FileUpload({
        apiBaseUrl: WebApi.getUrl(),
        authToken: new TokenService().get(),
        file,
        concurrency: 3,
      });

      upload.current.onProgress((bytes) => setProgress(bytes / file.size));
      const result = await upload.current.start();

      const uploadId = result.id;
      const newItem = await props.onUploaded(file.name, uploadId, zUp);

      props.onClose(newItem);
    } catch (err: unknown) {
      setStatus(Status.Error);
      if (err instanceof UploadManuallyStoppedError) {
        return;
      }

      handleError(err);
    } finally {
      setProgress(0);
    }
  };

  return (
    <>
      <Dialog
        closeIcon={status !== Status.Pending}
        closeOnDimmerClick={status !== Status.Pending}
        useGoogleTranslate={true}
        width={500}
        onClose={props.onClose}
      >
        {{
          header: `Uppladdning - ${props.title}`,
          content: (
            <Stack direction="column" spacing={1}>
              {status !== Status.Pending && (
                <Text topMargin={false}>Välj en fil för att starta uppladdning.</Text>
              )}

              {props.showZUpCheckBox && (
                <Stack direction="row" spacing={0.5}>
                  <CheckBox
                    checked={zUp}
                    disabled={status === Status.Pending}
                    onChange={() => setZUp(!zUp)}
                  >
                    Använd Z som vertikal axel
                  </CheckBox>
                  <TooltipInfoIcon
                    content={
                      <div>
                        Kryssa i för att processera modellen med Z som den vertikala axeln.
                        <br />
                        <br />
                        Y-axeln representerar upp/ner i de flesta Cesium 3D Tiles-modeller. Om din
                        modell är manuellt konverterad kan Z-axeln representera upp/ner istället.
                        <br />
                        <br />
                        Lämna urbockad om du är osäker.
                      </div>
                    }
                    placement="top"
                    title="Använd Z som vertikal axel"
                    useGoogleTranslate={true}
                  />
                </Stack>
              )}

              {status === Status.Pending && (
                <Stack direction="column" spacing={1}>
                  <span>
                    Laddar upp <b>{file!.name}</b>...
                  </span>
                  <ProgressBar
                    height={'2em'}
                    max={1}
                    maxPercentage={99}
                    min={0}
                    value={progress}
                    width={'100%'}
                  />
                </Stack>
              )}

              {buildErrorList({
                filter: includeErrorPaths(['body.uploadId']),
                defaultMessage: 'Det gick inte att ladda upp filen.',
              })}
            </Stack>
          ),
          footer: {
            right: (
              <>
                <FileSelectionButton
                  accept={props.accept}
                  color="primary"
                  disabled={status === Status.Pending}
                  leftIcon={{ icon: ['fad', 'upload'] }}
                  multiple={false}
                  text="Välj fil"
                  variant="contained"
                  onFilesSelected={async (files) => {
                    if (files.length > 0) {
                      await onFileAdded(files[0]);
                    }
                  }}
                />

                <Button
                  variant="text"
                  onClick={() => {
                    upload.current?.stop();
                    props.onClose();
                  }}
                >
                  Avbryt
                </Button>
              </>
            ),
          },
        }}
      </Dialog>
    </>
  );
};

const Text = styled.span<{ topMargin?: boolean; bottomMargin?: boolean }>`
  display: block;

  strong {
    font-weight: 600;
  }

  margin-top: ${(props) => (props.topMargin ? '0.5em' : undefined)};
  margin-bottom: ${(props) => (props.bottomMargin ? '0.5em' : undefined)};
`;

export { NewUploadDialog };
