import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

import { publish, SubscriptionTopic } from '../../../js/messaging/pubsub';
import { isDefined } from '../../../js/utils/variables';
import cameraManager from '../../../js/viewer/camera-manager';
import { CameraType } from '../../../js/viewer/cameras/types';
import { DxfUnit } from '../../../js/viewer/elements/dxf/types';
import { Icon } from '../icon/icon';
import { LabelledContainer } from '../labelled-container/labelled-container';
import { Select } from '../select/select';
import { Stack } from '../stack/stack';
import { Tooltip } from '../tooltip/tooltip';

const CadSideMenu = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [selectedCameraMode, setSelectedCameraMode] = useState(CameraType.OrthographicCamera);
  const [selectedScaleUnit, setSelectedScaleUnit] = useState<DxfUnit>(DxfUnit.Meters);

  useEffect(() => {
    publish(SubscriptionTopic.SceneScaleChanged, selectedScaleUnit);
  }, [selectedScaleUnit]);

  useEffect(() => {
    if (isDefined(cameraManager().getActiveCamera())) {
      cameraManager().setActiveCameraAndController(selectedCameraMode);
    }
  }, [selectedCameraMode]);

  return (
    <Component>
      <Stack direction="row" justifyContent="space-around" spacing={0.5}>
        <Tooltip
          content={t('cadSideMenu.perspectiveIconTooltip.text', { ns: 'components' })}
          mainAxisOffset={20}
          title={t('cadSideMenu.perspectiveIconTooltip.title', { ns: 'components' })}
        >
          <Icon
            color={
              selectedCameraMode === CameraType.PerspectiveCamera
                ? theme.color.brand.dark
                : theme.color.gray.dark
            }
            icon={['fal', 'cube']}
            size="xl"
            onClick={() => setSelectedCameraMode(CameraType.PerspectiveCamera)}
            onHoverStyle={{
              color:
                selectedCameraMode === CameraType.PerspectiveCamera
                  ? theme.color.brand.dark
                  : theme.color.gray.light,
            }}
          />
        </Tooltip>
        <Tooltip
          content={t('cadSideMenu.orthoIconTooltip.text', { ns: 'components' })}
          mainAxisOffset={20}
          title={t('cadSideMenu.orthoIconTooltip.title', { ns: 'components' })}
        >
          <Icon
            color={
              selectedCameraMode === CameraType.OrthographicCamera
                ? theme.color.brand.dark
                : theme.color.gray.dark
            }
            icon={['fal', 'image']}
            size="xl"
            onClick={() => setSelectedCameraMode(CameraType.OrthographicCamera)}
            onHoverStyle={{
              color:
                selectedCameraMode === CameraType.OrthographicCamera
                  ? theme.color.brand.dark
                  : theme.color.gray.light,
            }}
          />
        </Tooltip>
      </Stack>

      <LabelledContainer
        color={theme.color.gray.light}
        fontSize={'1.1em'}
        fontWeight={700}
        text={t('cadSideMenu.unitScale', { ns: 'components' })}
      >
        <Select
          backgroundColor="rgba(255, 255, 255, 0.3)"
          color={theme.color.gray.light}
          fontWeight={700}
          optionColor={theme.color.gray.darkest}
          options={[
            {
              id: DxfUnit.Millimeters.toString(),
              name: t('cadSideMenu.scale.millimeter', { ns: 'components' }),
            },
            {
              id: DxfUnit.Meters.toString(),
              name: t('cadSideMenu.scale.meter', { ns: 'components' }),
            },
          ]}
          padding={0.2}
          value={selectedScaleUnit.toString()}
          onChange={(e) => {
            setSelectedScaleUnit(parseInt(e.target.value) as DxfUnit);
          }}
        />
      </LabelledContainer>
    </Component>
  );
};

const Component = styled.div`
  position: absolute;

  top: 200px;
  right: 38px;
  width: 112px;

  display: grid;
  justify-content: space-evenly;

  grid-template-columns: 1fr;
  grid-template-rows: auto;

  > * {
    margin: 0.5rem 0;
  }
`;

CadSideMenu.propTypes = {
  wrapWithLanguageProvider: PropTypes.any,
  wrapWithLanguageProviderSetNoHeight: PropTypes.any,
};

export { CadSideMenu, Component as CadSideMenuStyled };
