import React from 'react';
import styled from 'styled-components';

import { getOneMomentText } from '../../../../locales/temp';
import { useDialog } from '../../../hooks/use-dialog';
import { Center } from '../../../styles/center';
import { reset } from '../../../utils/styled-reset';
import { Button } from '../../button/button';
import { NewIntegrationDialog } from '../dialogs/new-integration-dialog/new-integration-dialog';
import { NoIntegrations } from '../no-integrations';
import { IntegrationListStatus, IntegrationsContext } from '../state/integrations-state';
import { IntegrationList } from './integration-list';

const IntegrationListContent = () => {
  const { integrations, selectedIntegration, integrationStatus } =
    React.useContext(IntegrationsContext);
  const newIntegrationDialog = useDialog();

  return integrationStatus === IntegrationListStatus.Success ? (
    <Component>
      <Header>
        <h1 className="notranslate">CONNECT</h1>

        <Button color="primary" variant="contained" onClick={() => newIntegrationDialog.show()}>
          Lägg till integration
        </Button>
      </Header>

      {integrations.length > 0 ? (
        <IntegrationList items={integrations} selectedItem={selectedIntegration} />
      ) : (
        <NoIntegrations />
      )}

      {newIntegrationDialog.render(
        <NewIntegrationDialog onClose={() => newIntegrationDialog.hide()} />,
      )}
    </Component>
  ) : (
    <Center>{getOneMomentText()}</Center>
  );
};

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1em;

  h1 {
    ${reset}

    font-size: 14pt;
    font-weight: 600;
    flex: 1;
  }
`;

const Component = styled.div`
  ${reset}

  display: flex;
  flex-direction: column;
  padding: 2em;
  height: 100%;
  background-color: #fff;
`;

export { IntegrationListContent };
