import { Placement } from '@floating-ui/react';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled, { css } from 'styled-components';

import { isDefined } from '../../../js/utils/variables';
import { reset } from '../../utils/styled-reset';
import { Tooltip } from '../tooltip/tooltip';

type Props = {
  color: 'yellow' | 'red' | 'green' | 'blue';
  title?: string;
  width?: 'full' | 'fit-content';
  padding?: 'small' | 'medium';
  fontSize?: 'small' | 'medium';
  className?: string;
  topMargin?: boolean;
  bottomMargin?: boolean;
  leftIcon?: FontAwesomeIconProps;
  children?: React.ReactNode;
  leftTooltip?: {
    title?: string;
    content: React.ReactNode;
    placement?: Placement;
    positionOutsideInfoBox?: boolean;
  };
  onLeftIconClick?: () => void;
};

const InfoBox = ({ width = 'full', padding = 'small', fontSize = 'small', ...props }: Props) => {
  const tooltipPosRef = React.useRef<HTMLDivElement>(null);
  const leftIcon = props.leftIcon ? (
    <LeftIcon {...props} onClick={props.onLeftIconClick} onLeftIconClick={props.onLeftIconClick}>
      <FontAwesomeIcon fixedWidth={true} {...props.leftIcon} />
    </LeftIcon>
  ) : null;

  return (
    // Notation styled(InfoBox) requires InfoBox to render the passed-in className prop,
    // in order for styles to be applied.
    <Component {...props} padding={padding} ref={tooltipPosRef} width={width}>
      {leftIcon &&
        (props.leftTooltip ? (
          <Tooltip
            content={props.leftTooltip?.content}
            mainAxisOffset={22}
            placement={props.leftTooltip?.placement ?? 'left'}
            posRef={props.leftTooltip?.positionOutsideInfoBox ?? true ? tooltipPosRef : undefined}
            title={props.leftTooltip?.title}
          >
            {leftIcon}
          </Tooltip>
        ) : (
          leftIcon
        ))}
      <Content padding={padding}>
        <div>
          {props.title && <Title>{props.title}</Title>}
          {props.children}
        </div>
      </Content>
    </Component>
  );
};

const Component = styled.div<
  Pick<Props, 'color' | 'width' | 'padding' | 'topMargin' | 'bottomMargin'>
>`
  ${reset}

  position: relative;
  display: flex;
  align-items: stretch;
  align-self: stretch;
  width: ${(props) => (props.width === 'fit-content' ? 'fit-content' : 'calc(100% - 1px)')};

  font-size: ${(props) => (props.padding === 'medium' ? '14px' : '13px')};
  line-height: 1.3em;
  background-color: #f8f8f8;
  border-left: 0.4em solid
    ${(props): string => {
      switch (props.color) {
        case 'yellow':
          return '#fac801';
        case 'green':
          return '#49af1a';
        case 'blue':
          return '#00a9e0';
        case 'red':
          return '#b22222';
      }
    }};

  margin-top: ${(props) => (props.topMargin ? '1em' : null)};
  margin-bottom: ${(props) => (props.bottomMargin ? '1em' : null)};

  // Add shadow and use margin to make overflowed content (shadow) visible.
  // (Width of shadow is also used in width above)
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.06),
    0 1px 2px rgba(0, 0, 0, 0.12);
  margin-right: 1px;

  strong {
    font-weight: 500;
  }
`;

InfoBox.styled = Component;

const LeftIcon = styled.div<Pick<Props, 'color' | 'onLeftIconClick'>>`
  position: relative;
  left: 0;
  display: flex;
  align-items: center;
  padding: 1em;
  user-select: none;

  background-color: ${(props) => {
    switch (props.color) {
      case 'yellow':
        return 'hsl(46, 75%, 89%)';
      case 'green':
        return 'hsl(124, 39%, 93%)';
      case 'blue':
        return 'hsl(195, 100%, 86%)';
      case 'red':
        return 'hsl(0, 37%, 94%)';
    }
  }};

  ${(props) =>
    isDefined(props.onLeftIconClick) &&
    css`
      &:active {
        background-color: ${() => {
          switch (props.color) {
            case 'yellow':
              return 'hsl(46, 75%, 78%)';
            case 'green':
              return 'hsl(124, 39%, 80%)';
            case 'blue':
              return 'hsl(195, 100%, 73%)';
            case 'red':
              return 'hsl(0, 37%, 81%)';
          }
        }};
      }

      &:hover {
        cursor: pointer;

        &:not(:active) {
          background-color: ${() => {
            switch (props.color) {
              case 'yellow':
                return 'hsl(46, 75%, 83%)';
              case 'green':
                return 'hsl(124, 39%, 85%)';
              case 'blue':
                return 'hsl(195, 100%, 78%)';
              case 'red':
                return 'hsl(0, 37%, 86%)';
            }
          }};
        }
      }
    `}
`;

const Content = styled.div<Pick<Props, 'padding'>>`
  display: flex;
  flex: 1;
  align-items: center;

  padding: ${(props) => (props.padding === 'medium' ? '0.8em' : '0.5em')};

  p + p {
    margin-top: 0.75em;
  }

  > div {
    flex: 1;
  }
`;

const Title = styled.span`
  display: block;
  font-weight: 500;
  margin-bottom: 0.5em;
`;

export { InfoBox, Props as InfoBoxProps };
