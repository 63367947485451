import React from 'react';
import styled from 'styled-components';

import { reset } from '../../utils/styled-reset';

export interface Props {
  children: React.ReactNode;
}

const TableHead = (props: Props) => {
  return <Component>{props.children}</Component>;
};

const Component = styled.thead`
  ${reset}

  font-weight: 500;
`;

export { TableHead };
