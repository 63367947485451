import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Color } from 'three';

import { designColors } from '../../../js/viewer/elements/design/colors';
import { Point2d } from '../../../js/viewer/utilities/math-helpers';
import { useErrorHandling } from '../../hooks/use-error-handling';
import { Button } from '../button/button';
import { Dialog } from '../dialog/dialog';
import { LabelledContainer } from '../labelled-container/labelled-container';
import { Slider } from '../slider/slider';
import { Stack } from '../stack/stack';

interface Props {
  onConfirm: (rgbColor: [number, number, number]) => Promise<void> | void;
  onCancel: () => Promise<void> | void;
  position?: Point2d;
  width?: number;
  initialValue: [number, number, number];
  valueType?: 'text' | 'number';
  children?: React.ReactNode;
}

/**
 * A dialog for picking colors
 */
const ColorPickerDialog = (props: Props) => {
  const { t } = useTranslation();
  const [hsl, setHsl] = React.useState<[number, number, number]>([0, 1, 0.5]);
  const color = new Color().setHSL(...hsl);
  const { handleError, buildErrorList } = useErrorHandling();

  React.useEffect(() => {
    const hsl = new Color(...props.initialValue).getHSL({ h: 0, s: 0, l: 0 });
    setHsl([hsl.h, hsl.s, hsl.l]);
  }, [props]);

  const onSetColor = (hslColor: readonly [number, number, number]) => {
    setHsl([...hslColor]);
  };

  return (
    <Dialog
      closeIcon={false}
      closeOnDimmerClick={false}
      position={props.position ? { left: props.position.x, top: props.position.y } : 'center'}
      width={props.width ?? 250}
      onClose={props.onCancel}
    >
      {{
        header: t('colorPickerDialog.title', { ns: 'components' }),
        content: (
          <Content>
            {props.children}
            <LabelledContainer text={t('colorPickerDialog.color', { ns: 'components' })}>
              <ColorPreview data-color={color.toArray()} />
            </LabelledContainer>

            <Margin>
              <Stack direction="column" spacing={0.5}>
                <Stack direction="row" spacing={0.5}>
                  {designColors.colorPickerHsl.row1.map((color, idx) => (
                    <ColorPanel
                      color={color}
                      id={`row1-color${idx.toString()}`}
                      key={idx}
                      onSetColor={onSetColor}
                    />
                  ))}
                </Stack>
                <Stack direction="row" spacing={0.5}>
                  {designColors.colorPickerHsl.row2.map((color, idx) => (
                    <ColorPanel
                      color={color}
                      id={`row2-color${idx.toString()}`}
                      key={idx}
                      onSetColor={onSetColor}
                    />
                  ))}
                </Stack>
              </Stack>
            </Margin>

            <LabelledContainer text={t('colorPickerDialog.hue', { ns: 'components' })}>
              <SliderContainer>
                <Slider
                  max={1}
                  min={0}
                  step={0.01}
                  value={hsl[0]}
                  onChange={(value) => {
                    setHsl([value, hsl[1], hsl[2]]);
                  }}
                />
              </SliderContainer>
            </LabelledContainer>
            <LabelledContainer text={t('colorPickerDialog.saturation', { ns: 'components' })}>
              <SliderContainer>
                <Slider
                  max={1}
                  min={0}
                  step={0.01}
                  value={hsl[1]}
                  onChange={(value) => {
                    setHsl([hsl[0], value, hsl[2]]);
                  }}
                />
              </SliderContainer>
            </LabelledContainer>
            <LabelledContainer text={t('colorPickerDialog.lightness', { ns: 'components' })}>
              <SliderContainer>
                <Slider
                  max={1}
                  min={0}
                  step={0.01}
                  value={hsl[2]}
                  onChange={(value) => {
                    setHsl([hsl[0], hsl[1], value]);
                  }}
                />
              </SliderContainer>
            </LabelledContainer>

            {buildErrorList()}
          </Content>
        ),
        footer: {
          right: (
            <>
              <Button
                color="primary"
                variant="contained"
                onClick={async () => {
                  try {
                    await props.onConfirm([color.r, color.g, color.b]);
                  } catch (err) {
                    handleError(err);
                  }
                }}
              >
                {t('ok', { ns: 'common' })}
              </Button>
              <Button variant="text" onClick={props.onCancel}>
                {t('cancel', { ns: 'common' })}
              </Button>
            </>
          ),
        },
      }}
    </Dialog>
  );
};

type ColorPanelProps = {
  id: string;
  color: readonly [number, number, number];
  onSetColor: (color: readonly [number, number, number]) => void;
};

const ColorPanel = (props: ColorPanelProps) => {
  return (
    <SelectColorButton
      data-color={new Color().setHSL(...props.color).toArray()}
      data-testid={props.id}
      onClick={() => props.onSetColor(props.color)}
    />
  );
};

const SelectColorButton = styled.button<{ 'data-color': number[] }>`
  width: 1.2em;
  height: 1.2em;
  border-radius: 0.2em;
  border: 0.1em solid ${(props) => props.theme.color.gray.darkest};
  background-color: ${(props) =>
    `rgb(${props['data-color'].map((x) => Math.floor(x * 255)).join(',')})`};

  &:hover {
    border: 0.18em solid black;
  }
`;

const ColorPreview = styled.div<{ 'data-color': number[] }>`
  width: 100%;
  height: 2em;
  border-radius: 0.2em;
  background-color: ${(props) =>
    `rgb(${props['data-color'].map((x) => Math.floor(x * 255)).join(',')})`};
`;

const SliderContainer = styled.div`
  flex-grow: 1;
  height: 2em;
  padding-top: 0.18em;
`;

const Margin = styled.div`
  margin: auto;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

export { ColorPickerDialog };
