import { t } from 'i18next';
import React from 'react';
import styled from 'styled-components';

import { LabelledContainer } from '../../../labelled-container/labelled-container';
import { OptionLabels } from '../../../skyview/right-menu/components/option-labels';
import { RightPanelHeader } from '../../../skyview/right-menu/components/right-menu-header';
import { Stack } from '../../../stack/stack';
import { CadDrawingPdfReportMenu } from './cad-drawing-pdf-report/cad-drawing-pdf-report-menu';
import { DesignQuantityReportMenu } from './design-quantity-report/design-quantity-report-menu';
import { PrismPdfReportMenu } from './prism-pdf-report/prism-pdf-report-menu';
import { VolumeOverviewCsvReportMenu } from './volume-overview-csv-report/volume-overview-csv-report-menu';
import { VolumeOverviewPdfReportMenu } from './volume-overview-pdf-report/volume-overview-pdf-report-menu';

type ReportType =
  | 'volumeOverviewPdf'
  | 'volumeOverviewCsv'
  | 'prism'
  | 'cadDrawing'
  | 'designQuantityReport';

export const ReportToolMenu = () => {
  const [reportType, setReportType] = React.useState<ReportType>('cadDrawing');

  return (
    <Component spacing={0}>
      <RightPanelHeader icon={['fal', 'file-alt']} name={t('title', { ns: 'skyviewReport' })} />

      <Margin>
        <LabelledContainer text={t('reportTypeSection.header', { ns: 'skyviewReport' })}>
          <OptionLabels
            active={reportType}
            itemRows={[
              [
                {
                  title: t('reportTypeSection.cadDrawing', { ns: 'skyviewReport' }),
                  value: 'cadDrawing',
                  onSelect: () => setReportType('cadDrawing'),
                  tooltipText: t('reportTypeSection.cadDrawingTooltip', {
                    ns: 'skyviewReport',
                  }),
                },
              ],
              [
                {
                  title: t('reportTypeSection.volumeOverviewPdf', {
                    ns: 'skyviewReport',
                  }),
                  value: 'volumeOverviewPdf',
                  onSelect: () => setReportType('volumeOverviewPdf'),
                  tooltipText: t('reportTypeSection.volumeOverviewPdfTooltip', {
                    ns: 'skyviewReport',
                  }),
                },
              ],
              [
                {
                  title: t('reportTypeSection.volumeOverviewCsv', {
                    ns: 'skyviewReport',
                  }),
                  value: 'volumeOverviewCsv',
                  onSelect: () => setReportType('volumeOverviewCsv'),
                  tooltipText: t('reportTypeSection.volumeOverviewCsvTooltip', {
                    ns: 'skyviewReport',
                  }),
                },
              ],
              [
                {
                  title: t('reportTypeSection.prism', { ns: 'skyviewReport' }),
                  value: 'prism',
                  onSelect: () => setReportType('prism'),
                  tooltipText: t('reportTypeSection.prismTooltip', {
                    ns: 'skyviewReport',
                  }),
                },
              ],
              [
                {
                  title: t('reportTypeSection.designQuantityReport', { ns: 'skyviewReport' }),
                  value: 'designQuantityReport',
                  onSelect: () => setReportType('designQuantityReport'),
                  tooltipText: t('reportTypeSection.designQuantityReportTooltip', {
                    ns: 'skyviewReport',
                  }),
                },
              ],
            ]}
          />
        </LabelledContainer>
      </Margin>

      {reportType === 'cadDrawing' && <CadDrawingPdfReportMenu />}
      {reportType === 'volumeOverviewPdf' && <VolumeOverviewPdfReportMenu />}
      {reportType === 'volumeOverviewCsv' && <VolumeOverviewCsvReportMenu />}
      {reportType === 'prism' && <PrismPdfReportMenu />}
      {reportType === 'designQuantityReport' && <DesignQuantityReportMenu />}
    </Component>
  );
};

const Margin = styled.div`
  margin: 0.8em;
`;

const Component = styled(Stack)`
  height: 100%;
`;
