import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { CompanyModel } from '../../../../typings/api/skymap/rest/v0/.common';
import { SkyMapConnectDongle } from '../../../../typings/api/skymap/rest/v1/.common';
import { iiafe } from '../../../../utilities/async';
import { SkyMapAxiosServiceFactory } from '../../../js/services/axios/skymap-axios-service-factory';
import { Point2d } from '../../../js/viewer/utilities/math-helpers';
import { useDialog } from '../../hooks/use-dialog';
import { useErrorHandling } from '../../hooks/use-error-handling';
import { reset } from '../../utils/styled-reset';
import { Button } from '../button/button';
import { DataTable, DataTableColumns, DataTableProps } from '../data-table/data-table';
import { Icon } from '../icon/icon';
import { Stack } from '../stack/stack';
import { AddDongleDialog } from './add-dongle-dialog';
import { DeleteDongleConfirmationDialog } from './delete-dongle-confirmation-dialog';
import { DongleStatusChangeConfirmationDialog } from './dongle-status-change-confirmation-dialog';
import { EditDongleDialog } from './edit-dongle-dialog';
import DongleViewStateContext from './state/dongle-view-state';

export interface DongleTableView extends Record<string, any> {
  id: string;
  status: string;
  lastActive: 'YYYY-MM-DD';
  company: string;
  version: string;
  icon: React.ReactElement;
}

enum Status {
  Loaded,
  Loading,
  Error,
}

const donglesColumns: DataTableColumns<DongleTableView> = {
  id: {
    title: 'Id',
  },
  company: {
    title: 'Företag',
  },
  lastActive: {
    title: 'Senast aktiv',
  },
  version: {
    title: 'Version',
  },
  status: {
    title: 'Status',
  },
  icon: {
    title: '',
    unfilterable: true,
  },
};

const loadCompanies = async () => {
  const companyService = SkyMapAxiosServiceFactory.instance.createCompanyServiceV0();
  const response = await companyService.fetchAllCompanies({});

  return response ? response.data : [];
};

const loadDongles = async () => {
  const projectService = SkyMapAxiosServiceFactory.instance.createDongleServiceV1();
  const response = await projectService.fetchDongles();

  return response.data;
};

const createDongleTableView = (
  dongles: SkyMapConnectDongle[],
  companies: CompanyModel[],
  setCurrentDongleFunction: Dispatch<SetStateAction<SkyMapConnectDongle | undefined>>,
  showEditDialogFunction: (pos?: Point2d | undefined) => void,
) => {
  return dongles
    .sort((a, b) => (a.id < b.id ? -1 : 1))
    .map((dongle: SkyMapConnectDongle, index) => {
      const company = companies?.find((company) => company.id === dongle.companyId);
      const companyName = company ? company.name : '';

      return {
        id: dongle.id,
        status: dongle.status ? dongle.status : '',
        lastActive: dongle.lastSeen ? dongle.lastSeen : '',
        version: dongle.version ? dongle.version : '',
        company: companyName,
        icon: (
          <Icon
            icon={['fass', 'gear']}
            key={index}
            onClick={() => {
              void setCurrentDongleFunction(dongle);
              void showEditDialogFunction();
            }}
          />
        ),
      } as DongleTableView;
    });
};

const DongleList = () => {
  const [dongles, setDongles] = React.useState<SkyMapConnectDongle[]>([]);
  const [companies, setCompanies] = React.useState<CompanyModel[]>([]);
  const [donglesTableView, setDonglesTableView] = React.useState<DongleTableView[]>([]);
  const [dongleListStatus, setDongleListStatus] = React.useState(Status.Loaded);

  const addDongleDialog = useDialog();
  const dongleStatusChangeDialog = useDialog();
  const editDongleDialog = useDialog();
  const deleteDongleDialog = useDialog();

  const [currentDongle, setCurrentDongle] = React.useState<SkyMapConnectDongle | undefined>();
  const { buildErrorList, handleError } = useErrorHandling();

  const onUpdate = async () => {
    const companies = await DongleListFunctions.loadCompanies();
    const dongles = await DongleListFunctions.loadDongles();

    setCompanies(companies);
    setDongles(dongles);
    setDonglesTableView(
      DongleListFunctions.createDongleTableView(
        dongles,
        companies,
        setCurrentDongle,
        editDongleDialog.show,
      ),
    );
  };

  const closeConfirmDongleStatusChangeDialog = async () => {
    dongleStatusChangeDialog.hide();
    await onUpdate();
  };

  const closeEditDongeDialog = async () => {
    editDongleDialog.hide();
    await onUpdate();
  };

  const closeDeleteDongleDialog = async () => {
    deleteDongleDialog.hide();
    await onUpdate();
  };

  const closeAddDongleDialog = async () => {
    addDongleDialog.hide();
    await onUpdate();
  };

  React.useEffect(() => {
    setDongleListStatus(Status.Loading);
    iiafe(async () => {
      try {
        setCompanies(await DongleListFunctions.loadCompanies());
        setDongles(await DongleListFunctions.loadDongles());
        setDongleListStatus(Status.Loaded);
      } catch (error) {
        setDongleListStatus(Status.Error);
        handleError(error);
      }
    });
  }, [handleError]);

  React.useEffect(() => {
    setDonglesTableView(
      DongleListFunctions.createDongleTableView(
        dongles,
        companies,
        setCurrentDongle,
        editDongleDialog.show,
      ),
    );
  }, [dongles, companies, editDongleDialog.show]);

  const donglesTableStructure: DataTableProps<DongleTableView> = {
    columns: donglesColumns,
    items: donglesTableView,

    tableStyle: {
      headerCell: { backgroundColor: 'white' },
    },
  };

  return (
    <DongleViewStateContext.Provider
      value={{
        companies: companies,
        dongles: dongles,
        currentDongle: currentDongle,
      }}
    >
      <TopRightButtonContainer>
        <Button
          color={'primary'}
          disabled={dongleListStatus === Status.Error}
          variant={'contained'}
          onClick={() => {
            addDongleDialog.show();
          }}
        >
          Ny enhet
        </Button>
      </TopRightButtonContainer>
      {buildErrorList({
        infoBoxProps: {
          bottomMargin: true,
        },
      })}

      <DataTable {...donglesTableStructure} />
      {dongleListStatus === Status.Loading && (
        <Stack alignItems={'center'} margin="1em 0 0 0" spacing={0.5}>
          <Icon icon={['fas', 'spinner']} spin={true} />
        </Stack>
      )}
      {addDongleDialog.render(<AddDongleDialog onClose={closeAddDongleDialog} />)}
      {editDongleDialog.render(
        <EditDongleDialog
          openDeleteConfirmationDialog={deleteDongleDialog.show}
          openDongleStatusChangeConfirmationDialog={dongleStatusChangeDialog.show}
          onClose={closeEditDongeDialog}
        />,
      )}
      {dongleStatusChangeDialog.render(
        <DongleStatusChangeConfirmationDialog
          openEditDongleDialog={editDongleDialog.show}
          onClose={closeConfirmDongleStatusChangeDialog}
        />,
      )}
      {deleteDongleDialog.render(
        <DeleteDongleConfirmationDialog
          openEditDongleDialog={editDongleDialog.show}
          onClose={closeDeleteDongleDialog}
        />,
      )}
    </DongleViewStateContext.Provider>
  );
};

const TopRightButtonContainer = styled.div`
  ${reset}
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

export { DongleList };

export const DongleListFunctions = {
  loadCompanies: loadCompanies,
  loadDongles: loadDongles,
  createDongleTableView: createDongleTableView,
};
