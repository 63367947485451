import React from 'react';
import styled from 'styled-components';

import { Button } from '../../button/button';
import { Dialog } from '../../dialog/dialog';
import { Icon } from '../../icon/icon';
import { InfoBox } from '../../info-box/info-box';
import { Stack } from '../../stack/stack';
import {
  GcpRow,
  HeightFieldStatus,
  isValidCoordinateField,
  NameFieldStatus,
  NEFieldStatus,
} from '../gcp-parser';

type Props = {
  rows: GcpRow[];
  onClose: () => void;
  onSave: (rows: GcpRow[]) => void;
};

const ViewGcpDialog = (props: Props) => {
  const [rows, setRows] = React.useState<GcpRow[]>(props.rows);

  const includeRow = (index: number, include: boolean) => {
    // Create a new array of rows.
    const newRows = rows.map((row, i) =>
      i === index
        ? {
            ...row,
            included: include,
          }
        : row.name.value === row.name.value
          ? {
              ...row,
            }
          : row,
    );

    // Update name duplication error (important to create a new object for all rows where name
    // matches the name at the specified index, if we decide to update the "error" prop with a new
    // value.
    const sameNameRows = newRows.filter(
      (x) => x.name.value.trim().toLowerCase() === newRows[index].name.value.trim().toLowerCase(),
    );
    if (sameNameRows.length > 1) {
      sameNameRows.forEach((row) => {
        const duplicationsExist = sameNameRows.filter((x) => x.included).length > 1;
        row.name.status = duplicationsExist ? 'duplication' : 'valid';
      });
    }

    setRows(newRows);
  };

  const statusToText = (error?: NameFieldStatus | NEFieldStatus | HeightFieldStatus) => {
    switch (error) {
      case 'duplication':
        return 'En annan rad existerar redan med samma namn.';
      case 'invalid':
      case 'invalid_format':
        return 'Ogiltigt värde.';
      case 'invalid_geoid':
        return 'Koordinaten befinner sig utanför begränsningsområdet för projektets geoid.';
    }

    return undefined;
  };

  const hasNoIncludedRows = !rows.some((x) => x.included);
  const hasErrors = rows
    .filter((x) => x.included)
    .some(
      (x) =>
        !isValidCoordinateField(x.n) ||
        !isValidCoordinateField(x.e) ||
        !isValidCoordinateField(x.h) ||
        x.name.status !== 'valid',
    );

  return (
    <>
      <Dialog maxHeight="expand" useGoogleTranslate={true} width={700} onClose={props.onClose}>
        {{
          header: 'Ground Control Points (GCP)',
          content: (
            <Stack spacing={1}>
              {hasErrors && (
                <InfoBox color="red" leftIcon={{ icon: ['fad', 'exclamation'] }}>
                  Kontrollera rödmarkerade fält (placera muspekaren över fältet för mer
                  information). En rad kan exkluderas genom att klicka på ikonen till höger.
                </InfoBox>
              )}

              {rows.length === 0 ? (
                <InfoBox color="red" leftIcon={{ icon: ['fad', 'exclamation'] }}>
                  Filen är tom.
                </InfoBox>
              ) : (
                hasNoIncludedRows && (
                  <InfoBox color="red" leftIcon={{ icon: ['fad', 'exclamation'] }}>
                    Minst en rad måste inkluderas.
                  </InfoBox>
                )
              )}

              {rows.length > 0 && (
                <TableContainer numRows={rows.length.toString().length}>
                  <table>
                    <thead>
                      <tr>
                        <th>Namn</th>
                        <th>N (m)</th>
                        <th>E (m)</th>
                        <th>H (m)</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {rows.map((x, index) => (
                        <tr data-included={x.included} key={index}>
                          <ValueCell
                            data-invalid={x.included && x.name.status !== 'valid'}
                            title={statusToText(x.name.status)}
                          >
                            {x.name.value}
                          </ValueCell>
                          <ValueCell
                            data-invalid={x.included && !isValidCoordinateField(x.n)}
                            title={statusToText(x.n.status)}
                          >
                            {x.n.value}
                          </ValueCell>
                          <ValueCell
                            data-invalid={x.included && !isValidCoordinateField(x.e)}
                            title={statusToText(x.e.status)}
                          >
                            {x.e.value}
                          </ValueCell>
                          <ValueCell
                            data-invalid={x.included && !isValidCoordinateField(x.h)}
                            title={x.included ? statusToText(x.h.status) : undefined}
                          >
                            {x.h.value}
                          </ValueCell>
                          <td
                            title={x.included ? 'Exkludera' : 'Inkludera'}
                            onClick={() => includeRow(index, !x.included)}
                          >
                            {x.included ? (
                              <Icon color="crimson" icon={['fad', 'circle-minus']} />
                            ) : (
                              <Icon color="#00ad83" icon={['fad', 'circle-plus']} />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </TableContainer>
              )}
            </Stack>
          ),
          footer: {
            right: (
              <>
                {rows.length > 0 && (
                  <Button
                    color="primary"
                    disabled={hasNoIncludedRows || hasErrors}
                    variant="contained"
                    onClick={() => {
                      props.onSave(rows);
                    }}
                  >
                    Spara
                  </Button>
                )}

                <Button variant="text" onClick={props.onClose}>
                  {rows.length === 0 ? 'Stäng' : 'Avbryt'}
                </Button>
              </>
            ),
          },
        }}
      </Dialog>
    </>
  );
};

const ValueCell = styled.td`
  position: relative;
  border: 1px solid transparent;

  tr[data-included='false'] & {
    text-decoration: line-through;
    color: #ddd;
  }

  &[data-invalid='true'] {
    background-color: hsl(348, 83%, 47%);
    color: #fff;
    border: 1px solid #fff;
  }
`;

const TableContainer = styled.div<{ numRows: number }>`
  * {
    font-family: 'Roboto Mono';
  }

  overflow: auto;
  max-height: 400px;

  table {
    border-collapse: collapse;
  }

  // Header row.
  thead tr {
    position: sticky;
    top: 0;
    z-index: 2;

    th {
      background-color: #fff;
      text-align: left;
      white-space: nowrap;
    }

    // Hide row count for header.
    &:before {
      content: '';
      background-color: #fff;
    }
  }

  // Other rows.
  tbody tr {
    counter-increment: line;
    cursor: arrow;
    position: relative;

    td {
      position: relative;
      width: 0;
      white-space: nowrap;
    }

    &:hover {
      &::before {
        background-color: #eee;
      }

      td {
        background-color: #f5f5f5;
        border-color: #f5f5f5;

        &[data-invalid='true'] {
          background-color: hsl(348, 83%, 27%);
        }
      }
    }
  }

  // All rows.
  tr {
    &:before {
      content: counter(line);
      position: sticky;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: end;
      background-color: #f5f5f5;
      color: #888;
      width: ${(props) => props.numRows}ch;
      height: 2em;
      padding: 0 1ch;
      z-index: 1;
    }
  }

  // Make last cell sticky (include/exclude icon).
  td:last-child {
    position: sticky;
    right: 0;
    text-align: right;
    padding: 0 1ch;
    background-color: #fff;
    cursor: pointer;

    &:hover {
      background-color: #eee;
    }
  }

  td,
  th {
    padding: 0 20px;
  }

  // Expand the penultimate row.
  td:nth-last-child(2) {
    width: 100%;
  }

  td:last-child {
    text-align: center;
  }
`;

export { ViewGcpDialog, Props as ViewGcpDialogProps };
