import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { t } from 'i18next';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { InputHandler } from '../../../../../js/viewer/input-handler';
import { truncateText } from '../../../../utils/styled-helpers';
import { Icon } from '../../../icon/icon';
import { Stack } from '../../../stack/stack';

type Props = {
  icon: IconProp;
  name: string;
  className?: string;
  children?: ReactNode;
};

export const RightPanelHeader = (props: Props) => {
  return (
    <Component alignItems="center" className={props.className} direction="row" spacing={0.8}>
      <Icon icon={props.icon} />
      <NameLabel title={props.name}>{props.name}</NameLabel>
      {props.children}
      <Icon
        fixedWidth={true}
        icon={['fal', 'close']}
        mouseDownStyle={{ opacity: 0.5 }}
        title={t('close', { ns: 'common' })}
        onClick={() => InputHandler.instance.setTool('select')}
        onHoverStyle={{ icon: ['fas', 'close'] }}
      />
    </Component>
  );
};

export const Component = styled(Stack)`
  background-color: #dadada;
  padding: 0.8em;
  border-bottom: 1px solid #aaa;
  z-index: 1;

  * {
    font-size: 14px;
  }
`;

const NameLabel = styled.label`
  flex-grow: 1;

  // Increase line height to avoid cutting off the bottom of the characters and counter.
  line-height: 1.2em;
  // Negative margin to counter the extra height line height gives to the element.
  margin: -0.1em 0;

  ${truncateText(1)}
`;
