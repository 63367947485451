import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ThemeManager } from '../../../js/theming/theme-manager';
import { reset } from '../../utils/styled-reset';
import { FullPageBox } from '../full-page-box/full-page-box';
import { RegisterCompanyStepper } from './register-company-stepper';
import { FormState } from './state/form-state-provider';
import { StepperState } from './state/stepper-provider';
import { AdministratorAccountStep } from './steps/administrator-account-step';
import { CompanyInformationStep } from './steps/company-information-step';
import { SummaryStep } from './steps/summary-step';

type Props = {
  licenseType: 'Portal' | 'Design';
  overrideThemeBackgroundImageUrl?: string;
};

const RegisterCompany = (props: Props) => {
  const { t } = useTranslation();

  const steps = [
    {
      title: t('registerCompany.step1.title', { ns: 'components' }),
      component: <CompanyInformationStep key="company-information" />,
    },
    {
      title: t('registerCompany.step2.title', { ns: 'components' }),
      component: <AdministratorAccountStep key="administrator-account" />,
    },
    {
      title: t('registerCompany.step3.title', { ns: 'components' }),
      component: <SummaryStep key="summary" licenseType={props.licenseType} />,
    },
  ];

  return (
    <FullPageBox
      overrideThemeBackgroundImageUrl={
        ThemeManager.instance.hostnameStartsWith('moba')
          ? undefined
          : props.overrideThemeBackgroundImageUrl
      }
      width={'600px'}
    >
      <Component>
        <FormState>
          <StepperState currentStepIndex={0} steps={steps}>
            <RegisterCompanyStepper licenseType={props.licenseType} />
          </StepperState>
        </FormState>
      </Component>
    </FullPageBox>
  );
};

const Component = styled.div`
  ${reset}
  font-size: 14px;
`;

RegisterCompany.propTypes = {
  wrapWithLanguageProvider: PropTypes.any,
  licenseType: PropTypes.string.isRequired,
  overrideThemeBackgroundImageUrl: PropTypes.string,
};

export { RegisterCompany };
