import styled from 'styled-components';

export enum VolumeCalculationDataType {
  Area = 'Area',
  Design = 'Design',
  PointCloud = 'Punktmoln',
  Dxf = 'CAD',
  Glb = 'CAD',
  TerrainModel = 'Mark',
}

export const pdfPageDimensions = {
  a4: {
    landscape72Dpi: {
      width: 842,
      height: 595,
      defaultPadding: 24,
    },
  },
  a3: {
    landscape72Dpi: {
      height: 842,
      width: 1191,
      defaultPadding: 32,
    },
  },
};

export const StickySearchBox = styled.div<{ height: number }>`
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: ${(props) => props.height}px;
  z-index: 1;
  padding-top: 0.2em;
  background-color: white;
  box-shadow:
    0 8px 8px -4px rgba(0, 0, 0, 0.12),
    0 12px 12px -6px rgba(0, 0, 0, 0.24);

  // Adds white edges "borders" to the left and right of the search box.
  // Otherwise the box shadow of the elemens that scroll underneath it will be visible
  // behind the search box which does not look good.
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 0.2em;
    background-color: #fff;
  }

  &::before {
    left: -0.2em; // make sure this matches with content width
  }

  &::after {
    right: -0.2em; // make sure this matches with content width
  }
`;
