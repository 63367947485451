import React from 'react';
import styled from 'styled-components';

import { FileModel } from '../../../../../typings/api/skymap/rest/v1/.common';
import { filenameToFontAwesomeIcon } from '../../../../js/utils/io/file';
import { reset } from '../../../utils/styled-reset';
import { Icon } from '../../icon/icon';
import { useFileVersions } from '../hooks/use-file-versions';
import { FolderListViewContext } from '../state/folder-list-view-state';
import { FileVersion } from './file-version';

interface Props {
  file: FileModel;
}

const FileInfoPanel = (props: Props) => {
  const { setSelectedFile } = React.useContext(FolderListViewContext);
  const { getVersionNumber } = useFileVersions(props.file.versions);

  const { icon } = filenameToFontAwesomeIcon(props.file.name);

  const closeIconClick = () => {
    setSelectedFile(undefined);
  };

  return (
    <Component data-testid="file-info-panel">
      <Header>
        <Icon
          data-testid="icon-file"
          icon={['fad', icon]}
          opacity={0.6}
          style={{ marginRight: '0.5em' }}
        />
        <Filename>{props.file.name}</Filename>

        <Icon data-testid="close-icon" icon={'xmark'} onClick={closeIconClick} />
      </Header>

      <Content>
        {props.file.versions.map((x) => (
          <FileVersion
            file={props.file}
            key={x.id}
            version={x}
            versionNumber={getVersionNumber(x.id)}
          />
        ))}
      </Content>
    </Component>
  );
};

const Component = styled.aside`
  ${reset}
  font-size: 14px;

  display: flex;
  flex-direction: column;
  box-shadow: 0 0 1em #0003;
`;

const Header = styled.header`
  background-color: #fed530;
  padding: 1em;
  display: flex;
  align-items: center;

  div {
    flex: 1;
    margin-right: 1em;
  }
`;

const Filename = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;

  flex: 1;
  margin: 0 1.5em 0 0.5em;
`;

const Content = styled.div`
  flex: 1;

  padding: 0;
  overflow-y: auto;
`;

FileInfoPanel.styled = Component;

export { FileInfoPanel };
