import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { AssetWithFileInfo } from '../../../../../../js/viewer/elements/file-info/types';
import { useElementOverflow } from '../../../../../hooks/use-element-overflow';
import { truncateText } from '../../../../../utils/styled-helpers';
import { CheckBox } from '../../../../check-box/check-box';
import { Icon } from '../../../../icon/icon';
import { Stack } from '../../../../stack/stack';
import { AssetState } from '../../../state/asset-state';
import { CadGroupVisibilityContext } from '../../../state/cad-group-visibility-state';
import { CategoryVisibilityContext } from '../../../state/category-visibility-state';
import { CheckBoxContext } from '../../../state/checkbox-state';
import { CadAsset } from '../../assets/cad/cad-asset';
import { CheckBoxContainer } from '../../styles/checkbox-container';

export type CadGroupItem = {
  folderId: string;
  path: string;
  assets: AssetWithFileInfo[];
};

type Props = {
  cadGroup: CadGroupItem;
  name: string;
  stickyTopPos: number;
};

const CadGroup = (props: Props) => {
  const { t } = useTranslation();
  const checkBoxContext = React.useContext(CheckBoxContext);
  const categoryVisibilityContext = React.useContext(CategoryVisibilityContext);
  const cadGroupVisibilityContext = React.useContext(CadGroupVisibilityContext);
  const { ref: nameElementOverflowRef, isOverflowed: nameElementIsOverflowed } =
    useElementOverflow<HTMLDivElement>();

  if (cadGroupVisibilityContext === undefined) {
    throw new Error('CadGroup component must be wrapped within CadGroupVisibilityState.');
  }

  const checkBoxChecked = props.cadGroup.assets.every((x) =>
    checkBoxContext.checkedAssetUuids.includes(x.uuid),
  )
    ? true
    : props.cadGroup.assets.some((x) => checkBoxContext.checkedAssetUuids.includes(x.uuid))
      ? undefined
      : false;

  const onChange = () => {
    const assetUuids = props.cadGroup.assets.map((x) => x.uuid);
    if (checkBoxChecked) {
      checkBoxContext.uncheckAll(assetUuids);
    } else {
      checkBoxContext.checkAll(assetUuids);
    }
  };

  return (
    <Component
      expanded={cadGroupVisibilityContext.expanded}
      showEyeIcon={categoryVisibilityContext.visible && cadGroupVisibilityContext.visible}
      stickyTopPos={props.stickyTopPos}
    >
      <header
        onClick={() => cadGroupVisibilityContext.setExpanded(!cadGroupVisibilityContext.expanded)}
      >
        {checkBoxContext.visible && (
          <CheckBoxContainer
            disabled={checkBoxContext.disabled}
            onClick={(e) => {
              if (!checkBoxContext.disabled) {
                e.stopPropagation();
                onChange();
              }
            }}
          >
            <CheckBox
              checked={checkBoxChecked}
              disabled={checkBoxContext.disabled}
              onChange={onChange}
            />
          </CheckBoxContainer>
        )}

        <HeaderBody>
          <ExpandCollapseIcon
            fixedWidth={true}
            icon={['fas', cadGroupVisibilityContext.expanded ? 'caret-down' : 'caret-right']}
          />
          <Stack direction="row" flex={1} spacing={1}>
            <Stack direction="row" flex={1} spacing={0.5}>
              <Icon color="#FFF" icon={['fad', 'folder']} />
              <FolderPath
                ref={nameElementOverflowRef}
                title={nameElementIsOverflowed ? props.name : undefined}
              >
                {props.name.split('/').map((x) => (
                  <FolderName key={x}>{x}</FolderName>
                ))}
              </FolderPath>
            </Stack>

            <VisibilityIcon
              disabled={!categoryVisibilityContext.visible}
              fixedWidth={true}
              icon={[
                'fal',
                !categoryVisibilityContext.visible || !cadGroupVisibilityContext.visible
                  ? 'eye-slash'
                  : 'eye',
              ]}
              mouseDownStyle={{ opacity: 0.5 }}
              stopPropagationOnClick={true}
              title={
                !categoryVisibilityContext.visible
                  ? t('common.assetHeaderIcons.showCategory', { ns: 'skyviewAssetMenu' })
                  : cadGroupVisibilityContext.visible
                    ? t('hide', { ns: 'common' })
                    : t('show', { ns: 'common' })
              }
              onClick={() => cadGroupVisibilityContext.toggle()}
              onHoverStyle={{
                icon: [
                  'fad',
                  !categoryVisibilityContext.visible || !cadGroupVisibilityContext.visible
                    ? 'eye-slash'
                    : 'eye',
                ],
              }}
            />
          </Stack>
        </HeaderBody>
      </header>

      <Content expanded={cadGroupVisibilityContext.expanded}>
        {props.cadGroup.assets.map((x) => (
          <AssetState key={`cad-${x.uuid}`}>
            <CadAsset asset={x} />
          </AssetState>
        ))}
      </Content>
    </Component>
  );
};

const VisibilityIcon = styled(Icon)``;
const ExpandCollapseIcon = styled(Icon)`
  margin-right: 0.75em;
`;

const Component = styled.div<{ expanded: boolean; showEyeIcon: boolean; stickyTopPos: number }>`
  > header {
    cursor: pointer;
    background-color: #5f6a71;
    color: #fff;
    display: flex;
    position: sticky;
    top: ${(props) => props.stickyTopPos}px;
    z-index: 2;

    ${(props) =>
      props.showEyeIcon &&
      css`
        :not(:hover) {
          ${VisibilityIcon} {
            display: none;
          }
        }
      `}

    &:hover {
      background-color: #4b555a;
    }
  }
`;

const HeaderBody = styled.div`
  display: flex;
  flex: 1;
  padding: 0.3em 0.7em;
  align-items: center;
`;

const FolderPath = styled.div`
  flex: 1;
  ${truncateText(1)}
  font-size: 0.8em;
`;

const FolderName = styled.span`
  &::before {
    content: '/';
    margin-right: 0.5em;
    opacity: 0.5;
  }

  &:not(:first-child)::before {
    margin-left: 0.5em;
  }
`;

const Content = styled.div<{ expanded: boolean }>`
  display: ${(props) => (props.expanded ? 'block' : 'none')};
`;

export { CadGroup };
