import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { isDefined } from '../../../../../js/utils/variables';
import { AssetManager } from '../../../../../js/viewer/asset-manager';
import { useDialog } from '../../../../hooks/use-dialog';
import { getSelectedProjectId } from '../../../../routing/utils';
import { truncateText } from '../../../../utils/styled-helpers';
import { reset } from '../../../../utils/styled-reset';
import { Button } from '../../../button/button';
import { ConfirmationDialog } from '../../../confirmation-dialog/confirmation-dialog';
import { Hyperlink, HyperlinkStyled } from '../../../hyperlink/hyperlink';
import { InfoBox } from '../../../info-box/info-box';
import { LabelledContainer } from '../../../labelled-container/labelled-container';
import { Stack } from '../../../stack/stack';
import { Switch } from '../../../switch/switch';
import { Tooltip } from '../../../tooltip/tooltip';
import { useAssetMenu } from './use-asset-menu';
import { useCreateQuery } from './use-create-query';
import { useDeleteQuery } from './use-delete-query';
import { useFetchSharedView } from './use-fetch-shared-view';
import { useLink } from './use-link';
import { useToolbar } from './use-toolbar';

const ShareView = () => {
  const { t } = useTranslation();
  const projectId = getSelectedProjectId()!;
  const deleteDialog = useDialog();

  const [includeCameraView, setIncludeCameraView] = React.useState(false);
  const {
    data: sharedView,
    isRefetching,
    refetch,
    status: fetchStatus,
  } = useFetchSharedView(projectId);
  const { url, copyUrl } = useLink(sharedView);
  const {
    cameraTransformation,
    occlusionCulling,
    referencePlane,
    sceneType,
    sunDirectionPercent,
    timelineContainerId,
  } = useToolbar();
  const { status: createStatus, mutate: createLink } = useCreateQuery({ onSuccess: refetch });
  const { deleteLink, reset: resetDeleteQuery } = useDeleteQuery();
  const { checkedAssetIds } = useAssetMenu({
    showOverlay: fetchStatus === 'pending' || isRefetching || createStatus === 'pending',
    sharedView: sharedView ?? undefined,
  });

  React.useEffect(() => {
    setIncludeCameraView(isDefined(sharedView?.cameraTransformation));
  }, [sharedView]);

  const onCreateLinkButtonClicked = () => {
    const assets = AssetManager.instance.getAssets();

    const toRequestItem = (assetId: string) => {
      const asset = assets.find((x) => x.uuid === assetId)!;
      return {
        id: asset.assetId!,
        visible: asset.visible,
      };
    };

    createLink({
      body: {
        cameraTransformation: includeCameraView ? cameraTransformation : undefined,
        dynamicAssets: [
          ...checkedAssetIds.area,
          ...checkedAssetIds.cad,
          ...checkedAssetIds.comment,
          ...checkedAssetIds.line,
        ].map(toRequestItem),
        occlusionCulling,
        projectId,
        referencePlane,
        sceneType,
        staticAssets: checkedAssetIds.model.map(toRequestItem),
        sunDirectionPercent,
        timelineContainerId,
      },
    });
  };

  const renderInfoBox = () => {
    if (fetchStatus === 'pending' || isRefetching || createStatus === 'pending') {
      return (
        <InfoBox color="yellow" leftIcon={{ icon: ['fad', 'spinner'], spin: true }}>
          {t('oneMoment', { ns: 'common' })}
        </InfoBox>
      );
    }

    if (fetchStatus === 'error') {
      return (
        <InfoBox
          color="red"
          leftIcon={{ icon: ['fad', 'refresh'] }}
          leftTooltip={{
            content: t('tryAgain', { ns: 'common' }),
            placement: 'top',
            positionOutsideInfoBox: false,
          }}
          onLeftIconClick={refetch}
        >
          {t('unexpectedError', { ns: 'common' })}
        </InfoBox>
      );
    }

    if (createStatus === 'error') {
      return (
        <InfoBox color="red" leftIcon={{ icon: ['fad', 'exclamation'] }}>
          {t('shareView.errorCreatingLink', { ns: 'skyview' })}
        </InfoBox>
      );
    }

    if (fetchStatus === 'success' && isDefined(url)) {
      return (
        <InfoBox
          color="green"
          leftIcon={{ icon: ['fad', 'link'] }}
          leftTooltip={{
            content: t('shareView.copyLink.tooltip', { ns: 'skyview' }),
            placement: 'top',
            positionOutsideInfoBox: false,
          }}
          onLeftIconClick={copyUrl}
        >
          <Tooltip
            content={t('shareView.openLink.tooltip', { ns: 'skyview' })}
            mainAxisOffset={22}
            placement="top"
          >
            <Hyperlink target="_blank" url={url}>
              {url}
            </Hyperlink>
          </Tooltip>
        </InfoBox>
      );
    }

    return (
      <InfoBox color="yellow">
        <Trans components={{ br: <br /> }} i18nKey="shareView.noLinkInfo" ns="skyview" />
      </InfoBox>
    );
  };

  const deleteButtonDisabled = isRefetching || !isDefined(sharedView);
  const saveButtonDisabled =
    fetchStatus !== 'success' ||
    isRefetching ||
    isDefined(sharedView) ||
    createStatus === 'pending';

  return (
    <>
      <Component>
        <Stack spacing={0}>
          <Header>
            <h1>{t('shareView.title', { ns: 'skyview' })}</h1>
          </Header>

          <Content spacing={1}>
            {renderInfoBox()}

            <LabelledContainer text={t('shareView.saveCameraView', { ns: 'skyview' })}>
              {(formElementId) => (
                <Switch
                  checked={includeCameraView}
                  disabled={saveButtonDisabled}
                  id={formElementId()}
                  size={0.8}
                  onChange={() => {
                    setIncludeCameraView(!includeCameraView);
                  }}
                />
              )}
            </LabelledContainer>
          </Content>

          <Footer direction="row" justifyContent="space-between" spacing={0.5}>
            <Button
              color="error"
              disabled={deleteButtonDisabled}
              variant="contained"
              onClick={(e) =>
                deleteDialog.show({
                  x: e.clientX,
                  y: e.clientY,
                })
              }
            >
              {t('delete', { ns: 'common' })}
            </Button>
            <Button
              color="primary"
              disabled={saveButtonDisabled}
              variant="contained"
              onClick={onCreateLinkButtonClicked}
            >
              {t('shareView.createButton', { ns: 'skyview' })}
            </Button>
          </Footer>
        </Stack>
      </Component>

      {deleteDialog.render((pos) => (
        <ConfirmationDialog
          confirmationText={t('delete', { ns: 'common' })}
          negative={true}
          pos={pos}
          title={t('delete', { ns: 'common' })}
          onCancel={deleteDialog.hide}
          onConfirm={async () => {
            await deleteLink(sharedView!.id);
            deleteDialog.hide();
            await refetch();
            resetDeleteQuery();
          }}
        >
          <Stack direction="column" spacing={1}>
            <span>{t('shareView.delete.question', { ns: 'skyview' })}</span>
            <InfoBox
              color="red"
              leftIcon={{ icon: ['fad', 'exclamation'] }}
              title={t('shareView.delete.warningTitle', { ns: 'skyview' })}
            >
              {t('shareView.delete.warningText', { ns: 'skyview' })}
            </InfoBox>
          </Stack>
        </ConfirmationDialog>
      ))}
    </>
  );
};

const Component = styled.aside`
  position: absolute;
  right: 1em;
  top: 4em;
  width: 400px;
  background-color: #fff;
  box-shadow:
    0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 10px;
  overflow: hidden;
`;

const Header = styled.div`
  padding: 1em;
  background-color: #eee;
  border-bottom: 1px solid #ccc;

  h1 {
    ${reset}
    font-size: 18px;
    font-weight: 500;
  }
`;

const Content = styled(Stack)`
  padding: 1em;

  ${HyperlinkStyled} {
    ${truncateText(1)}
  }
`;

const Footer = styled(Stack)`
  padding: 1em;

  background-color: #eee;
  border-top: 1px solid #ccc;
`;

export { ShareView, Component as ShareViewStyled };
