import React from 'react';
import styled, { css, useTheme } from 'styled-components';

import { nameof } from '../../../../utils/nameof';
import { CheckBox } from '../../../check-box/check-box';
import { translateDbTagName } from '../../../project-user-tags/project-user-tags';
import { Table } from '../../../table/table';
import { CheckableTag } from './folder-tags-dialog';

interface Props {
  tag: CheckableTag;
  checked: boolean;
  disabled: boolean;
  onClick: (tag: CheckableTag) => void;
  onSelectionChanged: (selected: boolean, tag: CheckableTag) => void;
}

const TagRow = React.memo((props: Props) => {
  const theme = useTheme();
  const checkBoxRef = React.useRef<HTMLInputElement>(null);
  const { onSelectionChanged: propsOnSelectionChanged } = props;

  const onCheckBoxCellClick = () => {
    checkBoxRef.current?.click();
  };

  const onCheckBoxChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      propsOnSelectionChanged(e.target.checked, props.tag);
    },
    [props.tag, propsOnSelectionChanged],
  );

  return (
    <Component
      checked={props.checked}
      onClick={props.disabled ? undefined : () => props.onClick(props.tag)}
    >
      <Table.Cell
        padding={{ left: 0.7, right: 0.7 }}
        onClick={props.disabled ? undefined : onCheckBoxCellClick}
        onHoverBackgroundColor={props.checked ? theme.color.brand.medium : '#F0F0F0'}
      >
        <CheckBox
          checked={props.checked}
          disabled={props.disabled}
          ref={checkBoxRef}
          onChange={onCheckBoxChange}
        />
      </Table.Cell>

      <Table.Cell padding={{ left: 0.4 }}>
        <NameContainer validationNotFound={props.tag.validationNotFound}>
          <span>{translateDbTagName(props.tag.name)}</span>
        </NameContainer>
      </Table.Cell>
    </Component>
  );
});

TagRow.displayName = nameof({ TagRow });

const Component = styled(Table.Row)``;

const NameContainer = styled.div<Pick<Props['tag'], 'validationNotFound'>>`
  display: flex;
  gap: 0.75em;
  align-items: center;

  ${(props) =>
    props.validationNotFound &&
    css`
      color: crimson;
    `}
`;

export { TagRow };
