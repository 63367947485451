import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { reset } from '../../../../../../utils/styled-reset';
import { List } from '../../../../../list/list';

const TerrainModelInfo = () => {
  const { t } = useTranslation();
  return (
    <Grid>
      <Card>
        <CardTitle>
          {t('setupDialog.addOns.terrainModel.title', { ns: 'cloudProcessing' })}
        </CardTitle>
        <CardContent>
          {t('setupDialog.addOns.terrainModel.info', { ns: 'cloudProcessing' })}
        </CardContent>
        <List
          items={[
            t('setupDialog.addOns.terrainModel.terrainModelDxf', { ns: 'cloudProcessing' }),
            t('setupDialog.addOns.terrainModel.viewAndCalculateVolumes', { ns: 'cloudProcessing' }),
            t('setupDialog.items.dataExport', { ns: 'cloudProcessing' }),
          ]}
          marker="disc"
        />
      </Card>
    </Grid>
  );
};

const Grid = styled.div`
  display: grid;
  gap: 1em;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-auto-rows: repeat(auto, 3);
`;

const Card = styled.article`
  // Make sure grid-row spans the same number of columns specified in grid-auto-rows in parent
  // container. Use subgrid to align the variable sized card content.
  grid-row: span 3;
  grid-template-rows: subgrid;

  display: grid;
  gap: 1em;
  background-color: #fff3;
  border-radius: 0.5em;
  padding: 1em;
`;

const CardTitle = styled.h3`
  ${reset}
`;

const CardContent = styled.span`
  word-break: break-word;
`;

export { TerrainModelInfo };
