import type * as CSS from 'csstype';
import React from 'react';
import styled from 'styled-components';

import { Stack } from '../stack/stack';

type Props = {
  children: React.ReactNode;
  width: CSS.Properties['width'];
  overrideThemeBackgroundImageUrl?: string;
};

const FullPageBox = (props: Props) => {
  return (
    <Component>
      <Background overrideThemeBackgroundImageUrl={props.overrideThemeBackgroundImageUrl} />
      <Box width={props.width}>
        <Stack direction="column" spacing={2}>
          <Logotype />
          <div>{props.children}</div>
        </Stack>
      </Box>
    </Component>
  );
};

const Component = styled.div`
  display: grid;
  place-content: center;
  height: 100%;
`;

const Background = styled.div<{ overrideThemeBackgroundImageUrl?: string }>`
  --full-page-box-background: ${(props) =>
    props.overrideThemeBackgroundImageUrl
      ? `url(${props.overrideThemeBackgroundImageUrl})`
      : 'unset'};

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* IMAGES */
  background: var(--full-page-box-background);
  background-size: var(--full-page-box-background-size, cover);
`;

const Logotype = styled.div`
  width: 100%;
  height: 3em;
  background: var(--full-page-box-logotype) center no-repeat;
  background-size: contain;
`;

const Box = styled.div<Pick<Props, 'width'>>`
  width: ${(props) => props.width};
  padding: 1.3em;
  background-color: #fff;
  border-radius: 0.5em;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  z-index: 1;
`;

export { FullPageBox };
