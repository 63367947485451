import React from 'react';
import styled, { css } from 'styled-components';

import { isDefined } from '../../../js/utils/variables';
import { nameof } from '../../utils/nameof';
import { reset } from '../../utils/styled-reset';
import { InfoBox } from '../info-box/info-box';

interface Props {
  id?: string;
  value?: string;
  placeholder?: string;
  errorMessage?: string;
  disabled?: boolean;
  width?: number;
  height?: number;
  rows?: number;
  maxCharLength?: number;
  charSize?: number;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}

const TextArea = React.forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  return (
    <>
      <Component
        {...props}
        disabled={props.disabled}
        maxLength={props.maxCharLength}
        placeholder={props.placeholder}
        ref={ref}
        rows={props.rows}
        value={props.value}
        onBlur={props.onBlur}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
      />
      {props.errorMessage && (
        <ErrorMessage color="red" fontSize="small" padding="small">
          {props.errorMessage}
        </ErrorMessage>
      )}
    </>
  );
});

TextArea.displayName = nameof({ TextArea });

const Component = styled.textarea<Pick<Props, 'width' | 'height' | 'errorMessage'>>`
  && {
    ${reset}
    font-size: 14px;
    padding: 0.4em;
    resize: none;
    width: ${(props) => (props.width ? `${props.width}px` : '100%')};
    ${(props) =>
      isDefined(props.height) &&
      css`
        height: ${props.height}px;
      `}

    border: 1px solid ${(props) => (props.errorMessage !== undefined ? '#b22222' : '#888')};

    &:focus {
      outline: none;
    }
  }
`;

const ErrorMessage = styled(InfoBox)`
  margin-top: 3px;
`;

export { TextArea };
