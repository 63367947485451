import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  ReferencePlane,
  ReferencePlaneColors,
} from '../../../../../../js/viewer/elements/reference-plane/reference-plane';
import { useNonce } from '../../../../../hooks/use-nonce';
import { reset } from '../../../../../utils/styled-reset';
import { LabelledContainer } from '../../../../labelled-container/labelled-container';
import { Slider } from '../../../../slider/slider';
import { Stack } from '../../../../stack/stack';
import { TextBox } from '../../../../text-box/text-box';
import { ColorItem } from './color-item';

const Settings = () => {
  const { t } = useTranslation();
  const [_, refresh] = useNonce();
  const [input, setInput] = React.useState(ReferencePlane.instance.ReferenceHeight.toFixed(2));
  const [inputError, setInputError] = React.useState(false);

  const setHeight = (height: number) => {
    ReferencePlane.instance.ReferenceHeight = height;
    refresh();
  };

  const onReferencePlaneOpacityChanged = (value: number) => {
    ReferencePlane.instance.Opacity = value;
    refresh();
  };

  const setColor = (colorName: ReferencePlaneColors) => {
    ReferencePlane.instance.Color = colorName;
    refresh();
  };

  return (
    <>
      <Stack direction="column" flex={1} spacing={1}>
        <LabelledContainer
          noWrap={true}
          text={t('referencePlane.settings.mas.title', { ns: 'skyviewToolbar' })}
        >
          {(formElementId) => (
            <TextBox
              autoFocus={true}
              errorMessage={inputError ? '' : undefined}
              id={formElementId()}
              step={0.01}
              type="number"
              value={input}
              onInput={(e) => {
                if (Number.isNaN(e.currentTarget.valueAsNumber)) {
                  setInputError(true);
                  ReferencePlane.instance.Visibility = false;

                  setInput(e.currentTarget.value);
                } else {
                  setInputError(false);
                  ReferencePlane.instance.Visibility = true;

                  setInput(e.currentTarget.valueAsNumber.toString());
                  setHeight(e.currentTarget.valueAsNumber);
                }
              }}
            />
          )}
        </LabelledContainer>

        <LabelledContainer
          noWrap={true}
          text={t('referencePlane.settings.opacity.title', {
            ns: 'skyviewToolbar',
            value: ReferencePlane.instance.Opacity,
          })}
        >
          <Slider
            max={1}
            min={0}
            step={0.01}
            value={ReferencePlane.instance.Opacity}
            onChange={onReferencePlaneOpacityChanged}
          />
        </LabelledContainer>

        <LabelledContainer
          noWrap={true}
          text={t('referencePlane.settings.color.title', { ns: 'skyviewToolbar' })}
        >
          <ColorList>
            {Object.values(ReferencePlaneColors).map((color) => (
              <ColorItem
                color={color}
                key={color}
                selected={ReferencePlane.instance.Color === color}
                onSelected={() => setColor(color)}
              />
            ))}
          </ColorList>
        </LabelledContainer>
      </Stack>
    </>
  );
};

const ColorList = styled.ul`
  ${reset}

  display: flex;
  gap: 0.25em;
`;

export { Settings };
