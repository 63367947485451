import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Coordinates } from '../../../../../js/viewer/utilities/math-helpers';

export type CoordinateProps = {
  type: 'coordinates';
};

export const CoordinateProperties = ({
  displayMas = true,
  ...props
}: {
  coordinate: Coordinates;
  coordinateSystem?: string;
  displayMas?: boolean;
}) => {
  return (
    <Layout>
      <CoordinateInfo
        coordinate={props.coordinate}
        coordinateSystem={props.coordinateSystem}
        displayMas={displayMas}
      />
    </Layout>
  );
};

/**
 * Displays general metadata when using measure tool
 */
export const CoordinateInfo = ({
  displayMas = true,
  ...props
}: {
  coordinate: Coordinates;
  coordinateSystem?: string;
  displayMas?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <p className="coordinate-system">
        {props.coordinateSystem && (
          <>
            <label>
              {t('assetPositionTooltip.coordinateSystemProperties.coordinateSystem', {
                ns: 'skyview',
              })}
            </label>
            <span>{props.coordinateSystem}</span>
          </>
        )}
      </p>
      <p className="northing">
        <label>
          {t('assetPositionTooltip.coordinateSystemProperties.northing', {
            ns: 'skyview',
          })}
        </label>
        <span>{props.coordinate.northing.toFixed(2)}</span>
      </p>
      <p className="easting">
        <label>
          {t('assetPositionTooltip.coordinateSystemProperties.easting', {
            ns: 'skyview',
          })}
        </label>
        <span>{props.coordinate.easting.toFixed(2)}</span>
      </p>
      {displayMas && (
        <p className="mas">
          <label>
            {t('assetPositionTooltip.coordinateSystemProperties.mas', {
              ns: 'skyview',
            })}
          </label>
          <span>{props.coordinate.height.toFixed(2)}</span>
        </p>
      )}
      <p>
        <label>
          {t('assetPositionTooltip.coordinateSystemProperties.copyCoordinate', {
            ns: 'skyview',
          })}
        </label>
      </p>
    </>
  );
};

/**
 * Displays geodata point as xyz
 */
export const PositionInfo = (props: { coordinate: Coordinates }) => {
  return (
    <>
      <p>
        <label>X</label>
        <span>{props.coordinate.northing.toFixed(2)}</span>
      </p>
      <p>
        <label>Y</label>
        <span>{props.coordinate.easting.toFixed(2)}</span>
      </p>
      <p>
        <label>Z</label>
        <span>{props.coordinate.height.toFixed(2)}</span>
      </p>
    </>
  );
};

export const CoordinatePropertiesStyled = styled.div`
  display: grid;
  grid-column-gap: 50px;
  grid-row-gap: 10px;

  p {
    margin: 0;

    label {
      display: block;
      white-space: nowrap;
      text-align: left;
      color: #fffa;
      font-size: 0.9em;
      margin-bottom: 0.2em;
    }

    span {
      white-space: nowrap;
    }
  }

  .northing {
    grid-area: northing;
  }

  .easting {
    grid-area: easting;
  }

  .coordinate-system {
    grid-area: coordinate-system;
  }

  .mas {
    grid-area: mas;
    margin-top: 0.5em;
    border-top: 1px solid #fffa;
    padding-top: 2em;
  }
`;

const Layout = styled(CoordinatePropertiesStyled)`
  grid-template-areas:
    'coordinate-system  coordinate-system'
    'northing           easting'
    'mas                mas';
`;
