import { subWeeks } from 'date-fns';
import React, { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { formattedDateTime } from '../../../../../js/utils/dateUtils';
import { useDialog } from '../../../../hooks/use-dialog';
import { Button } from '../../../button/button';
import { DataTable, DataTableProps } from '../../../data-table/data-table';
import { DateTimeInput } from '../../../date-time-input/date-time-input';
import { IconPanel } from '../../../icon-panel/icon-panel';
import {
  IntegrationAsbuiltPoint,
  IntegrationsContext,
} from '../../../integrations/state/integrations-state';
import { LabelledContainer } from '../../../labelled-container/labelled-container';
import { Stack, StackStyled } from '../../../stack/stack';
import { SkyviewContext } from '../../skyview-state';
import { asbuiltContentColumns } from './asbuilt-content-columns';
import { AsbuiltExportDialog } from './asbuilt-export-dialog';

enum AsbuiltContentState {
  Idle,
  Loading,
}

const AsbuiltContent = () => {
  const { t } = useTranslation();
  const { skyview } = React.useContext(SkyviewContext);
  const { clearAsbuilt, loadAsbuilt, asbuilt } = useContext(IntegrationsContext);
  const [fromDate, setFromDate] = React.useState<Date | undefined>(subWeeks(new Date(), 1));
  const [toDate, setToDate] = React.useState<Date | undefined>(new Date());
  const [asbuiltContentState, setAsbuiltContentState] = React.useState(AsbuiltContentState.Idle);
  const asbuiltExportDialog = useDialog();

  // When the asbuilt panel is opened, publish the current asbuilt data.
  // When it is closed, publish an empty asbuilt array.
  useEffect(() => {
    skyview.asbuiltGeometry.setAsbuilt(asbuilt, []);

    return () => {
      skyview.asbuiltGeometry.setAsbuilt([], []);
    };
  }, [asbuilt, skyview.asbuiltGeometry]);

  useEffect(() => {
    return () => {
      if (asbuilt.length > 0) {
        clearAsbuilt();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAsbuiltContentState(AsbuiltContentState.Idle);
  }, [asbuilt]);

  /**
   *  Publish the new set of asbuilt points.
   *  Adds a little delay to not publish changes for each keystroke.
   */
  const onListChanged = (
    items: IntegrationAsbuiltPoint[],
    filteredItems: IntegrationAsbuiltPoint[],
  ) => {
    skyview.asbuiltGeometry.setAsbuilt(items, filteredItems);
  };

  const asbuiltTableStructure: DataTableProps<IntegrationAsbuiltPoint> = {
    columns: asbuiltContentColumns(),
    items: asbuilt,

    onListChanged: onListChanged.bind(this),

    tableStyle: {
      headerCell: { backgroundColor: 'white' },
    },
  };

  const displayEmptyTableIcon =
    (asbuiltContentState === AsbuiltContentState.Idle && asbuilt.length === 0) ||
    asbuiltContentState === AsbuiltContentState.Loading;
  const displayTableAndExportButton =
    asbuiltContentState === AsbuiltContentState.Idle && asbuilt.length > 0;
  const displayFetchAsbuiltButton = asbuiltContentState === AsbuiltContentState.Idle;
  const displayLoadingIndicator = asbuiltContentState === AsbuiltContentState.Loading;

  return (
    <Component>
      <Stack direction="column" spacing={0.5}>
        <h3>{t('title', { ns: 'skyviewAsbuilt' })}</h3>
        <Stack alignItems="stretch" direction="row" spacing={0.5}>
          <LabelledContainer text={t('dateFrom', { ns: 'skyviewAsbuilt' })}>
            <DateTimeInput
              collapseOnEmpty={false}
              data-testid={'datepicker-from'}
              dateFormatter={formattedDateTime}
              displayTime={true}
              initialValue={fromDate ?? new Date()}
              maxDate={toDate}
              onCleared={() => setFromDate(undefined)}
              onDateChoosen={(date) => {
                setFromDate(date);
              }}
            />
          </LabelledContainer>

          <DashStyle>-</DashStyle>

          <LabelledContainer text={t('dateTo', { ns: 'skyviewAsbuilt' })}>
            <DateTimeInput
              collapseOnEmpty={false}
              data-testid={'datepicker-to'}
              dateFormatter={formattedDateTime}
              displayTime={true}
              initialValue={toDate ?? new Date()}
              minDate={fromDate}
              onCleared={() => setToDate(undefined)}
              onDateChoosen={(date) => {
                setToDate(date);
              }}
            />
          </LabelledContainer>
        </Stack>

        {(displayFetchAsbuiltButton || displayLoadingIndicator) && (
          <Button
            color="primary"
            loading={displayLoadingIndicator}
            variant="contained"
            onClick={() => {
              setAsbuiltContentState(AsbuiltContentState.Loading);
              loadAsbuilt(fromDate, toDate);
            }}
          >
            {t('fetchButton', { ns: 'skyviewAsbuilt' })}
          </Button>
        )}
        {displayEmptyTableIcon && (
          <IconPanel
            header={{ icon: { icon: ['fad', 'chart-network'], opacity: '50%', size: '10x' } }}
            responsiveness={{ componentPxHeightBreak: 356 }}
          >
            {{
              title: t('info.title', { ns: 'skyviewAsbuilt' }),
              info: (
                <Trans components={{ bold: <strong /> }} i18nKey="info.text" ns="skyviewAsbuilt" />
              ),
            }}
          </IconPanel>
        )}
        {displayTableAndExportButton && (
          <>
            <DataTable {...asbuiltTableStructure} />
            <Button
              color="secondary"
              variant="contained"
              onClick={() => asbuiltExportDialog.show()}
            >
              {t('exportButton', { ns: 'skyviewAsbuilt' })}
            </Button>
          </>
        )}
      </Stack>

      {asbuiltExportDialog.render(
        <AsbuiltExportDialog onClose={() => asbuiltExportDialog.hide()} />,
      )}
    </Component>
  );
};

const Component = styled.div`
  overflow: hidden;

  > ${StackStyled} {
    height: 100%;
  }
`;

const DashStyle = styled.div`
  padding-top: 24px;

  font-size: 42px;
`;

export { Component as AsbuiltComponentStyle, AsbuiltContent };
