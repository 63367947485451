import React from 'react';
import styled, { css } from 'styled-components';

import { reset } from '../../../../utils/styled-reset';
import { Stack } from '../../../stack/stack';

interface Props {
  src?: string;
  title: string;
  onLayerSelected?: () => void;
  selected: boolean;
  disabled: boolean;
}

const WmsLayerSelectionItem = ({ src, title, onLayerSelected, selected, disabled }: Props) => {
  return (
    <StyledContainer
      alignItems="center"
      direction="column"
      disabled={disabled}
      selected={selected}
      spacing={0.5}
      onClick={disabled ? undefined : onLayerSelected}
    >
      <StyledLayerImage disabled={disabled} src={src} />
      <StyledTitle>{title}</StyledTitle>
    </StyledContainer>
  );
};

const StyledLayerImage = styled.div<{ disabled: boolean; src?: string }>`
  width: 64px;
  height: 64px;
  border-radius: 0.5em;
  background-image: url(${(props) => props.src});
  background-color: #ccddf0;
  background-position: center;
  background-size: 64px 64px;

  ${(props) =>
    props.disabled &&
    css`
      filter: grayscale(100%);
    `}
`;

const StyledTitle = styled.span`
  ${reset}

  font-size: 14px;
`;

const StyledContainer = styled(Stack)<{ selected: boolean; disabled: boolean }>`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  border-radius: 5px;

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.3;
    `}

  ${StyledLayerImage} {
    ${(props) =>
      props.selected &&
      css`
        border: 2px solid ${(props) => props.theme.color.blue};
        padding: 0.2em;
        background-clip: content-box;
      `}
  }

  &:hover ${StyledLayerImage} {
    ${(props) =>
      !props.disabled &&
      css`
        border: 2px solid ${(props) => props.theme.color.blue};
      `}
  }
`;

export { WmsLayerSelectionItem };
