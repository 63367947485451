import React from 'react';
import styled from 'styled-components';

import { reset } from '../../utils/styled-reset';
import { Icon, IconProps } from '../icon/icon';

interface Props extends Omit<IconProps, 'onClick'> {
  onFilesSelected: (files: File[]) => void | Promise<void>;
  accept?: string;
  multiple?: boolean;
}

export const FileSelectionIcon = (props: Props) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const onSelect = () => {
    if (inputRef.current?.files) {
      void props.onFilesSelected([...inputRef.current.files]);
      resetFileSelection();
    }
  };

  const resetFileSelection = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  return (
    <>
      <Icon
        {...props}
        onClick={() => {
          inputRef.current?.click();
        }}
      />

      <FileInput
        accept={props.accept}
        data-testid="file-selection"
        multiple={props.multiple ?? false}
        ref={inputRef}
        onChange={onSelect}
      />
    </>
  );
};

const FileInput = styled.input.attrs(() => ({
  type: 'file',
}))`
  // Bump up specificity.
  // "display:none;" from global css used otherwise.
  && {
    ${reset}

    display: none;
  }
`;
