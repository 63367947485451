import { parseISO } from 'date-fns';
import React from 'react';
import styled from 'styled-components';
import _ from 'underscore';

import { SkyMapLeicaConnectionModel } from '../../../../../typings/api/integrations/leica/rest/v1/skymap-leica-connection';
import {
  AsbuiltPoint,
  GetAsbuiltPointsParameters,
  IntegrationIssue,
} from '../../../../../typings/api/integrations/xsite/rest/v1/skymap-xsite-connection';
import {
  SkyMapConnectDongle,
  SkyMapConnectDongleRegistration,
} from '../../../../../typings/api/skymap/rest/v1/.common';
import { DroneHarmonyIntegrationResponse } from '../../../../../typings/api/skymap/rest/v1/project';
import { publish, SubscriptionTopic } from '../../../../js/messaging/pubsub';
import { TokenService } from '../../../../js/services/authentication/token.service';
import { LeicaAxiosServiceFactory } from '../../../../js/services/axios/leica-axios-service-factory';
import { SkyMapAxiosServiceFactory } from '../../../../js/services/axios/skymap-axios-service-factory';
import { XsiteAxiosServiceFactory } from '../../../../js/services/axios/xsite-axios-service-factory';
import { ThemeManager } from '../../../../js/theming/theme-manager';
import { RequestParamsToApiPaths } from '../../../../js/utils/typescript-utils';
import { isDefined } from '../../../../js/utils/variables';
import {
  errorUtils,
  getHttpStatusCode,
  handleError,
  TranslatedError,
} from '../../../api/error-handling';
import { useNonce } from '../../../hooks/use-nonce';
import { useParams } from '../../../hooks/use-params';
import { AppContext } from '../../../state/app-state';
import { ProjectContext } from '../../../state/project-state';
import { Center } from '../../../styles/center';
import { reset } from '../../../utils/styled-reset';
import { replaceUrlQueryString } from '../../../utils/url';
import { Button } from '../../button/button';

export type XsiteIntegrationItem = {
  key: 'xsite_manage';
  name: string;
  provider: string;
  status: IntegrationStatus;
  createdAt?: string;
  xsiteSiteId?: string;
  integrationId?: string;
  issues?: IntegrationIssue[];
  requireConnectAgreement: true;
};

export type LeicaIntegrationItem = {
  key: 'leica_conx';
  name: string;
  provider: string;
  status: IntegrationStatus;
  model?: SkyMapLeicaConnectionModel;
  issues?: IntegrationIssue[];
  requireConnectAgreement: true;
};

export type DonglesIntegrationItem = {
  key: 'dongles';
  name: string;
  provider: string;
  status: IntegrationStatus;
  projectId: string;
  issues?: IntegrationIssue[];
  requireConnectAgreement: true;
};

export type DroneHarmonyIntegrationItem = {
  key: 'drone_harmony';
  name: string;
  provider: string;
  projectId: string;
  issues?: IntegrationIssue[];
  requireConnectAgreement: false;
} & (
  | {
      status: IntegrationStatus.Setup;
    }
  | {
      status: IntegrationStatus.Active;
      createdAt: Date;
      authentication: DroneHarmonyIntegrationResponse['data']['authentication'];
      integration: DroneHarmonyIntegrationResponse['data']['integration'];
    }
);

type LoadXsiteApiRequestPath = RequestParamsToApiPaths<GetAsbuiltPointsParameters>;

export const isXsiteIntegrationItem = (item: IntegrationItem) => item.key === 'xsite_manage';
export const isLeicaIntegrationItem = (item: IntegrationItem) => item.key === 'leica_conx';
export const isDonglesIntegrationItem = (item: IntegrationItem) => item.key === 'dongles';

export const buildActiveDroneHarmonyIntegrationFromApiResponse = (
  data: DroneHarmonyIntegrationResponse['data'],
) =>
  buildDroneHarmonyIntegration(data.integration.projectId, {
    status: IntegrationStatus.Active,
    createdAt: parseISO(data.integration.createdAt),
    integration: data.integration,
    authentication: data.authentication,
  });

const integrationsThatSupportAsbuilt = ['xsite_manage'];

export type IntegrationItemKeyToType<T extends IntegrationItem['key']> =
  XsiteIntegrationItem extends {
    key: T;
  }
    ? XsiteIntegrationItem
    : LeicaIntegrationItem extends { key: T }
      ? LeicaIntegrationItem
      : DonglesIntegrationItem extends { key: T }
        ? DonglesIntegrationItem
        : DroneHarmonyIntegrationItem extends { key: T }
          ? DroneHarmonyIntegrationItem
          : never;

export type IntegrationItem =
  | XsiteIntegrationItem
  | LeicaIntegrationItem
  | DonglesIntegrationItem
  | DroneHarmonyIntegrationItem;

export enum IntegrationStatus {
  Setup,
  Active,
}

export enum IntegrationListStatus {
  Pending,
  Success,
  Failure,
}

export enum IntegrationAsbuiltStatus {
  NoIntegrations,
  Supported,
  NotSupported,
}

export interface IntegrationsContext {
  integrations: IntegrationItem[];
  selectedIntegration?: IntegrationItem;
  agreementAccepted: boolean;
  integrationStatus: IntegrationListStatus;
  asbuilt: IntegrationAsbuiltPoint[];
  addIntegration: (item: IntegrationItem) => void;
  toggleIntegration: (key: IntegrationItem) => void;
  removeIntegration: (key: IntegrationItem) => void;
  replaceIntegrationItem: <T extends IntegrationItem['key']>(
    key: T,
    item: IntegrationItemKeyToType<T>,
  ) => void;
  setAgreementAccepted: (value: boolean) => void;
  extractVolatileCharacters: (value: string) => string[];
  loadAsbuilt: (from?: Date, to?: Date) => void;
  clearAsbuilt: () => void;
  integrationAsbuiltSupportState: () => IntegrationAsbuiltStatus;
  skyMapDongles: SkyMapConnectDongle[];
  skyMapDongleRegistrations: SkyMapConnectDongleRegistration[];
  loadDonglesConnection: (
    skyMapProjectId: string,
    companyId: string | null,
  ) => Promise<IntegrationItem | undefined>;
}

export type IntegrationAsbuiltPoint = AsbuiltPoint & { integration: 'Xsite' | 'ConX' };

export const IntegrationsContext = React.createContext<IntegrationsContext>(undefined!);

export const getDonglesIntegration = (
  status: IntegrationStatus,
  projectId: string,
): IntegrationItem => {
  return {
    key: 'dongles',
    name: 'SkyMap Dongle',
    provider: 'SkyMap Innovations AB',
    status,
    projectId: projectId,
    requireConnectAgreement: true,
  };
};

export const getMobaXsiteIntegration = (
  status: IntegrationStatus,
  xsiteSiteId?: string,
  createdAt?: string,
  integrationId?: string,
  issues?: IntegrationIssue[],
): IntegrationItem => {
  const providerName = ThemeManager.instance.hostnameStartsWith('moba-de')
    ? 'MOBA Construction Solutions GmbH'
    : 'Moba Sweden AB';

  return {
    key: 'xsite_manage',
    name: 'Xsite Manage',
    provider: providerName,
    status,
    xsiteSiteId,
    createdAt,
    integrationId,
    issues,
    requireConnectAgreement: true,
  };
};

export const getLeicaIntegration = (
  status: IntegrationStatus,
  model?: SkyMapLeicaConnectionModel,
  issues?: IntegrationIssue[],
): IntegrationItem => {
  return {
    key: 'leica_conx',
    name: 'Leica ConX',
    provider: 'Leica Geosystems AB',
    status,
    model,
    issues,
    requireConnectAgreement: true,
  };
};

type BuildDroneHarmonyIntegrationProps =
  | {
      status: IntegrationStatus.Setup;
      issues?: IntegrationIssue[];
    }
  | {
      status: IntegrationStatus.Active;
      createdAt: Date;
      issues?: IntegrationIssue[];
      authentication: DroneHarmonyIntegrationResponse['data']['authentication'];
      integration: DroneHarmonyIntegrationResponse['data']['integration'];
    };

export const buildDroneHarmonyIntegration = (
  projectId: string,
  props: BuildDroneHarmonyIntegrationProps,
): DroneHarmonyIntegrationItem => {
  return {
    key: 'drone_harmony',
    name: 'Drone Harmony för DJI Dock',
    provider: 'Drone Harmony Ag',
    projectId,
    requireConnectAgreement: false,
    ...props,
  };
};

type Props = {
  children: React.ReactNode;
};

export const extractVolatileCharacters = (name: string) => {
  return _.uniq(
    name
      .match(/[^A-Za-z0-9 _\-.]+/g)
      ?.join('')
      .split('') ?? [],
  );
};

const IntegrationsState = (props: Props) => {
  const { project } = React.useContext(ProjectContext);
  const { currentUser, hasAdminRights } = React.useContext(AppContext);

  const { selected } = useParams();
  const [integrations, setIntegrations] = React.useState<IntegrationItem[]>([]);
  const [asbuilt, setAsbuilt] = React.useState<IntegrationAsbuiltPoint[]>([]);
  const [selectedIntegration, setSelectedIntegration] = React.useState<
    IntegrationItem | undefined
  >();

  const [agreementAccepted, setAgreementAccepted] = React.useState(false);
  const [status, setStatus] = React.useState(IntegrationListStatus.Pending);
  const [refreshNonce, setRefreshNonce] = useNonce();

  const token = new TokenService().get();
  const skyMapXsiteConnectionService = React.useMemo(
    () => XsiteAxiosServiceFactory.instance.createSkyMapXsiteConnectionServiceV1(),
    [],
  );
  const skyMapLeicaConnectionService = React.useMemo(
    () => LeicaAxiosServiceFactory.instance.createConnectionServiceV1(),
    [],
  );
  const [skyMapDongles, setSkyMapDongles] = React.useState<SkyMapConnectDongle[]>([]);
  const [skyMapDongleRegistrations, setSkyMapDongleRegistrations] = React.useState<
    SkyMapConnectDongleRegistration[]
  >([]);

  const addIntegration = async (item: IntegrationItem) => {
    setIntegrations([item, ...integrations]);
    await toggleIntegration(item);
  };

  const toggleIntegration = async (item: IntegrationItem) => {
    if (selectedIntegration?.key === item.key) {
      setSelectedIntegration(undefined);
      await replaceUrlQueryString(new URLSearchParams([['selected', '']]));
    } else {
      setSelectedIntegration(item);
      await replaceUrlQueryString(new URLSearchParams([['selected', item.key]]));
    }
  };

  const removeIntegration = (item: IntegrationItem) => {
    setIntegrations([...integrations.filter((x) => x.key !== item.key)]);
  };

  const resolveAgreementAccepted = React.useCallback(async () => {
    if (!hasAdminRights()) {
      return;
    }

    try {
      await SkyMapAxiosServiceFactory.instance
        .createCompanyServiceV1()
        .getSkyMapConnectLicenseAgreement({ path: { companyId: project.companyId! } });

      setAgreementAccepted(true);
    } catch (err: any) {
      // If the agreement is not found, ignore error and continue.
      if (
        !errorUtils.isResponseError(
          err,
          'resource_not_found',
          'company:skymap_connect_license_agreement_not_found',
        )
      ) {
        throw err;
      }
    }
  }, [setAgreementAccepted, project.companyId, hasAdminRights]);

  const resolveSkyMapXsiteConnection = React.useCallback(
    async (skyMapProjectId: string, token: string) => {
      try {
        return (
          await skyMapXsiteConnectionService.getSkyMapXsiteConnection({
            query: {
              skyMapProjectId: skyMapProjectId,
              skyMapAuthenticationToken: token,
            },
          })
        ).data;
      } catch (err: any) {
        // If the request is undefined,
        // it is a project user that is requesting the data, and no information should be returned.
        // TODO: [SK-4382] Remove "as never" when integration bubbles response error codes are be generated from backend
        // and included in typings
        if (
          errorUtils.isResponseError(
            err,
            'unauthorized',
            'xsite:unauthorized_skymap_token' as never,
          )
        ) {
          return undefined;
        }

        throw err;
      }
    },
    [skyMapXsiteConnectionService],
  );

  const loadXsiteSkyMapConnection = React.useCallback(async () => {
    const statusResponse = await skyMapXsiteConnectionService.getSkyMapXsiteConnectionStatus({
      query: {
        skyMapProjectId: project.id,
      },
    });

    if (statusResponse.data.status === 'active') {
      let skyMapXsiteConnectionData = undefined;
      if (token) {
        skyMapXsiteConnectionData = await resolveSkyMapXsiteConnection(project.id, token);
      }

      return getMobaXsiteIntegration(
        IntegrationStatus.Active,
        skyMapXsiteConnectionData?.xsiteSiteId,
        skyMapXsiteConnectionData?.createdAt,
        skyMapXsiteConnectionData?.id,
        statusResponse.data.issues as IntegrationIssue[],
      );
    }
  }, [project.id, resolveSkyMapXsiteConnection, skyMapXsiteConnectionService, token]);

  const loadLeicaXsiteConnection = React.useCallback(
    async (skyMapProjectId: string, token: string) => {
      try {
        const connectionData = (
          await skyMapLeicaConnectionService.getSkyMapLeicaConnection({
            query: {
              skyMapProjectId: skyMapProjectId,
              skyMapAuthToken: token,
            },
          })
        ).data;

        return getLeicaIntegration(
          IntegrationStatus.Active,
          connectionData.model,
          connectionData.issues,
        );
      } catch (err: any) {
        if (getHttpStatusCode(err) === 404) {
          return undefined;
        }

        // If the request is undefined,
        // it is a project user that is requesting the data, and no information should be returned.
        // TODO: [SK-4382] Remove "as never" when integration bubbles response error codes are be generated from backend
        // and included in typings
        if (
          errorUtils.isResponseError(
            err,
            'unauthorized',
            'leica:unauthorized_skymap_token' as never,
          )
        ) {
          return undefined;
        }

        throw err;
      }
    },
    [skyMapLeicaConnectionService],
  );

  const loadDonglesConnection = React.useCallback(
    async (skyMapProjectId: string, companyId: string | null) => {
      let dongles: SkyMapConnectDongle[] = [];
      let dongleRegistrations: SkyMapConnectDongleRegistration[] = [];

      try {
        dongles = (
          await SkyMapAxiosServiceFactory.instance
            .createCompanyServiceV1()
            .getAttachedDongles({ path: { companyId: companyId ? companyId : '' } })
        ).data;
        dongleRegistrations = (
          await SkyMapAxiosServiceFactory.instance
            .createProjectServiceV1()
            .getAttachedDonglesList({ path: { projectId: skyMapProjectId } })
        ).data;
        setSkyMapDongleRegistrations(dongleRegistrations);
        setSkyMapDongles(dongles);
      } catch (err: any) {
        return undefined;
      }

      return dongleRegistrations.length > 0
        ? getDonglesIntegration(IntegrationStatus.Active, project.id)
        : undefined;
    },
    [project.id],
  );

  const loadDroneHarmonyConnection = React.useCallback(async () => {
    try {
      const { data } = await SkyMapAxiosServiceFactory.instance
        .createProjectServiceV1()
        .getDroneHarmonyIntegration({
          path: {
            projectId: project.id,
          },
        });

      return buildActiveDroneHarmonyIntegrationFromApiResponse(data);
    } catch (err: any) {
      return undefined;
    }
  }, [project.id]);

  React.useEffect(() => {
    const load = async () => {
      try {
        setStatus(IntegrationListStatus.Pending);

        await resolveAgreementAccepted();

        const result = await Promise.all([
          loadXsiteSkyMapConnection(),
          loadLeicaXsiteConnection(project.id, token),
          loadDonglesConnection(project.id, project.companyId),
          loadDroneHarmonyConnection(),
        ]);

        setIntegrations(result.filter((x) => x !== undefined) as IntegrationItem[]);
        setSelectedIntegration(result.find((x) => x?.key === selected));

        setStatus(IntegrationListStatus.Success);
      } catch (err) {
        setStatus(IntegrationListStatus.Failure);
      }
    };

    if (isDefined(currentUser)) {
      void load();
    } else {
      // When user is not defined (shared folder link).
      setStatus(IntegrationListStatus.Success);
    }
  }, [
    refreshNonce,
    project.id,
    project.companyId,
    currentUser,
    resolveAgreementAccepted,
    loadXsiteSkyMapConnection,
    loadLeicaXsiteConnection,
    token,
    selected,
    loadDonglesConnection,
    loadDroneHarmonyConnection,
  ]);

  const loadXsiteAsbuitPoints = async (
    fromDate?: Date,
    toDate?: Date,
  ): Promise<IntegrationAsbuiltPoint[]> => {
    const xsiteIntegration = integrations.find(
      (integration) => integration.key === 'xsite_manage',
    ) as XsiteIntegrationItem;
    if (!isDefined(xsiteIntegration)) {
      return [];
    }

    try {
      const asbuiltPoints = (
        await skyMapXsiteConnectionService.getAsbuiltPoints({
          path: { skyMapXsiteConnectionId: xsiteIntegration.integrationId! },
          query: {
            skyMapAuthenticationToken: token,
            from: fromDate?.toISOString(),
            to: toDate?.toISOString(),
          },
        })
      ).data;
      return asbuiltPoints.map((asbuiltPoint) => ({
        ...asbuiltPoint,
        integration: 'Xsite',
      }));
    } catch (err) {
      handleError<LoadXsiteApiRequestPath>(
        err,
        ['path.skyMapXsiteConnectionId', 'query.skyMapAuthenticationToken'],
        (translatedErrors: TranslatedError<LoadXsiteApiRequestPath>[]) => {
          // Just publish a Toast for now.
          // User have no control over requests parameters, so persistent issues need to be handled by support.
          publish(SubscriptionTopic.ToastrMessage, {
            type: 'error',
            title: 'As-built',
            message:
              translatedErrors[0]?.errorMessage ??
              'Ett oväntat fel uppstod när as-built skulle hämtas. Kontakta vår support om problemet kvarstår.',
          });
        },
      );
      return [];
    }
  };

  const loadConxAsbuitPoints = async (): Promise<IntegrationAsbuiltPoint[]> => {
    // Leica asbuilt is not supported.
    // Return empty array until asbuilt privileges are added.
    // TODO: Reintroduce asbuilt fetch for Leica when privileges are added.
    return await Promise.resolve([]);
  };

  const loadAsbuilt = async (fromDate?: Date, toDate?: Date) => {
    const asbuiltPoints = await Promise.all([
      loadXsiteAsbuitPoints(fromDate, toDate),
      loadConxAsbuitPoints(),
    ]);
    setAsbuilt(_.flatten(asbuiltPoints));
  };

  const clearAsbuilt = () => {
    setAsbuilt([]);
  };

  const replaceIntegrationItem = <T extends IntegrationItem['key']>(
    key: T,
    value: IntegrationItemKeyToType<T>,
  ) => {
    setIntegrations(integrations.map((x) => (x.key === key ? value : x)));

    if (selectedIntegration?.key === key) {
      setSelectedIntegration(value);
    }
  };

  const integrationAsbuiltSupport = () => {
    return integrations.length === 0
      ? IntegrationAsbuiltStatus.NoIntegrations
      : integrations.find((integration) => integrationsThatSupportAsbuilt.includes(integration.key))
        ? IntegrationAsbuiltStatus.Supported
        : IntegrationAsbuiltStatus.NotSupported;
  };

  switch (status) {
    case IntegrationListStatus.Pending:
    case IntegrationListStatus.Success:
      return (
        <IntegrationsContext.Provider
          value={{
            integrations,
            selectedIntegration,
            addIntegration,
            toggleIntegration,
            removeIntegration,
            agreementAccepted,
            setAgreementAccepted,
            integrationStatus: status,
            extractVolatileCharacters,
            asbuilt,
            loadAsbuilt,
            clearAsbuilt,
            integrationAsbuiltSupportState: integrationAsbuiltSupport,
            skyMapDongles,
            skyMapDongleRegistrations,
            loadDonglesConnection,
            replaceIntegrationItem,
          }}
        >
          {props.children}
        </IntegrationsContext.Provider>
      );
    case IntegrationListStatus.Failure:
      return (
        <Center>
          <Error>
            <>
              <h3>Oväntat fel</h3>
              <span>Det gick inte att hämta information om integrationerna.</span>
            </>
            <Button color="primary" variant="contained" onClick={() => setRefreshNonce()}>
              Försök igen
            </Button>
          </Error>
        </Center>
      );
  }
};

const Error = styled.div`
  ${reset}
  font-size: 14px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
`;

export { IntegrationsState };
