import React from 'react';
import styled from 'styled-components';

import { Icon } from '../icon/icon';
import { TreeViewContext } from './tree-view-state';

interface Props {
  item: TreeViewItemModel;
  leftIndentPx: number;
}

export interface TreeViewItemModel {
  id: string;
  parent: TreeViewItemModel | null;
  renderOpenIcon: (item: TreeViewItemModel) => JSX.Element;
  renderCloseIcon: (item: TreeViewItemModel) => JSX.Element;
  renderExpandIcon: (
    item: TreeViewItemModel,
    onClick: (e: React.MouseEvent) => void,
  ) => JSX.Element;
  renderCollapseIcon: (
    item: TreeViewItemModel,
    onClick: (e: React.MouseEvent) => void,
  ) => JSX.Element;
  renderContent: (item: TreeViewItemModel) => JSX.Element;
}

const TreeViewItem = (props: Props) => {
  const { hasChildren, isItemExpanded, expandItem, collapseItem } =
    React.useContext(TreeViewContext);

  const toggleFolder = (e: React.MouseEvent) => {
    // Prevent folder from being selected when folder
    // is toggled clicking on the arrow icon.
    e.stopPropagation();

    if (hasChildren(props.item.id)) {
      if (isItemExpanded(props.item.id)) {
        collapseItem(props.item.id);
      } else {
        expandItem(props.item.id);
      }
    }
  };

  const getArrowIcon = () => {
    if (hasChildren(props.item.id)) {
      return isItemExpanded(props.item.id) ? (
        <ArrowIcon>{props.item.renderExpandIcon(props.item, toggleFolder)}</ArrowIcon>
      ) : (
        <ArrowIcon>{props.item.renderCollapseIcon(props.item, toggleFolder)}</ArrowIcon>
      );
    }

    // Preserves space without a visible icon.
    // Any icon can be used. Icon is set to hidden with CSS.
    return (
      <EmptyArrowIcon>
        <Icon data-testid="no-arrow" fixedWidth={true} icon={'abacus'} />
      </EmptyArrowIcon>
    );
  };

  const getFolderIcon = () => {
    return isItemExpanded(props.item.id) ? (
      <FolderIcon>{props.item.renderOpenIcon(props.item)}</FolderIcon>
    ) : (
      <FolderIcon>{props.item.renderCloseIcon(props.item)}</FolderIcon>
    );
  };

  return (
    <Component {...props} onDoubleClick={toggleFolder}>
      {getArrowIcon()}
      {getFolderIcon()}
      <Content>{props.item.renderContent(props.item)}</Content>
    </Component>
  );
};

const Component = styled.div<Pick<Props, 'leftIndentPx'>>`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;

  // Make element expand full width width of parent.
  // This is needed also to trigger hovering "outside" the item left
  // to the arrow for level 2nd, 3th etc.
  padding: 0 0 0 ${(props) => `${props.leftIndentPx}px`};
  margin: 0 0 0 ${(props) => `-${props.leftIndentPx}px`};

  &:hover,
  &[data-selected='true'] {
    background-color: #f5f5f5;
  }
`;

const Content = styled.div`
  width: 100%;
  line-height: 2em;
  margin-left: 0.5em;
  user-select: none;
`;

const FolderIcon = styled.div`
  position: relative;
  margin-left: 0.4em;
`;

const ArrowIcon = styled.div`
  position: relative;
  margin-left: 0.6em;
`;

const EmptyArrowIcon = styled(ArrowIcon)`
  visibility: hidden;
`;

export { TreeViewItem };
