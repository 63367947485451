import * as polished from 'polished';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { formattedDateTime } from '../../../../../../js/utils/dateUtils';
import { CellGridVolume } from '../../../../../../js/viewer/elements/volume/cell-grid-volume';
import { PrismVolume } from '../../../../../../js/viewer/elements/volume/prism-volume';
import { VolumeCalculationAsset } from '../../../../../../js/viewer/elements/volume/types';
import { InputHandler } from '../../../../../../js/viewer/input-handler';
import { useNonce } from '../../../../../hooks/use-nonce';
import { useSharedView } from '../../../../../hooks/use-shared-view';
import { Button } from '../../../../button/button';
import { InfoBox } from '../../../../info-box/info-box';
import { LabelledContainer } from '../../../../labelled-container/labelled-container';
import { Slider } from '../../../../slider/slider';
import { Stack } from '../../../../stack/stack';
import { Switch } from '../../../../switch/switch';
import { FullWidth } from '../../styles/grid';
import { numberOrDefault } from '../../utils/formatting';
import { CutFillBox } from './cut-fill-box';

type Props = {
  asset: VolumeCalculationAsset;
  volume: CellGridVolume | PrismVolume;
  enabled: boolean;
  onDeleted: () => void;
};

const AssetVolumeSelected = (props: Props) => {
  const { t } = useTranslation();
  const [refreshNonce, refresh] = useNonce();

  const [cutVisible, setCutVisible] = React.useState(props.asset.volumeHandler.isCutVisible());
  const [fillVisible, setFillVisible] = React.useState(props.asset.volumeHandler.isFillVisible());
  const [raised, setRaised] = React.useState(props.asset.volumeHandler.isModelRaised() || false);
  const [status, setStatus] = React.useState(props.asset.volumeHandler.getStatus());
  const [opacity, setOpacity] = React.useState(props.asset.volumeHandler.opacity);
  const { isSharedView } = useSharedView();

  useEffect(() => {
    props.asset.volumeHandler.opacity = opacity;
  }, [opacity, props.asset.volumeHandler]);

  React.useEffect(() => {
    setCutVisible(props.enabled && props.asset.volumeHandler.isCutVisible());
    setFillVisible(props.enabled && props.asset.volumeHandler.isFillVisible());
    setRaised(props.asset.volumeHandler.isModelRaised() || false);
    setStatus(props.asset.volumeHandler.getStatus());
  }, [refreshNonce, props.asset.volumeHandler, props.volume, props.enabled]);

  return (
    <Component>
      {status === 'COMPLETED' && (
        <>
          <Stack direction="row" spacing={0.5}>
            <CutFillBox
              backgroundColorHidden={{
                default: polished.hsla(348, 0.66, 0.58, 0.2),
                hover: polished.hsla(348, 0.66, 0.58, 0.5),
              }}
              backgroundColorVisible={{
                default: polished.hsla(348, 0.66, 0.58, 1),
                hover: undefined,
              }}
              disabled={!props.enabled || props.volume instanceof PrismVolume}
              iconHidden={{
                default: {
                  icon: ['fal', 'eye-slash'],
                  color: '#000',
                },
                hover: {
                  icon: ['fad', 'eye-slash'],
                  color: '#000',
                },
              }}
              iconVisible={{
                default: {
                  icon: ['fal', 'eye'],
                  color: '#FFF',
                },
                hover: {
                  icon: ['fad', 'eye'],
                  color: '#FFF',
                },
              }}
              visible={cutVisible}
              volumeType={props.volume instanceof PrismVolume ? 'prism' : 'cellGrid'}
              onClick={() => {
                props.asset.volumeHandler.toggleCut();
                setCutVisible(props.asset.volumeHandler.isCutVisible());
              }}
            />
            <LabelledContainer text={t('common.cut', { ns: 'skyviewVolumeCalculation' })}>
              {numberOrDefault(props.volume.cut, 'm³')}
            </LabelledContainer>
          </Stack>

          <Stack direction="row" spacing={0.5}>
            <CutFillBox
              backgroundColorHidden={{
                default: polished.hsla(195, 1, 0.44, 0.2),
                hover: polished.hsla(195, 1, 0.44, 0.5),
              }}
              backgroundColorVisible={{
                default: polished.hsla(195, 1, 0.44, 1),
                hover: undefined,
              }}
              disabled={!props.enabled || props.volume instanceof PrismVolume}
              iconHidden={{
                default: {
                  icon: ['fal', 'eye-slash'],
                  color: '#000',
                },
                hover: {
                  icon: ['fad', 'eye-slash'],
                  color: '#000',
                },
              }}
              iconVisible={{
                default: {
                  icon: ['fal', 'eye'],
                  color: '#FFF',
                },
                hover: {
                  icon: ['fad', 'eye'],
                  color: '#FFF',
                },
              }}
              visible={fillVisible}
              volumeType={props.volume instanceof PrismVolume ? 'prism' : 'cellGrid'}
              onClick={() => {
                props.asset.volumeHandler.toggleFill();
                setFillVisible(props.asset.volumeHandler.isFillVisible());
              }}
            />

            <LabelledContainer text={t('common.fill', { ns: 'skyviewVolumeCalculation' })}>
              {numberOrDefault(props.volume.fill, 'm³')}
            </LabelledContainer>
          </Stack>

          <FullWidth>
            <LabelledContainer
              text={t('opacity.sectionName', { ns: 'skyviewAssetMenu', value: opacity })}
            >
              <Slider
                disabled={!props.enabled}
                max={1}
                min={0}
                step={0.01}
                value={opacity}
                onChange={setOpacity}
              />
            </LabelledContainer>
          </FullWidth>
        </>
      )}
      <LabelledContainer text={t('common.sourceSurface', { ns: 'skyviewVolumeCalculation' })}>
        <WrapText>{props.volume.getSourceName()}</WrapText>
      </LabelledContainer>
      <LabelledContainer text={t('volumes.section.calculationMethod', { ns: 'skyviewAssetMenu' })}>
        <WrapText>
          {props.volume instanceof PrismVolume
            ? t('section.method.itemPrism.text', { ns: 'skyviewVolumeCalculation' })
            : t('section.method.itemCellGrid.text', { ns: 'skyviewVolumeCalculation' })}
        </WrapText>
      </LabelledContainer>
      <LabelledContainer text={t('volumes.section.createdAt', { ns: 'skyviewAssetMenu' })}>
        <WrapText>{formattedDateTime(props.volume.createdAt)}</WrapText>
      </LabelledContainer>

      {status === 'COMPLETED' && (
        <LabelledContainer text={t('volumes.section.liftUpVolume', { ns: 'skyviewAssetMenu' })}>
          <Switch
            checked={raised}
            disabled={!props.enabled}
            onChange={() => {
              props.asset.volumeHandler.toggleModelRaised();
              setRaised(props.asset.volumeHandler.isModelRaised() || false);
            }}
          />
        </LabelledContainer>
      )}

      {status === 'FAILED' && (
        <FullWidth>
          <InfoBox color="red" leftIcon={{ icon: ['fad', 'exclamation'] }}>
            {props.volume.errorText || t('unexpectedError', { ns: 'common' })}
          </InfoBox>
        </FullWidth>
      )}

      {status === 'RUNNING' && (
        <>
          <FullWidth>
            <InfoBox color="yellow" leftIcon={{ icon: ['fad', 'spinner'], spin: true }}>
              {props.volume.statusMessage}
            </InfoBox>
          </FullWidth>

          <FullWidth>
            <Button
              color="primary"
              disabled={props.volume.status === 'PENDING' || props.volume instanceof PrismVolume}
              variant={'contained'}
              width="100%"
              onClick={async () => {
                try {
                  await props.asset.volumeHandler.delete();
                } catch (err) {
                  // todo: Handle errors
                }
                props.onDeleted();
              }}
            >
              {t('cancel', { ns: 'common' })}
            </Button>
          </FullWidth>
        </>
      )}

      {props.volume instanceof CellGridVolume && status === 'COMPLETED' && !isSharedView && (
        <FullWidth>
          {InputHandler.instance.isVolumeInspectorToolActive() ? (
            <Button
              color="secondary"
              variant="contained"
              width="100%"
              onClick={() => {
                InputHandler.instance.setTool('select');
                refresh();
              }}
            >
              {t('volumes.cutAndFillButton.close', { ns: 'skyviewAssetMenu' })}
            </Button>
          ) : (
            <Button
              color="primary"
              disabled={!props.asset.visible}
              variant="contained"
              width="100%"
              onClick={() => {
                InputHandler.instance.setTool('volume-inspector');
                refresh();
              }}
            >
              {t('volumes.cutAndFillButton.show', { ns: 'skyviewAssetMenu' })}
            </Button>
          )}
        </FullWidth>
      )}
    </Component>
  );
};

const Component = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.2em;
`;

const WrapText = styled.span`
  word-break: break-all;
`;

export { AssetVolumeSelected, Props as AssetVolumeSelectedProps };
