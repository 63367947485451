import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { CheckBox } from '../../../../check-box/check-box';
import { Icon } from '../../../../icon/icon';
import { CategoryVisibilityContext } from '../../../state/category-visibility-state';
import { CheckBoxContext } from '../../../state/checkbox-state';
import { CheckBoxContainer } from '../../styles/checkbox-container';

type Props = {
  children: React.ReactNode;
  headerRef?: React.RefObject<HTMLDivElement>;
  icon: FontAwesomeIconProps;
  title: string;
};

const Category = (props: Props) => {
  const { t } = useTranslation();
  const checkBoxContext = React.useContext(CheckBoxContext);
  const { assets, visible, toggleVisibility, expanded, setExpanded } =
    React.useContext(CategoryVisibilityContext);

  const checkBoxChecked = assets.every((x) => checkBoxContext.checkedAssetUuids.includes(x.uuid))
    ? true
    : assets.some((x) => checkBoxContext.checkedAssetUuids.includes(x.uuid))
      ? undefined
      : false;

  const onChange = () => {
    const assetUuids = assets.map((x) => x.uuid);
    if (checkBoxChecked) {
      checkBoxContext.uncheckAll(assetUuids);
    } else {
      checkBoxContext.checkAll(assetUuids);
    }
  };

  const onVisibilityIconClick = () => {
    toggleVisibility();
  };

  return (
    <Component expanded={expanded} visible={visible}>
      <header ref={props.headerRef} onClick={() => setExpanded(!expanded)}>
        {checkBoxContext.visible && (
          <CheckBoxContainer
            disabled={checkBoxContext.disabled}
            onClick={(e) => {
              if (!checkBoxContext.disabled) {
                e.stopPropagation();
                onChange();
              }
            }}
          >
            <CheckBox
              checked={checkBoxChecked}
              disabled={checkBoxContext.disabled}
              onChange={onChange}
            />
          </CheckBoxContainer>
        )}
        <HeaderBody>
          <Icon fixedWidth={true} icon={['fas', expanded ? 'caret-down' : 'caret-right']} />
          <Icon {...props.icon} />
          <span>{props.title}</span>
          <VisibilityIcon
            fixedWidth={true}
            icon={['fal', visible ? 'eye' : 'eye-slash']}
            mouseDownStyle={{ opacity: 0.5 }}
            stopPropagationOnClick={true}
            title={visible ? t('hide', { ns: 'common' }) : t('show', { ns: 'common' })}
            onClick={onVisibilityIconClick}
            onHoverStyle={{ icon: ['fad', visible ? 'eye' : 'eye-slash'] }}
          />
        </HeaderBody>
      </header>

      <Content expanded={expanded}>{props.children}</Content>
    </Component>
  );
};

const VisibilityIcon = styled(Icon)``;

const Component = styled.div<{ expanded: boolean; visible: boolean }>`
  // Same shadow as top menu.
  box-shadow:
    0 1px 3px rgb(0 0 0 / 12%),
    0 1px 2px rgb(0 0 0 / 24%);

  // In order to get bottom part of box-shadow visible, increase bottom margin for the last category.
  // Set it to a few pixels bigger than the shadow itself.
  &:last-child {
    margin-bottom: 5px;
  }

  > header {
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
    background-color: #dadada;
    cursor: pointer;
    z-index: 3;

    ${(props) =>
      props.visible &&
      css`
        &:not(:hover) {
          ${VisibilityIcon} {
            display: none;
          }
        }
      `}

    &:hover {
      background-color: #d0d0d0;
    }
  }
`;

const HeaderBody = styled.div`
  display: flex;
  padding: 0.7em;
  flex: 1;
  gap: 0.5em;
  align-items: center;

  span {
    flex: 1;
    text-transform: uppercase;
  }
`;

const Content = styled.div<{ expanded: boolean }>`
  display: ${(props) => (props.expanded ? 'block' : 'none')};
`;

export { Category };
