import useResizeObserver from '@react-hook/resize-observer';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

import { formattedDate } from '../../../../../js/utils/dateUtils';
import { isElementOverflowed } from '../../../../../js/utils/dom/element';
import { toHumanReadableFileSize } from '../../../../../js/utils/io/file';
import { getErrorForPath } from '../../../../api/error-handling';
import { useDialog } from '../../../../hooks/use-dialog';
import { nameof } from '../../../../utils/nameof';
import { DateTimePickerDialog } from '../../../date-time-picker-dialog/date-time-picker-dialog';
import { Icon } from '../../../icon/icon';
import { ProgressBar } from '../../../progress-bar/progress-bar';
import { Table } from '../../../table/table';
import { TextArea } from '../../../text-area/text-area';
import { TextBox } from '../../../text-box/text-box';
import { UploadItem, UploadItemStatus } from './upload-files-table';

export interface Props {
  item: UploadItem;
  bytesUploaded?: number;
  onRevisionDateSet: (itemId: string, revisionDate: Date) => void;
  onRevisionDateRemoved: (itemId: string) => void;
  onRevisionChanged: (itemId: string, revision: string) => void;
  onScaleChanged: (itemId: string, revision: string) => void;
  onDescriptionChanged: (itemId: string, description: string) => void;
  onDescriptionVisibilityChanged: (itemId: string, visible: boolean) => void;
  onRemoveFile: (itemId: string) => void;
  onRetry: (itemId: string) => void;
}

const UploadFileRow = React.memo((props: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const nameRef = React.useRef<HTMLSpanElement>(null);
  const dateTimePickerDialog = useDialog();
  const [nameTitle, setNameTitle] = React.useState('');

  const isEnabled = [UploadItemStatus.Idle, UploadItemStatus.Error].includes(props.item.status);

  useResizeObserver(nameRef, (entry) => {
    setNameTitle(isElementOverflowed(entry.target) ? props.item.file.htmlInputFile.name : '');
  });

  const renderStatus = () => {
    switch (props.item.status) {
      case UploadItemStatus.Idle:
        return (
          <StatusGray>
            {t('fileManager.uploadFilesDialog.uploadStatus.idle', { ns: 'components' })}
          </StatusGray>
        );

      case UploadItemStatus.Pending:
        return (
          <StatusGray>
            {t('fileManager.uploadFilesDialog.uploadStatus.pending', { ns: 'components' })}
          </StatusGray>
        );

      case UploadItemStatus.UploadingToS3:
      case UploadItemStatus.SavingToDatabase:
        return (
          // Substract 1 from bytes uploaded in order to never display 100%.
          // We stop at 99% for the user. The last 1% is for the API call to
          // save the file to database. This means 100% won't be displayed for
          // the user because when file is saved to database "Uppladdad" is displayed.
          <ProgressBar
            max={props.item.file.htmlInputFile.size}
            maxPercentage={99}
            min={0}
            value={props.item.file.bytesUploaded}
            width={'100px'}
          />
        );

      case UploadItemStatus.SavedToDatabase:
        return (
          <>
            <span>
              {t('fileManager.uploadFilesDialog.uploadStatus.uploaded', { ns: 'components' })}
            </span>
          </>
        );

      case UploadItemStatus.Error:
        return (
          <>
            <StatusError>
              {t('fileManager.uploadFilesDialog.uploadStatus.error', { ns: 'components' })}
            </StatusError>
          </>
        );
    }
  };

  const isDeleteIconVisible = () => {
    if (props.item.status === UploadItemStatus.SavedToDatabase) {
      return false;
    }

    if (props.item.status === UploadItemStatus.Error) {
      return true;
    }

    return (
      props.item.status !== UploadItemStatus.SavingToDatabase &&
      props.item.file.bytesUploaded !== props.item.file.htmlInputFile.size
    );
  };

  const getInputError = (path: string) => {
    if (props.item.errors) {
      const errorMessage = getErrorForPath(props.item.errors, path);
      return errorMessage;
    }
  };

  return (
    <>
      <Table.Row key={props.item.file.htmlInputFile.name}>
        <Table.Cell
          padding={0.5}
          onClick={() =>
            props.onDescriptionVisibilityChanged(props.item.id, !props.item.showDescription)
          }
        >
          <Icon
            data-testid="icon"
            fixedWidth={true}
            icon={props.item.showDescription ? 'caret-down' : 'caret-right'}
          />
        </Table.Cell>

        <Table.Cell
          padding={0} // Remove padding to make cell 0 width if no icon in table exist.
          onClick={() =>
            props.onDescriptionVisibilityChanged(props.item.id, !props.item.showDescription)
          }
        >
          {props.item.file.isPreviouslyUploaded && (
            <NewVersionIcon
              color={theme.color.blue}
              icon={['fad', 'copy']}
              title={t('fileManager.uploadFilesDialog.newVersionWillBeCreated', {
                ns: 'components',
              })}
            />
          )}
        </Table.Cell>

        <Table.Cell
          padding={{ left: 0 }}
          width="100%"
          onClick={() =>
            props.onDescriptionVisibilityChanged(props.item.id, !props.item.showDescription)
          }
        >
          <FilenameWrapper>
            <span ref={nameRef} title={nameTitle}>
              {props.item.file.htmlInputFile.name}
            </span>
          </FilenameWrapper>
        </Table.Cell>

        <Table.Cell wrapping={'none'}>
          <RevisionDate>
            {props.item.meta.revisionDate ? (
              formattedDate(props.item.meta.revisionDate)
            ) : (
              <NotSet>-</NotSet>
            )}
          </RevisionDate>

          {isEnabled && (
            <RevisionIcons>
              <Icon
                data-testid="icon"
                icon={['far', 'calendar-days']}
                title={t('fileManager.uploadFilesDialog.calendarIcon', { ns: 'components' })}
                onClick={() => dateTimePickerDialog.show()}
              />
              {props.item.meta.revisionDate && (
                <Icon
                  color="#b22222"
                  data-testid="icon"
                  icon="xmark"
                  title={t('fileManager.uploadFilesDialog.deleteRevisionIcon', {
                    ns: 'components',
                  })}
                  onClick={() => props.onRevisionDateRemoved(props.item.id)}
                />
              )}
            </RevisionIcons>
          )}
        </Table.Cell>

        <Table.Cell wrapping={'none'}>
          {isEnabled ? (
            <>
              <TextBox
                errorMessage={getInputError('body.revision')}
                maxLength={16}
                maxVisibleChars={6}
                value={props.item.meta.revision}
                onChange={(e) => {
                  return props.onRevisionChanged(props.item.id, e.target.value);
                }}
              />
            </>
          ) : (
            props.item.meta.revision ?? <NotSet>-</NotSet>
          )}
        </Table.Cell>

        <Table.Cell wrapping={'none'}>
          {isEnabled ? (
            <>
              <TextBox
                errorMessage={getInputError('body.scale')}
                maxLength={16}
                maxVisibleChars={6}
                value={props.item.meta.scale}
                onChange={(e) => props.onScaleChanged(props.item.id, e.target.value)}
              />
            </>
          ) : (
            props.item.meta.scale ?? <NotSet>-</NotSet>
          )}
        </Table.Cell>

        <Table.Cell wrapping={'none'}>
          {toHumanReadableFileSize(props.item.file.htmlInputFile.size)}
        </Table.Cell>

        <Table.Cell wrapping={'none'}>{renderStatus()}</Table.Cell>

        <Table.Cell wrapping={'none'}>
          <StatusIcons>
            {props.item.status === UploadItemStatus.SavedToDatabase && (
              <Icon color="green" data-testid="icon" icon="check-circle" />
            )}

            {props.item.status === UploadItemStatus.Error && (
              <Icon
                data-testid="icon"
                icon="redo"
                title={t('retry', { ns: 'common' })}
                onClick={() => props.onRetry(props.item.id)}
              />
            )}

            {isDeleteIconVisible() && (
              <Icon
                color="#b22222"
                data-testid="icon"
                icon="trash"
                title={t('fileManager.uploadFilesDialog.removeFile', { ns: 'components' })}
                onClick={() => props.onRemoveFile(props.item.id)}
              />
            )}
          </StatusIcons>
        </Table.Cell>
      </Table.Row>

      {props.item.showDescription && (
        <Table.Row borderTop={false} key={`${props.item.file.htmlInputFile.name}-description`}>
          <Table.Cell colSpan={9}>
            <TextArea
              disabled={!isEnabled}
              errorMessage={getInputError('body.description')}
              maxCharLength={512}
              placeholder={t('fileManager.uploadFilesDialog.descriptionTitle', {
                ns: 'components',
              })}
              rows={3}
              value={props.item.meta.description}
              onChange={(e) => {
                return props.onDescriptionChanged(props.item.id, e.target.value);
              }}
            />
          </Table.Cell>
        </Table.Row>
      )}

      {dateTimePickerDialog.render(
        <DateTimePickerDialog
          displayTime={false}
          startDate={new Date()}
          onCancel={() => {
            dateTimePickerDialog.hide();
          }}
          onDateChoosen={(date) => {
            props.onRevisionDateSet(props.item.id, date);
            dateTimePickerDialog.hide();
          }}
        />,
      )}
    </>
  );
});

UploadFileRow.displayName = nameof({ UploadFileRow });

const FilenameWrapper = styled.div`
  display: flex;
  gap: 0.5em;
  align-items: center;

  span {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
  }
`;

const NotSet = styled.span`
  color: #aaa;
`;

const NewVersionIcon = styled(Icon)`
  margin-right: 0.5em;
`;

const RevisionDate = styled.span`
  display: inline-block;
  margin-right: 1em;
  min-width: 80px;
`;

const RevisionIcons = styled.span`
  display: inline-flex;
  gap: 0.4em;
`;

const StatusGray = styled.span`
  color: #888;
`;

const StatusError = styled.span`
  color: #b22222;
`;

const StatusIcons = styled.div`
  display: flex;
  gap: 0.5em;
  justify-content: end;
`;

export { UploadFileRow };
