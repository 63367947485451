import styled, { css } from 'styled-components';

import { isDefined } from '../../../../../js/utils/variables';

/**
 * Makes item span over two columns.
 */
export const FullWidth = styled.div<{ apply?: boolean }>`
  ${(props) =>
    (!isDefined(props.apply) || props.apply) &&
    css`
      grid-column: 1 / 3;
    `}
`;
