import styled from 'styled-components';

import { ButtonStyled } from '../../button/button';

export const Component = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25em;
  margin-top: 1em;
  width: 100%;

  ${ButtonStyled} {
    align-self: flex-start;
    margin-top: 1em;
  }
`;

export const InfoItem = styled.div`
  label {
    font-weight: 400;
    font-size: 0.9em;
    display: block;
    margin-bottom: 0.2em;
  }

  span {
    font-weight: 300;
    display: block;
    font-weight: 300;
    font-size: 0.9em;
  }
`;

export const InfoItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

export const Footer = styled.div`
  margin-top: 2em;
`;
