import PropTypes from 'prop-types';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import { SkyView } from '../../../js/viewer/skyview';
import { defaultTheme } from '../../themes/default/styled-component/theme';
import { LanguageProvider } from '../language-provider/language-provider';
import { SkyviewState } from '../skyview/skyview-state';
import { WmsAttributions } from '../wms-map/wms-attributions';
import { AssetMenu } from './asset-menu/asset-menu';
import { CollapsableContainer } from './asset-menu/collapsable-container';

type Props = {
  skyView: SkyView;
  displayAssetMenu: boolean;
};

/**
 * Component to use in Angular Skyview that renders the React asset menu.
 */
const ReactSkyviewAssetMenuForAngularSkyview = (props: Props) => {
  return (
    <LanguageProvider>
      <ThemeProvider theme={defaultTheme}>
        <SkyviewState skyview={props.skyView}>
          <Component>
            {props.displayAssetMenu && (
              <CollapsableContainer width={410}>
                <AssetMenu />
              </CollapsableContainer>
            )}
            <Center>
              <WmsAttributions />
            </Center>
          </Component>
        </SkyviewState>
      </ThemeProvider>
    </LanguageProvider>
  );
};

ReactSkyviewAssetMenuForAngularSkyview.propTypes = {
  skyView: PropTypes.object.isRequired,
  displayAssetMenu: PropTypes.bool.isRequired,
};

const Component = styled.div`
  position: relative;
  height: 100%;
  display: flex;
`;

const Center = styled.div`
  position: relative;

  // When hovering the center area, set z-index to anything > 0 (doesn't matter in this case), in
  // order to make z-index higher than the right panel. Tooltip will the be rendered above the
  // right panel.
  &:hover {
    z-index: 1;
  }
`;

export { ReactSkyviewAssetMenuForAngularSkyview };
