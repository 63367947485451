import React from 'react';
import styled from 'styled-components';
import _ from 'underscore';

import { ThinScrollbar } from '../../../styles/thin-scrollbar';
import { reset } from '../../../utils/styled-reset';
import { SelectableItem } from '../styles/selectable-item';

interface Props {
  minValue: number;
  maxValue: number;
  minSelectableValue?: number;
  maxSelectableValue?: number;
  selectedValue: number;
  numVisibleItems: number;
  scrollIntoViewNonce: number;
  onNumberChanged: (number: number) => void;
}

const ScrollableNumberList = (props: Props) => {
  const [selectedValue, setSelectedValue] = React.useState(props.selectedValue);
  const { onNumberChanged: propsOnNumberChanged } = props;
  const refSelected = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    // todo: Ugly solution using setTimeout to do work after refSelected has been
    // todo: initialized with a new value (when both props.selectedValue and
    // todo: props.scrollIntoViewNonce are changed).
    // todo:
    // todo: Remove setTimeout.
    window.setTimeout(() => {
      if (refSelected?.current) {
        refSelected.current.scrollIntoView?.();
      }
    }, 0);
  }, [refSelected, props.scrollIntoViewNonce]);

  React.useEffect(() => {
    setSelectedValue(props.selectedValue);
  }, [props.selectedValue]);

  const onItemClicked = (n: number) => {
    if (isEnabled(n)) {
      setSelectedValue(n);
      propsOnNumberChanged(n);
    }
  };

  const isEnabled = (n: number) => {
    if (props.minSelectableValue !== undefined && n < props.minSelectableValue) {
      return false;
    }

    if (props.maxSelectableValue !== undefined && n > props.maxSelectableValue) {
      return false;
    }

    return true;
  };

  const numDigits = props.maxValue.toString().length;

  return (
    <Component {...props}>
      {_.times(props.maxValue - props.minValue + 1, (n) => n + props.minValue).map((n) => (
        <Item key={n}>
          <SelectableItem
            boldText={false}
            enabled={isEnabled(n)}
            lightColor={false}
            ref={selectedValue === n ? refSelected : null}
            selected={selectedValue === n}
            onClick={() => onItemClicked(n)}
          >
            {String(n).padStart(numDigits, '0')}
          </SelectableItem>
        </Item>
      ))}
    </Component>
  );
};

const Component = styled.ol<Pick<Props, 'numVisibleItems'>>`
  ${reset}
  ${ThinScrollbar}

  height: calc(${(props) => props.numVisibleItems} * 30px);
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
`;

const Item = styled.li`
  ${reset}

  scroll-snap-align: start;
`;

ScrollableNumberList.styled = Component;

export { ScrollableNumberList };
