import React from 'react';
import styled from 'styled-components';

import { reset } from '../../../utils/styled-reset';

const NoGeodata = () => {
  return (
    <Component>
      <img src="/static/assets/img/cloudprocessing/state_empty.svg" />
      <p>
        <label>Det finns inga molnprocesseringar</label>
        <span>
          Här visas alla molnprocesseringar som skapats av uppladdade drönarbilder för projektet.
          Skapa din första uppladdning genom att klicka på knappen
          <b>Ny molnprocessering</b>.
        </span>
      </p>
    </Component>
  );
};

const Component = styled.div`
  ${reset}
  font-size: 14px;

  display: flex;
  gap: 1em;
  flex-direction: row;
  margin: auto;

  p {
    width: 25em;
    margin: 2em 0 2em 0;
    display: block;

    label {
      font-weight: 500;
      display: block;
      margin-bottom: 0.5em;
    }

    span {
      font-weight: 300;
      b {
        padding-left: 0.3em;
      }
    }
  }
`;

export { NoGeodata };
