import React from 'react';
import styled from 'styled-components';

import { reset } from '../../../utils/styled-reset';
import { Icon } from '../../icon/icon';

interface Props {
  title: string;
  onPrevMonth: () => void;
  onNextMonth: () => void;
}

const CalendarHeader = (props: Props) => {
  return (
    <Component>
      <Icon data-testid="prev-month" icon="arrow-left" onClick={props.onPrevMonth} />
      <span>{props.title}</span>
      <Icon data-testid="next-month" icon="arrow-right" onClick={props.onNextMonth} />
    </Component>
  );
};

const Component = styled.div`
  ${reset}

  font-size: 14px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  user-select: none;
`;

CalendarHeader.styled = Component;

export { CalendarHeader };
