import type * as CSS from 'csstype';
import React from 'react';
import styled, { css } from 'styled-components';

import { reset } from '../../utils/styled-reset';

export interface Props {
  children?: React.ReactNode;
  colSpan?: number;
  /**
   *  Should be a valid CSS width style property.
   */
  width?: CSS.Properties['width'];
  wrapping?: 'normal' | 'anywhere' | 'break-word' | 'none';
  /**
   * Padding specified in em.
   */
  padding?:
    | number
    | {
        top?: number;
        right?: number;
        bottom?: number;
        left?: number;
      };

  /**
   * This will override the width prop.
   */
  shrink?: boolean;
  onHoverBackgroundColor?: string;
  onClick?: (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => void;
  monospaced?: 'montserrat' | 'roboto';
  textAlign?: 'left' | 'center' | 'right';
  minWidth?: CSS.Properties['minWidth'];
  backgroundColor?: CSS.Properties['backgroundColor'];
  fontSize?: CSS.Properties['fontSize'];
  fontWeight?: CSS.Properties['fontWeight'];
  className?: string;
}

const TableCell = ({ textAlign = 'left', ...props }: Props) => {
  return (
    <Component
      {...props}
      className={props.className}
      clickable={!!props.onClick}
      colSpan={props.colSpan}
      textAlign={textAlign}
      onClick={(event) => {
        if (props.onClick) {
          event.stopPropagation();
          props.onClick(event);
        }
      }}
    >
      {props.children}
    </Component>
  );
};

type ComponentProps = Pick<
  Props,
  | 'wrapping'
  | 'width'
  | 'padding'
  | 'shrink'
  | 'onHoverBackgroundColor'
  | 'monospaced'
  | 'textAlign'
  | 'minWidth'
  | 'backgroundColor'
  | 'fontSize'
  | 'fontWeight'
> & {
  clickable: boolean;
};

const defaultPadding = {
  top: 0.5,
  right: 1,
  bottom: 0.5,
  left: 1,
};

const Component = styled.td<ComponentProps>`
  ${reset}

  position: relative;
  vertical-align: middle;
  width: ${(props) => (props.shrink ? '0' : props.width)};
  text-align: ${(props) => props.textAlign};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};

  // Monospaced?
  ${(props) =>
    props.monospaced === 'roboto' &&
    css`
      font-family: 'Roboto Mono';
      font-weight: 300;
    `}
  ${(props) =>
    props.monospaced === 'montserrat' &&
    css`
      font-feature-settings: 'tnum';
    `}

  // Padding
  ${(props) =>
    typeof props.padding === 'number'
      ? css`
          padding: ${props.padding}em};
        `
      : typeof props.padding === 'object'
        ? css`
            padding-top: ${props.padding.top ?? defaultPadding.top}em;
            padding-right: ${props.padding.right ?? defaultPadding.right}em;
            padding-bottom: ${props.padding.bottom ?? defaultPadding.bottom}em;
            padding-left: ${props.padding.left ?? defaultPadding.left}em;
          `
        : css`
            padding-top: ${defaultPadding.top}em;
            padding-right: ${defaultPadding.right}em;
            padding-bottom: ${defaultPadding.bottom}em;
            padding-left: ${defaultPadding.left}em;
          `}

  // Wrapping
  ${(props) =>
    props.wrapping === 'none'
      ? css`
          white-space: nowrap;
        `
      : props.wrapping !== undefined
        ? css`
            overflow-wrap: ${props.wrapping};
          `
        : undefined}

  // Clickable
  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
    `}

  // On hover
  ${(props) =>
    props.onHoverBackgroundColor &&
    css`
      &&:hover {
        background-color: ${props.onHoverBackgroundColor};
      }
    `}

  // Min width
  ${(props) =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth};
    `}

    // Background color
    ${(props) =>
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `}
`;

TableCell.styled = Component;

export { TableCell };
