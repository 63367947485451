import React from 'react';
import styled, { css } from 'styled-components';

import { reset } from '../../utils/styled-reset';
import { Icon } from '../icon/icon';

export interface BreadCrumbItem {
  id: string;
  name: string;
  shared: boolean;
  onClick?: () => void;
}

interface Props {
  children: {
    items: BreadCrumbItem[];
  };
  divider: React.ReactElement;
}

const BreadCrumbs = (props: Props) => {
  return (
    <Component aria-label="breadcrumb" className="notranslate">
      {props.children.items.map((item, index) => (
        <React.Fragment key={item.id}>
          <LinkItem>
            {item.onClick ? (
              <ClickableLinkItemText onClick={item.onClick}>{item.name}</ClickableLinkItemText>
            ) : (
              <NonClickableLinkItemText onClick={item.onClick}>
                {item.name}
              </NonClickableLinkItemText>
            )}

            {item.shared && (
              <Icon
                color={item.onClick ? '#666;' : '#000000'}
                data-testid="icon-folder-shared"
                fixedWidth={true}
                icon={['fad', 'share-nodes']}
                size={'xs'}
                title="Mappen delas"
              />
            )}
          </LinkItem>

          {index < props.children.items.length - 1 && <Divider>{props.divider}</Divider>}
        </React.Fragment>
      ))}
    </Component>
  );
};

const Component = styled.ol`
  ${reset}

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  word-break: break-all;

  list-style-type: none;
  gap: 1em;
`;

const Divider = styled.li`
  ${reset}
  font-size: 14px;

  user-select: none;
  pointer-events: none;

  display: flex;
  align-items: center;
`;

const LinkItem = styled.li`
  display: flex;
  gap: 0.2em;
  align-items: baseline;
  max-width: 200px;
`;

const listItemStyle = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ClickableLinkItemText = styled.a`
  ${reset}
  ${listItemStyle}

  font-size: 14px;

  text-decoration: none;
  color: #666;
  font-weight: 400;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const NonClickableLinkItemText = styled.span`
  ${reset}
  ${listItemStyle}

  font-size: 14px;
  font-weight: 500;
`;

export { BreadCrumbs };
