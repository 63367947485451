import React from 'react';
import styled from 'styled-components';

import { GeodataModel } from '../../../../../typings/api/skymap/rest/v1/.common';
import { formattedDateTime } from '../../../../js/utils/dateUtils';
import { translatedProcessType } from '../geodata';

interface Props {
  selectedItem: GeodataModel;
}

const GeodataDetails = (props: Props) => {
  const getUploadedByname = () =>
    `${props.selectedItem.uploadedBy?.firstName} ${props.selectedItem.uploadedBy?.lastName}`;

  const hasCamerasArtifact =
    props.selectedItem.resourceUrls?.some((x) => x.name === 'cameras') ?? false;
  const isOrthoProcess = ['ORTHO_ONLY', 'ORTHO_TIF'].includes(props.selectedItem.processType);

  return (
    <Component>
      <h5>Detaljer</h5>
      <InfoRow>
        <InfoItem>
          <label id="process-type">Processeringspaket</label>
          <span aria-labelledby="process-type">
            {translatedProcessType(props.selectedItem.processType)}
          </span>
        </InfoItem>

        {props.selectedItem.processType !== 'ORTHO_TIF' && (
          <InfoItem>
            <label id="photo-count">Antal foton</label>
            <span aria-labelledby="photo-count">{props.selectedItem.photoCount}</span>
          </InfoItem>
        )}
      </InfoRow>

      <InfoRow>
        <InfoItem>
          <label id="uploaded">Uppladdad</label>
          <span aria-labelledby="uploaded">{formattedDateTime(props.selectedItem.uploaded)}</span>
        </InfoItem>

        <InfoItem>
          <label id="uploaded-by">Uppladdad av</label>
          <span aria-labelledby="uploaded-by">{getUploadedByname()}</span>
        </InfoItem>
      </InfoRow>
      <InfoRow>
        <InfoItem>
          <label id="boundary-file">Begränsningspolygon</label>
          <span aria-labelledby="boundary-file">{props.selectedItem.boundaryFile ?? 'Nej'}</span>
        </InfoItem>

        {props.selectedItem.processType !== 'ORTHO_TIF' && (
          <InfoItem>
            <label id="gsd">GSD</label>
            <span aria-labelledby="gsd">{props.selectedItem.gsd}</span>
          </InfoItem>
        )}

        <InfoItem>
          <label id="rolling-shutter">Rolling shutter</label>
          <span aria-labelledby="rolling-shutter">
            {props.selectedItem.rollingShutter ? 'Ja' : 'Nej'}
          </span>
        </InfoItem>
      </InfoRow>

      <h5>Projektion</h5>

      <InfoRow>
        <InfoItem>
          <label id="coordinate-system">Koordinatsystem</label>
          <span aria-labelledby="coordinate-system">{props.selectedItem.coordinateSystemName}</span>
        </InfoItem>

        <InfoItem>
          <label id="geoid">Geoid</label>
          <span aria-labelledby="geoid">{props.selectedItem.geoidName ?? 'Ingen geoid'}</span>
        </InfoItem>
      </InfoRow>

      {(hasCamerasArtifact || !isOrthoProcess) && (
        <>
          <h5>Tilläggspaket</h5>
          <InfoRow>
            <InfoItem>
              <label id="gcp-correction">GCP-justering beställd</label>
              <span aria-labelledby="gcp-correction">
                {props.selectedItem.gcpAdjustmentRequest ? 'Ja' : 'Nej'}
              </span>
            </InfoItem>

            {!isOrthoProcess && (
              <>
                <InfoItem>
                  <label id="terrain-model">Markmodell</label>
                  <span aria-labelledby="terrain-model">
                    {props.selectedItem.terrainModel ? 'Ja' : 'Nej'}
                  </span>
                </InfoItem>

                <InfoItem>
                  <label id="filtered-pointcloud">Export av utglesat punktmoln</label>
                  <span aria-labelledby="filtered-pointcloud">
                    {props.selectedItem.filteredPointCloud?.format ?? 'Nej'}
                  </span>
                </InfoItem>
              </>
            )}
          </InfoRow>
        </>
      )}
    </Component>
  );
};

const Component = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const InfoItem = styled.div`
  width: 30%;

  label {
    font-weight: 400;
    font-size: 0.9em;
    display: block;
    margin-bottom: 0.2em;
  }

  span {
    font-weight: 300;
    display: block;
    font-weight: 300;
    font-size: 0.9em;
  }
`;

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export { GeodataDetails };
