import styled, { css } from 'styled-components';

export const CheckBoxContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  padding: 0 0.6em;

  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }

      &:active {
        background-color: rgba(0, 0, 0, 0.1);
      }
    `}
`;
