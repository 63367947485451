import { useFloatingTree, useListItem, useMergeRefs } from '@floating-ui/react';
import * as React from 'react';
import styled from 'styled-components';

import { isDefined } from '../../../../js/utils/variables';
import { nameof } from '../../../utils/nameof';
import { CheckBox } from '../../check-box/check-box';
import { Stack } from '../../stack/stack';
import { MenuContext } from '../context/menu-context';
import { MenuItemButton } from './styles';

type Props = {
  disabled?: () => boolean | undefined;
  hotkey?: string;
  label: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>;
  toggled?: () => boolean | undefined;
};

const MenuItem = React.forwardRef<HTMLButtonElement, Props>((props, forwardedRef) => {
  const checkBoxRef = React.useRef<HTMLInputElement>(null);
  const menu = React.useContext(MenuContext);
  const item = useListItem({ label: props.label });
  const tree = useFloatingTree();
  const isActive = item.index === menu.activeIndex;

  return (
    <MenuItemButton
      {...props}
      disabled={props.disabled?.()}
      ref={useMergeRefs([item.ref, forwardedRef])}
      role="menuitem"
      tabIndex={isActive ? 0 : -1}
      type="button"
      {...menu.getItemProps({
        async onClick(event: React.MouseEvent<HTMLButtonElement>) {
          await props.onClick?.(event);

          if (!isDefined(props.toggled)) {
            tree?.events.emit('click');
          }
        },
        onFocus(event: React.FocusEvent<HTMLButtonElement>) {
          menu.setHasFocusInside(true);
        },
        onContextMenu(event) {
          event.preventDefault();
          event.stopPropagation();
        },
      })}
    >
      {isDefined(props.toggled?.()) && (
        <CheckBoxContainer>
          <CheckBox checked={props.toggled?.()} disabled={props.disabled?.()} ref={checkBoxRef} />
        </CheckBoxContainer>
      )}
      <Stack direction="row" flex={1} spacing={2}>
        <Label>{props.label}</Label>
        {props.hotkey && <HotKey aria-hidden={true}>{props.hotkey}</HotKey>}
      </Stack>
    </MenuItemButton>
  );
});

MenuItem.displayName = nameof({ MenuItem });

const HotKey = styled.span`
  font-size: 0.9em;
  opacity: 0.5;
  margin-right: 1em;
`;

const CheckBoxContainerWidth = 20;
const CheckBoxContainer = styled.div`
  width: ${CheckBoxContainerWidth}px;
  pointer-events: none;
`;

const Label = styled.span`
  flex: 1;

  // This prevents label to be rendered above arrow of child menu items.
  margin-right: 2em;
`;

export { CheckBoxContainerWidth, MenuItem };
