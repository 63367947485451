import { t } from 'i18next';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { OrthographicCamera, Vector3 } from 'three';

import { GeodataStore } from '../../../../js/stores/geodata-store';
import { isDefined } from '../../../../js/utils/variables';
import { useObject3d } from '../../../hooks/three/use-object-3d';
import { useOrbitControls } from '../../../hooks/three/use-orbit-controls';
import { useDialog } from '../../../hooks/use-dialog';
import { reset } from '../../../utils/styled-reset';
import { Button } from '../../button/button';
import { Dialog } from '../../dialog/dialog';
import { Stack } from '../../stack/stack';
import { GeodataConfiguration } from '../geodata-configuration/geodata-configuration';
import { sfmViewerLayering } from './layering';
import { SfmViewerRightMenu } from './sfm-viewer-right-menu';
import { useCursor } from './use-cursor';
import { useSfmRenderingContext } from './use-sfm-rendering-context';
import { useSparseReconstruction } from './use-sparse-reconstruction';

/**
 * SfM viewer (Structure from motion).
 *
 * Works as an interface towards the SfM step in photogrammetry which is an intermediate state
 * where the camera poses and sparse reconstruction can be refined before moving on to dense
 * reconstruction.
 */
export const SfmViewer = () => {
  const webGlContainer = React.useRef<HTMLDivElement>(null);
  const css3dContainer = React.useRef<HTMLDivElement>(null);
  const settingsDialog = useDialog();

  const { webGlScene, css2dScene, camera, setCameraType } = useSfmRenderingContext(
    webGlContainer,
    css3dContainer,
  );

  const isOrthoView = camera instanceof OrthographicCamera;

  const { sparsePointCloud, gcp, cameras } = useSparseReconstruction(GeodataStore.instance.geodata);
  const [showSparsePoints, setShowSparsePoints] = useState(true);

  useObject3d(webGlScene, sparsePointCloud);
  useObject3d(webGlScene, gcp?.points);
  useObject3d(css2dScene, gcp?.labels);
  useObject3d(webGlScene, cameras?.cameras);
  useObject3d(webGlScene, cameras?.points);

  const controls = useOrbitControls(
    css3dContainer,
    camera,
    cameras?.points.geometry.boundingSphere?.center,
  );

  useCursor(css3dContainer, css2dScene, webGlScene, camera, cameras?.points, gcp?.points);

  return (
    <Component>
      <StyledStack direction="row" spacing={0}>
        <Viewer>
          <WebGlCanvasContainer ref={webGlContainer} />
          <Css3dCanvasContainer ref={css3dContainer} />

          <QuickButtonsContainer direction="row" spacing={0.2}>
            {isDefined(camera) && isDefined(controls) && isDefined(cameras?.points) && (
              <Button
                color="secondary"
                leftIcon={{ icon: ['fal', 'camera'] }}
                variant="contained"
                onClick={() => {
                  controls.target.copy(cameras.points.geometry.boundingSphere!.center);
                  camera.position.copy(controls.target.clone().add(new Vector3(0, 0, 100)));
                  controls.update();
                }}
              >
                {t('aligner.center', { ns: 'cloudProcessing' })}
              </Button>
            )}

            <Button
              color="secondary"
              leftIcon={{ icon: isOrthoView ? ['fad', 'image'] : ['fad', 'cube'] }}
              variant="contained"
              onClick={() => {
                setCameraType(isOrthoView ? 'perspective' : 'orthographic');
              }}
            >
              {t('aligner.view', { ns: 'cloudProcessing' })}
            </Button>

            {isDefined(sparsePointCloud) && (
              <Button
                color="secondary"
                leftIcon={{ icon: showSparsePoints ? ['fad', 'eye'] : ['fad', 'eye-slash'] }}
                title={t('aligner.previewInfo', { ns: 'cloudProcessing' })}
                variant="contained"
                onClick={() => {
                  setShowSparsePoints((oldState) => {
                    sparsePointCloud.visible = !oldState;
                    return !oldState;
                  });
                }}
              >
                {t('aligner.preview', { ns: 'cloudProcessing' })}
              </Button>
            )}

            <Button
              color="secondary"
              leftIcon={{ icon: ['fad', 'cog'] }}
              variant="contained"
              onClick={() => {
                settingsDialog.show();
              }}
            >
              {t('settings.title', { ns: 'cloudProcessing' })}
            </Button>
          </QuickButtonsContainer>
        </Viewer>

        <SfmViewerRightMenu />
      </StyledStack>
      {settingsDialog.render(
        <SettingsDialog dialog={settingsDialog} geodataId={GeodataStore.instance.geodata.id} />,
      )}
    </Component>
  );
};

const SettingsDialog = (props: { geodataId: string; dialog: ReturnType<typeof useDialog> }) => {
  return (
    <Dialog closeIcon={true} maxHeight="expand" maxWidth={'90%'} onClose={props.dialog.hide}>
      {{
        header: t('settings.title', { ns: 'cloudProcessing' }),
        content: <GeodataConfiguration geodataId={props.geodataId} onSuccess={props.dialog.hide} />,
        footer: {
          right: (
            <>
              <Button variant="text" onClick={props.dialog.hide}>
                {t('close', { ns: 'common' })}
              </Button>
            </>
          ),
        },
      }}
    </Dialog>
  );
};

const StyledStack = styled(Stack)`
  position: relative;
  height: 100%;
`;

const QuickButtonsContainer = styled(Stack)`
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  z-index: ${sfmViewerLayering.menu};
`;

const Component = styled.div`
  ${reset}
  position: relative;
  width: 100%;
  height: 100%;
`;

const Viewer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const WebGlCanvasContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: ${sfmViewerLayering.webGlCanvasContainer};
`;

const Css3dCanvasContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: ${sfmViewerLayering.css3dCanvasContainer};
`;

SfmViewer.propTypes = {
  wrapWithLanguageProvider: PropTypes.any,
};
