import { TFunction } from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import _ from 'underscore';

import {
  BasicCompanyInformation,
  LicenseInformation,
} from '../../../../typings/api/skymap/rest/v0/company';
import { UserStore } from '../../../js/stores/user-store';
import { ThemeManager } from '../../../js/theming/theme-manager';
import { sessionStorageUtils } from '../../../js/utils/session-storage-utils';
import { isDefined } from '../../../js/utils/variables';
import { useRouter } from '../../hooks/use-router';
import { Button } from '../button/button';
import { DataTable, DataTableColumns, DataTableSortType } from '../data-table/data-table';
import { OverlayLoader } from '../overlay-loader/overlay-loader';
import { useGetCompaniesQuery } from '../projects-views/use-get-companies-query';
import { Stack } from '../stack/stack';
import { TextBox } from '../text-box/text-box';

const pageSize = 15;

export interface CompanyTableView extends Record<string, any> {
  id: string;
  accountName: string;
  licenseText: string;
  name: string;
  adminCount: number;
  projectCount: number;
}

function getColumns(t: TFunction): DataTableColumns<CompanyTableView> {
  if (UserStore.instance.isOrganizationAdmin()) {
    return {
      name: {
        sortableBy: { type: DataTableSortType.STRING },
        title: t('name', { ns: 'common' }),
        unfilterable: true,
      },
      accountName: {
        sortableBy: { type: DataTableSortType.STRING },
        title: t('columns.account', { ns: 'companies' }),
        unfilterable: true,
      },
      licenseText: {
        sortableBy: { type: DataTableSortType.STRING },
        title: t('columns.licenses', { ns: 'companies' }),
        unfilterable: true,
      },
      adminCount: {
        sortableBy: { type: DataTableSortType.NUMBER },
        title: t('columns.adminCount', { ns: 'companies' }),
        shrink: true,
        unfilterable: true,
      },
      projectCount: {
        sortableBy: { type: DataTableSortType.NUMBER },
        title: t('columns.projectCount', { ns: 'companies' }),
        shrink: true,
        unfilterable: true,
      },
    };
  }

  return {
    name: {
      sortableBy: { type: DataTableSortType.STRING },
      title: t('name', { ns: 'common' }),
      unfilterable: true,
    },
    licenseText: {
      sortableBy: { type: DataTableSortType.STRING },
      title: t('columns.licenses', { ns: 'companies' }),
      unfilterable: true,
    },
    adminCount: {
      sortableBy: { type: DataTableSortType.NUMBER },
      title: t('columns.adminCount', { ns: 'companies' }),
      shrink: true,
      unfilterable: true,
    },
    projectCount: {
      sortableBy: { type: DataTableSortType.NUMBER },
      title: t('columns.projectCount', { ns: 'companies' }),
      shrink: true,
      unfilterable: true,
    },
  };
}

/**
 * Returns a few temporary rows to show underneath the overlay loader while the data is fetching.
 * Should ideally be the same amount of rows
 */
function getPlaceholderItems(): CompanyTableView[] {
  return _.times(pageSize, () => {
    return {
      id: '',
      name: '---',
      accountName: '---',
      licenseText: '',
      adminCount: 0,
      projectCount: 0,
    };
  });
}

function summarizeLicenses(licenses: LicenseInformation[], t: TFunction): string {
  const hasActiveDesignLicense = licenses.some((x) => x.type === 'DESIGN' && x.active > 0);

  // Note: Don't display any information about demo if there are active licenses.
  const licensesToDisplay = hasActiveDesignLicense
    ? licenses.filter((x) => x.type !== 'DESIGN_DEMO')
    : licenses;

  const getLicenseCountText = (license: LicenseInformation): string => {
    if (license.active + license.expired === 0) {
      return '';
    }

    const parts = [];
    if (license.active > 0) {
      parts.push(t('licenses.activeCount', { ns: 'companies', count: license.active }));
    }

    if (license.expired > 0) {
      parts.push(t('licenses.expiredCount', { ns: 'companies', count: license.expired }));
    }

    return `(${parts.join(', ')})`;
  };

  return licensesToDisplay
    .map((x) => `${x.type} ${getLicenseCountText(x)}`)
    .join(', ')
    .trim();
}

const createTableView = (companies: BasicCompanyInformation[], t: TFunction): CompanyTableView[] =>
  companies.map((company) => ({
    name: company.name,
    id: company.id,
    accountName: company.accountName ?? '',
    licenseText: isDefined(company.licenses) ? summarizeLicenses(company.licenses, t) : '',
    adminCount: company.adminCount,
    projectCount: company.projectCount,
  }));

const searchColumns = ['name', 'licenseText', 'accountName'] as const;

function filterItems(items: CompanyTableView[], searchText: string) {
  return items.filter((item) => {
    const searchValue = searchText.trim().toLowerCase();
    return searchColumns.some((x) => (item[x] ?? '').toLowerCase().includes(searchValue));
  });
}

/**
 * Hook that preserves text in search during the user session.
 */
function useCompanySerachFilter() {
  const [searchText, setSearchText] = useState(
    sessionStorageUtils.getUserItemOrDefault('companyListSearchText', ''),
  );

  useEffect(() => {
    sessionStorageUtils.setUserItem('companyListSearchText', searchText);
  }, [searchText]);

  return { searchText, setSearchText };
}

export const CompanyList = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { redirectToAngularState } = useRouter();

  const { companies, buildErrorList, hasError, isLoading } = useGetCompaniesQuery();
  const [tableView, setTableView] = useState<CompanyTableView[]>(getPlaceholderItems());
  const { searchText, setSearchText } = useCompanySerachFilter();
  const showRegisterCompanyButton = !ThemeManager.instance.isMobaTheme();

  const columns = useRef(getColumns(t));

  useEffect(() => {
    if (isDefined(companies)) {
      const items = filterItems(createTableView(companies, t), searchText);
      setTableView(items);
    }
  }, [companies, searchText, theme, t]);

  if (hasError()) {
    return (
      <>
        {buildErrorList({
          infoBoxProps: {
            width: 'fit-content',
          },
        })}
      </>
    );
  }

  return (
    <>
      <Title>{t('title', { ns: 'companies' })}</Title>
      <OverlayLoader visible={isLoading}>
        <Stack alignItems="baseline" direction="row" justifyContent="space-between" spacing={0.5}>
          <SearchFilterContainer direction="row" spacing={0.5}>
            <TextBox
              placeholder={t('search', { ns: 'common' })}
              value={searchText}
              width={250}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </SearchFilterContainer>

          {showRegisterCompanyButton && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => redirectToAngularState('sky.companyregister')}
            >
              {t('registerNewCompany', { ns: 'companies' })}
            </Button>
          )}
        </Stack>

        <DataTable
          columns={columns.current}
          fixedLayout={false}
          items={tableView}
          pageSize={pageSize}
          tableStyle={{
            rowCell: {
              fontSize: '14px',
            },
            headerCell: {
              fontSize: '14px',
            },
          }}
          onRowClicked={async (x) => {
            await redirectToAngularState('sky.companies.list.company.overview', {
              companyId: x.id,
            });
          }}
        />
      </OverlayLoader>
    </>
  );
};

// Styling is made to match the other pages in angular.
const Title = styled.h1`
  font-size: 19px;
  font-weight: bold;
  text-transform: uppercase;
`;

const SearchFilterContainer = styled(Stack)`
  width: 450px;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

CompanyList.propTypes = {
  wrapWithLanguageProvider: PropTypes.any,
};
