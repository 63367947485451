import React from 'react';
import styled from 'styled-components';

import { reset } from '../../utils/styled-reset';

type Props = {
  items: string[];
};

const OrderedList = (props: Props) => {
  return props.items.length > 0 ? (
    <Component>
      {props.items.map((x) => (
        <li key={x}>{x}</li>
      ))}
    </Component>
  ) : null;
};

const Component = styled.ol`
  ${reset}
  list-style: auto;
  margin-left: 1em;
`;

export { OrderedList };
