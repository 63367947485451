import React from 'react';
import styled from 'styled-components';

import { reset } from '../../../utils/styled-reset';
import { Icon } from '../../icon/icon';

type Props = {
  children: React.ReactNode;
  width: number;
};

const CollapsableContainer = (props: Props) => {
  const [collapsed, setCollapsed] = React.useState(false);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Component collapsed={collapsed} width={props.width}>
      <OverflowWrapper>
        <FixedWidthContainer width={props.width}>{props.children}</FixedWidthContainer>
      </OverflowWrapper>
      <ToggleThumb onClick={toggle}>
        <Icon
          color="white"
          fixedWidth={true}
          icon={['fas', collapsed ? 'chevron-right' : 'chevron-left']}
        />
      </ToggleThumb>
    </Component>
  );
};

const Component = styled.div<{ collapsed: boolean; width: number }>`
  ${reset}

  position: relative;
  width: ${(props) => (props.collapsed ? '0' : `${props.width}px`)};
  transition: width 0.5s ease-in-out;
`;

const OverflowWrapper = styled.div`
  height: 100%;
  overflow: hidden;
`;

const FixedWidthContainer = styled.div<{ width: number }>`
  height: 100%;
  width: ${(props) => props.width}px;
`;

const ToggleThumb = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(100%, -50%);

  padding: 3em 0.1em;
  font-size: 0.8em;
  background-color: #5f6a71;
  cursor: pointer;
  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 0.5em;

  // Border color same as background color in Skyview.
  border-top: 1px solid #ccddf0;
  border-right: 1px solid #ccddf0;
  border-bottom: 1px solid #ccddf0;

  &:hover {
    background-color: #4b555a;
  }
`;

export { CollapsableContainer, Component as CollapsableContainerStyled };
