import React, { useContext } from 'react';
import styled from 'styled-components';

import {
  AttachDongleToCompanyParameters,
  RemoveDongleFromCompanyParameters,
} from '../../../../typings/api/skymap/rest/v1/company';
import { SkyMapAxiosServiceFactory } from '../../../js/services/axios/skymap-axios-service-factory';
import { RequestParamsToApiPaths } from '../../../js/utils/typescript-utils';
import { isDefined } from '../../../js/utils/variables';
import { useErrorHandling } from '../../hooks/use-error-handling';
import { Button } from '../button/button';
import { Dialog } from '../dialog/dialog';
import { Icon } from '../icon/icon';
import { InfoBox } from '../info-box/info-box';
import { Content } from '../integrations/providers/style';
import { LabelledContainer } from '../labelled-container/labelled-container';
import { Select } from '../select/select';
import { Stack } from '../stack/stack';
import DongleViewStateContext from './state/dongle-view-state';

interface Props {
  onClose: () => void;
  openDongleStatusChangeConfirmationDialog: () => void;
  openDeleteConfirmationDialog: () => void;
}

type ApiRequestPath = RequestParamsToApiPaths<
  AttachDongleToCompanyParameters | RemoveDongleFromCompanyParameters
>;

export const EditDongleDialog = ({
  onClose,
  openDongleStatusChangeConfirmationDialog,
  openDeleteConfirmationDialog,
}: Props) => {
  const { companies, currentDongle } = useContext(DongleViewStateContext);

  if (!currentDongle) {
    throw new Error(
      "'EditDongleDialog' requires a dongle to exist in the 'DongleViewStateContext'",
    );
  }

  const { handleError, buildErrorList, includeErrorPaths } = useErrorHandling<ApiRequestPath>();

  const companiesOptions = companies
    ? companies.map((company) => ({ id: company.id, name: company.name }))
    : [];
  const [companyId, setCompanyId] = React.useState<string | undefined>();
  const [isUpdating, setIsUpdating] = React.useState(false);

  React.useEffect(() => {
    const currentlyAttachedCompany = companies?.find(
      (company) => company.id === currentDongle.companyId ?? null,
    );
    currentlyAttachedCompany?.id && setCompanyId(currentlyAttachedCompany.id);
  }, [companies, currentDongle]);

  const closeCurrentAndOpenStatusConfirmationDialog = () => {
    onClose();
    openDongleStatusChangeConfirmationDialog();
  };

  const closeCurrentAndOpenDeleteConfirmationDialog = () => {
    onClose();
    openDeleteConfirmationDialog();
  };

  const attachDongleToCompany = async () => {
    const companyService = SkyMapAxiosServiceFactory.instance.createCompanyServiceV1();
    if (companyId) {
      return await companyService.attachDongleToCompany({
        path: {
          companyId: companyId,
        },
        body: {
          dongleId: currentDongle.id,
        },
      });
    } else if (!companyId && currentDongle.companyId) {
      await companyService.removeDongleFromCompany({
        path: {
          companyId: currentDongle.companyId,
        },
        body: {
          dongleId: currentDongle.id,
        },
      });
    }
  };

  const saveSettings = async () => {
    setIsUpdating(true);
    if (currentDongle) {
      try {
        await attachDongleToCompany();
        onClose();
      } catch (error) {
        handleError(error);
        setIsUpdating(false);
      }
    }
  };

  return (
    <Dialog
      closeIcon={!isUpdating}
      closeOnDimmerClick={!isUpdating}
      position="center"
      useGoogleTranslate={true}
      width={500}
      onClose={onClose}
    >
      {{
        header: 'INSTÄLLNINGAR',
        content: (
          <Content>
            <Stack direction="column" spacing={1.5}>
              {buildErrorList({
                filter: includeErrorPaths(['body.dongleId']),
                infoBoxProps: {
                  bottomMargin: true,
                },
              })}
              {isDefined(currentDongle) && (
                <InfoBox color="yellow">
                  Ange ett företag att koppla till enheten med ID{' '}
                  <strong>{currentDongle.id}</strong>.
                </InfoBox>
              )}
              <LabelledContainer text={'Företag'}>
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                  spacing={0.5}
                >
                  <Dropdown
                    options={companiesOptions}
                    placeholderText="-- Välj företag --"
                    value={companyId}
                    onChange={(e) => setCompanyId(e.target.value)}
                  />
                  <Icon
                    icon={['fass', 'times-circle']}
                    onClick={() => {
                      setCompanyId(undefined);
                    }}
                  />
                </Stack>
              </LabelledContainer>
            </Stack>
          </Content>
        ),
        footer: {
          left: (
            <>
              <Button
                color={'secondary'}
                variant={'contained'}
                onClick={closeCurrentAndOpenStatusConfirmationDialog}
              >
                {currentDongle.status === 'ACTIVE' ? 'Inaktivera enhet' : 'Aktivera enhet'}
              </Button>
              <Button
                color={'error'}
                variant={'contained'}
                onClick={closeCurrentAndOpenDeleteConfirmationDialog}
              >
                {'Ta bort enhet'}
              </Button>
            </>
          ),
          right: (
            <>
              <Button
                color={'primary'}
                loading={isUpdating}
                variant={'contained'}
                onClick={saveSettings}
              >
                Spara
              </Button>
              <Button
                color={'secondary'}
                disabled={isUpdating}
                variant={'contained'}
                onClick={onClose}
              >
                Avbryt
              </Button>
            </>
          ),
        },
      }}
    </Dialog>
  );
};

const Dropdown = styled(Select)`
  border: 1px solid ${(props) => props.theme.color.gray.medium};
`;
