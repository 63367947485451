import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import PubSub from 'pubsub-js';
import React, { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  InviteCompanyAdminErrorResponse,
  InviteCompanyAdminParameters,
} from '../../../../typings/api/skymap/rest/v0/company';
import { SkyMapAxiosServiceFactory } from '../../../js/services/axios/skymap-axios-service-factory';
import { CompanyStore } from '../../../js/stores/company.store';
import { ThemeManager } from '../../../js/theming/theme-manager';
import { emailRegex } from '../../../js/utils/text/validation';
import { RequestParamsToApiPaths } from '../../../js/utils/typescript-utils';
import { useDialog } from '../../hooks/use-dialog';
import { useErrorHandling } from '../../hooks/use-error-handling';
import { reset } from '../../utils/styled-reset';
import { Button } from '../button/button';
import { CheckBox } from '../check-box/check-box';
import { Dialog } from '../dialog/dialog';
import { InfoBox } from '../info-box/info-box';
import { LabelledContainer } from '../labelled-container/labelled-container';
import { Stack } from '../stack/stack';
import { TextBox } from '../text-box/text-box';

type Form = {
  email: string;
  isDemoPeriod: boolean;
};

const InviteCompanyAdminDialog = (props: { dialog: ReturnType<typeof useDialog> }) => {
  const company = useRef(CompanyStore.instance.company);
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);

  const {
    handleError,
    getErrorForPath,
    clearErrors,
    buildErrorList,
    hasError,
    setApiError,
    excludeErrorPaths,
  } = useErrorHandling<RequestParamsToApiPaths<InviteCompanyAdminParameters>>();
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm<Form>({
    defaultValues: {
      email: '',
      isDemoPeriod: false,
    },
  });

  const invite = useMutation({
    mutationFn: async (form: Form) =>
      SkyMapAxiosServiceFactory.instance.createCompanyServiceV0().inviteCompanyAdmin({
        body: {
          companyId: company.current.id,
          email: form.email,
          isDemo: form.isDemoPeriod,
        },
      }),
    retry: false,
    networkMode: 'always',
    onError(error) {
      // TODO: V0 error code needs to be handled outside of handleError.
      // TODO: When endpoint is moved to V1, handleError(error) is the only line needed in this
      // TODO: function.
      if (isAxiosError(error)) {
        switch ((error.response?.data as InviteCompanyAdminErrorResponse).code) {
          case 'user_is_already_company_admin':
            setApiError('body.companyId', 'Användaren är redan administratör för detta företag.');
            break;
          default:
            handleError(error);
            break;
        }
      } else {
        handleError(error);
      }
      mapApiErrorsToFormFieldErrors();
    },
    onSuccess() {
      reset();
      PubSub.publish('notify', { code: 'succ0315' });
      props.dialog.hide();
    },
    onMutate() {
      clearErrors();
    },
  });

  const mapApiErrorsToFormFieldErrors = () => {
    setError('email', {
      type: 'custom',
      message: getErrorForPath('body.email'),
    });
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  };

  return (
    <Dialog onClose={props.dialog.hide}>
      {{
        header: t('companyAdmins.inviteCompanyAdmin.title', { ns: 'companies' }),
        content: (
          <Component>
            <Stack spacing={2}>
              <span>
                <Trans
                  components={{
                    bold: <strong />,
                  }}
                  i18nKey="companyAdmins.inviteCompanyAdmin.enterEmailInfo"
                  ns="companies"
                  values={{
                    companyName: company.current.name,
                  }}
                />
              </span>

              <form ref={formRef} onSubmit={handleSubmit((form) => invite.mutate(form))}>
                <Stack spacing={1}>
                  <LabelledContainer text={t('email', { ns: 'common' })}>
                    {(formElementId) => (
                      <TextBox
                        {...register('email', {
                          required: true,
                          pattern: {
                            value: emailRegex,
                            message: t('companyAdmins.inviteCompanyAdmin.emailAddressError', {
                              ns: 'companies',
                            }),
                          },
                        })}
                        disabled={invite.isPending}
                        errorMessage={errors.email?.message}
                        id={formElementId()}
                      />
                    )}
                  </LabelledContainer>

                  {ThemeManager.instance.isMobaTheme() && (
                    <Controller
                      control={control}
                      disabled={invite.isPending}
                      name="isDemoPeriod"
                      render={({ field }) => (
                        <CheckBox {...field}>
                          {t('companyAdmins.inviteCompanyAdmin.demoPeriodCheckbox', {
                            ns: 'companies',
                          })}
                        </CheckBox>
                      )}
                    />
                  )}

                  {/**
                   * TODO: Modify buildErrorList to not render anything if no network errors exist
                   * TODO: instead of using hasError here.
                   */}
                  {hasError(excludeErrorPaths(['body.email', 'body.companyId'])) &&
                    buildErrorList({
                      filter: excludeErrorPaths(['body.email', 'body.companyId']),
                    })}

                  {ThemeManager.instance.isMobaTheme() && (
                    <InfoBox color="yellow" leftIcon={{ icon: ['fad', 'info'] }} topMargin={true}>
                      <Trans
                        components={{
                          bold: <strong />,
                          br: <br />,
                        }}
                        i18nKey="companyAdmins.inviteCompanyAdmin.terms"
                        ns="companies"
                        values={{
                          productName: ThemeManager.instance.getProductName(),
                        }}
                      />
                    </InfoBox>
                  )}
                </Stack>
              </form>
            </Stack>
          </Component>
        ),
        footer: {
          right: (
            <>
              <Button
                alignSelf="flex-end"
                color="primary"
                disabled={invite.isPending}
                loading={invite.isPending}
                variant="contained"
                onClick={handleSubmitClick}
              >
                {t('companyAdmins.inviteCompanyAdmin.sendInvite', { ns: 'companies' })}
              </Button>

              <Button variant="text" onClick={props.dialog.hide}>
                {t('close', { ns: 'common' })}
              </Button>
            </>
          ),
        },
      }}
    </Dialog>
  );
};

const Component = styled.div`
  ${reset}

  font-size: 14px;

  strong {
    font-weight: 500;
  }
`;

export { InviteCompanyAdminDialog };
