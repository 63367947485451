import { FloatingPortal } from '@floating-ui/react';
import { autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/react-dom';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { isDefined } from '../../../js/utils/variables';
import { useButtonHotkey } from '../../hooks/use-button-hotkey';
import { Button } from '../button/button';
import { NoTranslateDiv } from '../no-translate/no-translate';
import { Stack } from '../stack/stack';
import { TextBox } from '../text-box/text-box';

type Suggestion = {
  name: string;
  id: string;
};

function filterSuggestions<T extends Suggestion>(
  name: string,
  suggestions: T[],
  excludedSuggestions?: T[],
) {
  return suggestions
    .filter(
      (suggestion) =>
        suggestion.name.toLowerCase().includes(name) &&
        !excludedSuggestions?.some((x) => x.id === suggestion.id),
    )
    .slice(0, 5);
}

interface Props<T extends Suggestion> {
  suggestions: T[];
  onValidateInput?: (input: string) => boolean;
  onAddInput?: (input: string) => void;
  excludedSuggestions?: T[];
  onSuggestionSelected: (suggestion: T) => void;
  clearOnSelection?: boolean;
  width?: number;
  placeholder?: string;
}

const DropdownSuggestion = <T extends Suggestion>({
  suggestions,
  excludedSuggestions,
  onSuggestionSelected,
  onValidateInput,
  onAddInput,
  clearOnSelection = false,
  width = 200,
  placeholder = '',
}: Props<T>) => {
  const [inputValue, setInputValue] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState<T[]>([]);
  const [isFocused, setIsFocused] = useState(false);
  const [isInputValid, setIsInputValid] = useState(false);
  const confirmButtonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();

  const { refs, strategy, update, floatingStyles } = useFloating({
    middleware: [offset(0), flip(), shift()],
    placement: 'bottom-start',
  });

  useEffect(() => {
    if (isFocused && refs.reference.current && refs.floating.current) {
      return autoUpdate(refs.reference.current, refs.floating.current, update);
    }
  }, [isFocused, refs.reference, refs.floating, update]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    setIsFocused(true);
    setFilteredSuggestions(
      filterSuggestions(value.toLowerCase(), suggestions, excludedSuggestions),
    );
  };

  useEffect(() => {
    setIsInputValid(onValidateInput?.(inputValue) ?? true);
  }, [inputValue, onValidateInput]);

  const handleSuggestionClick = (suggestion: T) => {
    onSuggestionSelected(suggestion);
    if (clearOnSelection) {
      setInputValue('');
    } else {
      setInputValue(suggestion.name);
    }
    setFilteredSuggestions([]);
    setIsFocused(false);
  };

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (
        !isFocused &&
        isDefined(refs.reference.current) &&
        (refs.reference.current as HTMLElement).contains(event.target as Node)
      ) {
        setIsFocused(true);
        setFilteredSuggestions(
          filterSuggestions(inputValue.toLowerCase(), suggestions, excludedSuggestions),
        );
      }

      if (
        isDefined(refs.floating.current) &&
        !refs.floating.current.contains(event.target as Node)
      ) {
        setIsFocused(false);
        setFilteredSuggestions([]);
      }
    },
    [isFocused, suggestions, excludedSuggestions, inputValue, refs.reference, refs.floating],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClick]);

  const handleOnInputAdded = () => {
    if (!isDefined(onAddInput) || !isInputValid) {
      return;
    }

    onAddInput(inputValue);
    setInputValue('');
    setIsFocused(false);
    setFilteredSuggestions([]);
  };

  useButtonHotkey(confirmButtonRef, 'Enter');

  return (
    <Component width={width}>
      <StackContainer direction="row" spacing={0.5} width={width}>
        <TextBox
          placeholder={placeholder}
          ref={refs.setReference}
          value={inputValue}
          onChange={handleInputChange}
        />
        {isDefined(onAddInput) && (
          <Button
            color="secondary"
            disabled={!isInputValid}
            leftIcon={{ icon: ['fad', 'plus'] }}
            noWrap={true}
            ref={confirmButtonRef}
            variant="contained"
            width="fit-content"
            onClick={handleOnInputAdded}
          >
            {t('add', { ns: 'common' })}
          </Button>
        )}
      </StackContainer>
      {isFocused && filteredSuggestions.length > 0 && (
        <FloatingPortal>
          <NoTranslateDiv>
            <SuggestionsList
              ref={refs.setFloating}
              style={{ ...floatingStyles, position: strategy }}
            >
              {filteredSuggestions.map((suggestion, index) => (
                <SuggestionItem
                  key={index}
                  width={width}
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion.name}
                </SuggestionItem>
              ))}
            </SuggestionsList>
          </NoTranslateDiv>
        </FloatingPortal>
      )}
    </Component>
  );
};

const StackContainer = styled(Stack)<{ width: number }>`
  width: ${({ width }) => width}px;
`;

const Component = styled.div<{ width: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width}px;
`;

const SuggestionsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #888;
  overflow-y: hidden;
  z-index: 1000;
`;

const SuggestionItem = styled.li<{ width: number }>`
  padding: 0.5em;
  width: ${({ width }) => width}px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.color.brand.light};
  }
`;

export { DropdownSuggestion, Props as DropdownSuggestionProps, Suggestion };
