import { t } from 'i18next';
import React from 'react';
import styled, { css } from 'styled-components';

import { CoordinateSystemModel } from '../../../../../../../typings/api/skymap/rest/v0/.common';
import { CoordinateManager } from '../../../../../../js/viewer/coordinate-manager';
import { Coordinates, Point3d } from '../../../../../../js/viewer/utilities/math-helpers';
import { Stack } from '../../../../stack/stack';
import { PositionInput } from '../../components/position-input';

type Props = {
  coordinateSystem: CoordinateSystemModel;
  defaultValue?: Point3d;
  leftBorderColor?: 'blue' | 'green';
  showHeight?: boolean;
  disabled?: boolean;
  onValidate: (valid: boolean) => void;
  onValueChanged: (point: Coordinates) => void;
};

type State = 'idle' | 'error' | 'valid';

export const CoordinateValidationInput = (props: Props) => {
  const [state, setState] = React.useState<State>('idle');
  const [value, setValue] = React.useState<Point3d>();

  const onValueChanged = React.useCallback(
    (value: Point3d) => {
      const coordinates: Coordinates = {
        northing: value.x,
        easting: value.y,
        height: value.z,
      };

      props.onValueChanged(coordinates);

      const isValid = CoordinateManager.getInstance().isValidCoordinate(coordinates, {
        proj4: props.coordinateSystem.proj4!,
      });

      props.onValidate(isValid);
      setState(isValid ? 'valid' : 'error');
    },
    [props],
  );

  React.useEffect(() => {
    if (!value && props.defaultValue) {
      setValue(props.defaultValue);
      onValueChanged(props.defaultValue);
    }
  }, [props, value, onValueChanged]);

  return (
    <Stack spacing={0.5}>
      <Section leftBorderColor={state === 'error' ? 'red' : props.leftBorderColor} spacing={0.5}>
        <Span>{props.coordinateSystem.name}</Span>
        <Span>
          {state === 'error'
            ? t('coordinateOutOfBounds', { ns: 'skyviewScale' })
            : t('validCoordinate', { ns: 'skyviewScale' })}
        </Span>
        <PositionInput
          defaultValue={props.defaultValue}
          disabled={props.disabled}
          display={props.showHeight ? ['N', 'E', 'H'] : ['N', 'E']}
          onValueChanged={onValueChanged}
        />
      </Section>
    </Stack>
  );
};

const Section = styled(Stack)<{ leftBorderColor?: Props['leftBorderColor'] | 'red' }>`
  ${(props) =>
    props.leftBorderColor &&
    css`
      padding: 0.4em;
      border-left: 0.32em solid
        ${props.leftBorderColor === 'blue'
          ? props.theme.color.blue
          : props.leftBorderColor === 'green'
            ? props.theme.color.green
            : props.leftBorderColor === 'red'
              ? props.theme.color.red
              : 'transparent'};
    `}
`;

const Span = styled.span`
  color: ${(props) => props.theme.color.gray.dark};
  font-size: 0.9em;
  font-style: italic;
`;
