import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { SubscriptionTopic } from '../../../../../../js/messaging/pubsub';
import { isDefined } from '../../../../../../js/utils/variables';
import { AssetManager } from '../../../../../../js/viewer/asset-manager';
import { Design } from '../../../../../../js/viewer/elements/design';
import { DesignTool, DesignToolDialogState } from '../../../../../../js/viewer/tools/design-tool';
import { saveTool } from '../../../../../../js/viewer/tools/utils/context-menu/design-tool/commands/general';
import { useSubscribe } from '../../../../../hooks/use-subscribe';
import { Button } from '../../../../button/button';
import { Icon } from '../../../../icon/icon';
import { InfoBox } from '../../../../info-box/info-box';
import { Stack } from '../../../../stack/stack';
import { SkyviewContext } from '../../../skyview-state';
import { CollapsableSectionGroup } from '../../components/collapsable-section-group';
import { FocusIcon } from '../../components/focus-icon';
import { RightPanelHeader } from '../../components/right-menu-header';
import { RightPanelContent } from '../../components/styles';
import { DesignToolDialogs as DesignToolDialogs } from './dialogs/design-tool-dialogs';
import { AttributesSection } from './sections/attributes-section';
import { DesignFeatureSection } from './sections/design-feature-section';
import { DesignModeSection } from './sections/design-mode-section';
import { OptionsSection } from './sections/options-section';
import { PositionSection } from './sections/position-section';
import { SnapSection } from './sections/snap-section';
import { ToolStartedPanel } from './tool-started-panel';

type Props = { tool: DesignTool };

export type SectionExpandState = {
  designMode: boolean;
  snapMode: boolean;
  placeMode: boolean;
  features: boolean;
  configureAttributes: boolean;
  options: boolean;
};

const DesignToolMenu = (props: Props) => {
  const { t } = useTranslation();
  const { selectedAsset } = React.useContext(SkyviewContext);
  const [focusedAssetId, setFocusedAssetId] = React.useState<string>();
  const [sectionExpandState, setSectionExpandState] = React.useState<SectionExpandState>({
    designMode: true,
    snapMode: true,
    features: false,
    configureAttributes: false,
    options: false,
    placeMode: false,
  });

  const assetFocusChangedMessage = useSubscribe(SubscriptionTopic.SkyviewAssetFocusChanged);

  React.useEffect(() => {
    setFocusedAssetId(assetFocusChangedMessage?.assetUuid);
  }, [assetFocusChangedMessage]);

  const [editedGeometry, setEditedGeometry] = React.useState<Design>(
    selectedAsset instanceof Design ? selectedAsset : props.tool.getDefaultGeometry(),
  );

  const [isEdited, setIsEdited] = React.useState(false);
  const [saved, setSaved] = React.useState(true);
  const [state, setState] = React.useState(DesignToolDialogState.None);

  const disableSaveIcon = !isEdited || saved;
  const isSaving = state === DesignToolDialogState.Saving;

  const initToolWithGeometry = React.useCallback(
    async (geometry: Design) => {
      props.tool.reset();
      await props.tool.onInitiate(geometry);
      setEditedGeometry(geometry);
    },
    [props.tool],
  );

  useSubscribe(SubscriptionTopic.DesignToolMenuStateChanged, (newState) => {
    setSectionExpandState({
      ...sectionExpandState,
      ...newState,
    });
  });

  React.useEffect(() => {
    props.tool.onStateChanged = () => {
      setState(props.tool.uiState);
    };

    props.tool.onEdited = () => {
      setIsEdited(true);
      props.tool.saved = false;
      setSaved(false);
    };

    props.tool.onSaved = () => {
      props.tool.saved = true;
      setSaved(true);
    };

    void initToolWithGeometry(editedGeometry);

    return () => {
      props.tool.onStateChanged = undefined;
      props.tool.onEdited = undefined;
      props.tool.onSaved = undefined;
    };
  }, [props.tool, editedGeometry, initToolWithGeometry]);

  React.useEffect(() => {
    if (!isEdited && selectedAsset instanceof Design) {
      void initToolWithGeometry(selectedAsset);
    }
  }, [props.tool, selectedAsset, isEdited, initToolWithGeometry]);

  if (!isDefined(editedGeometry) || (!isEdited && !(selectedAsset instanceof Design))) {
    return (
      <Component spacing={0}>
        <RightPanelHeader
          icon={['fal', 'pen-ruler']}
          name={t('initial.title', { ns: 'skyviewDesign' })}
        />
        <ToolStartedPanel setIsEdited={setIsEdited} />
      </Component>
    );
  }

  return (
    <Component spacing={0}>
      <RightPanelHeader icon={['fal', 'pen-ruler']} name={editedGeometry.name}>
        {isEdited && !saved && !isSaving && (
          <Icon
            color="#fac801"
            fixedWidth={true}
            icon={['fas', 'exclamation-circle']}
            title={t('initial.changesNotSaved', { ns: 'skyviewDesign' })}
          />
        )}

        <Icon
          disabled={disableSaveIcon}
          fixedWidth={true}
          icon={isSaving ? ['far', 'spinner'] : ['fal', 'cloud-upload']}
          mouseDownStyle={{ opacity: 0.5 }}
          spin={isSaving}
          title={t('save', { ns: 'common' })}
          onClick={() => saveTool(props.tool)}
          onHoverStyle={{ icon: isSaving ? ['far', 'spinner'] : ['fad', 'cloud-upload'] }}
        />

        <FocusIcon asset={editedGeometry} focusedAssetId={focusedAssetId} />

        <Icon
          disabled={!saved}
          fixedWidth={true}
          icon={['fal', 'plus']}
          mouseDownStyle={{ opacity: 0.5 }}
          title={
            saved
              ? t('initial.newIcon', { ns: 'skyviewDesign' })
              : t('initial.newIconNotSaved', { ns: 'skyviewDesign' })
          }
          onClick={async () => {
            AssetManager.instance.select(undefined);
            await initToolWithGeometry(props.tool.getDefaultGeometry());
            setIsEdited(true);
          }}
          onHoverStyle={{ icon: ['fas', 'plus'] }}
        />
      </RightPanelHeader>

      <RightPanelContent useTopPadding={!isEdited}>
        {!isEdited && (
          <InfoBox color="yellow" leftIcon={{ icon: 'info' }}>
            <Stack alignItems={'center'} direction="row" spacing={0.5}>
              <NotSavedLabel>{t('initial.editingExisting', { ns: 'skyviewDesign' })}</NotSavedLabel>
              <Button
                color="secondary"
                variant="contained"
                onClick={async () => {
                  AssetManager.instance.select(undefined);
                  await initToolWithGeometry(props.tool.getDefaultGeometry());
                  setIsEdited(true);
                }}
              >
                {t('initial.createNew', { ns: 'skyviewDesign' })}
              </Button>
            </Stack>
          </InfoBox>
        )}

        <CollapsableSectionGroup>
          <DesignModeSection expanded={sectionExpandState.designMode} tool={props.tool} />
          <SnapSection expanded={sectionExpandState.snapMode} tool={props.tool} />
          <PositionSection expanded={sectionExpandState.placeMode} tool={props.tool} />
          <DesignFeatureSection expanded={sectionExpandState.features} tool={props.tool} />
          <AttributesSection expanded={sectionExpandState.configureAttributes} tool={props.tool} />
          <OptionsSection expanded={sectionExpandState.options} tool={props.tool} />
        </CollapsableSectionGroup>
      </RightPanelContent>

      <DesignToolDialogs state={state} tool={props.tool} />
    </Component>
  );
};

const Component = styled(Stack)`
  height: 100%;
`;

const NotSavedLabel = styled.label`
  font-size: 0.95em;
  flex-grow: 1;
`;

export { DesignToolMenu };
