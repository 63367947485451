import React from 'react';
import styled from 'styled-components';

import { ProjectModel } from '../../../../../typings/api/skymap/rest/v0/.common';
import { GeodataModel } from '../../../../../typings/api/skymap/rest/v1/.common';
import { SkyMapAxiosServiceFactory } from '../../../../js/services/axios/skymap-axios-service-factory';
import { useNonce } from '../../../hooks/use-nonce';
import { Center } from '../../../styles/center';
import { reset } from '../../../utils/styled-reset';
import { Button } from '../../button/button';
import { GeodataListContent } from './geodata-list-content';
import { GeodataListItem } from './geodata-list-item';
import { NoGeodata } from './no-geodata';

interface Props {
  project: ProjectModel;
}

export const translatedProcessType = (processType: string) => {
  switch (processType) {
    case 'FULL_HIGH_QUALITY':
      return 'PREMIUM';
    case 'FULL':
      return 'STANDARD';
    case 'ORTHO_ONLY':
      return 'ORTOFOTO';
    case 'ORTHO_TIF':
      return 'ORTOFOTO FRÅN TIF';
    case 'POINTCLOUD_ONLY':
      return 'PUNKTMOLN';
  }
};

enum Status {
  Pending,
  Error,
  Success,
}

const sortByUploadedDesc = (a: GeodataModel, b: GeodataModel) =>
  new Date(a.uploaded) < new Date(b.uploaded) ? 1 : -1;

const GeodataList = (props: Props) => {
  const [status, setStatus] = React.useState(Status.Pending);
  const [refreshNonce, setRefreshNonce] = useNonce();
  const [items, setItems] = React.useState<GeodataModel[]>([]);
  const [selectedItem, setSelectedItem] = React.useState<GeodataModel>();
  const [fetchGeodataInterval, setFetchGeodataInterval] = React.useState<number>();

  const loadGeodata = React.useCallback(async () => {
    const response = await SkyMapAxiosServiceFactory.instance
      .createProjectServiceV1()
      .getProjectGeodata({
        path: { projectId: props.project.id },
      });

    const newItems = [...response.data];
    setItems(newItems.sort(sortByUploadedDesc));
  }, [props.project.id]);

  const load = React.useCallback(async () => {
    try {
      await loadGeodata();
      setStatus(Status.Success);
    } catch (err) {
      console.error(err);
      setStatus(Status.Error);
    }
  }, [loadGeodata]);

  const initPolling = React.useCallback(() => {
    setStatus(Status.Pending);
    void load();
    setFetchGeodataInterval(
      setInterval(() => {
        void load();
      }, 20000) as unknown as number,
    );
  }, [load]);

  const onDeleteDialogClosed = (wasDeleted: boolean) => {
    if (wasDeleted) {
      const newItems = [...items];
      newItems.splice(newItems.indexOf(selectedItem!), 1);

      setItems(newItems.sort(sortByUploadedDesc));
      setSelectedItem(undefined);
    }
  };

  React.useEffect(() => {
    if (!fetchGeodataInterval) {
      void initPolling();
    }
  }, [refreshNonce, fetchGeodataInterval, initPolling]);

  React.useEffect(() => {
    if (selectedItem) {
      setSelectedItem(items.find((x) => x.id === selectedItem.id));
    }
  }, [items, selectedItem]);

  if (status === Status.Error) {
    return (
      <StyledError>
        <h3>Oväntat fel</h3>
        <span>Molnprocesseringarna för projektet kunde inte läsas in.</span>
        <Button color="primary" variant="contained" onClick={() => setRefreshNonce()}>
          Försök igen
        </Button>
      </StyledError>
    );
  }

  if (status === Status.Pending) {
    return <Center>Ett ögonblick...</Center>;
  }

  if (items.length === 0) {
    return <NoGeodata />;
  }

  return (
    <Component>
      <LeftSide>
        <ul>
          {items.map((item) => (
            <GeodataListItem
              item={item}
              key={item.id}
              selected={selectedItem?.id === item.id}
              onClick={() => setSelectedItem(item)}
              onDeleteDialogClosed={onDeleteDialogClosed}
            />
          ))}
        </ul>
      </LeftSide>

      <RightSide>
        <GeodataListContent items={items} selectedItem={selectedItem} />
      </RightSide>
    </Component>
  );
};

const Component = styled.div`
  ${reset}

  display: flex;
  flex: 1;
  gap: 2em;
  width: 100%;
  justify-content: space-evenly;
`;

const LeftSide = styled.div`
  width: 20em;
  height: 35em;
  overflow-y: auto;
`;

const RightSide = styled.div`
  flex: 1;
  height: 35em;
  overflow-y: auto;
`;

const StyledError = styled.div`
  ${reset}
  font-size: 14px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
`;

export { GeodataList };
