import React from 'react';
import styled from 'styled-components';

import { roundToDecimals } from '../../../js/utils/math/number';
import { clamp } from '../../../js/viewer/utilities/math-helpers';

interface Props {
  autoFocus?: boolean;
  disabled?: boolean;
  max: number;
  min: number;
  onChange: (value: number) => void;
  step: number;
  value: number;
}

const Slider = (props: Props) => {
  const value = roundToDecimals(clamp(props.value, props.min, props.max), 2);

  return (
    <Component>
      <Input
        autoFocus={props.autoFocus}
        disabled={props.disabled}
        max={props.max}
        min={props.min}
        step={props.step}
        type="range"
        value={value}
        onChange={(event) => props.onChange(event.currentTarget.valueAsNumber)}
        onInput={(event) => props.onChange(event.currentTarget.valueAsNumber)}
      />
    </Component>
  );
};

const Component = styled.div`
  --thumb-size: 1em;
  --slider-height: 0.25em;

  display: flex;
  align-items: center;
  font-size: 12px;
  height: var(--thumb-size);
`;

const Input = styled.input`
  appearance: none;
  font-size: inherit;
  width: 100%;
  height: var(--slider-height);
  background: #ccc;
  outline: none;

  &:disabled {
    opacity: 0.5;
  }

  &::-webkit-slider-thumb {
    appearance: none;
    position: relative;
    width: var(--thumb-size);
    aspect-ratio: 1/1;
    border-radius: 50%;
    background: #888;
  }

  &:not(:disabled)::-webkit-slider-thumb {
    &:hover {
      background: #555;
      cursor: pointer;
    }

    &:active {
      box-shadow: 0 0 2px 3px #0002;
    }
  }
`;

export { Slider, Props as SliderProps };
