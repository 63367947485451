import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FileModel, FileVersionModel } from '../../../../../../typings/api/skymap/rest/v1/.common';
import { SkyMapAxiosServiceFactory } from '../../../../../js/services/axios/skymap-axios-service-factory';
import { getHttpStatusCode } from '../../../../api/error-handling';
import { Button } from '../../../button/button';
import { Dialog } from '../../../dialog/dialog';
import { InfoBox } from '../../../info-box/info-box';

interface Props {
  file: FileModel;
  fileVersion: FileVersionModel;
  fileVersionNumber: number;
  onClose: (deleted: boolean) => void;
}

enum DialogStatus {
  Idle,
  InProgress,
  Error,
}
const DeleteFileVersionDialog = (props: Props) => {
  const { t } = useTranslation();
  const [dialogStatus, setDialogStatus] = React.useState(DialogStatus.Idle);

  const deleteButtonClick = async () => {
    try {
      setDialogStatus(DialogStatus.InProgress);
      await SkyMapAxiosServiceFactory.instance.createFileVersionServiceV1().deleteFileVersion({
        path: {
          fileVersionId: props.fileVersion.id,
        },
      });
      props.onClose(true);
    } catch (err) {
      if (getHttpStatusCode(err) === 404) {
        props.onClose(true);
      } else {
        setDialogStatus(DialogStatus.Error);
      }
    }
  };

  const closeButtonClicked = () => {
    props.onClose(false);
  };

  const deleteButtonDisabled = () => dialogStatus === DialogStatus.InProgress;

  const dialogClosable = () => dialogStatus !== DialogStatus.InProgress;

  const renderContent = () => {
    switch (dialogStatus) {
      case DialogStatus.Idle:
        return (
          <>
            <Text>
              <Trans
                components={{ bold: <strong /> }}
                i18nKey="fileManager.deleteFileVersionDialog.idle"
                ns="components"
                values={{
                  fileName: props.file.name,
                  fileVersionNumber: props.fileVersionNumber,
                }}
              />
            </Text>

            <InfoBox color="red" leftIcon={{ icon: ['fad', 'exclamation'] }} topMargin={true}>
              {t('fileManager.deleteFileVersionDialog.idleWarning', { ns: 'components' })}
            </InfoBox>
          </>
        );

      case DialogStatus.InProgress:
        return (
          <InfoBox color="yellow" leftIcon={{ icon: 'spinner', spin: true }} topMargin={true}>
            <Trans
              components={{ bold: <strong /> }}
              i18nKey="fileManager.deleteFileVersionDialog.inProgress"
              ns="components"
              values={{
                fileVersionNumber: props.fileVersionNumber,
              }}
            />
          </InfoBox>
        );

      case DialogStatus.Error:
        return (
          <InfoBox color="red" topMargin={true}>
            <Trans
              components={{ br: <br />, bold: <strong /> }}
              i18nKey="fileManager.deleteFileVersionDialog.error"
              ns="components"
            />
          </InfoBox>
        );
    }
  };

  return (
    <Component
      closeIcon={dialogClosable()}
      closeOnDimmerClick={false}
      width={350}
      onClose={closeButtonClicked}
    >
      {{
        header: t('fileManager.deleteFileVersionDialog.title', { ns: 'components' }),
        content: renderContent(),
        footer: {
          right: (
            <>
              <Button
                color="error"
                disabled={deleteButtonDisabled()}
                variant="contained"
                onClick={deleteButtonClick}
              >
                {t('delete', { ns: 'common' })}
              </Button>

              <Button disabled={!dialogClosable()} variant="text" onClick={closeButtonClicked}>
                {t('close', { ns: 'common' })}
              </Button>
            </>
          ),
        },
      }}
    </Component>
  );
};

const Component = styled(Dialog)`
  strong {
    font-weight: 600;
  }
`;

const Text = styled.span<{ topMargin?: boolean; bottomMargin?: boolean }>`
  display: block;

  margin-top: ${(props) => (props.topMargin ? '0.5em' : undefined)};
  margin-bottom: ${(props) => (props.bottomMargin ? '0.5em' : undefined)};
`;

export { DeleteFileVersionDialog };
